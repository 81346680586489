import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { firstCharToUpperCase } from '../../../../utils';
import moment from 'moment';
import { RootState } from '../../../../redux/reducers';

import { columnType } from '../types';
import { fetchedEventTypeDb } from '../../../../types';
import { APPLICATION_HOME } from '../../../../routes';

const dateRender = (value: Date): ReactElement => {
    return <span>{moment(value).format('LL')}</span>;
};
// const dispatch = useDispatch();

const eventNameRender = (value: string): ReactElement => {
    return <span>{firstCharToUpperCase(value)}</span>;
};

const ActionRender = (_: string, record: fetchedEventTypeDb): ReactElement => {
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;
    return (
        <Link
            className="view-btn-dark"
            to={`${APPLICATION_HOME}${record._id}/view`}
            style={{
                backgroundColor: subdomainUIstyling[0]
                    ? subdomainUIstyling[0].primaryColor
                    : '#1bca8f',
            }}
        >
            applicants
        </Link>
    );
};

const TableColumn: columnType = [
    {
        title: 'Created Date',
        dataIndex: 'startDate',
        render: dateRender,
    },
    {
        title: 'Contest',
        dataIndex: 'eventTitle',
        render: eventNameRender,
    },

    // {
    //     title: 'Category',
    //     dataIndex: 'eventType',
    //     render: eventTypeRender,
    // },

    {
        title: 'End Date',
        dataIndex: 'closingDate',
        render: dateRender,
    },
    {
        title: '',
        key: 'action',
        dataIndex: 'action',
        render: ActionRender,
    },
];

export default TableColumn;
