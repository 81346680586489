export const CONTESTANTS_FETCH_INIT = 'CONTESTANTS_FETCH_INIT';
export const CONTESTANTS_FETCH_SUCCESS = 'CONTESTANTS_FETCH_SUCCESS';
export const CONTESTANTS_FETCH_FAILED = 'CONTESTANTS_FETCH_FAILED';

export const FETCH_CONTESTANT_DETAILS_INIT = 'FETCH_CONTESTANT_DETAILS_INIT';
export const FETCH_CONTESTANT_DETAILS_SUCCESS =
    'FETCH_CONTESTANT_DETAILS_SUCCESS';
export const FETCH_CONTESTANT_DETAILS_FAILED =
    'FETCH_CONTESTANT_DETAILS_FAILED';

export const CLEAR_CONTESTANT_DATAS = 'CLEAR_CONTESTANT_DATAS';

export const DELETE_CONTESTANT = 'DELETE_CONTESTANT';
export const DELETE_CONTESTANT_SUCCESS = 'DELETE_CONTESTANT_SUCCESS';
export const DELETE_CONTESTANT_FAILED = 'DELETE_CONTESTANT_FAILED';

export const ADD_CONTESTANT_INIT = 'ADD_CONTESTANT_INIT';
export const ADD_CONTESTANT_SUCCESS = 'ADD_CONTESTANT_SUCCESS';
export const ADD_CONTESTANT_FAILED = 'ADD_CONTESTANT_FAILED';

export const EVICT_CONTESTANT_INIT = 'EVICT_CONTESTANT_INIT';
export const EVICT_CONTESTANT_SUCCESS = 'EVICT_CONTESTANT_SUCCESS';
export const EVICT_CONTESTANT_FAILED = 'EVICT_CONTESTANT_FAILED';

export const ADD_CONTESTANT_IMAGE_INIT = 'ADD_CONTESTANT_IMAGE_INIT';
export const ADD_CONTESTANT_IMAGE_FAILED = 'ADD_CONTESTANT_IMAGE_FAILED';
export const ADD_CONTESTANT_IMAGE_SUCCESS = 'ADD_CONTESTANT_IMAGE_SUCCESS';

export const FETCH_CONTESTANT_IMAGES_INIT = 'FETCH_CONTESTANT_IMAGES_INIT';
export const FETCH_CONTESTANT_IMAGES_SUCCESS =
    'FETCH_CONTESTANT_IMAGES_SUCCESS';
export const FETCH_CONTESTANT_IMAGES_FAILED = 'FETCH_CONTESTANT_IMAGES_FAILED';

export const ADD_CONTESTANT_VIDEO_INIT = 'ADD_CONTESTANT_VIDEO_INIT';
export const ADD_CONTESTANT_VIDEO_SUCCESS = 'ADD_CONTESTANT_VIDEO_SUCCESS';
export const ADD_CONTESTANT_VIDEO_FAILED = 'ADD_CONTESTANT_VIDEO_FAILED';
export const ADD_CONTESTANT_VIDEO_BY_USER_SUCCESS =
    'ADD_CONTESTANT_VIDEO_BY_USER_SUCCESS';

export const FETCH_CONTESTANT_VIDEOS_INIT = 'FETCH_CONTESTANT_VIDEOS_INIT';
export const FETCH_CONTESTANT_VIDEOS_SUCCESS =
    'FETCH_CONTESTANT_VIDEOS_SUCCESS';
export const FETCH_CONTESTANT_VIDEOS_FAILED = 'FETCH_CONTESTANT_VIDEOS_FAILED';

export const EVICTION_CONTESTANT_DETAILS = 'EVICTION_CONTESTANT_DETAILS';
