export const INITIALIZE_PAYMENT = 'INITIALIZE_PAYMENT';
export const INITIALIZE_PAYMENT_SUCCESSFUL = 'INITIALIZE_PAYMENT_SUCCESSFUL';
export const INITIALIZE_PAYMENT_FAILED = 'INITIALIZE_PAYMENT_FAILED';

export const VERIFY_PAYMENT_INIT = 'VERIFY_PAYMENT_INIT';
export const VERIFY_PAYMENT_SUCCESSFUL = 'VERIFY_PAYMENT_SUCCESSFUL';
export const VERIFY_PAYMENT_FAILED = 'VERIFY_PAYMENT_FAILED';

export const CLEAR_PAYMENT_STATE = 'CLEAR_PAYMENT_STATE';

export const PAYMENT_CANCELLED = 'PAYMENT_CANCELLED';
