import React, { FC, useState, ReactElement, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    clearError,
    clearMessage,
} from '../../../../../redux/actions/common.action';
import { updateSubdomainUI } from '../../../../../redux/actions/subdomainUI.action';
import { RootState } from '../../../../../redux/reducers';
import { DASHBOARD } from '../../../../../routes';
import { accountSetup } from '../../../../../types';
import { FormContext } from './FormContext';

const FormProvider: FC<ReactNode> = ({ children }): ReactElement => {
    const dispatch = useDispatch();
    const { replace } = useHistory();
    const [formInputs, setFormInputs] = useState<accountSetup>({
        logo: '',
        bannerImage: '',
        authImage: '',
        primaryColor: '#13BC78',
        secondaryColor: '#16c280',
        aboutCompany: '',
    });
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );

    const { subdomainUIstyling, feedbackMsg, loadingUI } = stylingState;

    useEffect(() => {
        if (feedbackMsg && !loadingUI) {
            replace(DASHBOARD);
        }
        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
        };
    }, [feedbackMsg, loadingUI, replace, dispatch]);

    const onFormSubmit = async () => {
        dispatch(updateSubdomainUI(subdomainUIstyling[0]._id, formInputs));
    };

    return (
        <FormContext.Provider
            value={{
                setFormInputs,
                formInputs,
                onFormSubmit,
                loadingUI,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export default FormProvider;
