import React, { FC, ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Layout, Menu, Typography } from 'antd';
import { sidebarPropType } from '../types';
import profilePic from '../../../assets/profile.svg';

import { firstCharToUpperCase, isEmpty } from '../../../utils';
import { Scrollbars } from 'react-custom-scrollbars';

import { eventFire } from '../../../utils/fireEventHandler';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';

import './style.scss';

const { Sider } = Layout;
const { Title } = Typography;
const { SubMenu, Item } = Menu;

const Sidebar: FC<sidebarPropType> = ({
    isMobile,
    collapsed,
    toggleSidebar,
    routes,
    companyImage,
    primaryColor,
}): ReactElement => {
    const user = useSelector((state: RootState) => state.auth.usersInfo);

    const {
        location: { pathname },
    } = useHistory();

    const subMenuControl = () => {
        const el = document.querySelector('.ant-menu-submenu-open');
        const clickableEl = document.querySelector('.ant-menu-submenu-open i');

        if (el) {
            //removes class to prevent callback
            el.classList.remove('ant-menu-submenu-open');
            eventFire(clickableEl, 'click');
        }
    };

    const navPath =
        pathname.split('/')[2] === undefined
            ? 'dashboard'
            : pathname.split('/')[2];

    return (
        <Sider
            trigger={false}
            collapsed={collapsed}
            collapsedWidth={isMobile ? 0 : 80}
            className="dashboard-sidebar"
            style={{
                backgroundImage: companyImage && `url(${companyImage})`,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                backgroundBlendMode: 'overlay',
            }}
            width={isMobile ? '60%' : '20%'}
        >
            <Scrollbars className="scrollbar" autoHide>
                {!collapsed && (
                    <div className="siderbar-profile">
                        <div
                            className="siderbar-profile--top"
                            style={{
                                backgroundImage: `${
                                    primaryColor
                                        ? `linear-gradient(to right top, ${primaryColor}, ${primaryColor})`
                                        : 'linear-gradient(to right top,#11b56c,#12ba75,#15c07e,#17c586,#1bca8f)'
                                }`,
                            }}
                        >
                            {/* //  style={{backgroundColor: primaryColor}}> */}
                        </div>

                        <div className="siderbar-image">
                            <img
                                src={
                                    isEmpty(user ? user.imageUrl : '')
                                        ? profilePic
                                        : user?.imageUrl
                                }
                                alt="profile"
                            />
                            <span className="avatar-online"></span>
                        </div>

                        <div className="sidebar-text text-white-50">
                            <Title level={5}>{`${firstCharToUpperCase(
                                user ? user.firstName : '',
                            )} ${firstCharToUpperCase(
                                user ? user.lastName : '',
                            )}`}</Title>
                            <span>
                                {firstCharToUpperCase(user ? user.role : '')}
                            </span>
                        </div>
                    </div>
                )}

                <Menu
                    className={`dashboard-menus ${collapsed && 'no-padding'}`}
                    theme="light"
                    mode="inline"
                    style={{ color: 'red !important' }}
                    defaultOpenKeys={[navPath?.toLowerCase()]}
                    defaultSelectedKeys={[
                        pathname.split('/')[3] === undefined
                            ? navPath
                            : pathname.split('/')[3],
                    ]}
                >
                    {routes &&
                        routes.map(
                            ({ title, icon, path, hasSubRoute, sub }) => {
                                if (hasSubRoute) {
                                    return (
                                        <SubMenu
                                            key={title
                                                .toLocaleLowerCase()
                                                .split(' ')
                                                .join('')}
                                            icon={icon}
                                            title={firstCharToUpperCase(title)}
                                            onTitleClick={subMenuControl}
                                        >
                                            {sub?.map(
                                                ({
                                                    title: subtitle,
                                                    path: subPath,
                                                }) => (
                                                    <Item
                                                        key={subtitle
                                                            .toLocaleLowerCase()
                                                            .split(' ')
                                                            .join('')}
                                                        onClick={
                                                            isMobile
                                                                ? toggleSidebar
                                                                : () => {
                                                                      return;
                                                                  }
                                                        }
                                                    >
                                                        <Link to={subPath}>
                                                            {firstCharToUpperCase(
                                                                subtitle,
                                                            )}
                                                        </Link>
                                                    </Item>
                                                ),
                                            )}
                                        </SubMenu>
                                    );
                                } else {
                                    return (
                                        <Item
                                            key={title
                                                .toLocaleLowerCase()
                                                .split(' ')
                                                .join('')}
                                            className="dashboard-menus__item"
                                            icon={icon}
                                            // style={{ color: 'red' }}
                                            onClick={
                                                isMobile
                                                    ? toggleSidebar
                                                    : () => {
                                                          return;
                                                      }
                                            }
                                        >
                                            <Link to={path}>
                                                {firstCharToUpperCase(title)}
                                            </Link>
                                        </Item>
                                    );
                                }
                            },
                        )}
                </Menu>
            </Scrollbars>
        </Sider>
    );
};

export default Sidebar;
