import { PaymentVerification } from '../../transactionViews/verifyPayment';
import { VerifyApplicationPayment } from '../../transactionViews/verifyApplicationPayment';
import {
    TRANSACTION_VERIFICATION,
    TRANSACTION_APPLICATION_VERIFICATION,
    TRANSACTION_PURCHASE,
    TRANSACTION_VOTE,
    TRANSACTION_HOME,
    TRANSACTION_VOTING_HISTORY,
    ALL_TRANSACTION,
    DISPUTE_TRANSACTION,
} from '../../../routes';
import { PurchaseHome } from '../../transactionViews/purchase';
import { VoteHome } from '../../transactionViews/vote';
import { TransactionHome } from '../../transactionViews/home';
import { EnterpriseVotingHistory } from '../../transactionViews/details';
import { Dispute } from '../../transactionViews/dispute';

const routeTransaction = [
    {
        id: 1,
        title: 'Enterprise transactions',
        exact: true,
        path: TRANSACTION_HOME,
        component: TransactionHome,
    },

    {
        id: 2,
        title: 'purchase',
        exact: true,
        path: TRANSACTION_PURCHASE,
        component: PurchaseHome,
    },

    {
        id: 3,
        title: 'vote',
        exact: true,
        path: TRANSACTION_VOTE,
        component: VoteHome,
    },
    {
        id: 4,
        title: 'VerifyPayment',
        exact: true,
        path: TRANSACTION_VERIFICATION,
        component: PaymentVerification,
    },
    {
        id: 5,
        title: 'VerifyApplicationPayment',
        exact: true,
        path: TRANSACTION_APPLICATION_VERIFICATION,
        component: VerifyApplicationPayment,
    },
    {
        id: 6,
        title: 'All transaction',
        exact: true,
        path: ALL_TRANSACTION,
        component: PurchaseHome,
    },
    {
        id: 8,
        title: 'Enterprise Voting History',
        exact: true,
        path: TRANSACTION_VOTING_HISTORY,
        component: EnterpriseVotingHistory,
    },
    {
        id: 6,
        title: 'Dispute transaction',
        exact: true,
        path: DISPUTE_TRANSACTION,
        component: Dispute,
    },
];

export default routeTransaction;
