import { TagsOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { Navigation } from '../../components/navigation';
import FooterSection from '../landing/FooterSection';

import './styles.scss';

const ScrollLink = Scroll.Link;

const Privacy: FC = (): ReactElement => {
    const scrollTo: { to: string; title: string }[] = [
        {
            to: 'privacy',
            title: 'PRIVACY POLICY AGREEMENT',
        },
        {
            to: 'data',
            title: 'PROTECTION OF USER DATA',
        },
        {
            to: 'credit',
            title: 'CREDIT CARD INFORMATION',
        },
        {
            to: 'vendors',
            title: 'THIRD PARTY VENDORS',
        },
        {
            to: 'alteration',
            title: 'ALTERATION TO PRIVACY POLICY',
        },

        {
            to: 'indemnity',
            title: 'INDEMNITY BY SERVCE PROVIDER',
        },
        {
            to: 'other',
            title: 'OTHER AGREEMENT',
        },
        {
            to: 'acceptance',
            title: 'ACCEPTANCE',
        },
        {
            to: 'information',
            title: 'COMPANY INFORMATION',
        },
    ];
    return (
        <>
            <Row>
                <Col xs={24}>
                    <Navigation />
                </Col>
            </Row>
            <Row className="privacy-header" justify="center" align="middle">
                <Col className="privacy-header__title">Privacy Policy</Col>
            </Row>
            <Row className="privacy-section">
                <Col xs={24} lg={6} className="privacy-section__left">
                    {scrollTo.map(({ to, title }, i) => (
                        <p key={i}>
                            <ScrollLink
                                activeClass="active"
                                to={to}
                                spy={true}
                                smooth={true}
                                offset={-50}
                                duration={500}
                            >
                                {title.toLowerCase()}
                            </ScrollLink>
                        </p>
                    ))}
                </Col>
                <Col xs={24} lg={18} className="privacy-section__right">
                    <div className="privacy-section__right-wrapper">
                        <div
                            id="privacy"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                PRIVACY POLICY AGREEMENT
                            </h2>
                            <div className="privacy-section__right--text">
                                This privacy policy was last modified on{' '}
                                <b>May 31st, 2021</b> 1024 Concepts operates{' '}
                                <Link
                                    to="/"
                                    style={{ textDecoration: 'underline' }}
                                >
                                    www.pageant.africa
                                </Link>
                                This page informs you the User of our policies
                                regarding the collection, use and disclosure of
                                Personal Information we receive from Users of
                                this site. While using our Site, we may ask you
                                to provide us with certain personally
                                identifiable information that can be used to
                                identify and contact you. We only collect data
                                from users only if they voluntarily submit such
                                information to us. Users can always refuse to
                                supply data except it may prevent them from
                                engaging in certain site related activities that
                                we offer. Information collected from our users
                                will NOT be sold, shared or rented out to third
                                parties. Like many site operators, we collect
                                information that your browser sends whenever you
                                visit our Site (‘Log Data’). This Log Data may
                                include information such as the pages of Site
                                that you visit, the time and date of your visit,
                                the time spent on those pages and other
                                statistics. Our Site may use ‘Cookies’ to
                                enhance User experience. The User’s web browser
                                places cookies on their hard drive for record
                                keeping purposes. User may choose not to set
                                their web browser to refuse cookies or to alert
                                the User when cookies are being sent.
                            </div>
                        </div>
                        <div
                            id="data"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                PROTECTION OF USER DATA
                            </h2>
                            <div className="privacy-section__right--text">
                                Using appropriate data collection software,
                                storage and optimum security measures, we
                                protect User-data and our website against
                                unauthorized access, alteration, disclosure or
                                destruction of information. By using a secured
                                communication channel that is encrypted and
                                protected with digital signatures, sensitive and
                                private data between the Site and its Users can
                                occur without fear of tampering and destruction.
                            </div>
                        </div>
                        <div
                            id="credit"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                CREDIT CARD INFORMATION
                            </h2>
                            <div className="privacy-section__right--text">
                                Your security is important to us. We do not
                                store your credit card information. Online
                                payments are passed via a secure socket layer to
                                a payment processor. The payment processor is
                                PCI compliant which ensures that your
                                information is being handled in accordance with
                                industry security standards. In compliance with
                                the Payment Card Industry Data Security Standard
                                (PCI DSS Requirements”), the payment processor
                                implements access control measures, security
                                protocols and standards including the use of
                                encryption and firewall technologies to ensure
                                your card information is safe and secure in
                                their servers, additionally, they implement
                                periodical security updates to ensure that their
                                security infrastructures are in compliance with
                                reasonable industry standards
                            </div>
                        </div>
                        <div
                            id="vendors"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                THIRD PARTY VENDORS
                            </h2>
                            <div className="privacy-section__right--text">
                                Users of our website may discover other content
                                that links service owners/providers and other
                                third parties to Users. We act as a link between
                                customers and service providers. We, however, do
                                not own the services and contests provided by
                                the service owners/providers offered on our
                                website.
                            </div>
                        </div>
                        <div
                            id="alteration"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                ALTERATION TO PRIVACY POLICY
                            </h2>
                            <div className="privacy-section__right--text">
                                1024 Concepts reserves the right to alter this
                                privacy policy at any time. If this occurs, a
                                notification will be posted on the main page of
                                the website, revise the last date of
                                modification of Privacy Policy to reflect new
                                date and inform Users of new changes via email
                                notifications.
                            </div>
                        </div>
                        <div
                            id="indemnity"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                INDEMNITY BY SERVCE PROVIDER
                            </h2>
                            <div className="privacy-section__right--text">
                                The service owner/provider hereby indemnifies
                                1024 Concepts and holds it harmless for any
                                claims instituted against 1024 Concepts by any
                                user in events where a loss is suffered by such
                                user as a result of negligence or misconduct on
                                the part of the service owner/provider. Where
                                1024 Concepts suffers any loss including but not
                                limited to reputational loss as a result of the
                                conduct of service owners/providers failure to
                                comply with legal obligations under relevant
                                consumer protection laws, the service
                                owners/providers indemnify 1024 Concepts fully
                                for such any loss incurred by 1024 Concepts.
                            </div>
                        </div>
                        <div
                            id="other"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                OTHER AGREEMENT
                            </h2>
                            <div className="privacy-section__right--text">
                                • This constitutes the entire agreement between
                                users and 1024 Concepts in respect of the use of
                                the Site and services offered. • You must be 18
                                years or older to access the website and have an
                                account. • Login details must be used by one
                                user. Multiple persons sharing one login detail
                                is NOT permitted. • Violating laws of a region
                                (In this case Nigeria) for illegal and
                                unauthorized use of Pageant Africa is prohibited
                                and not permissible. • Technical support in case
                                of issues and emergencies is provided via email,
                                live chat and phone call to service operators. •
                                Unless with the express permission of 1024
                                Concepts, a user must agree not to reproduce,
                                duplicate, copy, sell, resell or exploit any
                                portion of the website service. • Hosting,
                                posting, uploading or transmitting unsolicited
                                emails, SMS and spam messages from Pageant
                                Africa Website are prohibited and unauthorized.
                            </div>
                        </div>

                        <div
                            id="acceptance"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                ACCEPTANCE
                            </h2>
                            <div className="privacy-section__right--text">
                                By using the Pageant Africa Website, you
                                indicate your acceptance of this policy.
                                Continued use of our website following the
                                posting of changes to this policy will be deemed
                                acceptance of changes effected.
                            </div>
                        </div>
                        <div
                            id="information"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                COMPANY INFORMATION
                            </h2>
                            <div className="privacy-section__right--text">
                                This website is run and operated by 1024
                                Concepts Nigeria Limited, based in Nigeria. It
                                is a private company incorporated in accordance
                                with the laws of the Federal Republic of
                                Nigeria. You can reach 1024 concepts by sending
                                an email to info@1024concepts.com.
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <FooterSection />
        </>
    );
};

export default Privacy;
