import { ENTERPRISE_HOME } from '../../../routes';
import { EnterpriseAccounts } from '../../enterpriseAccount/home';

const routeEnterprise = [
    {
        id: 2,
        title: 'Home',
        exact: true,
        path: ENTERPRISE_HOME,
        component: EnterpriseAccounts,
    },
];

export default routeEnterprise;
