import { Button, Col, message, Row } from 'antd';
// import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import React, { FC, FormEvent, ReactElement, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    clearError,
    clearMessage,
} from '../../../../redux/actions/common.action';
import {
    clearTaskForm,
    editTask,
    updateTask,
} from '../../../../redux/actions/tasks.action';
import { RootState } from '../../../../redux/reducers';
import { TASK_HOME } from '../../../../routes';
import { firstCharToUpperCase } from '../../../../utils';
import { DatePickerCustom } from '../../../datePicker';
import { handlerEvent, Input } from '../../../input';
import LoadingSpinner from '../../../spinners/loadingSpinner';

const EditTask: FC = (): ReactElement => {
    const {
        push,
        location: { search },
    } = useHistory();

    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    const dispatch = useDispatch();

    const taskId = new URLSearchParams(search).get('id');

    const taskState = useSelector((state: RootState) => state.tasks);

    const { loading, error: requestError, feedbackMsg, singleTask } = taskState;

    const [formInputs, setFormInputs] = useState({
        title: '',
        task: '',
        postImage: true,
        postVideo: true,
        startDate: '',
        endDate: '',
    });
    const { title, task } = formInputs;

    useEffect(() => {
        if (taskId) {
            dispatch(editTask(taskId));
        }
    }, [taskId, dispatch]);

    useEffect(() => {
        if (!singleTask) return;
        const {
            postVideo,
            postImage,
            title,
            task,
            startDate,
            endDate,
        } = singleTask;
        setFormInputs({
            postVideo,
            postImage,
            title,
            task,
            startDate,
            endDate,
        });
    }, [singleTask]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }
        if (feedbackMsg) {
            //clear form inputs
            setFormInputs({
                title: '',
                task: '',
                postImage: true,
                postVideo: true,
                startDate: '',
                endDate: '',
            });
            message.success(feedbackMsg);
            push(TASK_HOME);
        }

        //clean up error and message
        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
            dispatch(clearTaskForm());
        };
    }, [requestError, feedbackMsg, dispatch, push]);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInputs({ ...formInputs, [name]: value });
    };

    const ondateChange = (dateStr: string, name: string) => {
        if (dateStr) {
            setFormInputs({
                ...formInputs,
                [name]: moment(dateStr, 'YYYY-MM-DD'),
            });
        } else {
            setFormInputs({ ...formInputs, [name]: moment() });
        }
    };

    // const onCheckedChange = (e: CheckboxChangeEvent) => {
    //     const { checked } = e.target;
    //     const name = e.target.name as string;
    //     setFormInputs({ ...formInputs, [name]: checked });
    // };

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!title || !task || !taskId) {
            return message.error('All fields are required!!!');
        }

        return dispatch(updateTask(taskId, formInputs));
    };
    return (
        <>
            {!loading && (
                <div className="addtask">
                    <form onSubmit={onFormSubmit}>
                        <Row
                            gutter={{ sm: 0, md: 20 }}
                            className="fieldrow first-row"
                        >
                            <Col xs={24}>
                                <Input
                                    name="title"
                                    label={firstCharToUpperCase('title')}
                                    value={formInputs.title}
                                    hasLabel={true}
                                    placeholder="task 1"
                                    onChange={onInputChange}
                                />
                            </Col>
                        </Row>
                        <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                            <Col xs={24} md={12}>
                                <DatePickerCustom
                                    name="startDate"
                                    label={firstCharToUpperCase('startDate')}
                                    hasLabel={true}
                                    value={moment(formInputs.startDate)}
                                    placeholder="enter event startDate"
                                    onChange={(_, dateStr) =>
                                        ondateChange(dateStr, 'startDate')
                                    }
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <DatePickerCustom
                                    name="endDate"
                                    label={firstCharToUpperCase('endDate')}
                                    hasLabel={true}
                                    value={moment(formInputs.endDate)}
                                    placeholder="enter event endDate"
                                    onChange={(_, dateStr) =>
                                        ondateChange(dateStr, 'endDate')
                                    }
                                />
                            </Col>
                        </Row>
                        {/* <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                            <Col xs={24} md={12}>
                                <Checkbox
                                    name="postImage"
                                    checked={formInputs.postImage}
                                    onChange={onCheckedChange}
                                >
                                    Post Image
                                </Checkbox>
                            </Col>
                            <Col xs={24} md={12}>
                                <Checkbox
                                    name="postVideo"
                                    checked={formInputs.postVideo}
                                    onChange={onCheckedChange}
                                >
                                    Post Video
                                </Checkbox>
                            </Col>
                        </Row> */}

                        <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                            <Input
                                name="task"
                                onChange={onInputChange}
                                label={firstCharToUpperCase('task')}
                                hasLabel={true}
                                componentType="TextArea"
                                placeholder="tasks..."
                                rows={4}
                                value={formInputs.task}
                            />
                        </Row>

                        <Row
                            gutter={{ xs: 10, md: 20 }}
                            justify="end"
                            className="fieldrow btn-container"
                        >
                            <Col xs={10} md={5}>
                                <Button onClick={() => push(TASK_HOME)}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={14} md={8}>
                                <Button
                                    loading={loading}
                                    htmlType="submit"
                                    // type="primary"
                                    style={{
                                        background: `${
                                            subdomainUIstyling['0']
                                                ? subdomainUIstyling['0'][
                                                      'primaryColor'
                                                  ]
                                                : '#1bca8f'
                                        }`,
                                        color: 'white',
                                    }}
                                >
                                    update task
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </div>
            )}
            {loading && <LoadingSpinner />}
        </>
    );
};

export default EditTask;
