import { Dispatch } from 'redux';
import { baseInstance as axios } from '../../axios-base';
import { addTaskInputType, updateTaskInputType } from '../../types';
import {
    ADD_TASK_FAILED,
    ADD_TASK_INIT,
    ADD_TASK_SUCCESS,
    CLEAR_TASK_FORM,
    EDITING_TASK_FAILED,
    EDITING_TASK_INIT,
    EDITING_TASK_SUCCESS,
    TASK_FETCH_FAILED,
    TASK_FETCH_INIT,
    TASK_FETCH_SUCCESS,
    UPDATE_TASK_FAILED,
    UPDATE_TASK_INIT,
    UPDATE_TASK_SUCCESS,
} from '../types/tasks.type';

export const fetchtasks = () => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: TASK_FETCH_INIT });
    try {
        const { data } = await axios.get('/tasks');

        dispatch({ type: TASK_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: TASK_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const addtasks = (payload: addTaskInputType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: ADD_TASK_INIT });
    try {
        const { data } = await axios.post('/tasks', payload);

        dispatch({ type: ADD_TASK_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: ADD_TASK_FAILED,
            payload: error.response.data.message,
        });
    }
};
export const editTask = (taskId: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: EDITING_TASK_INIT });
    try {
        const { data } = await axios.get(`/tasks/${taskId}`);

        dispatch({ type: EDITING_TASK_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: EDITING_TASK_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const updateTask = (id: string, payload: updateTaskInputType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: UPDATE_TASK_INIT });
    try {
        const { data } = await axios.patch(`/tasks/${id}`, payload);

        dispatch({ type: UPDATE_TASK_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: UPDATE_TASK_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const clearTaskForm = () => (dispatch: Dispatch) =>
    dispatch({ type: CLEAR_TASK_FORM });
