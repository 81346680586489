import { EVICTION_DETAIL, EVICTION_HOME } from '../../../routes';
import { EvictionDetails } from '../../evictionViews/evictionDetails';
import { EvictionHome } from '../../evictionViews/home';

const routeEviction = [
    {
        id: 2,
        title: 'Home',
        exact: true,
        path: EVICTION_HOME,
        component: EvictionHome,
    },
    {
        id: 3,
        title: 'eviction details',
        exact: true,
        path: EVICTION_DETAIL,
        component: EvictionDetails,
    },
];

export default routeEviction;
