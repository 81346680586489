import { Button, Col, message, Modal, Result, Row } from 'antd';
import React, { ReactElement, FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { USER } from '../../../../../constants';
import {
    clearError,
    clearMessage,
} from '../../../../../redux/actions/common.action';
import { addContestantImage } from '../../../../../redux/actions/image.action';
import { RootState } from '../../../../../redux/reducers';
import { routerParamsType } from '../../../../../types';
import { FileUploader } from '../../../../fileUploader';
import {
    SelectDropdown,
    selectDropDownOptionsType,
} from '../../../../SelectDropdown';
import { modalProps } from '../types';
import { baseInstance as axios } from '../../../../../axios-base';

import './style.scss';

const AddImageModal: FC<modalProps> = ({
    isOpen,
    onActionComplete,
}): ReactElement => {
    const dispatch = useDispatch();
    const {
        location: { search },
    } = useHistory();
    const { id }: routerParamsType = useParams();

    const [url, setUrl] = useState('');

    const [uploaded, setUploaded] = useState(false);
    const [selectedTask, setSelectedTask] = useState<string>('');

    console.log('selectedTask', selectedTask);
    const [eventOptions, setEventOptions] = useState<
        selectDropDownOptionsType[]
    >([]);
    const contestantID = new URLSearchParams(search).get('cid');

    const imagesState = useSelector((state: RootState) => state.images);
    const role = useSelector((state: RootState) => state.auth.usersInfo?.role);

    const { error: requestError, loading, feedbackMsg } = imagesState;
    useEffect(() => {
        if (requestError) {
            onActionComplete();
            return message.error(requestError);
        }

        if (feedbackMsg) {
            if (role === USER) {
                setUploaded(true);
            } else {
                onActionComplete();
            }
            return message.success(feedbackMsg);
        }

        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
        };
        // eslint-disable-next-line
    }, [dispatch, requestError, feedbackMsg]);

    const imageUploadHandler = async () => {
        if (!contestantID) return;

        dispatch(
            addContestantImage({
                contestant: contestantID,
                event: id,
                url: url,
                task: selectedTask,
            }),
        );
    };

    const getUserTasks = async () => {
        const { data } = await axios.post('/tasks/getusertasks', { event: id });
        // console.log('getUserTasks', data);
        const eventOptions = data.map(({ _id, title }) => {
            return { id: _id, name: title.toUpperCase() };
        });
        setEventOptions(eventOptions);
    };
    return (
        <Modal
            onCancel={onActionComplete}
            onOk={onActionComplete}
            visible={isOpen}
            title="Add Contestant Image"
            footer={false}
            width={!uploaded ? 300 : 700}
            className="add-image__modal"
        >
            {!uploaded ? (
                <Row gutter={[20, 20]}>
                    <Col xs={24}>
                        <SelectDropdown
                            label="Select A Task"
                            hasLabel
                            options={
                                eventOptions as selectDropDownOptionsType[]
                            }
                            value={selectedTask}
                            placeholder={
                                selectedTask ? selectedTask : 'select task'
                            }
                            loading={loading}
                            onClick={getUserTasks}
                            onChange={(val) =>
                                setSelectedTask(val ? val.toString() : '')
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <FileUploader
                            label="Upload image"
                            onUploadComplete={(url: string) => setUrl(url)}
                            showUploadList={true}
                            className="addImageModal"
                        />
                    </Col>
                    <Col xs={24}>
                        <Button
                            type="primary"
                            loading={loading}
                            className="add-image--btn"
                            disabled={!url || !selectedTask}
                            onClick={() => imageUploadHandler()}
                        >
                            Add Image
                        </Button>
                    </Col>
                </Row>
            ) : (
                <Result
                    status="success"
                    title="Successfully uploaded image, admin will approve and publish shortly"
                    extra={[
                        <Button
                            type="primary"
                            onClick={onActionComplete}
                            key="ok-btn"
                        >
                            Ok
                        </Button>,
                    ]}
                />
            )}
        </Modal>
    );
};

export default AddImageModal;
