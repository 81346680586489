/* eslint-disable no-console */
import { message } from 'antd';
import moment from 'moment';
import React, { FC, useState, ReactElement, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addtasks } from '../../../../../redux/actions/tasks.action';
import { RootState } from '../../../../../redux/reducers';
import { addTaskInputType } from '../../../../../types';
import { selectDropDownOptionsType } from '../../../../SelectDropdown';
import { FormContext } from './FormContext';

const FormProvider: FC<ReactNode> = ({ children }): ReactElement => {
    const dispatch = useDispatch();
    const [formInputs, setFormInputs] = useState<addTaskInputType>({
        event: '',
        title: '',
        task: '',
        postImage: true,
        postVideo: true,
        startDate: moment(),
        endDate: moment(),
    });
    const [eventOptions, setEventOptions] = useState<
        selectDropDownOptionsType[]
    >([]);
    const contestsState = useSelector((state: RootState) => state.contests);

    const { loading, contestsInfo } = contestsState;

    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    useEffect(() => {
        // filter out contest
        const eventOptions = contestsInfo
            .filter(
                ({ totalTasks }) =>
                    totalTasks < subdomainUIstyling[0].plan.taskLimit,
            )
            .map(({ _id, eventTitle }) => {
                return { id: _id, name: eventTitle.toUpperCase() };
            });
        if (contestsInfo.length > 0 && eventOptions.length === 0) {
            message.info('Task limit exceeded!!!');
        }
        setEventOptions(eventOptions);
    }, [contestsInfo, subdomainUIstyling]);

    const onFormSubmit = async () => {
        //post request
        dispatch(addtasks(formInputs));
    };

    return (
        <FormContext.Provider
            value={{
                setFormInputs,
                formInputs,
                loading,
                eventOptions,
                onFormSubmit,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export default FormProvider;
