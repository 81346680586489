import { CreditCardOutlined } from '@ant-design/icons';
import { FUNDING, PayPalMarks } from '@paypal/react-paypal-js';
import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { paymentType } from '../../../constants/transactionEnum';
import { RootState } from '../../../redux/reducers';
import { selectPaymentProps } from '../types';

const SelectPayment: FC<selectPaymentProps> = ({
    onRadioSelect,
}): ReactElement => {
    const account = useSelector(
        (state: RootState) => state.subdomainStyling.subdomainUIstyling,
    );

    const fundingSources = account[0]?.paymentMethods?.includes(
        paymentType.PAYPAL,
    )
        ? [paymentType.OTHERS, FUNDING.PAYPAL]
        : [paymentType.OTHERS];
    // , FUNDING.PAYPAL

    return (
        <div style={{ display: 'flex', margin: '10px auto' }}>
            {fundingSources.map((fundingSource) => (
                <label className="mark" key={fundingSource}>
                    <input
                        defaultChecked={fundingSource === paymentType.OTHERS}
                        onChange={onRadioSelect}
                        type="radio"
                        name="fundingSource"
                        value={fundingSource}
                    />
                    {FUNDING.PAYPAL === fundingSource ? (
                        <PayPalMarks fundingSource={fundingSource} />
                    ) : (
                        <CreditCardOutlined
                            style={{ fontSize: '35px', marginLeft: '10px' }}
                        />
                    )}
                </label>
            ))}
        </div>
    );
};

export default SelectPayment;
