import { baseInstance as axios } from '../../axios-base';
import { Dispatch } from 'redux';
import {
    ACCEPT_APPLICATION_FAILED,
    ACCEPT_APPLICATION_INIT,
    ACCEPT_APPLICATION_SUCCESS,
    ADD_APPLICATION_FAILED,
    ADD_APPLICATION_INIT,
    ADD_APPLICATION_SUCCESS,
    APPLICATION_FETCH_FAILED,
    APPLICATION_FETCH_INIT,
    APPLICATION_FETCH_SUCCESS,
    REJECT_APPLICATION_FAILED,
    REJECT_APPLICATION_INIT,
    REJECT_APPLICATION_SUCCESS,
} from '../types/application.type';
import { applicationStatus } from '../../constants/applicationStatusEnum';
import { addApplicationType } from '../../types';

export const fetchApplications = (eventId: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: APPLICATION_FETCH_INIT });
    try {
        const { data } = await axios.get(`/application?event=${eventId}`);

        dispatch({ type: APPLICATION_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: APPLICATION_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const rejectApplication = (applicationID: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: REJECT_APPLICATION_INIT });
    try {
        const { data } = await axios.patch(`/application/${applicationID}`, {
            status: applicationStatus.REJECTED,
        });

        dispatch({ type: REJECT_APPLICATION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: REJECT_APPLICATION_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const acceptApplication = (applicationId: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: ACCEPT_APPLICATION_INIT });
    try {
        const { data } = await axios.post(`/application/accept`, {
            applicationId: applicationId,
        });

        dispatch({ type: ACCEPT_APPLICATION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: ACCEPT_APPLICATION_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const createApplication = (payload: addApplicationType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: ADD_APPLICATION_INIT });
    try {
        await axios.post(`/application`, payload);

        dispatch({ type: ADD_APPLICATION_SUCCESS });
    } catch (error) {
        dispatch({
            type: ADD_APPLICATION_FAILED,
            payload: error.response.data.message,
        });
    }
};
