import { CONTEST as BASE_PATH } from './dashboard';

export const CONTEST_HOME = `${BASE_PATH}/`;
export const CONTEST_DETAIL = `${BASE_PATH}/:id`;
export const CONTEST_VIEW = `${BASE_PATH}/:id/view`;
export const CONTEST_VIDEO = `${BASE_PATH}/:id/video`;
export const CONTEST_ADD_CONTESTANT = `${BASE_PATH}/:id/addcontestant`;
export const CONTEST_APPLICATION_CONTESTANT = `${BASE_PATH}/:id/applycontestant`;

export const CREATE_CONTEST = `${BASE_PATH}/create_contest`;
