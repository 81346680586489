import React, { FC, ReactElement, FormEvent } from 'react';
import { Row, Col, Button } from 'antd';
import { Input, handlerEvent } from '../../../input';
import FileUploader from './FileUploader';
import profilePic from '../../../../assets/profile.svg';
import moment from 'moment';
import { firstCharToUpperCase } from '../../../../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

const Profile: FC = (): ReactElement => {
    const user = useSelector((state: RootState) => state.auth.usersInfo);
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;
    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        return { name, value };
    };

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();

        return;
    };

    return (
        <div className="profileDetail box-shadow">
            <form onSubmit={onFormSubmit}>
                <Row justify="center" className="fieldrow">
                    <Col>
                        <img src={profilePic} alt="profile" />
                    </Col>
                </Row>
                <Row justify="center" className="fieldrow ">
                    <Col>
                        {false && (
                            <FileUploader
                                onUploadComplete={(url: string) => alert(url)}
                            />
                        )}
                    </Col>
                </Row>

                <Row gutter={{ sm: 0, md: 20 }} className="fieldrow first-row">
                    <Col xs={24} md={8}>
                        <Input
                            label={firstCharToUpperCase('First Name')}
                            value={user?.firstName}
                            hasLabel={true}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <Input
                            label={firstCharToUpperCase('Last Name')}
                            value={user?.lastName}
                            hasLabel={true}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <Input
                            label={firstCharToUpperCase('Email')}
                            hasLabel={true}
                            value={user?.email}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                    <Col xs={24} md={8}>
                        <Input
                            name="phone"
                            onChange={onInputChange}
                            inputType="number"
                            label={firstCharToUpperCase('Phone Number')}
                            hasLabel={true}
                            value=""
                            disabled={true}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <Input
                            label={firstCharToUpperCase('Gender')}
                            value=""
                            hasLabel={true}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <Input
                            label={firstCharToUpperCase('Date Of Birth')}
                            value={moment().format('LL')}
                            hasLabel={true}
                            disabled={true}
                        />
                    </Col>
                </Row>

                <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                    <Col xs={24} md={16}>
                        <Input
                            name="bio"
                            onChange={onInputChange}
                            label={firstCharToUpperCase('Bio')}
                            hasLabel={true}
                            value=""
                            placeholder="description of yourself"
                            disabled={true}
                        />
                    </Col>
                </Row>

                <Row className="fieldrow btn-container" justify="space-between">
                    <Col></Col>
                    <Col sm={12} md={5}>
                        <Button
                            loading={false}
                            htmlType="submit"
                            className="custom-btn"
                            // type="primary"
                            disabled
                            style={{
                                background: `${
                                    subdomainUIstyling['0']
                                        ? subdomainUIstyling['0'][
                                              'primaryColor'
                                          ]
                                        : 'rgb(19, 188, 120)'
                                }`,
                            }}
                        >
                            Update Profile
                        </Button>
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default Profile;
