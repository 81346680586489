import React, { FC, ReactElement, Fragment, useEffect } from 'react';
import { Row, Col } from 'antd';
import { propsType } from './types';
import { Signup as SignUpForm } from '../../components/signup';
import { Signin as SigninForm } from '../../components/signin';
import { ForgotPassword as ForgotPasswordForm } from '../../components/forgotPassword';
import { Link } from 'react-router-dom';
import { HOME } from '../../routes';
import { HomeOutlined } from '@ant-design/icons';
import { RootState } from '../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../components/spinners/loadingSpinner';
import bg from './img/jjj1.jpg';
import { resetAuthState } from '../../redux/actions/auth.action';

import './style.scss';

const AuthPage: FC<propsType> = ({ history }): ReactElement => {
    const dispatch = useDispatch();
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { loadingUI, subdomainUIstyling } = stylingState;
    const {
        location: { pathname },
    } = history;

    useEffect(() => {
        dispatch(resetAuthState());
    }, [dispatch]);

    if (loadingUI) return <LoadingSpinner />;

    return (
        <div
            className="auth-page"
            style={{
                /**@note do not change conditions for bacground image*/
                backgroundImage:
                    subdomainUIstyling['0'] && subdomainUIstyling['0'].authImage
                        ? `url(${subdomainUIstyling['0']['bannerImage']})`
                        : `url(${bg})`,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                // subdomainUIstyling.length > 0 &&
                // subdomainUIstyling['0']['bannerImage']
                //     ? 'rgba(0, 0, 0, 0.5)'
                //     : 'rgba(255, 255, 255, 0.5)',
                backgroundBlendMode: 'overlay',
            }}
        >
            <div className="auth-page__icon">
                <Link to={HOME}>
                    <HomeOutlined className="auth-page__icon--desktop" />
                    {/* {subdomainUIstyling.length > 0 ? (
                    ) : (
                        <HomeOutlined className="auth-page__icon--desktop-2" />
                    )} */}
                </Link>
            </div>
            <Row
                justify="space-around"
                align="middle"
                className="auth-page-sect"
            >
                {pathname === '/signup' ? (
                    <Col xs={24} md={14} lg={12} xl={8}>
                        <SignUpForm
                            primaryColor={
                                subdomainUIstyling['0'] &&
                                subdomainUIstyling['0']['primaryColor']
                            }
                        />
                    </Col>
                ) : pathname === '/signin' ? (
                    <Fragment>
                        <Col xs={24} md={14} lg={12} xl={8}>
                            <SigninForm
                                primaryColor={
                                    subdomainUIstyling['0'] &&
                                    subdomainUIstyling['0']['primaryColor']
                                }
                            />
                        </Col>
                    </Fragment>
                ) : pathname === '/forgotpassword' ? (
                    <Fragment>
                        <Col xs={24} md={14} lg={12} xl={8}>
                            <ForgotPasswordForm />
                        </Col>
                    </Fragment>
                ) : null}
            </Row>
            {/* <Authbackground /> */}
        </div>
    );
};

export default AuthPage;
