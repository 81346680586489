import React, { ReactElement, FC } from 'react';
import { DatePicker } from 'antd';
import { datePickerProps } from '../types';

const DatePickerCustom: FC<datePickerProps> = ({
    label,
    placeholder,
    hasLabel,
    onChange,
    name,
    value,
    disabled,
}): ReactElement => {
    return (
        <div className="custom-datepicker">
            {hasLabel && <label htmlFor={name}>{label}</label>}
            <DatePicker
                value={value}
                id={name}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    );
};

export default DatePickerCustom;
