import React, { ReactElement, FC } from 'react';
import profilePic from '../../../assets/profile.svg';

const Logo: FC = (): ReactElement => {
    return (
        <div className="image-container">
            <div className="text-guard"></div>
            <div className="image-guard">
                <img
                    src={profilePic}
                    alt="profile"
                    style={{ marginLeft: '10px' }}
                />
            </div>
        </div>
    );
};

export default Logo;
