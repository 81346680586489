import React, {
    ChangeEvent,
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';
import {
    Row,
    Col,
    Input,
    Typography,
    Button,
    message,
    notification,
} from 'antd';
import exchange from '../../../../assets/exchange.svg';
import { handlerEvent } from '../types';
import { TRANSACTION_VERIFICATION } from '../../../../routes';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearPayment,
    initializePayment,
    onVerifyPaypalpayment,
} from '../../../../redux/actions/payment.action';
import { RootState } from '../../../../redux/reducers';
import { clearError } from '../../../../redux/actions/common.action';
import { paymentType } from '../../../../constants/transactionEnum';
import { MIN_PRICE, PRICE } from '../../../../constants/price';
import { SelectPayment } from '../../../selectPayment';
import { PaypalButton } from '../../../paypalButton';
import { FlutterwaveButton } from '../../../flutterwaveButton';
import ReactGA from 'react-ga';

const { Title } = Typography;

const BuyMore: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const payment = useSelector((state: RootState) => state.payment);
    const { authorizationUrl, error: requestError, loading } = payment;

    const [values, setValues] = useState({
        vote: 0,
        amount: 0,
    });
    const [isPaypal, setIsPaypal] = useState(false);

    // const [paymentMethod, setPaymentMethod] = useState<paymentType>(
    //     paymentType.FLUTTERWAVE,
    // );

    // const amountToPay =
    //     values.amount.toString().length > 2
    //         ? Math.floor(values.amount / price) * price
    //         : 0;

    const amountToPay =
        values.amount >= MIN_PRICE
            ? Math.floor(values.amount / PRICE) * PRICE
            : 0;

    const voteToGet = values.vote.toString().length >= 2 ? values.vote : 0;

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        if (loading) {
            message.loading('Connecting payment..');
        }

        return () => {
            dispatch(clearError());
            dispatch(clearPayment());
        };
    }, [requestError, dispatch, loading]);

    useEffect(() => {
        if (authorizationUrl) {
            window.location.href = authorizationUrl;
        }
    }, [authorizationUrl]);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        const numValue = parseInt(value);
        //Highest amount to process
        if (numValue > 10000000) return;

        if (name === 'vote') {
            //Highest vote to process
            if (numValue > 100000) return;

            const newAmount = numValue * PRICE;

            // if (numValue < 10) {
            if (numValue < 1) {
                return setValues({
                    vote: numValue,
                    amount: 0,
                });
            }

            setValues({
                vote: numValue,
                amount: newAmount,
            });
        } else {
            const newVote = Math.floor(numValue / PRICE);
            if (!newVote || numValue < MIN_PRICE) {
                return setValues({
                    vote: 0,
                    amount: numValue,
                });
            }

            setValues({
                vote: newVote,
                amount: numValue,
            });
        }
    };

    const onClearValues = () => {
        setValues({
            vote: 0,
            amount: 0,
        });
    };

    // const onPaymentSelectorHandler = (e: RadioChangeEvent) => {
    //     const { value } = e.target;
    //     setPaymentMethod(value);
    // };

    const onPayHandler = async (paymentMethod) => {
        const callback_url = `${window.location.origin}${TRANSACTION_VERIFICATION}`;
        dispatch(
            initializePayment({
                callback_url,
                amount: amountToPay,
                paymentMethod: paymentMethod,
            }),
        );
        ReactGA.event({
            category: 'BUY VOTES',
            action: 'buying vote from dashboard',
            label: 'Wallet Widget',
        });
    };

    const selectFundingChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsPaypal(event.target.value !== 'OTHERS');
    };

    const onPayPalApproved = (data) => {
        const { purchase_units, status, id } = data;

        dispatch(
            onVerifyPaypalpayment({
                amount: parseInt(purchase_units[0]?.amount?.value, 10),
                transactionID: id,
                status: status,
            }),
        );

        setValues({
            vote: 0,
            amount: 0,
        });
        notification.success({
            message:
                'Votes have been added to your account.You can now vote for your favourite contestants',
        });
    };

    return (
        <>
            <Row className="buymore">
                <Col xs={24} lg={12} className="buymore__calc">
                    <Title className="text-1" level={3}>
                        Get More Votes
                    </Title>
                    <SelectPayment onRadioSelect={selectFundingChange} />
                    <Row gutter={{ xs: 10, md: 20 }} className="field-row">
                        <Col xs={24}>
                            <label htmlFor="naira">($) Dollar</label>
                            <Input
                                id="naira"
                                name="amount"
                                value={
                                    values.amount
                                        ? values.amount.toString()
                                        : ''
                                }
                                onChange={onInputChange}
                            />
                            {!isPaypal ? (
                                <small>
                                    Minimum Amount is {`$`}
                                    {MIN_PRICE}
                                </small>
                            ) : (
                                <small style={{ color: 'red' }}>
                                    Minimum Amount is {`$`}
                                    10
                                </small>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 10, md: 20 }} className="field-row">
                        <Col xs={24}>
                            <label htmlFor="vote">Votes</label>

                            <Input
                                id="vote"
                                name="vote"
                                value={
                                    values.vote ? values.vote.toString() : ''
                                }
                                onChange={onInputChange}
                            />
                            {!isPaypal ? (
                                <small>
                                    Minimum Vote is {MIN_PRICE / PRICE}
                                </small>
                            ) : (
                                <small style={{ color: 'red' }}>
                                    Minimum Vote is 10
                                </small>
                            )}
                        </Col>
                    </Row>
                    <Row className="buymore__calc--display">
                        <Col
                            xs={24}
                            className="buymore__calc--display-section1"
                        >
                            <p>
                                Amount to pay :
                                <span>
                                    {' '}
                                    {`$`}{' '}
                                    {values.amount
                                        ? amountToPay.toLocaleString()
                                        : 0}
                                </span>
                            </p>
                        </Col>
                        <Col
                            xs={24}
                            className="buymore__calc--display-section2"
                        >
                            <p>
                                VOTES YOU WILL GET :
                                <span>
                                    {' '}
                                    {values.vote
                                        ? voteToGet.toLocaleString()
                                        : 0}{' '}
                                    Votes
                                </span>{' '}
                            </p>
                            <span>
                                ( {`$`}
                                {PRICE}/vote){' '}
                            </span>
                        </Col>
                        <Col>
                            <p style={{ color: 'red', marginTop: '5px' }}>
                                Do cast your vote after purchase.
                            </p>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={24}>
                            {isPaypal ? (
                                <PaypalButton
                                    isPublicVote={false}
                                    amount={amountToPay}
                                    visible={amountToPay < 10}
                                    onPaymentApproved={onPayPalApproved}
                                    initData={{
                                        amount: amountToPay,
                                    }}
                                />
                            ) : (
                                <FlutterwaveButton
                                    handleFlutterPay={() =>
                                        onPayHandler(paymentType.FLUTTERWAVE)
                                    }
                                />
                            )}
                        </Col>
                    </Row>

                    <Row
                        gutter={{ xs: 10, md: 20 }}
                        justify="end"
                        className="field-row buymore__calc--btn-container"
                    >
                        <Col xs={24}>
                            <Button onClick={onClearValues}>Reset</Button>
                        </Col>
                        <Col sm={12} md={8}>
                            {/* <Button
                                type="primary"
                                disabled={
                                    values.amount < MIN_PRICE || !values.vote
                                }
                                onClick={handleCheckout}
                            >
                                Proceed
                            </Button> */}
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={12}>
                    <div className="image-guard side-image">
                        <img className="image" src={exchange} alt="mail" />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default BuyMore;
