import React, { ReactElement, FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { firstCharToUpperCase } from '../../utils';
import confirm from '../../assets/confirm.svg';
import { AUTHREDIRECT } from '../../constants';
import { RootState } from '../../redux/reducers';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const VerifyemailView: FC = (): ReactElement => {
    const usersInfo = useSelector((state: RootState) => state.auth.usersInfo);
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    return (
        <div className="content-wrapper">
            <div className="image-guard">
                <img className="image" src={confirm} alt="mail" />
            </div>
            <Text className="text-1">
                Hi <b>{usersInfo?.email}</b>, your account confirmation was
                successful
            </Text>
            <Text className="text-2">
                We are happy to have you onboard, please proceed to Dashboard
            </Text>
            <Link to={AUTHREDIRECT(usersInfo?.role)}>
                <Button
                    style={{
                        backgroundColor: `${
                            subdomainUIstyling['0']
                                ? subdomainUIstyling['0']['primaryColor']
                                : '#1bca8f'
                        }`,
                        color: 'white',
                    }}
                >
                    {firstCharToUpperCase('Go to dashboard')}
                </Button>
            </Link>
        </div>
    );
};

export default VerifyemailView;
