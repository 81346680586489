import * as material from 'material-colors';

export const materialColors = [
    [material.red['900'], material.red['700'], material.red['500']],
    [material.pink['900'], material.pink['700'], material.pink['500']],
    [material.purple['900'], material.purple['700'], material.purple['500']],
    [
        material.deepPurple['900'],
        material.deepPurple['700'],
        material.deepPurple['500'],
    ],
    [material.indigo['900'], material.indigo['700'], material.indigo['500']],
    [material.blue['900'], material.blue['700'], material.blue['500']],
    [material.lightBlue['900'], '#6c1cff', material.lightBlue['700']],
    [material.cyan['700'], material.cyan['500']],
    [material.teal['700'], material.teal['500']],
    ['#16c280', material.green['700'], material.green['500']],
    ['#13BC78', material.lightGreen['900'], material.lightGreen['700']],
    [material.lime['900'], material.lime['700']],
    [material.yellow['900'], material.yellow['700']],
    [material.amber['900'], material.amber['700']],
    [material.orange['900'], material.orange['700']],
    [material.deepOrange['900'], material.deepOrange['700']],
];
