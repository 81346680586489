import { transactionTypeDb } from '../../types';
import { CLEAR_ERROR } from '../types/common.type';
import {
    CLEAR_PAYMENT_STATE,
    INITIALIZE_PAYMENT,
    INITIALIZE_PAYMENT_FAILED,
    INITIALIZE_PAYMENT_SUCCESSFUL,
    VERIFY_PAYMENT_FAILED,
    VERIFY_PAYMENT_INIT,
    VERIFY_PAYMENT_SUCCESSFUL,
} from '../types/payment.type';

type PAYMENT_INIT_STATE = {
    authorizationUrl: string;
    error: string | null;
    loading: boolean;
    transactionInfo: transactionTypeDb | null;
};

const PAYMENT_INITIAL_STATE: PAYMENT_INIT_STATE = {
    authorizationUrl: '',
    error: null,
    loading: false,
    transactionInfo: null,
};

const paymentReducer = (
    state = PAYMENT_INITIAL_STATE,
    action: any,
): PAYMENT_INIT_STATE => {
    switch (action.type) {
        case INITIALIZE_PAYMENT:
        case VERIFY_PAYMENT_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case INITIALIZE_PAYMENT_FAILED:
        case VERIFY_PAYMENT_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case INITIALIZE_PAYMENT_SUCCESSFUL: {
            return {
                ...state,
                authorizationUrl: action.payload,
                loading: false,
                error: null,
            };
        }

        case VERIFY_PAYMENT_SUCCESSFUL: {
            return {
                ...state,
                loading: false,
                transactionInfo: action.payload,
                error: null,
            };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_PAYMENT_STATE: {
            return {
                ...state,
                ...PAYMENT_INITIAL_STATE,
            };
        }
        default:
            return state;
    }
};

export default paymentReducer;
