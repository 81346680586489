export const LOGOUT_USER = 'LOGOUT_USER';

export const AUTH_USER = 'AUTH_USER';

export const RESET_AUTH_STATE = 'RESET_AUTH_STATE';

export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const SIGNUP_INIT = 'SIGNUP_INIT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';

export const VERIFY_USER_INIT = 'VERIFY_USER_INIT';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAILED = 'VERIFY_USER_FAILED';

export const FORGOT_PASSWORD_INIT = 'FORGOT_PASSWORD_INIT';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const NOTIFICATION_VIEWED = 'NOTIFICATION_VIEWED';
export const NOTIFICATION_UNVIEWED = 'NOTIFICATION_UNVIEWED';

export const SUBDOMAIN_UI_INIT = 'SUBDOMAIN_UI_INIT';
export const SUBDOMAIN_UI_SUCCESS = 'SUBDOMAIN_UI_SUCCESS';
export const SUBDOMAIN_UI_FAILED = 'SUBDOMAIN_UI_FAILED';
