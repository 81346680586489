import { Button, Col, message, Modal, Row } from 'antd';
import React, { ReactElement, useEffect, FC } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearMediaPublish,
    getAllPendingVideos,
    publishVideo,
} from '../../../../redux/actions/media.action';
import { RootState } from '../../../../redux/reducers';
import { fetchPendingMediasDb } from '../../../../types';

const VideoRenderModal: FC<{
    record: fetchPendingMediasDb;
    openModal: boolean;
    onActionComplete: () => void;
}> = ({ record, openModal, onActionComplete }): ReactElement => {
    const dispatch = useDispatch();
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { publishingVideo, publishFeedback, error } = useSelector(
        (state: RootState) => state.media,
    );
    const { subdomainUIstyling } = stylingState;

    useEffect(() => {
        if (!publishingVideo && publishFeedback === 'done') {
            dispatch(getAllPendingVideos());
            onActionComplete();
        }
        if (!publishingVideo && error && publishFeedback === 'error') {
            return message.error(error);
        }

        return () => {
            dispatch(clearMediaPublish());
        };
        // eslint-disable-next-line
    }, [publishingVideo, publishFeedback, dispatch, error]);

    const publish = () => {
        dispatch(publishVideo(record._id));
    };
    return (
        <Modal
            onCancel={onActionComplete}
            onOk={onActionComplete}
            visible={openModal}
            title="Sure to publish Video?"
            footer={false}
            width={700}
            className="add-image__modal"
        >
            <Row>
                <Col xs={24}>
                    <ReactPlayer
                        key={Date.now()}
                        loop
                        controls
                        style={{
                            padding: '10px',
                        }}
                        width="100%"
                        height="400px"
                        padding="30px"
                        url={record.url}
                    />
                </Col>
            </Row>
            <Row
                style={{
                    marginTop: '10px',
                }}
                justify="space-between"
            >
                <Col md={8}>
                    <Button onClick={onActionComplete}>Cancel</Button>
                </Col>
                <Col md={4}>
                    <Button
                        style={{
                            backgroundColor: subdomainUIstyling[0]
                                ? subdomainUIstyling[0].primaryColor
                                : '#1bca8f',
                            color: '#fff',
                        }}
                        onClick={publish}
                        loading={publishingVideo}
                    >
                        Publish
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default VideoRenderModal;
