import { useWindowWidth } from '@react-hook/window-size';
import { Col, message, Modal, Row, Skeleton, Spin } from 'antd';
import moment from 'moment';
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeCommentModal } from '../../../../redux/actions/UI.action';
import { RootState } from '../../../../redux/reducers';
import {
    COMMENT_FETCH_INIT,
    COMMENT_FETCH_SUCCESS,
} from '../../../../redux/types/comment.type';
import { fetchCommentTypeDb } from '../../../../types';
import profilePic from '../../../../assets/profile.svg';
import { handlerEvent, Input } from '../../../input';
import Scrollbars from 'react-custom-scrollbars';
import { useState } from 'react';
import {
    addComment,
    clearComments,
} from '../../../../redux/actions/comment.action';
import { LoadingOutlined, SendOutlined } from '@ant-design/icons';
import {
    clearError,
    clearMessage,
} from '../../../../redux/actions/common.action';
import { socket } from '../../../../UIProvider';

const Home: FC = (): ReactElement => {
    const windowWidth = useWindowWidth();
    const dispatch = useDispatch();
    const isOpen = useSelector(
        (state: RootState) => state.UI.commentModalIsOpen,
    );

    const commentsState = useSelector((state: RootState) => state.comments);
    const usersInfo = useSelector((state: RootState) => state.auth.usersInfo);

    const {
        activeImage,
        comments,
        feedbackMsg,
        error: requestError,
        loading,
        addLoading,
    } = commentsState;

    const [commentInput, setCommentInput] = useState('');

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }
        if (feedbackMsg) {
            //clear form inputs
            setCommentInput('');
        }

        //clean up error , message ,comments
        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
        };
    }, [requestError, feedbackMsg, dispatch]);

    useEffect(() => {
        if (activeImage) {
            dispatch({ type: COMMENT_FETCH_INIT });
            const { id } = activeImage;

            socket.emit('comments', { imageId: id });

            socket.on(`comment-${id}`, function (data: fetchCommentTypeDb[]) {
                dispatch({ type: COMMENT_FETCH_SUCCESS, payload: data });
            });
        }

        //clean up listener to avoid memory leak
        return () => {
            socket.removeAllListeners(`comment-${activeImage?.id}`);
            dispatch(clearComments());
        };
    }, [dispatch, activeImage]);

    const onActionComplete = () => {
        dispatch(closeCommentModal());
    };

    const onInputChange = (e: handlerEvent) => {
        const { value } = e.target;
        setCommentInput(value);
    };

    const onCommentSubmit = () => {
        if (!commentInput) return message.warning('No comment typed!!');
        if (activeImage) {
            dispatch(
                addComment({
                    imageId: activeImage.id,
                    comment: commentInput,
                }),
            );
        }
    };
    const handleCommentDelete = (cId: string) => {
        if (activeImage) {
            socket.emit('deleteComment', {
                imageId: activeImage.id,
                commentId: cId,
            });
        }
    };
    const antIcon = (
        <LoadingOutlined
            style={{
                marginTop: '10px',
                marginRight: '10px',
                fontSize: '25px',
                color: '#16c280',
            }}
            spin
        />
    );
    return (
        <Modal
            onCancel={onActionComplete}
            onOk={onActionComplete}
            visible={isOpen}
            title={false}
            footer={false}
            width={1000}
            style={{ marginLeft: windowWidth <= 1025 ? 'auto' : '21%' }}
            bodyStyle={{ padding: '0px' }}
            className="comment-modal"
        >
            <Row>
                <Col xs={0} xl={8}>
                    <div className="comment-modal__imgwrapper">
                        <img alt="contestant" src={activeImage?.url} />
                    </div>
                </Col>
                <Col xs={24} xl={16}>
                    <div className="comment-modal__content">
                        {!loading && (
                            <>
                                <div className="comment-modal__content-wrapper">
                                    {comments.length !== 0 ? (
                                        <Scrollbars
                                            className="scrollbar"
                                            autoHide
                                        >
                                            {comments.map(
                                                (
                                                    {
                                                        _id: cId,
                                                        comment,
                                                        createdAt,
                                                        user: {
                                                            _id,
                                                            firstName,
                                                            imageUrl,
                                                            lastName,
                                                        },
                                                    },
                                                    i,
                                                ) => (
                                                    <Row key={i}>
                                                        <Col md={2}>
                                                            <div className="comment-modal__content--img">
                                                                <img
                                                                    alt="profile"
                                                                    src={
                                                                        imageUrl !==
                                                                        ''
                                                                            ? imageUrl
                                                                            : profilePic
                                                                    }
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={22}>
                                                            <span className="comment-modal__content--name">
                                                                <span>
                                                                    {lastName}
                                                                </span>
                                                                <span>
                                                                    {firstName}
                                                                </span>
                                                            </span>
                                                            <span className="comment-modal__content--time">
                                                                {moment(
                                                                    createdAt,
                                                                ).fromNow()}
                                                            </span>
                                                            <p className="comment-modal__content--details">
                                                                {comment}
                                                            </p>

                                                            {_id ===
                                                                usersInfo?._id && (
                                                                <span
                                                                    onClick={() =>
                                                                        handleCommentDelete(
                                                                            cId,
                                                                        )
                                                                    }
                                                                    className="comment-modal__content--delete"
                                                                >
                                                                    Delete
                                                                </span>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                ),
                                            )}
                                        </Scrollbars>
                                    ) : (
                                        <Row
                                            justify="center"
                                            align="middle"
                                            style={{ height: '200px' }}
                                        >
                                            <Col className="no_comment">
                                                <p>Comments</p>
                                                <p>Be the first to comment</p>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                                <Row>
                                    <Col xs={24}>
                                        <Input
                                            name="comment"
                                            onChange={onInputChange}
                                            componentType="TextArea"
                                            placeholder="comment...."
                                            rows={4}
                                            value={commentInput}
                                        />
                                    </Col>
                                </Row>
                                <Row justify="end">
                                    <Col>
                                        {!addLoading ? (
                                            <SendOutlined
                                                style={{
                                                    marginTop: '10px',
                                                    marginRight: '10px',
                                                    fontSize: '25px',
                                                    color: '#16c280',
                                                }}
                                                onClick={onCommentSubmit}
                                            />
                                        ) : (
                                            <Spin indicator={antIcon} />
                                        )}
                                    </Col>
                                </Row>
                            </>
                        )}
                        {loading && (
                            <Skeleton paragraph={{ rows: 10 }} active />
                        )}
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

export default Home;
