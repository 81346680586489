import { fetchCommentTypeDb, selectedImageType } from '../../types';
import {
    ADD_COMMENT_FAILED,
    ADD_COMMENT_INIT,
    ADD_COMMENT_SUCCESS,
    CLEAR_COMMENT_DATAS,
    COMMENT_FETCH_FAILED,
    COMMENT_FETCH_INIT,
    COMMENT_FETCH_SUCCESS,
    SELECTED_IMAGE_DATA,
} from '../types/comment.type';
import { CLEAR_MESSAGE, CLEAR_ERROR } from '../types/common.type';

type COMMENT_INIT_STATE = {
    comments: fetchCommentTypeDb[];
    activeImage: selectedImageType | null;
    loading: boolean;
    addLoading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const COMMENT_INITIAL_STATE: COMMENT_INIT_STATE = {
    comments: [],
    loading: false,
    addLoading: false,
    feedbackMsg: null,
    error: null,
    activeImage: null,
};

const commentReducer = (
    state = COMMENT_INITIAL_STATE,
    action: any,
): COMMENT_INIT_STATE => {
    switch (action.type) {
        case COMMENT_FETCH_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case ADD_COMMENT_INIT: {
            return {
                ...state,
                addLoading: true,
                error: null,
            };
        }

        case COMMENT_FETCH_SUCCESS: {
            const comments = [...action.payload].reverse();

            return {
                ...state,
                comments: comments,
                loading: false,
                error: null,
            };
        }
        case ADD_COMMENT_SUCCESS: {
            return {
                ...state,
                addLoading: false,
                feedbackMsg: 'Comment added',
                error: null,
            };
        }
        case SELECTED_IMAGE_DATA: {
            return {
                ...state,
                activeImage: action.payload,
                loading: false,
                error: null,
            };
        }
        case COMMENT_FETCH_FAILED:
        case ADD_COMMENT_FAILED: {
            return {
                ...state,
                loading: false,
                addLoading: false,
                error: action.payload,
            };
        }
        case CLEAR_COMMENT_DATAS: {
            return {
                ...state,
                comments: [],
            };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }
        default:
            return state;
    }
};

export default commentReducer;
