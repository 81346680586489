import { Button, Col, message, Modal, Row } from 'antd';
import React, { ReactElement, FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    clearError,
    clearMessage,
} from '../../../../../redux/actions/common.action';
import { addEventImage } from '../../../../../redux/actions/image.action';
import { closeAddEventImageModal } from '../../../../../redux/actions/UI.action';
import { RootState } from '../../../../../redux/reducers';
import { routerParamsType } from '../../../../../types';
import { FileUploader } from '../../../../fileUploader';

const AddContestImageModal: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const { id }: routerParamsType = useParams();

    const [url, setUrl] = useState('');

    const imagesState = useSelector((state: RootState) => state.images);

    const isOpen = useSelector(
        (state: RootState) => state.UI.addContestImageModalIsOpen,
    );
    const { error: requestError, loading, feedbackMsg } = imagesState;
    useEffect(() => {
        if (requestError) {
            dispatch(closeAddEventImageModal());
            return message.error(requestError);
        }

        if (feedbackMsg) {
            dispatch(closeAddEventImageModal());
            return message.success(feedbackMsg);
        }

        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
        };
        // eslint-disable-next-line
    }, [dispatch, requestError, feedbackMsg]);

    const imageUploadHandler = async () => {
        if (!id) return;
        dispatch(
            addEventImage({
                event: id,
                url: url,
            }),
        );
    };
    return (
        <Modal
            onCancel={() => dispatch(closeAddEventImageModal())}
            onOk={() => dispatch(closeAddEventImageModal())}
            visible={isOpen}
            title="Add Contestant Image"
            footer={false}
            width={300}
            maskClosable={false}
            className="add-image__modal"
        >
            <Row>
                <Col xs={24}>
                    <FileUploader
                        label="Upload image"
                        onUploadComplete={(url: string) => setUrl(url)}
                        showUploadList={true}
                        className="addImageModal"
                    />
                </Col>
                <Col xs={24}>
                    <Button
                        type="primary"
                        loading={loading}
                        className="add-image--btn"
                        disabled={!url}
                        onClick={() => imageUploadHandler()}
                    >
                        Add Image
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default AddContestImageModal;
