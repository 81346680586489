import { message } from 'antd';
import moment from 'moment';
import React, { FC, useState, ReactElement, ReactNode } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    clearError,
    clearMessage,
} from '../../../../../redux/actions/common.action';
import {
    addContest,
    clearEventDetails,
    fetchContestById,
    updateContest,
} from '../../../../../redux/actions/contests.action';
import { RootState } from '../../../../../redux/reducers';
import { CONTEST } from '../../../../../routes';
import { FormContext } from './FormContext';
import { ContestFormType } from './types';

const FormProvider: FC<ReactNode> = ({ children }): ReactElement => {
    const dispatch = useDispatch();
    const {
        replace,
        location: { search },
    } = useHistory();
    const contestsState = useSelector((state: RootState) => state.contests);
    const { loading, feedbackMsg, eventInfo } = contestsState;

    const parsedSearch = new URLSearchParams(search);
    const id = parsedSearch.get('id');
    const mode = parsedSearch.get('mode');

    const [formInputs, setFormInputs] = useState<ContestFormType>({
        eventTitle: '',
        organiserName: '',
        eventDetails: '',
        image: '',
        requirements: '',
        entryPayment: 0,
        formApply: false, //all boolean value should always be set to a default false to keep wizard behaviour
        isPublished: false,
        isPaid: false,
        startDate: moment(),
        closingDate: moment(),
    });

    useEffect(() => {
        if (id && mode === 'edit') {
            dispatch(fetchContestById(id));
        }
        return () => {
            dispatch(clearEventDetails());
        };
    }, [id, dispatch, mode]);

    useEffect(() => {
        if (eventInfo && mode === 'edit') {
            setFormInputs({
                ...eventInfo,
            });
        }
    }, [eventInfo, mode]);

    useEffect(() => {
        if (feedbackMsg && !loading) {
            replace(CONTEST);
        }
        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
        };
    }, [feedbackMsg, loading, replace, dispatch]);

    const onFormSubmit = async (cond: { isDraft: boolean }) => {
        try {
            const {
                entryPayment,
                isPaid,
                closingDate,
                startDate,
                eventDetails,
                eventTitle,
                image,
                organiserName,
                formApply,
            } = formInputs;

            if (!organiserName || !eventDetails || !eventTitle) {
                return message.warning('All fields are required!!!');
            }

            if (!image) {
                return message.error(
                    'Contest Featured Image should be uploaded',
                );
            }

            if (!moment(closingDate).isAfter(startDate, 'day')) {
                return message.warning(
                    'Closing date should be after starting date!!!',
                );
            }

            if (!entryPayment && isPaid && formApply) {
                return message.warning(
                    'Application form payment was not provided!!!',
                );
            }

            const { isDraft } = cond;

            if (isDraft) {
                if (id && mode === 'edit') {
                    return dispatch(
                        updateContest(id, {
                            ...formInputs,
                            isPublished: false,
                            isPaid: !formApply ? false : isPaid,
                        }),
                    );
                }
                dispatch(
                    addContest({
                        ...formInputs,
                        isPublished: false,
                        isPaid: !formApply ? false : isPaid,
                    }),
                );
            } else {
                if (id && mode === 'edit') {
                    return dispatch(
                        updateContest(id, {
                            ...formInputs,
                            isPublished: true,
                            isPaid: !formApply ? false : isPaid,
                        }),
                    );
                }

                //post request
                dispatch(
                    addContest({
                        ...formInputs,
                        isPublished: true,

                        isPaid: !formApply ? false : isPaid,
                    }),
                );
            }
        } catch (error) {
            message.error('image not uploaded, please upload an image');
        }
    };

    return (
        <FormContext.Provider
            value={{
                setFormInputs,
                formInputs,
                onFormSubmit,
                loading,
                eventInfo,
                mode,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export default FormProvider;
