import _ from 'lodash';

export const firstCharToUpperCase = (str: string): string => {
    const wordArr = str.split(' ');
    const upperCased = wordArr.map((word) => _.capitalize(word));
    return upperCased.join(' ');
};

export const sentenceTruncater = (sentence: string, length: number): string =>
    _.truncate(_.capitalize(sentence), { length: length });

export const sentenceFirstLetterCap = (sentence: string): string =>
    _.capitalize(sentence);
