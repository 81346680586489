import { Row, Col } from 'antd';
import React, { FC, ReactElement, useContext } from 'react';
import { handlerEvent, Input } from '../../../../input';
import { FormContext } from '../formProvider/FormContext';

const Step7: FC = (): ReactElement => {
    const { formInputs, setFormInputs } = useContext(FormContext);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInputs({ ...formInputs, [name]: +value });
    };

    return (
        <div>
            <div className="step7__title">Application Fees</div>
            <div className="step7__text">
                <span>Note:</span>
                <span>
                    contestants are required to make payment before application
                    would be processed
                </span>
            </div>
            <Row className="field-row" justify="center">
                <Col xs={22} md={18}>
                    <Input
                        name="entryPayment"
                        value={formInputs?.entryPayment.toString()}
                        onChange={onInputChange}
                        hasLabel
                        label="Fees ($)"
                        inputType="number"
                        min={50}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Step7;
