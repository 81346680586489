import { message } from 'antd';
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StepComponentProps } from 'react-step-builder';
import {
    clearError,
    clearMessage,
} from '../../../../../redux/actions/common.action';
import { RootState } from '../../../../../redux/reducers';
import { TASK_HOME } from '../../../../../routes';

const LoadingStep: FC<StepComponentProps> = (props): ReactElement => {
    const { push } = useHistory();

    const dispatch = useDispatch();
    const taskState = useSelector((state: RootState) => state.tasks);
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    const { error: requestError, feedbackMsg } = taskState;

    useEffect(() => {
        if (requestError) {
            message.error(requestError);
            return props.prev();
        }
        if (feedbackMsg) {
            message.success(feedbackMsg);
            push(TASK_HOME);
        }

        //clean up error and message
        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
        };
    }, [requestError, feedbackMsg, dispatch, push, props]);

    return (
        <div className="loader-container">
            <div className="loader">
                <div
                    className="loader-bar"
                    style={{
                        backgroundColor: `${
                            subdomainUIstyling['0']
                                ? subdomainUIstyling['0']['primaryColor']
                                : '#1bca8f'
                        }`,
                        color: 'white',
                    }}
                />
            </div>
            <span className="loading-text"></span>
        </div>
    );
};

export default LoadingStep;
