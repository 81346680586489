import React, { FC, ReactElement, useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { HOME } from '../../routes';
import VerifyemailView from '../../components/authactionViews/VerifyemailView';
import ConfirmnoticeView from '../../components/authactionViews/ConfirmnoticeView';
import PasswordresetView from '../../components/authactionViews/PasswordresetView';
// import appLogo from '../../assets/applogo.png';
import FailedView from '../../components/authactionViews/FailedView';
import NotFoundView from '../../components/authactionViews/notFoundView';

import { useDispatch, useSelector } from 'react-redux';

import { verifyUser } from '../../redux/actions/auth.action';
import { RootState } from '../../redux/reducers';
import { clearError } from '../../redux/actions/common.action';
import { subdomainUI } from '../../redux/actions/subdomainUI.action';
import { DOMAINPARTS } from '../../routes';
import bg from '../auth/img/lp9.jpg';
import './style.scss';
import LoadingSpinner from '../../components/spinners/loadingSpinner';
// import { subdomainUI } from '../../redux/actions/subdomainUI.action';
const AuthactionPage: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth);
    const { error, loading, usersInfo } = auth;
    const [mode, setMode] = useState<string | null>('');
    // const usersInfo = useSelector((state: RootState) => state.auth.usersInfo);
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { loadingUI, subdomainUIstyling } = stylingState;

    const {
        location: { search },
    } = useHistory();

    useEffect(() => {
        const domainParts = window.location.host.split('.');

        if (domainParts.length >= DOMAINPARTS) {
            const subDomain = domainParts[0];
            dispatch(subdomainUI(subDomain));
        } else {
            dispatch(subdomainUI());
        }
    }, [dispatch]);

    useEffect(() => {
        if (error && !loading) {
            return setMode('failed');
        }
        if (!loading && usersInfo) {
            setMode('verifyEmail');
        }

        return () => {
            dispatch(clearError());
        };
    }, [loading, error, usersInfo, dispatch]);

    useEffect(() => {
        (async () => {
            const parsedSearch = new URLSearchParams(search);
            const mode = parsedSearch.get('mode');

            if (mode === 'confirmNotice') {
                setMode('confirmNotice');
            } else if (mode === 'verifyEmail') {
                //run verification
                const verifyToken = parsedSearch.get('verifyToken');
                if (verifyToken) {
                    dispatch(verifyUser({ token: verifyToken }));
                    //    dispatch(subdomainUI({ token: verifyToken }));
                }
            } else if (mode === 'resetPassword') {
                setMode('resetPassword');
            }
        })();
        // eslint-disable-next-line
    }, []);

    return loadingUI ? (
        <LoadingSpinner />
    ) : (
        <div
            className="email-notice-page auth-page"
            style={{
                backgroundImage: subdomainUIstyling['0']
                    ? `url(${subdomainUIstyling['0']['bannerImage']})`
                    : `url(${bg})`,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                backgroundBlendMode: 'overlay',
            }}
        >
            <Row>
                <Col xs={24} className="top-pane">
                    <Link to={HOME}></Link>
                </Col>
            </Row>
            <Row align="middle">
                <Col
                    xs={{ span: 20, offset: 2 }}
                    md={{ span: 20, offset: 2 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 12, offset: 6 }}
                    className="middle-pane"
                >
                    {mode === 'confirmNotice' ? (
                        <ConfirmnoticeView />
                    ) : mode === 'verifyEmail' ? (
                        <VerifyemailView />
                    ) : mode === 'resetPassword' ? (
                        <PasswordresetView />
                    ) : mode === 'failed' ? (
                        <FailedView />
                    ) : (
                        <NotFoundView loading={loading} />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default AuthactionPage;
