import { plansType } from '../../types';
import { CLEAR_ERROR } from '../types/common.type';
import {
    PLANS_FETCH_FAILED,
    PLANS_FETCH_INIT,
    PLAN_FETCH_ID_SUCCESS,
    PLANS_FETCH_SUCCESS,
    PLAN_FETCH_ID_FAILED,
    PLAN_FETCH_ID_INIT,
} from '../types/plans.type';

type PLANS_INIT_STATE = {
    plans: plansType[];
    singlePlan: plansType | null;
    loading: boolean;
    error: string | null;
};

const PLANS_INITIAL_STATE: PLANS_INIT_STATE = {
    plans: [],
    singlePlan: null,
    loading: false,
    error: null,
};

const PlansReducer = (
    state = PLANS_INITIAL_STATE,
    action: any,
): PLANS_INIT_STATE => {
    switch (action.type) {
        case PLANS_FETCH_INIT:
        case PLAN_FETCH_ID_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case PLANS_FETCH_SUCCESS: {
            const plans = [...action.payload];

            return {
                ...state,
                plans: plans,
                loading: false,
                error: null,
            };
        }
        case PLAN_FETCH_ID_SUCCESS: {
            return {
                ...state,
                singlePlan: action.payload,
                loading: false,
                error: null,
            };
        }

        case PLANS_FETCH_FAILED:
        case PLAN_FETCH_ID_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }

        default:
            return state;
    }
};

export default PlansReducer;
