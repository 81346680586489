import { ContestHome } from '../../contestsViews/home';
import { ContestDetail } from '../../contestsViews/detail';
import { ContestantHome } from '../../contestantViews/home';
import { AddContestant } from '../../contestsViews/addContestant';

import { ContestantApplicationForm } from '../../contestsViews/applicationForm';
import {
    CONTEST_HOME,
    CONTEST_DETAIL,
    CONTEST_VIEW,
    CONTEST_ADD_CONTESTANT,
    CONTEST_APPLICATION_CONTESTANT,
    CONTEST_VIDEO,
} from '../../../routes';
import { ContestVideoHome } from '../../contestsViews/contestVideoHome';

const routeContest = [
    // {
    //     id: 1,
    //     title: 'Register',
    //     exact: true,
    //     path: CREATE_CONTEST,
    //     component: AddContest,
    // },
    {
        id: 2,
        title: 'Home',
        exact: true,
        path: CONTEST_HOME,
        component: ContestHome,
    },
    {
        id: 3,
        title: 'Contest Details',
        exact: true,
        path: CONTEST_DETAIL,
        component: ContestDetail,
    },
    {
        id: 4,
        title: 'Contest Views',
        exact: true,
        path: CONTEST_VIEW,
        component: ContestantHome,
    },
    {
        id: 5,
        title: 'Contest Add contestant',
        exact: true,
        path: CONTEST_ADD_CONTESTANT,
        component: AddContestant,
    },
    {
        id: 6,
        title: 'Contest Application for contestant',
        exact: true,
        path: CONTEST_APPLICATION_CONTESTANT,
        component: ContestantApplicationForm,
    },
    {
        id: 7,
        title: 'Contest Videos',
        exact: true,
        path: CONTEST_VIDEO,
        component: ContestVideoHome,
    },
];

export default routeContest;
