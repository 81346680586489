import React, { ReactElement, FC, useState, FormEvent, useEffect } from 'react';
import { Button, Col, message, Row, Typography } from 'antd';
import resetpassword from '../../assets/resetpassword.svg';
import { handlerEvent } from '../input/types';
import { Input } from '../input';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../redux/actions/auth.action';
import { RootState } from '../../redux/reducers';
import { AUTHREDIRECT } from '../../constants';

const { Text } = Typography;

const PasswordresetView: FC = (): ReactElement => {
    const dispatch = useDispatch();

    const authState = useSelector((state: RootState) => state.auth);
    const { loading, token, error: requestError, usersInfo } = authState;
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;
    const {
        location: { search },
        push,
    } = useHistory();

    const [formInputs, setFormInput] = useState({
        password: '',
        confirmPassword: '',
    });

    const parsedSearch = new URLSearchParams(search);
    const resetToken = parsedSearch.get('resetToken');

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }
        if (token) {
            //clear form inputs
            setFormInput({
                confirmPassword: '',
                password: '',
            });

            //push to dashboard
            return push(AUTHREDIRECT(usersInfo?.role));
        }
    }, [token, push, requestError, dispatch, usersInfo]);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInput({ ...formInputs, [name]: value });
    };

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const { password, confirmPassword } = formInputs;
        if (!password || !confirmPassword) {
            return message.error('Please fillout all fields');
        }
        if (password !== confirmPassword) {
            return message.error('Password are not the same');
        }
        if (!resetToken) return message.error('invalid token');

        return dispatch(
            resetPassword({
                newPassword: password,
                token: resetToken,
            }),
        );
    };

    return (
        <Row className="reset-wrapper">
            <Col xs={24} md={12}>
                <div className="image-guard side-image">
                    <img className="image" src={resetpassword} alt="mail" />
                </div>
            </Col>
            <Col xs={24} md={12}>
                <Text className="text-1">
                    Your password must be different from previous used
                    passwords.
                </Text>

                <form onSubmit={onFormSubmit} className="reset-password">
                    <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                        <Col xs={24}>
                            <Input
                                name="password"
                                onChange={onInputChange}
                                inputType="password"
                                hasLabel={true}
                                placeholder="New Password"
                                value={formInputs.password}
                            />
                        </Col>
                    </Row>
                    <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                        <Col xs={24}>
                            <Input
                                name="confirmPassword"
                                onChange={onInputChange}
                                inputType="password"
                                hasLabel={true}
                                placeholder="Confirm Password"
                                value={formInputs.confirmPassword}
                            />
                        </Col>
                    </Row>
                    <Row className="field-row">
                        <Col span={24}>
                            <Button
                                htmlType="submit"
                                className="custom-btn"
                                // type="primary"
                                style={{
                                    backgroundColor: `${
                                        subdomainUIstyling['0']
                                            ? subdomainUIstyling['0'][
                                                  'primaryColor'
                                              ]
                                            : '#1bca8f'
                                    }`,
                                    color: 'white',
                                }}
                                loading={loading}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Col>
        </Row>
    );
};

export default PasswordresetView;
