import React, { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Col, Drawer, Row } from 'antd';
import {
    ABOUT_US,
    CONTEST,
    DOMAINPARTS,
    ENTERPRISELANDING,
    HOME,
    PRICING_PLAN,
    SIGN_IN,
    SIGN_UP,
} from '../../../routes';

import './style.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { useWindowWidth } from '@react-hook/window-size';

const Navigation: FC = (): ReactElement => {
    const {
        location: { pathname },
    } = useHistory();
    const windowWidth = useWindowWidth();

    const token = useSelector((state: RootState) => state.auth.token);
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { loadingUI, subdomainUIstyling } = stylingState;
    const domainParts = window.location.host.split('.');
    const [isMobile, setIsMobile] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        if (windowWidth <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowWidth]);
    return (
        <>
            {!isMobile && (
                <Row
                    className="main-nav"
                    justify="space-between"
                    align="middle"
                >
                    <Col className="main-nav__logo" xs={12} md={4}>
                        <Link to={!token ? HOME : CONTEST}>
                            {!loadingUI &&
                                subdomainUIstyling['0'] &&
                                subdomainUIstyling['0']['logo'] !== '' && (
                                    <img
                                        src={
                                            !loadingUI &&
                                            subdomainUIstyling['0']
                                                ? subdomainUIstyling['0'][
                                                      'logo'
                                                  ]
                                                : `https://res.cloudinary.com/content-1024concepts/image/upload/v1605173734/main-images/logo_ve5gax.png`
                                        }
                                        alt="logo"
                                    />
                                )}
                            {!loadingUI && subdomainUIstyling.length === 0 && (
                                <span
                                    style={{
                                        color: 'black',
                                        height: '30px',
                                        fontSize: '18px',
                                    }}
                                >
                                    Pageant Africa
                                </span>
                            )}
                        </Link>
                    </Col>
                    <Col>
                        <div className="main-nav__menu">
                            {domainParts.length < DOMAINPARTS && (
                                <>
                                    <span
                                        className={`${
                                            pathname === HOME
                                                ? 'nav-active'
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to={HOME}
                                            style={{ color: 'inherit' }}
                                        >
                                            Home
                                        </Link>
                                    </span>

                                    <span
                                        className={`${
                                            pathname === ABOUT_US
                                                ? 'nav-active'
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to={ABOUT_US}
                                            style={{ color: 'inherit' }}
                                        >
                                            About
                                        </Link>
                                    </span>
                                    <span
                                        className={`${
                                            pathname === ENTERPRISELANDING
                                                ? 'nav-active'
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to={ENTERPRISELANDING}
                                            style={{ color: 'inherit' }}
                                        >
                                            Enterprise
                                        </Link>
                                    </span>
                                    <span
                                        className={`${
                                            // !!matchPath(location.pathname, PRICING_PLAN)
                                            pathname === PRICING_PLAN
                                                ? 'nav-active'
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to={PRICING_PLAN}
                                            style={{ color: 'inherit' }}
                                        >
                                            Pricing
                                        </Link>
                                    </span>
                                </>
                            )}
                            {!token ? (
                                <>
                                    <span
                                        className={`${
                                            pathname === SIGN_IN
                                                ? 'nav-active'
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to={SIGN_IN}
                                            style={{ color: 'inherit' }}
                                        >
                                            Sign In
                                        </Link>
                                    </span>

                                    <span
                                        className={`${
                                            pathname === SIGN_UP
                                                ? 'nav-active'
                                                : ''
                                        }`}
                                    >
                                        <Link to={SIGN_UP}>
                                            <Button
                                                className="main-nav__menu--btn"
                                                type="primary"
                                            >
                                                Register
                                            </Button>
                                        </Link>
                                    </span>
                                </>
                            ) : (
                                <span
                                    className={`${
                                        pathname === CONTEST ? 'nav-active' : ''
                                    }`}
                                >
                                    <Link to={CONTEST}>
                                        <Button
                                            className="main-nav__menu--btn"
                                            type="primary"
                                        >
                                            DASHBOARD
                                        </Button>
                                    </Link>
                                </span>
                            )}
                        </div>
                    </Col>
                </Row>
            )}

            {isMobile && (
                <Row justify="end" className="mobile-menu">
                    <Col>
                        <MenuOutlined
                            style={{ fontSize: '25px', color: '#13bc78' }}
                            onClick={() => setIsOpened(true)}
                        />
                    </Col>
                </Row>
            )}
            <Drawer
                placement="right"
                closable={true}
                closeIcon={
                    <CloseOutlined
                        style={{ fontSize: '25px', color: 'red' }}
                        onClick={() => setIsOpened(false)}
                    />
                }
                width={'100vw'}
                onClose={() => setIsOpened(false)}
                visible={isOpened && isMobile}
                mask={false}
                className="mobile-modal"
            >
                <div className="mobile-modal__container">
                    <div
                        className={`mobile-modal__items ${
                            pathname === HOME ? 'nav-active' : ''
                        }`}
                    >
                        <Link
                            to={HOME}
                            style={{ color: 'inherit' }}
                            className={`${
                                pathname === HOME ? 'nav-active' : ''
                            }`}
                        >
                            Home
                        </Link>
                    </div>
                    <div
                        className={`mobile-modal__items ${
                            pathname === ABOUT_US ? 'nav-active' : ''
                        }`}
                    >
                        <Link to={ABOUT_US} style={{ color: 'inherit' }}>
                            About
                        </Link>
                    </div>
                    {domainParts.length < DOMAINPARTS && (
                        <>
                            <div
                                className={`mobile-modal__items ${
                                    pathname === ENTERPRISELANDING
                                        ? 'nav-active'
                                        : ''
                                }`}
                            >
                                <Link
                                    to={ENTERPRISELANDING}
                                    style={{ color: 'inherit' }}
                                >
                                    Enterprise
                                </Link>
                            </div>

                            <div
                                className={`mobile-modal__items ${
                                    pathname === PRICING_PLAN
                                        ? 'nav-active'
                                        : ''
                                }`}
                            >
                                <Link
                                    to={PRICING_PLAN}
                                    style={{ color: 'inherit' }}
                                >
                                    Pricing
                                </Link>
                            </div>
                        </>
                    )}
                </div>
                <div className="mobile-modal__actionbox">
                    {!token ? (
                        <Fragment>
                            <div className="mobile-modal__items">
                                <Link to={SIGN_IN} style={{ color: 'inherit' }}>
                                    Sign In
                                </Link>
                            </div>
                            <div className="mobile-modal__items">
                                <Link to={SIGN_UP}>
                                    <Button
                                        className="main-nav__menu--btn"
                                        type="primary"
                                        style={{
                                            height: '43px',
                                            fontSize: '20px',
                                            width: '80%',
                                        }}
                                    >
                                        Register
                                    </Button>
                                </Link>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="mobile-modal__items">
                            <Link to={CONTEST}>
                                <Button
                                    className="main-nav__menu--btn"
                                    type="primary"
                                    style={{
                                        height: '43px',
                                        fontSize: '20px',
                                        width: '80%',
                                    }}
                                >
                                    DASHBOARD
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
            </Drawer>
        </>
    );
};

export default Navigation;
