import { fetchSudomainAccountTypeDb } from '../../types';
import {
    SUBDOMAIN_UI_INIT,
    UPDATE_SUBDOMAIN_UI_INIT,
    SUBDOMAIN_UI_SUCCESS,
    SUBDOMAIN_UI_FAILED,
    UPDATE_SUBDOMAIN_UI_FAILED,
    UPDATE_SUBDOMAIN_UI_SUCCESS,
} from '../types/subdomainUI.type';

type SUB_UI_INIT = {
    subdomainUIstyling: fetchSudomainAccountTypeDb[];
    loadingUI: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const SUBDOMAIN_UI_INITIAL_STATE: SUB_UI_INIT = {
    subdomainUIstyling: [],
    loadingUI: false,
    feedbackMsg: null,
    error: null,
};

const subdomainUIReducer = (
    state = SUBDOMAIN_UI_INITIAL_STATE,
    action: any,
): SUB_UI_INIT => {
    switch (action.type) {
        case SUBDOMAIN_UI_INIT:
        case UPDATE_SUBDOMAIN_UI_INIT: {
            return {
                ...state,
                loadingUI: true,
                error: null,
            };
        }
        case UPDATE_SUBDOMAIN_UI_SUCCESS: {
            const styling = [action.payload];
            return {
                ...state,
                subdomainUIstyling: styling,
                loadingUI: false,
                error: null,
                feedbackMsg: 'loading !!!',
            };
        }
        case SUBDOMAIN_UI_SUCCESS: {
            const styling = [...action.payload].reverse();

            return {
                ...state,
                subdomainUIstyling: styling,
                loadingUI: false,
                error: null,
            };
        }
        case SUBDOMAIN_UI_FAILED:
        case UPDATE_SUBDOMAIN_UI_FAILED: {
            return {
                ...state,
                loadingUI: false,
                error: action.payload,
            };
        }

        default:
            return state;
    }
};

export default subdomainUIReducer;
