import { Col, Row } from 'antd';
import moment from 'moment';
import React, { FC, ReactElement, useContext } from 'react';
import { StepComponentProps } from 'react-step-builder';
import { DatePickerCustom } from '../../../../datePicker';
import { FormContext } from '../formProvider/FormContext';

const Step3: FC<StepComponentProps> = (): ReactElement => {
    const { formInputs, setFormInputs } = useContext(FormContext);

    const ondateChange = (dateStr: string, name: string) => {
        if (dateStr) {
            setFormInputs({
                ...formInputs,
                [name]: moment(dateStr, 'YYYY-MM-DD'),
            });
        } else {
            setFormInputs({ ...formInputs, [name]: moment() });
        }
    };

    return (
        <Row justify="center" className="step1">
            <Col md={14}>
                <p className="step1__text1">Pick A Start and End Date</p>

                <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                    <Col xs={24} md={12}>
                        <DatePickerCustom
                            name="startDate"
                            label={'startDate'.toUpperCase()}
                            hasLabel={true}
                            value={moment(formInputs?.startDate)}
                            placeholder="enter event startDate"
                            onChange={(_, dateStr) =>
                                ondateChange(dateStr, 'startDate')
                            }
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <DatePickerCustom
                            name="endDate"
                            label={'endDate'.toUpperCase()}
                            hasLabel={true}
                            value={moment(formInputs?.endDate)}
                            placeholder="enter event endDate"
                            onChange={(_, dateStr) =>
                                ondateChange(dateStr, 'endDate')
                            }
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default Step3;
