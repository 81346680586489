import { fetchedtaskTypeDb } from '../../types';
import {
    ADD_TASK_FAILED,
    ADD_TASK_INIT,
    ADD_TASK_SUCCESS,
    CLEAR_TASK_FORM,
    EDITING_TASK_FAILED,
    EDITING_TASK_INIT,
    EDITING_TASK_SUCCESS,
    TASK_FETCH_FAILED,
    TASK_FETCH_INIT,
    TASK_FETCH_SUCCESS,
    UPDATE_TASK_FAILED,
    UPDATE_TASK_INIT,
    UPDATE_TASK_SUCCESS,
} from '../types/tasks.type';
import { CLEAR_MESSAGE, CLEAR_ERROR } from '../types/common.type';

type TASKS_INIT_STATE = {
    tasks: fetchedtaskTypeDb[];
    singleTask: fetchedtaskTypeDb | null;
    loading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const TASKS_INITIAL_STATE: TASKS_INIT_STATE = {
    tasks: [],
    singleTask: null,
    loading: false,
    feedbackMsg: null,
    error: null,
};

const tasksReducer = (
    state = TASKS_INITIAL_STATE,
    action: any,
): TASKS_INIT_STATE => {
    switch (action.type) {
        case ADD_TASK_INIT:
        case EDITING_TASK_INIT:
        case UPDATE_TASK_INIT:
        case TASK_FETCH_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case ADD_TASK_FAILED:
        case EDITING_TASK_FAILED:
        case UPDATE_TASK_FAILED:
        case TASK_FETCH_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case TASK_FETCH_SUCCESS: {
            const tasks = [...action.payload].reverse();

            return {
                ...state,
                tasks: tasks,
                loading: false,
                error: null,
            };
        }
        case ADD_TASK_SUCCESS: {
            return {
                ...state,
                feedbackMsg: 'Task added successfully',
                loading: false,
                error: null,
            };
        }
        case EDITING_TASK_SUCCESS: {
            return {
                ...state,
                singleTask: action.payload,
                loading: false,
                error: null,
            };
        }
        case CLEAR_TASK_FORM: {
            return {
                ...state,
                singleTask: null,
            };
        }
        case UPDATE_TASK_SUCCESS: {
            return {
                ...state,
                feedbackMsg: 'Task updated successfully',
            };
        }

        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }

        default:
            return state;
    }
};

export default tasksReducer;
