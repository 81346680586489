import { baseInstance as axios } from '../../axios-base';

import { Dispatch } from 'redux';
import {
    APPLICANT_STATS_FETCH_FAILED,
    APPLICANT_STATS_FETCH_INIT,
    APPLICANT_STATS_FETCH_SUCCESS,
    CLEAR_STATS,
    TASK_STATS_FETCH_FAILED,
    TASK_STATS_FETCH_INIT,
    TASK_STATS_FETCH_SUCCESS,
    VOTE_STATS_FETCH_FAILED,
    VOTE_STATS_FETCH_INIT,
    VOTE_STATS_FETCH_SUCCESS,
} from '../types/stats.type';

export const getEventStats = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: TASK_STATS_FETCH_INIT });
    try {
        const { data } = await axios.post('/stats/events');
        dispatch({ type: TASK_STATS_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: TASK_STATS_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const getVoteStats = () => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: VOTE_STATS_FETCH_INIT });
    try {
        const { data } = await axios.post('/stats/vote');
        dispatch({ type: VOTE_STATS_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: VOTE_STATS_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const getApplicantsStats = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: APPLICANT_STATS_FETCH_INIT });
    try {
        const { data } = await axios.post('/stats/application');
        dispatch({ type: APPLICANT_STATS_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: APPLICANT_STATS_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const clearStats = () => async (dispatch: Dispatch): Promise<void> => {
    dispatch({
        type: CLEAR_STATS,
    });
};
