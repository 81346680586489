import { Row, Col, Button } from 'antd';
import React, { FC, ReactElement, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { CONTEST } from '../../../../routes';
import { FormContext } from './formProvider/FormContext';

import { useEffect } from 'react';
import { stepRenderType, stepStage } from '../types';

import Step1 from './formSteps/Step1';
import Step2 from './formSteps/Step2';
import Step3 from './formSteps/Step3';
import Step4 from './formSteps/Step4';
import Step5 from './formSteps/Step5';
import Step6 from './formSteps/Step6';
import Step7 from './formSteps/Step7';
import FinalStep from './formSteps/FinalStep';

import { caseOne, caseTwo } from './formSteps/StepTrack';
import { OverlayLoader } from '../../../overlayLoader';
import { spinnerEnum } from '../../../overlayLoader/types';
import { RootState } from '../../../../redux/reducers';

const StepNavigation = (props: any) => {
    const { replace } = useHistory();
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    return (
        <Row
            justify="end"
            gutter={{ xs: 10, md: 30 }}
            style={{ marginTop: '40px' }}
        >
            <Col md={5}>
                {props.currentStep === 1 ? (
                    <Button
                        onClick={() => replace(CONTEST)}
                        type="dashed"
                        style={{ borderColor: 'red', color: 'red' }}
                    >
                        CANCEL
                    </Button>
                ) : (
                    <Button
                        style={{ width: '100%' }}
                        onClick={props.previousStep}
                    >
                        Previous
                    </Button>
                )}
            </Col>
            <Col md={5}>
                {props.currentStep !== props.totalSteps && (
                    <Button
                        // style={{ width: '100%' }}
                        // type="primary"
                        onClick={props.nextStep}
                        style={{
                            background: `${
                                subdomainUIstyling['0']
                                    ? subdomainUIstyling['0']['primaryColor']
                                    : '#1bca8f'
                            }`,
                            width: '100%',
                            color: 'white',
                        }}
                        // disabled={disabled}
                    >
                        Next
                    </Button>
                )}
            </Col>
        </Row>
    );
};

const stepRender: stepRenderType[] = [
    {
        title: 'starting',
        component: Step1,
        step: stepStage.START,
    },
    {
        title: 'Image upload',
        component: Step2,
        step: stepStage.IMAGEUPLOAD,
    },
    {
        title: 'date pickers',
        component: Step3,
        step: stepStage.DATEPICKER,
    },
    {
        title: 'Form Apply',
        component: Step4,
        step: stepStage.FORMAPPLY,
    },
    {
        title: 'Requirement',
        component: Step5,
        step: stepStage.REQUIREMENT,
    },
    {
        title: 'Paid Application',
        component: Step6,
        step: stepStage.ISPAID,
    },
    {
        title: 'Payment',
        component: Step7,
        step: stepStage.PAYMENT,
    },
    {
        title: 'Submit',
        component: FinalStep,
        step: stepStage.FINALSTEP,
    },
];

const Wizard: FC = (): ReactElement => {
    const { formInputs, loading, mode } = useContext(FormContext);

    const [activeSteps, setActiveSteps] = useState<stepRenderType[]>(
        stepRender,
    );

    useEffect(() => {
        if (!formInputs?.formApply) {
            //runs only when formapply is false, skip to last step
            const newSteps: stepRenderType[] = [];

            stepRender.forEach((el) => {
                if (caseOne.includes(el.step)) {
                    newSteps.push(el);
                }
            });

            return setActiveSteps(newSteps);
        }

        if (!formInputs?.isPaid) {
            //runs only when isPaid is false(free application), skip to last step
            const newSteps: stepRenderType[] = [];

            stepRender.forEach((el) => {
                if (caseTwo.includes(el.step)) {
                    newSteps.push(el);
                }
            });

            return setActiveSteps(newSteps);
        }

        //set returns initial path
        setActiveSteps(stepRender);
        // eslint-disable-next-line
    }, [formInputs?.formApply, formInputs?.isPaid]);

    return (
        <Row className="create-contest" justify="center">
            <Col xs={22} md={12}>
                <h1 style={{ textAlign: 'center' }}>
                    {mode === 'edit' ? 'Edit' : 'Create Your'} Contest
                </h1>
                <StepWizard nav={<StepNavigation />} className="step-wizard">
                    {activeSteps.map(({ component }, i: number) => {
                        const Component = component;
                        return (
                            <div key={i} className="step-wizard__steps">
                                <Component />
                            </div>
                        );
                    })}
                </StepWizard>
            </Col>
            {loading && (
                <OverlayLoader opacity={1} spinnerType={spinnerEnum.Triangle} />
            )}
        </Row>
    );
};

export default Wizard;
