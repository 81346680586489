export enum spinnerEnum {
    Audio = 'Audio',
    BallTriangle = 'BallTriangle',
    Bars = 'Bars',
    Circles = 'Circles',
    Grid = 'Grid',
    Hearts = 'Hearts',
    Oval = 'Oval',
    Puff = 'Puff',
    Rings = 'Rings',
    TailSpin = 'TailSpin',
    ThreeDots = 'ThreeDots',
    Watch = 'Watch',
    RevolvingDot = 'RevolvingDot',
    Triangle = 'Triangle',
    Plane = 'Plane',
    MutatingDots = 'MutatingDots',
    CradleLoader = 'CradleLoader',
}
