import React, { ReactElement, FC } from 'react';
import { Select } from 'antd';
import { selectProps } from '../types';

const { Option } = Select;
const SelectDropdown: FC<selectProps> = ({
    label,
    placeholder,
    hasLabel,
    onChange,
    name,
    options,
    onClick,
    loading,
    disabled,
    isRequired,
}): ReactElement => {
    return (
        <div className="custom-select-dropdown">
            {hasLabel && (
                <label htmlFor={name}>
                    {isRequired && (
                        <span style={{ color: 'red', marginRight: '2px' }}>
                            *
                        </span>
                    )}
                    {label}
                </label>
            )}
            <Select
                className="custom-select"
                placeholder={placeholder}
                onChange={(val, option) => onChange(val, option)}
                onClick={onClick}
                loading={loading}
                id={name}
                disabled={disabled}
            >
                {options.map(({ id, name }) => (
                    <Option key={id} value={id}>
                        {name}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default SelectDropdown;
