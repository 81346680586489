import { Radio } from 'antd';
import React, { FC, ReactElement, useContext } from 'react';
import { FormContext } from '../formProvider/FormContext';

const Step6: FC = (): ReactElement => {
    const { formInputs, setFormInputs } = useContext(FormContext);

    function onChange(e) {
        const { name, value } = e.target;

        setFormInputs({ ...formInputs, [name]: value });
    }

    return (
        <div>
            <div className="step6__title">Application Payment</div>
            <p className="step6__text">
                Would contestants make payment for this application ?
            </p>
            <Radio.Group
                name="isPaid"
                onChange={onChange}
                value={formInputs?.isPaid}
                size="large"
                style={{
                    display: 'flex',
                    width: '100%',
                    padding: '0px 30px',
                    justifyContent: 'center',
                    marginBottom: '30px',
                }}
            >
                <Radio.Button value={false} style={{ marginRight: '20px' }}>
                    FREE
                </Radio.Button>
                <Radio.Button value={true}>PAID</Radio.Button>
            </Radio.Group>
        </div>
    );
};

export default Step6;
