import { Dispatch } from 'redux';
import {
    COMMENT_FETCH_INIT,
    COMMENT_FETCH_SUCCESS,
    COMMENT_FETCH_FAILED,
    SELECTED_IMAGE_DATA,
    ADD_COMMENT_FAILED,
    ADD_COMMENT_INIT,
    ADD_COMMENT_SUCCESS,
    CLEAR_COMMENT_DATAS,
    DELETE_COMMENT,
    DELETE_COMMENT_FAILED,
    DELETE_COMMENT_SUCCESS,
} from '../types/comment.type';
import { baseInstance as axios } from '../../axios-base';
import { addCommentType, selectedImageType } from '../../types';

export const selectedImage = (data: selectedImageType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: SELECTED_IMAGE_DATA, payload: data });
};

export const fetchComments = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: COMMENT_FETCH_INIT });
    try {
        const { data } = await axios.get(`/comment?imageId=${id}`);

        dispatch({ type: COMMENT_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: COMMENT_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const addComment = (payload: addCommentType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: ADD_COMMENT_INIT });
    try {
        await axios.post(`/comment`, payload);

        dispatch({ type: ADD_COMMENT_SUCCESS });
    } catch (error) {
        dispatch({
            type: ADD_COMMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const deleteComment = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: DELETE_COMMENT });

    try {
        const { data } = await axios.delete(`/comment/${id}`);
        dispatch({ type: DELETE_COMMENT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: DELETE_COMMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const clearComments = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: CLEAR_COMMENT_DATAS });
};
