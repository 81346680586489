import { APPLICATION_DETAIL, APPLICATION_HOME } from '../../../routes';
import { ApplicationHome } from '../../applicationViews/home';
import { AppliactionDetails } from '../../applicationViews/details';

const routeApplication = [
    {
        id: 2,
        title: 'Home',
        exact: true,
        path: APPLICATION_HOME,
        component: ApplicationHome,
    },
    {
        id: 3,
        title: 'applicants',
        exact: true,
        path: APPLICATION_DETAIL,
        component: AppliactionDetails,
    },
];

export default routeApplication;
