import { ProfileHome } from '../../profileViews/home';
import { BuyMore } from '../../profileViews/buyMore';
import { PROFILE_HOME, PROFILE_BUY_MORE } from '../../../routes';

const routeProfile = [
    {
        id: 2,
        title: 'Home',
        exact: true,
        path: PROFILE_HOME,
        component: ProfileHome,
    },
    {
        id: 3,
        title: 'Buy Votes',
        exact: true,
        path: PROFILE_BUY_MORE,
        component: BuyMore,
    },
];

export default routeProfile;
