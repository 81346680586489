import { fetchNotificationTypeDb } from '../../types';
import {
    CLEAR_NOTIFICATION,
    NOTIFICATION_FETCH_INIT,
    NOTIFICATION_FETCH_SUCCESS,
} from '../types/notification.type';
import {
    OPEN_NOTIFICATION_DETAIL_MODAL,
    CLOSE_NOTIFICATION_DETAIL_MODAL,
} from '../types/UI.types';

type NOTIFICATION_INIT_STATE = {
    notificationsList: fetchNotificationTypeDb[];
    notificationDetail: fetchNotificationTypeDb | null;
    loading: boolean;
};

const NOTIFICATION_INITIAL_STATE: NOTIFICATION_INIT_STATE = {
    notificationsList: [],
    loading: false,
    notificationDetail: null,
};

const NotificationReducer = (
    state = NOTIFICATION_INITIAL_STATE,
    action: any,
): NOTIFICATION_INIT_STATE => {
    switch (action.type) {
        case NOTIFICATION_FETCH_INIT: {
            return {
                ...state,
                loading: true,
            };
        }
        case NOTIFICATION_FETCH_SUCCESS: {
            return {
                ...state,
                notificationsList: action.payload,
                loading: false,
            };
        }
        case OPEN_NOTIFICATION_DETAIL_MODAL: {
            const index = state.notificationsList.findIndex(
                (el) => el._id === action.payload,
            );
            return {
                ...state,
                notificationDetail: state.notificationsList[index],
            };
        }
        case CLOSE_NOTIFICATION_DETAIL_MODAL: {
            return {
                ...state,
                notificationDetail: null,
            };
        }

        case CLEAR_NOTIFICATION: {
            return {
                ...state,
                notificationsList: [],
                notificationDetail: null,
            };
        }
        default:
            return state;
    }
};

export default NotificationReducer;
