import React, { useState, useEffect } from 'react';
import { Button, Col, message, Modal, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addEventVideo } from '../../../../../redux/actions/video.action';
import { RootState } from '../../../../../redux/reducers';
import { routerParamsType } from '../../types';
import {
    clearError,
    clearMessage,
} from '../../../../../redux/actions/common.action';
import { handlerEvent, Input } from '../../../../input';
import { firstCharToUpperCase } from '../../../../../utils';
import { closeAddEventVideoModal } from '../../../../../redux/actions/UI.action';

export default function AddVideo() {
    const dispatch = useDispatch();
    const { id }: routerParamsType = useParams();

    const [url, setUrl] = useState('');

    const videoState = useSelector((state: RootState) => state.videos);
    const isOpen = useSelector(
        (state: RootState) => state.UI.addContestVideoModalIsOpen,
    );
    const { error: requestError, loading, feedbackMsg } = videoState;

    useEffect(() => {
        if (requestError) {
            dispatch(closeAddEventVideoModal());
            return message.error(requestError);
        }

        if (feedbackMsg) {
            dispatch(closeAddEventVideoModal());
            return message.success(feedbackMsg);
        }

        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
        };
        // eslint-disable-next-line
    }, [dispatch, requestError, feedbackMsg]);

    const onInputChange = (e: handlerEvent) => {
        const { value } = e.target;
        setUrl(value);
    };
    const videoUploadHandler = async () => {
        if (!id) return;
        dispatch(
            addEventVideo({
                event: id,
                url: url,
            }),
        );
    };

    return (
        <Modal
            onCancel={() => dispatch(closeAddEventVideoModal())}
            onOk={() => dispatch(closeAddEventVideoModal())}
            visible={isOpen}
            title="Add Video Url"
            footer={false}
            width={300}
            className="add-image__modal"
        >
            <Row gutter={[20, 20]}>
                <Col xs={24}>
                    <Input
                        name="url"
                        onChange={onInputChange}
                        hasLabel={true}
                        placeholder={firstCharToUpperCase('add video url')}
                        value={url}
                    />
                </Col>
                <Col xs={24}>
                    <Button
                        type="primary"
                        loading={loading}
                        className="add-image--btn"
                        onClick={() => videoUploadHandler()}
                        disabled={!url}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}
