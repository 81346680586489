import { Row, Col, Table } from 'antd';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPendingVideos } from '../../../../redux/actions/media.action';
import { RootState } from '../../../../redux/reducers';
import TableColumn from './TableColumn';

const Home: FC = (): ReactElement => {
    const { pendingVideos, loading } = useSelector(
        (state: RootState) => state.media,
    );
    const [tableData, setTableData] = useState<any[]>([]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllPendingVideos());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTableData(pendingVideos);
    }, [pendingVideos]);
    return (
        <>
            <Row className="table-content">
                <Col className="table-wrapper" span={24}>
                    <Table
                        loading={loading}
                        title={() => <h2>Videos</h2>}
                        columns={TableColumn}
                        dataSource={tableData || []}
                        size="small"
                        rowKey="_id"
                    />
                </Col>
            </Row>
        </>
    );
};

export default Home;
