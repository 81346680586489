import {
    Alert,
    Button,
    Col,
    message,
    Radio,
    RadioChangeEvent,
    Row,
    Spin,
} from 'antd';
import { Input, handlerEvent } from '../../../input';
import React, { ReactElement, FC, FormEvent, useState, useEffect } from 'react';
import { firstCharToUpperCase } from '../../../../utils';
import { routerParamsType } from '../types';

import { useHistory, useParams } from 'react-router-dom';
import {
    CONTEST,
    CONTEST_HOME,
    TRANSACTION_VERIFICATION,
} from '../../../../routes';
import { useWindowWidth } from '@react-hook/window-size';

import { useDispatch, useSelector } from 'react-redux';
import {
    clearEventDetails,
    fetchContestById,
} from '../../../../redux/actions/contests.action';
import { RootState } from '../../../../redux/reducers';

import {
    clearError,
    clearMessage,
} from '../../../../redux/actions/common.action';

import './style.scss';
import { FileUploader } from '../../../fileUploader';
import {
    clearPayment,
    initializeApplicationPayment,
} from '../../../../redux/actions/payment.action';
import { ENTERPRISE } from '../../../../constants';
import { createApplication } from '../../../../redux/actions/application.action';
import { paymentType } from '../../../../constants/transactionEnum';

const ContestantApplicationForm: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.usersInfo);

    const contestState = useSelector((state: RootState) => state.contests);
    const { eventInfo, error: requestError, loading } = contestState;

    const applicationsState = useSelector(
        (state: RootState) => state.applications,
    );
    const { loading: addLoading, feedbackMsg } = applicationsState;
    const payment = useSelector((state: RootState) => state.payment);
    const {
        authorizationUrl,
        error: paymentError,
        loading: paymentLoading,
    } = payment;

    const { id }: routerParamsType = useParams();
    const { push } = useHistory();
    const windowWidth = useWindowWidth();

    const [formInputs, setFormInputs] = useState({
        fullName: '',
        age: '',
        bio: '',
        imageUrl: '',
    });
    const [paymentMethod, setPaymentMethod] = useState<paymentType>(
        paymentType.FLUTTERWAVE,
    );

    useEffect(() => {
        dispatch(fetchContestById(id));
        return () => {
            dispatch(clearEventDetails());
        };
    }, [id, dispatch]);

    useEffect(() => {
        if (feedbackMsg) {
            message.success(feedbackMsg);
            return push(CONTEST);
        }

        return () => {
            dispatch(clearMessage());
        };
    }, [feedbackMsg, dispatch, push]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }
    }, [requestError, dispatch]);

    useEffect(() => {
        if (paymentError) {
            return message.error(paymentError);
        }

        if (paymentLoading) {
            message.loading('Connecting payment..');
        }

        return () => {
            dispatch(clearError());
            dispatch(clearPayment());
        };
    }, [paymentError, dispatch, paymentLoading]);

    useEffect(() => {
        if (authorizationUrl) {
            window.location.href = authorizationUrl;
        }
    }, [authorizationUrl]);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInputs({ ...formInputs, [name]: value });
    };

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (user?.role === ENTERPRISE) return;
        if (!eventInfo) return message.error('Error!!! Please reload page');
        const callBackUrl = `${window.location.origin}${TRANSACTION_VERIFICATION}/${id}`;

        if (eventInfo?.entryPayment === 0) {
            //if free application
            return dispatch(
                createApplication({
                    ...formInputs,
                    age: parseInt(formInputs.age, 10),
                    event: id,
                }),
            );
        }
        dispatch(
            initializeApplicationPayment({
                transactionDetails: {
                    amount: eventInfo.entryPayment,
                    callback_url: callBackUrl,
                    event: id,
                    paymentMethod: paymentMethod,
                },
                applicationDetails: {
                    ...formInputs,
                    age: parseInt(formInputs.age, 10),
                    event: id,
                },
            }),
        );
    };

    const btnDisable = () => {
        const { fullName, age, imageUrl, bio } = formInputs;
        return !fullName || !age || !imageUrl || !bio;
    };

    const uploadCompleted = (url: string) => {
        setFormInputs({
            ...formInputs,
            imageUrl: url,
        });
    };
    const onPaymentSelectorHandler = (e: RadioChangeEvent) => {
        const { value } = e.target;
        setPaymentMethod(value);
    };

    const initPaymentLoading = false;
    return (
        <>
            {user?.role === ENTERPRISE && (
                <Alert
                    message="Note: An Enterprise user can't apply"
                    type="info"
                    showIcon
                />
            )}
            <Row>
                <Col
                    md={14}
                    className="applycontestant"
                    order={windowWidth <= 768 ? 2 : 1}
                >
                    <form onSubmit={onFormSubmit}>
                        <Row
                            gutter={{ sm: 0, md: 20 }}
                            className="fieldrow first-row"
                        >
                            <Col xs={24}>
                                <Input
                                    name="fullName"
                                    label={firstCharToUpperCase('Full Name')}
                                    value={formInputs.fullName}
                                    hasLabel={true}
                                    placeholder="Selina Gomez"
                                    onChange={onInputChange}
                                />
                            </Col>
                        </Row>
                        <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                            {/* <Col xs={24} md={12}>
                                    <Input
                                        name="height"
                                        label={firstCharToUpperCase('Height')}
                                        value={formInputs.height}
                                        inputType="number"
                                        hasLabel={true}
                                        placeholder="1.75"
                                        onChange={onInputChange}
                                    />
                                </Col> */}
                            <Col xs={24} md={12}>
                                <Input
                                    name="age"
                                    onChange={onInputChange}
                                    inputType="number"
                                    label={firstCharToUpperCase('Age')}
                                    hasLabel={true}
                                    value={formInputs.age}
                                    placeholder="35"
                                />
                            </Col>

                            <Col xs={24} md={12}>
                                <FileUploader
                                    label={firstCharToUpperCase('cover image')}
                                    onUploadComplete={uploadCompleted}
                                />
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                            <Col xs={24}>
                                <Input
                                    name="bio"
                                    onChange={onInputChange}
                                    label={firstCharToUpperCase('Bio')}
                                    hasLabel={true}
                                    value={formInputs.bio}
                                    componentType="TextArea"
                                    placeholder="description of yourself"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24}>
                                <p>Pay with :</p>
                                <Radio.Group
                                    defaultValue={paymentType.FLUTTERWAVE}
                                    buttonStyle="solid"
                                    onChange={onPaymentSelectorHandler}
                                >
                                    {/* <Radio.Button value={paymentType.PAYSTACK}>
                                        PAYSTACK
                                    </Radio.Button> */}
                                    <Radio.Button
                                        value={paymentType.FLUTTERWAVE}
                                    >
                                        FLUTTERWAVE
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row
                            gutter={{ xs: 10, md: 20 }}
                            justify="end"
                            className="fieldrow btn-container"
                        >
                            <Col xs={10} md={5}>
                                <Button
                                    onClick={() => push(`${CONTEST_HOME}${id}`)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={14} md={8}>
                                <Button
                                    disabled={btnDisable()}
                                    loading={initPaymentLoading || addLoading}
                                    htmlType="submit"
                                    type="primary"
                                >
                                    Apply
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Col>
                <Col
                    md={8}
                    className="applyFee"
                    order={windowWidth <= 768 ? 1 : 2}
                >
                    <Row>
                        <Spin spinning={loading}>
                            <Col xs={24} className="applyFee__title">
                                {eventInfo ? eventInfo.eventTitle : ''}
                            </Col>
                            {eventInfo?.entryPayment !== 0 && (
                                <>
                                    <Col xs={24} className="applyFee__text">
                                        You have to pay
                                    </Col>
                                    <Col xs={24} className="applyFee__price">
                                        {`$`}
                                        {eventInfo
                                            ? eventInfo.entryPayment.toLocaleString()
                                            : '0'}
                                    </Col>
                                </>
                            )}
                            <Col xs={24} className="applyFee__description">
                                Once application is submitted, your application
                                gets processed by the contest owner. Wish you
                                the best!!!
                            </Col>
                        </Spin>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default ContestantApplicationForm;
