import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, notification, Tooltip } from 'antd';
import React, { ReactElement, FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'antd';
import { handlerEvent } from '../types';
import { PROFILE_BUY_MORE } from '../../../routes';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

import './style.scss';
import { userTypeDb } from '../../../types';
import { voteContestant } from '../../../redux/actions/vote.action';
import { closeVoteModal } from '../../../redux/actions/UI.action';
import { clearMessage } from '../../../redux/actions/common.action';

const ActionModal: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const user = useSelector(
        (state: RootState) => state.auth.usersInfo,
    ) as userTypeDb;

    const activeVoteContestant = useSelector(
        (state: RootState) => state.vote.activeVoteContestant,
    );
    const vote = useSelector((state: RootState) => state.vote);
    const { loading, feedbackMsg, error: requestError } = vote;
    const isOpen = useSelector((state: RootState) => state.UI.voteModalIsOpen);

    const { fullName, contestantId, imageUrl, event } = activeVoteContestant;
    const { walletBalance } = user;

    const [activeVotes, setActiveVotes] = useState(1);

    useEffect(() => {
        if (isOpen) {
            setActiveVotes(1);
        }
    }, [isOpen]);

    useEffect(() => {
        if (feedbackMsg && !loading) {
            notification.success({
                message: 'Successfully',
                description: feedbackMsg,
            });
        }
        return () => {
            dispatch(clearMessage());
        };
    }, [feedbackMsg, loading, dispatch, requestError]);

    const addUnit = () => {
        if (!walletBalance) return;
        if (!activeVotes) return setActiveVotes(1);
        setActiveVotes(activeVotes + 1);
    };

    const removeUnit = () => {
        if (!walletBalance) return;
        if (activeVotes === 1) return;
        setActiveVotes(activeVotes - 1);
    };

    const onActionComplete = () => {
        dispatch(closeVoteModal());
    };
    const onConfirmVote = async () => {
        if (walletBalance) {
            dispatch(
                voteContestant({
                    contestant: contestantId,
                    numberOfVote: activeVotes,
                    event,
                }),
            );
        } else {
            onActionComplete();
            message.info('Not enough units');
        }
    };

    const onInputChange = (e: handlerEvent) => {
        const { value } = e.target;

        // parseint also removes text from value
        const numValue = parseInt(value);

        //if negative return
        if (Math.sign(numValue) === -1) return;
        setActiveVotes(numValue);
    };

    return (
        <Modal
            onCancel={onActionComplete}
            onOk={onActionComplete}
            visible={isOpen}
            title={false}
            footer={false}
            width={300}
            className="action__modal"
        >
            <div className="action__modal--image">
                <img alt="example" src={imageUrl} className="inner-img" />
            </div>
            <p className="action__modal--text">Vote</p>
            <p className="action__modal--title">{fullName}</p>
            <p className="action__modal--artist"></p>
            {walletBalance ? (
                <>
                    <p className="action__modal--votes">Votes</p>
                    <div className="unit-control">
                        <Tooltip title="remove vote">
                            <Button
                                disabled={
                                    !activeVotes ||
                                    activeVotes === 1 ||
                                    activeVotes > walletBalance
                                }
                                onClick={removeUnit}
                                shape="circle"
                                icon={<MinusOutlined />}
                            />
                        </Tooltip>
                        <div className="unit-value">
                            <Input
                                value={
                                    activeVotes ? activeVotes.toString() : ''
                                }
                                onChange={onInputChange}
                                // inputType="number"
                            />
                        </div>
                        <Tooltip title="add vote">
                            <Button
                                disabled={
                                    activeVotes === walletBalance ||
                                    activeVotes > walletBalance
                                }
                                onClick={addUnit}
                                shape="circle"
                                type="primary"
                                icon={<PlusOutlined />}
                            />
                        </Tooltip>
                    </div>
                    <p className="action__modal--units">
                        {activeVotes ? activeVotes.toLocaleString() : 'No'}{' '}
                        Votes
                    </p>
                </>
            ) : (
                <>
                    <p className="action__modal--buymore">NO AVAILABLE VOTE.</p>
                    <p className="action__modal--buymore">
                        <span>Please </span>
                        <Link
                            to={PROFILE_BUY_MORE}
                            className="action__modal--buymore-btn"
                        >
                            PURCHASE
                        </Link>
                        <span> more votes</span>
                    </p>
                </>
            )}
            <Button
                type="primary"
                loading={loading}
                onClick={onConfirmVote}
                disabled={
                    !walletBalance ||
                    !activeVotes ||
                    activeVotes > walletBalance
                }
            >
                CONFIRM VOTE
            </Button>
        </Modal>
    );
};

export default ActionModal;
