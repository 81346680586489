import { Radio, Space } from 'antd';
import React, { FC, ReactElement, useContext } from 'react';
import { FormContext } from '../formProvider/FormContext';

const Step4: FC = (): ReactElement => {
    const { formInputs, setFormInputs } = useContext(FormContext);

    function onChange(e) {
        const { name, value } = e.target;

        setFormInputs({ ...formInputs, [name]: value });
    }
    return (
        <div>
            <div className="step4__title">Application form</div>
            <p className="step4__text">
                How would you like contestants to be onboarded for this contest?
            </p>
            <Radio.Group
                name="formApply"
                onChange={onChange}
                value={formInputs?.formApply}
                size="large"
                style={{
                    display: 'flex',
                    width: '100%',
                    padding: '0px 30px',
                    justifyContent: 'center',
                    marginBottom: '30px',
                }}
            >
                <Space direction="vertical">
                    <Radio
                        value={false}
                        style={{ marginRight: '20px', fontSize: '18px' }}
                    >
                        I would add contestants only
                    </Radio>
                    <Radio
                        value={true}
                        style={{ marginRight: '20px', fontSize: '18px' }}
                    >
                        I would add contestants and contestant can also apply
                    </Radio>
                </Space>
            </Radio.Group>
        </div>
    );
};

export default Step4;
