import React, { FC, ReactElement, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import routeContest from './contestRoutes';

const Contest: FC = (): ReactElement => {
    return (
        <Fragment>
            <Switch>
                {routeContest.map(({ exact, path, component, id }) => (
                    <Route
                        key={id}
                        exact={exact}
                        path={path}
                        component={component}
                    />
                ))}
            </Switch>
        </Fragment>
    );
};

export default Contest;
