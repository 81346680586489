import React from 'react';
import { routeType } from './types';
import {
    PROFILE,
    CONTEST,
    TRANSACTION,
    TRANSACTION_VOTE,
    TRANSACTION_PURCHASE,
    TASK,
    EVICTION,
    APPLICATION,
    DASHBOARD,
    ENTERPRISE,
    ALL_TRANSACTION,
    DISPUTE_TRANSACTION,
    MEDIA,
    MEDIA_IMAGE,
    MEDIA_VIDEO,
} from '../../routes';

import {
    WalletOutlined,
    UserOutlined,
    DashboardOutlined,
    FormOutlined,
    UsergroupDeleteOutlined,
    HistoryOutlined,
    IdcardOutlined,
    VideoCameraAddOutlined,
} from '@ant-design/icons';
import Contest from '../../components/dashRouteViews/contest/Contest';
import Profile from '../../components/dashRouteViews/profile/Profile';
import Transaction from '../../components/dashRouteViews/transaction/Transaction';
import Task from '../../components/dashRouteViews/task/Task';
import Media from '../../components/dashRouteViews/media/Media';
import Enterprise from '../../components/dashRouteViews/enterprise/Enterprise';
import Eviction from '../../components/dashRouteViews/eviction/Eviction';
import Application from '../../components/dashRouteViews/applications/Application';
import DashStats from '../../components/dashRouteViews/dashStats/dashStats';
// import { DashStat } from '../../components/statsView/home';

export const adminRoutes: routeType = [
    {
        title: 'Dashboard',
        Page: DashStats,
        path: DASHBOARD,
        exact: true,
        icon: <DashboardOutlined />,
        hasSubRoute: false,
    },
    {
        title: 'Contest',
        path: CONTEST,
        Page: Contest,
        icon: <IdcardOutlined />,
        hasSubRoute: false,
    },
    {
        title: 'Enterprise',
        path: ENTERPRISE,
        Page: Enterprise,
        icon: <IdcardOutlined />,
        hasSubRoute: false,
    },
    {
        title: 'TRANSACTIONS',
        path: TRANSACTION,
        icon: <WalletOutlined />,
        hasSubRoute: true,
        sub: [
            {
                path: ALL_TRANSACTION,
                Page: Transaction,
                title: 'ALL TRANSACTIONS',
            },
            {
                title: 'EVENT VOTING',
                path: TRANSACTION,
                Page: Transaction,
            },
            {
                title: 'DISPUTES',
                path: DISPUTE_TRANSACTION,
                Page: Transaction,
            },
        ],
    },

    {
        title: 'PROFILE',
        path: PROFILE,
        Page: Profile,
        icon: <UserOutlined />,
        hasSubRoute: false,
    },
];

export const enterpriseRoutes: routeType = [
    {
        title: 'Dashboard',
        path: DASHBOARD,
        Page: DashStats,
        exact: true,
        icon: <DashboardOutlined />,
        hasSubRoute: false,
    },
    {
        title: 'CONTEST',
        path: CONTEST,
        Page: Contest,
        icon: <IdcardOutlined />,
        hasSubRoute: false,
    },
    {
        title: 'APPLICATION',
        path: APPLICATION,
        Page: Application,
        icon: <FormOutlined />,
        hasSubRoute: false,
    },
    {
        title: 'TASK',
        path: TASK,
        Page: Task,
        icon: <HistoryOutlined />,
        hasSubRoute: false,
    },

    {
        title: 'MEDIA',
        path: MEDIA,
        icon: <VideoCameraAddOutlined />,
        hasSubRoute: true,
        sub: [
            {
                path: MEDIA_IMAGE,
                Page: Media,
                title: 'IMAGES',
            },
            {
                path: MEDIA_VIDEO,
                Page: Media,
                title: 'VIDEOS',
            },
        ],
    },
    {
        title: 'eviction',
        path: EVICTION,
        Page: Eviction,
        icon: <UsergroupDeleteOutlined />,
        hasSubRoute: false,
    },
    {
        title: 'TRANSACTIONS',
        path: TRANSACTION,
        icon: <WalletOutlined />,
        hasSubRoute: false,
        Page: Transaction,
    },
    {
        title: 'PROFILE',
        path: PROFILE,
        Page: Profile,
        icon: <UserOutlined />,
        hasSubRoute: false,
    },
];

export const userRoutes: routeType = [
    {
        title: 'Contest',
        path: CONTEST,
        Page: Contest,
        icon: <IdcardOutlined />,
        hasSubRoute: false,
    },
    {
        title: 'TRANSACTION',
        path: TRANSACTION,
        icon: <WalletOutlined />,
        hasSubRoute: true,
        sub: [
            {
                path: TRANSACTION_PURCHASE,
                Page: Transaction,
                title: 'PURCHASE',
            },
            {
                path: TRANSACTION_VOTE,
                Page: Transaction,
                title: 'VOTES',
            },
        ],
    },
    {
        title: 'PROFILE',
        path: PROFILE,
        Page: Profile,
        icon: <UserOutlined />,
        hasSubRoute: false,
    },
];
