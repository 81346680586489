import { baseInstance as axios } from '../../axios-base';

import { Dispatch } from 'redux';
import { addContestMediaTypeDb, addMediaTypeDb } from '../../types';
import {
    ADD_CONTESTANT_IMAGE_FAILED,
    ADD_CONTESTANT_IMAGE_INIT,
    ADD_CONTESTANT_IMAGE_SUCCESS,
    FETCH_CONTESTANT_IMAGES_FAILED,
    FETCH_CONTESTANT_IMAGES_INIT,
    FETCH_CONTESTANT_IMAGES_SUCCESS,
} from '../types/contestant.type';
import {
    FETCH_CONTEST_IMAGE_INIT,
    FETCH_CONTEST_IMAGE_SUCCESS,
    FETCH_CONTEST_IMAGE_FAILED,
    ADD_CONTEST_IMAGE_FAILED,
    ADD_CONTEST_IMAGE_INIT,
    ADD_CONTEST_IMAGE_SUCCESS,
} from '../types/contests.type';

export const addContestantImage = (payload: addMediaTypeDb) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: ADD_CONTESTANT_IMAGE_INIT });
    try {
        await axios.post('/images', payload);
        dispatch({ type: ADD_CONTESTANT_IMAGE_SUCCESS });
    } catch (error) {
        dispatch({
            type: ADD_CONTESTANT_IMAGE_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const getContestantImages = (query: {
    cid: string;
    eventId: string;
}) => async (dispatch: Dispatch): Promise<void> => {
    const { cid, eventId } = query;
    dispatch({ type: FETCH_CONTESTANT_IMAGES_INIT });
    try {
        const { data } = await axios.get(
            `/images?contestant=${cid}&event=${eventId}`,
        );
        dispatch({ type: FETCH_CONTESTANT_IMAGES_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_CONTESTANT_IMAGES_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const getContestImages = (eventId: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_CONTEST_IMAGE_INIT });
    try {
        const { data } = await axios.get(
            `/images?eventMedia=true&event=${eventId}`,
        );
        dispatch({ type: FETCH_CONTEST_IMAGE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_CONTEST_IMAGE_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const addEventImage = (payload: addContestMediaTypeDb) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: ADD_CONTEST_IMAGE_INIT });
    try {
        const { data } = await axios.post('/images/contest', payload);
        dispatch({ type: ADD_CONTEST_IMAGE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: ADD_CONTEST_IMAGE_FAILED,
            payload: error.response.data.message,
        });
    }
};
