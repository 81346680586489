import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Card, Row, Col, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import {
    clearStats,
    getApplicantsStats,
    getEventStats,
    getVoteStats,
} from '../../../../redux/actions/stats.action';
import ContestantStats from '../../statsGraph/contestant';
import {
    clearContestantData,
    fetchContestants,
} from '../../../../redux/actions/contestant.action';
import CountUp from 'react-countup';
// import { DOMAINPARTS } from '../../../../routes';
// import { fetchSudomainAccountTypeDb } from '../../../../types';

// import { subdomainUI } from '../../../../redux/actions/subdomainUI.action';

const { Title } = Typography;
const Home: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const statState = useSelector((state: RootState) => state.stats);
    const { loading, eventStats, voteStats, applicantStats } = statState;
    const [title, setTitle] = useState('');
    const [activeEvent, setActiveEvent] = useState('');

    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    useEffect(() => {
        // let domainParts = window.location.host.split('.');

        // if (domainParts.length >= DOMAINPARTS) {
        //     const subDomain = domainParts[0];
        //     dispatch(subdomainUI(subDomain))
        // } else {
        //     dispatch(subdomainUI())
        // }

        dispatch(getEventStats());
        dispatch(getVoteStats());
        dispatch(getApplicantsStats());
        return () => {
            dispatch(clearStats());
            dispatch(clearContestantData());
        };
    }, [dispatch]);

    useEffect(() => {
        if (eventStats.length >= 1) {
            dispatch(fetchContestants(eventStats[0]._id));
            setTitle(eventStats[0].eventTitle);
            setActiveEvent(eventStats[0]._id);
        }
    }, [eventStats, dispatch]);

    const eventGraphHandler = (id: string, eventTitle: string) => {
        dispatch(clearContestantData());

        dispatch(fetchContestants(id));
        setTitle(eventTitle);
        setActiveEvent(id);
    };

    return (
        <div className="subdomainUI">
            <Row className="fieldrow">
                <Col xs={24} md={8}>
                    <div style={{ margin: '10px' }}>
                        <Card
                            className="mycard"
                            size="small"
                            loading={loading}
                            style={{ height: 150 }}
                            title={
                                <Row justify="space-between">
                                    <Col md={8}>
                                        <span className="mycard__title">
                                            Total Events
                                        </span>
                                    </Col>
                                    <Col md={2}>
                                        <span className="mycard__value">
                                            {loading ? (
                                                <Spin />
                                            ) : (
                                                eventStats.length >= 1 &&
                                                eventStats.length
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                            }
                        >
                            {eventStats.length >= 1 ? (
                                <div>
                                    {eventStats
                                        .slice(0, 3)
                                        .map(
                                            ({ _id, eventTitle, numTasks }) => (
                                                <Row
                                                    justify="space-between"
                                                    key={_id}
                                                >
                                                    <Col md={12}>
                                                        <span
                                                            className="mycard__content"
                                                            onClick={
                                                                activeEvent !==
                                                                _id
                                                                    ? () =>
                                                                          eventGraphHandler(
                                                                              _id,
                                                                              eventTitle,
                                                                          )
                                                                    : undefined
                                                            }
                                                        >
                                                            {eventTitle}
                                                        </span>
                                                    </Col>
                                                    <Col
                                                        md={10}
                                                        style={{
                                                            display: 'contents',
                                                        }}
                                                    >
                                                        <span
                                                            className="mycard__content-value"
                                                            style={{
                                                                color: `${
                                                                    subdomainUIstyling[
                                                                        '0'
                                                                    ] &&
                                                                    subdomainUIstyling[
                                                                        '0'
                                                                    ][
                                                                        'primaryColor'
                                                                    ]
                                                                }`,
                                                            }}
                                                        >
                                                            <CountUp
                                                                start={0}
                                                                end={numTasks}
                                                                duration={2.75}
                                                                useEasing
                                                                separator=","
                                                            />
                                                            <span
                                                                className="mycard__content-value--text"
                                                                style={{
                                                                    color: `${
                                                                        subdomainUIstyling[
                                                                            '0'
                                                                        ] &&
                                                                        subdomainUIstyling[
                                                                            '0'
                                                                        ][
                                                                            'primaryColor'
                                                                        ]
                                                                    }`,
                                                                }}
                                                            >
                                                                Tasks
                                                            </span>
                                                        </span>
                                                    </Col>
                                                </Row>
                                            ),
                                        )}
                                </div>
                            ) : (
                                <Row justify="center">
                                    <Col xs={6}>NO DATA</Col>
                                </Row>
                            )}
                        </Card>
                    </div>
                </Col>

                <Col xs={24} md={8}>
                    <div style={{ margin: '10px' }}>
                        <Card
                            className="mycard"
                            size="small"
                            loading={loading}
                            style={{ height: 150 }}
                            title={
                                <Row justify="space-between">
                                    <Col md={8}>
                                        <span className="mycard__title">
                                            Votes
                                        </span>
                                    </Col>
                                </Row>
                            }
                        >
                            {voteStats.length >= 1 ? (
                                <div>
                                    {voteStats
                                        .slice(0, 3)
                                        .map(
                                            ({
                                                _id,
                                                eventTitle,
                                                totalVotes,
                                            }) => (
                                                <Row
                                                    justify="space-between"
                                                    key={_id}
                                                >
                                                    <Col md={12}>
                                                        <span
                                                            className="mycard__content"
                                                            onClick={
                                                                activeEvent !==
                                                                _id
                                                                    ? () =>
                                                                          eventGraphHandler(
                                                                              _id,
                                                                              eventTitle,
                                                                          )
                                                                    : undefined
                                                            }
                                                        >
                                                            {eventTitle}
                                                        </span>
                                                    </Col>
                                                    <Col
                                                        md={10}
                                                        style={{
                                                            display: 'contents',
                                                        }}
                                                    >
                                                        <span
                                                            className="mycard__content-value"
                                                            style={{
                                                                color: `${
                                                                    subdomainUIstyling[
                                                                        '0'
                                                                    ] &&
                                                                    subdomainUIstyling[
                                                                        '0'
                                                                    ][
                                                                        'primaryColor'
                                                                    ]
                                                                }`,
                                                            }}
                                                        >
                                                            <CountUp
                                                                start={0}
                                                                end={totalVotes}
                                                                duration={2.75}
                                                                useEasing
                                                                separator=","
                                                            />
                                                            <span
                                                                className="mycard__content-value--text"
                                                                style={{
                                                                    color: `${
                                                                        subdomainUIstyling[
                                                                            '0'
                                                                        ] &&
                                                                        subdomainUIstyling[
                                                                            '0'
                                                                        ][
                                                                            'primaryColor'
                                                                        ]
                                                                    }`,
                                                                }}
                                                            >
                                                                votes
                                                            </span>
                                                        </span>
                                                    </Col>
                                                </Row>
                                            ),
                                        )}
                                </div>
                            ) : (
                                <Row justify="center">
                                    <Col xs={6}>NO DATA</Col>
                                </Row>
                            )}
                        </Card>
                    </div>
                </Col>
                <Col xs={24} md={8}>
                    <div style={{ margin: '10px' }}>
                        <Card
                            className="mycard"
                            size="small"
                            loading={loading}
                            style={{ height: 150 }}
                            title={
                                <Row justify="space-between">
                                    <Col md={8}>
                                        <span className="mycard__title">
                                            Applicants
                                        </span>
                                    </Col>
                                </Row>
                            }
                        >
                            {voteStats.length >= 1 ? (
                                <div>
                                    {applicantStats
                                        .slice(0, 3)
                                        .map(
                                            ({
                                                _id,
                                                eventTitle,
                                                totalApplcants,
                                            }) => (
                                                <Row
                                                    justify="space-between"
                                                    key={_id}
                                                >
                                                    <Col md={12}>
                                                        <span
                                                            className="mycard__content"
                                                            onClick={
                                                                activeEvent !==
                                                                _id
                                                                    ? () =>
                                                                          eventGraphHandler(
                                                                              _id,
                                                                              eventTitle,
                                                                          )
                                                                    : undefined
                                                            }
                                                        >
                                                            {eventTitle}
                                                        </span>
                                                    </Col>
                                                    <Col
                                                        md={10}
                                                        style={{
                                                            display: 'contents',
                                                        }}
                                                    >
                                                        <span
                                                            className="mycard__content-value"
                                                            style={{
                                                                color: `${
                                                                    subdomainUIstyling[
                                                                        '0'
                                                                    ] &&
                                                                    subdomainUIstyling[
                                                                        '0'
                                                                    ][
                                                                        'primaryColor'
                                                                    ]
                                                                }`,
                                                            }}
                                                        >
                                                            <CountUp
                                                                start={0}
                                                                end={
                                                                    totalApplcants
                                                                }
                                                                duration={2.75}
                                                                useEasing
                                                                separator=","
                                                            />
                                                            <span
                                                                className="mycard__content-value--text"
                                                                style={{
                                                                    color: `${
                                                                        subdomainUIstyling[
                                                                            '0'
                                                                        ] &&
                                                                        subdomainUIstyling[
                                                                            '0'
                                                                        ][
                                                                            'primaryColor'
                                                                        ]
                                                                    }`,
                                                                }}
                                                            >
                                                                applicants
                                                            </span>
                                                        </span>
                                                    </Col>
                                                </Row>
                                            ),
                                        )}
                                </div>
                            ) : (
                                <Row justify="center">
                                    <Col xs={6}>NO DATA</Col>
                                </Row>
                            )}
                        </Card>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: '30px',
                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                            margin: '10px',
                        }}
                    >
                        <Title level={4}>{title}</Title>
                        {eventStats.length >= 1 && <ContestantStats />}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Home;
