import { baseInstance as axios } from '../../axios-base';

import { Dispatch } from 'redux';
import {
    FETCH_PENDING_IMAGES_FAILED,
    FETCH_PENDING_IMAGES_INIT,
    FETCH_PENDING_IMAGES_SUCCESS,
    FETCH_PENDING_VIDEOS_FAILED,
    FETCH_PENDING_VIDEOS_INIT,
    FETCH_PENDING_VIDEOS_SUCCESS,
    PENDING_IMAGES_CLEAR,
    PUBLISH_PENDING_IMAGES_FAILED,
    PUBLISH_PENDING_IMAGES_INIT,
    PUBLISH_PENDING_IMAGES_SUCCESS,
    PUBLISH_PENDING_VIDEOS_FAILED,
    PUBLISH_PENDING_VIDEOS_INIT,
    PUBLISH_PENDING_VIDEOS_SUCCESS,
} from '../types/media.type';

export const getAllPendingImages = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_PENDING_IMAGES_INIT });
    try {
        const { data } = await axios.post('/images/pendingImages');

        dispatch({ type: FETCH_PENDING_IMAGES_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_PENDING_IMAGES_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const publishImage = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: PUBLISH_PENDING_IMAGES_INIT });
    try {
        await axios.patch(`/images/${id}`, {
            accepted: true,
            isPublished: true,
        });

        dispatch({ type: PUBLISH_PENDING_IMAGES_SUCCESS });
    } catch (error) {
        dispatch({
            type: PUBLISH_PENDING_IMAGES_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const getAllPendingVideos = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_PENDING_VIDEOS_INIT });
    try {
        const { data } = await axios.post('/videos/pendingVideos');

        console.log('pendingVideos', data);
        dispatch({ type: FETCH_PENDING_VIDEOS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_PENDING_VIDEOS_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const publishVideo = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: PUBLISH_PENDING_VIDEOS_INIT });
    try {
        await axios.patch(`/videos/${id}`, {
            accepted: true,
            isPublished: true,
        });

        dispatch({ type: PUBLISH_PENDING_VIDEOS_SUCCESS });
    } catch (error) {
        dispatch({
            type: PUBLISH_PENDING_VIDEOS_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const clearMediaPublish = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: PENDING_IMAGES_CLEAR });
};
