import { Row, Col, Button, message } from 'antd';
import React, { FC, FormEvent, ReactElement, useState } from 'react';
import { handlerEvent, Input } from '../../components/input';
import { baseInstance as axios } from '../../axios-base';

const ContactForm: FC = (): ReactElement => {
    const [formInputs, setFormInput] = useState({
        fullname: '',
        email: '',
        content: '',
        phoneNum: '',
    });

    const [loading, setLoading] = useState<boolean>(false);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInput({ ...formInputs, [name]: value });
    };

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const { fullname, email, content } = formInputs;

        if (!content || !email || !fullname) {
            return message.error('Please fillout all fields');
        }
        //dispatch payload to backend
        setLoading(true);
        try {
            await axios.post(`/notifications/sendmail`, formInputs);
        } catch (err) {
            // console.log(err);
        }
        setLoading(false);
        setFormInput({
            fullname: '',
            email: '',
            content: '',
            phoneNum: '',
        });
        message.success('sent successfully, mail will be reply shortly!!');
    };
    return (
        <div style={{ margin: '0px 30px' }}>
            <form onSubmit={onFormSubmit}>
                <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                    <Col xs={24}>
                        <Input
                            name="fullname"
                            onChange={onInputChange}
                            inputType="text"
                            hasLabel={true}
                            placeholder="full name"
                            value={formInputs.fullname}
                            label="Full Name"
                            isRequired
                        />
                    </Col>
                </Row>
                <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                    <Col xs={24}>
                        <Input
                            name="email"
                            onChange={onInputChange}
                            inputType="email"
                            hasLabel={true}
                            placeholder="Enter email Address"
                            value={formInputs.email}
                            label="Email"
                            isRequired
                        />
                    </Col>
                </Row>
                <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                    <Col xs={24}>
                        <Input
                            name="phoneNum"
                            onChange={onInputChange}
                            inputType="number"
                            min={0}
                            hasLabel={true}
                            placeholder="enter phone number"
                            value={formInputs.phoneNum}
                            label="phone number"
                        />
                    </Col>
                </Row>
                <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                    <Col xs={24}>
                        <Input
                            name="content"
                            onChange={onInputChange}
                            inputType="text"
                            componentType="TextArea"
                            min={0}
                            hasLabel={true}
                            placeholder="message"
                            value={formInputs.content}
                            label="Message"
                            rows={5}
                            isRequired
                        />
                    </Col>
                </Row>
                <Row className="field-row">
                    <Col xs={12} lg={8}>
                        <Button
                            htmlType="submit"
                            className="custom-btn"
                            type="primary"
                            loading={loading}
                        >
                            Send Message
                        </Button>
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default ContactForm;
