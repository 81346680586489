import { fetchApplicationTypeDb } from '../../types';
import { CLEAR_MESSAGE, CLEAR_ERROR } from '../types/common.type';
import {
    ACCEPT_APPLICATION_FAILED,
    ACCEPT_APPLICATION_INIT,
    ACCEPT_APPLICATION_SUCCESS,
    ADD_APPLICATION_FAILED,
    ADD_APPLICATION_INIT,
    ADD_APPLICATION_SUCCESS,
    APPLICATION_FETCH_FAILED,
    APPLICATION_FETCH_INIT,
    APPLICATION_FETCH_SUCCESS,
    REJECT_APPLICATION_FAILED,
    REJECT_APPLICATION_INIT,
    REJECT_APPLICATION_SUCCESS,
} from '../types/application.type';

type APPLICATION_INIT_STATE = {
    applications: fetchApplicationTypeDb[];
    singleApplication: fetchApplicationTypeDb | null;
    loading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const APPLICATION_INITIAL_STATE: APPLICATION_INIT_STATE = {
    applications: [],
    singleApplication: null,
    loading: false,
    feedbackMsg: null,
    error: null,
};

const applicationReducer = (
    state = APPLICATION_INITIAL_STATE,
    action: any,
): APPLICATION_INIT_STATE => {
    switch (action.type) {
        case APPLICATION_FETCH_INIT:
        case REJECT_APPLICATION_INIT:
        case ACCEPT_APPLICATION_INIT:
        case ADD_APPLICATION_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case APPLICATION_FETCH_FAILED:
        case REJECT_APPLICATION_FAILED:
        case ACCEPT_APPLICATION_FAILED:
        case ADD_APPLICATION_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case ADD_APPLICATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                feedbackMsg: 'Application submitted successfuly',
            };
        }

        case APPLICATION_FETCH_SUCCESS: {
            const applications = [...action.payload].reverse();

            return {
                ...state,
                applications: applications,
                loading: false,
                error: null,
            };
        }

        case REJECT_APPLICATION_SUCCESS:
        case ACCEPT_APPLICATION_SUCCESS: {
            const newApplications = [...state.applications];
            const index = newApplications.findIndex(
                (el) => el._id === action.payload._id,
            );
            newApplications[index].status = action.payload.status;

            return {
                ...state,
                applications: newApplications,
                loading: false,
                error: null,
            };
        }

        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }

        default:
            return state;
    }
};

export default applicationReducer;
