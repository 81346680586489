import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const RouteChangeTracker = ({ history }) => {
    history.listen((location: { pathname: string }) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });

    return <Fragment></Fragment>;
};

export default withRouter(RouteChangeTracker);
