import React, { ReactElement } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import randomColor from 'randomcolor';

const VerticalBar: FC = (): ReactElement => {
    const contestantsState = useSelector(
        (state: RootState) => state.contestants,
    );
    const { contestantsInfo } = contestantsState;

    const [dataSet, setDataSet] = useState<number[]>([]);
    const [labelSet, setLabelSet] = useState<string[]>([]);

    useEffect(() => {
        const labels: string[] = [];
        const data: number[] = [];
        contestantsInfo.forEach(({ fullName, totalVotes }) => {
            labels.push(fullName);
            data.push(totalVotes);
        });

        setDataSet(data);
        setLabelSet(labels);
    }, [contestantsInfo]);

    const color = randomColor({
        count: dataSet.length,
        // hue: 'green',
        format: 'rgba',
        luminosity: 'dark',
        alpha: 1,
    });

    const data = {
        labels: labelSet,
        datasets: [
            {
                label: '# of Votes',
                data: dataSet,
                backgroundColor: color,
                borderColor: color,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };
    return (
        <div>
            <Bar type={Bar} data={data} options={options} />
        </div>
    );
};

export default VerticalBar;
