import {
    FileDoneOutlined,
    MinusCircleTwoTone,
    PlusCircleTwoTone,
} from '@ant-design/icons';
import { Col, message, Row, Table, Image } from 'antd';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchApplications } from '../../../../redux/actions/application.action';
import { clearError } from '../../../../redux/actions/common.action';
import { RootState } from '../../../../redux/reducers';
import { fetchApplicationTypeDb, routerParamsType } from '../../../../types';
import { Searchbar } from '../../../searchbar';
import TableColumn from './TableColumn';

const AppliactionDetails: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const applicationState = useSelector(
        (state: RootState) => state.applications,
    );
    const { applications, error: requestError, loading } = applicationState;

    const [tableData, setTableData] = useState<fetchApplicationTypeDb[]>([]);
    const { id }: routerParamsType = useParams();

    useEffect(() => {
        dispatch(fetchApplications(id));
    }, [dispatch, id]);

    useEffect(() => {
        setTableData(applications);
    }, [applications]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        //clean up error and message
        return () => {
            dispatch(clearError());
        };
    }, [requestError, dispatch]);

    const onSearch = (input: string) => {
        const query = input.toLowerCase();

        if (query === '') return setTableData(applications);
        if (tableData.length <= 0) return;

        const result = applications.filter(
            (application: fetchApplicationTypeDb) => {
                const fullName = application.fullName.toLowerCase();
                const isMatch = fullName.includes(query);
                return isMatch;
            },
        );
        setTableData(result);
    };

    const prefix = <FileDoneOutlined className="search-prefix" />;

    return (
        <>
            <Row className="task-bar" justify="space-between">
                <Col md={10}>
                    <Searchbar
                        onChange={(e) => onSearch(e)}
                        placeholder="Search applicants"
                        onSearch={onSearch}
                        prefix={prefix}
                    />
                </Col>
            </Row>
            <Row className="table-content">
                <Col className="table-wrapper" span={24}>
                    <Table
                        loading={loading}
                        title={() => <h2>Applicants</h2>}
                        columns={TableColumn}
                        dataSource={tableData || []}
                        size="small"
                        rowKey="_id"
                        expandable={{
                            expandedRowRender: (record) => (
                                <Row justify="space-between">
                                    <Col md={5}>
                                        <Image
                                            className="expand-img"
                                            src={record.imageUrl}
                                        />
                                    </Col>
                                    <Col md={18}>
                                        <p style={{ fontWeight: 'bold' }}>
                                            BIO
                                        </p>
                                        <p>{record.bio}</p>
                                    </Col>
                                </Row>
                            ),
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <MinusCircleTwoTone
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                ) : (
                                    <PlusCircleTwoTone
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                ),
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default AppliactionDetails;
