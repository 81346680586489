import React, { FC, ReactElement, useEffect } from 'react';
import { Row, Col, Table, Typography, message } from 'antd';
import tableColumns from './TableColumn';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import {
    fetchAllVotes,
    fetchMyVotes,
} from '../../../../redux/actions/vote.action';
import { ADMIN } from '../../../../constants';
import { clearError } from '../../../../redux/actions/common.action';

const { Title } = Typography;
const Vote: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const usersInfo = useSelector((state: RootState) => state.auth.usersInfo);

    const votes = useSelector((state: RootState) => state.vote);
    const { loading, error: requestError, votesInfo } = votes;

    useEffect(() => {
        if (usersInfo?.role === ADMIN) {
            dispatch(fetchAllVotes());
        } else {
            //user transaction
            dispatch(fetchMyVotes());
        }
    }, [usersInfo, dispatch]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        return () => {
            dispatch(clearError());
        };
    }, [requestError, dispatch]);

    return (
        <div className="transaction-container">
            <Row className="table-content box-shadow">
                <Row className="table-header">
                    <Col>
                        <Title level={4}>Vote history</Title>
                    </Col>
                </Row>
                <Col className="table-wrapper" span={24}>
                    <Table
                        loading={loading}
                        columns={tableColumns}
                        dataSource={votesInfo || []}
                        rowKey="_id"
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Vote;
