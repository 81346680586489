import React, { FC, ReactElement, useState, FormEvent, useEffect } from 'react';
import { Row, Col, Button, Typography, message } from 'antd';
import { handlerEvent } from '../../input/types';
import { Input } from '../../input';
import unlock from '../../../assets/unlock.svg';
import { SIGN_IN } from '../../../routes';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../../../redux/actions/auth.action';
import { RootState } from '../../../redux/reducers';
import { clearError } from '../../../redux/actions/common.action';
import { forgotpasswordFormType } from '../types';

const { Title } = Typography;

const PasswordReset: FC = (): ReactElement => {
    const { push } = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth);
    const { error, loading } = auth;

    const [formInputs, setFormInput] = useState<forgotpasswordFormType>({
        email: '',
    });

    useEffect(() => {
        if (error) {
            return message.error(error);
        }
        /**@todo to be recheck later */
        // if (!loading) {
        //     const { email } = formInputs;
        //     push(`${AUTH_ACTIONS}?mode=confirmNotice&email=${email}`);
        // }

        //clean up error
        return () => {
            dispatch(clearError());
        };
    }, [push, error, loading, dispatch]);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInput({ ...formInputs, [name]: value });
    };

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const { email } = formInputs;
        if (!email) {
            /**@todo  you'll use yup to define validation schema*/
            return message.error('Please enter your email');
        }

        const domainParts = window.location.host;
        const protocol = window.location.protocol;
        const domain = `${protocol}//${domainParts}`;

        return dispatch(forgotPassword({ ...formInputs, domain: domain }));
    };

    return (
        <form onSubmit={onFormSubmit} className="signup-form">
            <Row justify="center">
                <img className="img-title" src={unlock} alt="logo" />
            </Row>
            <Row justify="center">
                <Title className="form-title" level={4}>
                    Reset Password
                </Title>
            </Row>
            <Row justify="center">
                <span className="forgotpassword__text text-muted">
                    Enter your email address associated with your account, we
                    will send you a link to reset your password
                </span>
            </Row>
            <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                <Col xs={24}>
                    <Input
                        name="email"
                        onChange={onInputChange}
                        inputType="email"
                        hasLabel={true}
                        placeholder="Email"
                        value={formInputs.email}
                    />
                </Col>
            </Row>

            <Row className="field-row">
                <Col span={24}>
                    <Button
                        htmlType="submit"
                        className="custom-btn"
                        type="primary"
                        loading={loading}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>
            <div className="extras text-muted">
                <small>
                    Remember It ?
                    <Link to={SIGN_IN}>
                        <span> Sign In here</span>
                    </Link>
                </small>
            </div>
        </form>
    );
};

export default PasswordReset;
