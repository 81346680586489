import { Dispatch } from 'redux';
import {
    FORGOT_PASSWORD_FAILED,
    FORGOT_PASSWORD_INIT,
    FORGOT_PASSWORD_SUCCESS,
    LOGIN_FAILED,
    LOGIN_INIT,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    NOTIFICATION_UNVIEWED,
    NOTIFICATION_VIEWED,
    RESET_AUTH_STATE,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_INIT,
    RESET_PASSWORD_SUCCESS,
    SIGNUP_FAILED,
    SIGNUP_INIT,
    SIGNUP_SUCCESS,
    VERIFY_USER_FAILED,
    VERIFY_USER_INIT,
    VERIFY_USER_SUCCESS,
} from '../types/auth.type';
import { baseInstance as axios } from '../../axios-base';
// import { setAuthToken } from '../../utils';
import { history } from '../../utils';
import { AUTH_ACTIONS } from '../../routes';
import { forgotpasswordType, loginUserType, signupUserType } from '../../types';
// import { subdomainUI } from './subdomainUI.action';

// get payload type from base types
export const loginUser = (payload: loginUserType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: LOGIN_INIT });
    try {
        const { data } = await axios.post('/auth/signin', payload);

        //set bearer token for socket
        // subdomainUI(data.token);
        // setAuthToken(data.token);
        /**
         * @todo set token here to local storage or persist user
         * consider walletbalance been expose and
         * user have to persist to reduce uneccessary queries on refetch
         */

        //on success dispatch payload
        dispatch({ type: LOGIN_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: LOGIN_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const signupUser = (payload: signupUserType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: SIGNUP_INIT });

    try {
        const { data } = await axios.post('/auth/signup', payload);

        //set bearer token
        // setAuthToken(data.token);

        /**
         * @todo set token here to local storage or persist user
         * consider walletbalance been expose and
         * user have to persist to reduce uneccessary queries on refetch
         */
        //on success dispatch payload
        dispatch({ type: SIGNUP_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: SIGNUP_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const verifyUser = (payload: { token: string }) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: VERIFY_USER_INIT });
    try {
        const { data } = await axios.post('/auth/verifyuser', payload);

        //set bearer token
        // setAuthToken(data.token);

        /**
         * @todo set token here to local storage or persist user
         * consider walletbalance been expose and
         * user have to persist to reduce uneccessary queries on refetch
         */
        //on success dispatch payload
        dispatch({ type: VERIFY_USER_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: VERIFY_USER_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const forgotPassword = (payload: forgotpasswordType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FORGOT_PASSWORD_INIT });

    try {
        const { data } = await axios.post('/auth/forgotpassword', payload);
        dispatch({ type: FORGOT_PASSWORD_SUCCESS });

        history.push(`${AUTH_ACTIONS}?mode=confirmNotice&email=${data.email}`);

        window.location.reload();
    } catch (error) {
        dispatch({
            type: FORGOT_PASSWORD_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const resetPassword = (payload: {
    newPassword: string;
    token: string;
}) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: RESET_PASSWORD_INIT });

    try {
        const { data } = await axios.post('/auth/resetpassword', payload);
        //set bearer token
        // setAuthToken(data.token);
        /**
         * @todo set token here to local storage or persist user
         * consider walletbalance been expose and
         * user have to persist to reduce uneccessary queries on refetch
         */

        //on success dispatch payload
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: RESET_PASSWORD_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const logOut = () => async (dispatch: Dispatch): Promise<void> => {
    dispatch({
        type: LOGOUT_USER,
    });
};

export const notificationViewed = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: NOTIFICATION_VIEWED,
    });
};

export const notificationUnviewed = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: NOTIFICATION_UNVIEWED,
    });
};

export const resetAuthState = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: RESET_AUTH_STATE,
    });
};
