import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth.reducer';
import tasksReducer from './tasks.reducer';
import contestsReducer from './contests.reducer';
import paymentReducer from './payment.reducer';
import transactionReducer from './transaction.reducer';
import UIReducer from './UI.reducer';
import voteReducer from './vote.reducer';
import contestantsReducer from './contestants.reducer';
import videosReducer from './video.reducer';
import imagesReducer from './images.reducer';
import applicationReducer from './application.reducer';
import NotificationReducer from './notification.reducer';
import statsReducer from './stats.reducer';
import commentReducer from './comment.reducer';
import MediaReducer from './media.reducer';

import subdomainUIReducer from './subdomainUI.reducer';

import PlansReducer from './plans.reducer';
import accountsReducer from './enterprise.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
};

const reducer = combineReducers({
    auth: authReducer,
    contests: contestsReducer,
    contestants: contestantsReducer,
    transaction: transactionReducer,
    vote: voteReducer,
    UI: UIReducer,
    payment: paymentReducer,
    tasks: tasksReducer,
    videos: videosReducer,
    images: imagesReducer,
    applications: applicationReducer,
    notifications: NotificationReducer,
    stats: statsReducer,
    comments: commentReducer,
    subdomainStyling: subdomainUIReducer,
    plans: PlansReducer,
    accounts: accountsReducer,
    media: MediaReducer,
});

export default persistReducer(persistConfig, reducer);

export type RootState = ReturnType<typeof reducer>;
