import React, { ReactElement, FC } from 'react';
import { Button, Spin, Typography } from 'antd';
import notFound from '../../assets/notFound.svg';
import { Link } from 'react-router-dom';
import { HOME } from '../../routes';
import { firstCharToUpperCase } from '../../utils';
import { notFoundViewType } from './types';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';

const { Text } = Typography;

const NotFoundView: FC<notFoundViewType> = ({ loading }): ReactElement => {
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;
    return (
        <>
            {loading ? (
                <div className="content-wrapper">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="content-wrapper">
                    <div className="image-guard">
                        <img className="image" src={notFound} alt="not found" />
                    </div>
                    <Text className="text-1">
                        <b>PAGE NOT FOUND</b>
                    </Text>
                    <Link to={HOME}>
                        <Button
                            style={{
                                backgroundColor: `${
                                    subdomainUIstyling['0']
                                        ? subdomainUIstyling['0'][
                                              'primaryColor'
                                          ]
                                        : '#1bca8f'
                                }`,
                            }}
                        >
                            {firstCharToUpperCase('Go back home')}
                        </Button>
                    </Link>
                </div>
            )}
        </>
    );
};

export default NotFoundView;
