import { baseInstance as axios } from '../axios-base';

const setAuthToken = (token?: string | null): void => {
    if (token) {
        localStorage.setItem('token', token);

        // Apply to every request
        axios.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
        };
    } else {
        localStorage.removeItem('token');
        // Delete auth header
        delete axios.defaults.headers.common['Authorization'];
    }
};

export default setAuthToken;
