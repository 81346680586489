import { Dispatch } from 'redux';
import {
    CLOSE_ADD_EVENT_IMAGE_MODAL,
    CLOSE_ADD_EVENT_VIDEO_MODAL,
    CLOSE_COMMENT_MODAL,
    CLOSE_EVICTION_PROFILE_MODAL,
    CLOSE_NOTIFICATION_DETAIL_MODAL,
    CLOSE_NOTIFICATION_MODAL,
    CLOSE_VOTE_MODAL,
    OPEN_ADD_EVENT_IMAGE_MODAL,
    OPEN_ADD_EVENT_VIDEO_MODAL,
    OPEN_COMMENT_MODAL,
    OPEN_EVICTION_PROFILE_MODAL,
    OPEN_NOTIFICATION_DETAIL_MODAL,
    OPEN_NOTIFICATION_MODAL,
    OPEN_VOTE_MODAL,
} from '../types/UI.types';

export const openVoteModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: OPEN_VOTE_MODAL,
    });
};

export const closeVoteModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: CLOSE_VOTE_MODAL,
    });
};

export const openNotificationModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: OPEN_NOTIFICATION_MODAL,
    });
};

export const closeNotificationModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: CLOSE_NOTIFICATION_MODAL,
    });
};

export const openNotificationDetailModal = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: OPEN_NOTIFICATION_DETAIL_MODAL,
        payload: id,
    });
};

export const closeNotificationDetailModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: CLOSE_NOTIFICATION_DETAIL_MODAL,
    });
};

export const openCommentModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: OPEN_COMMENT_MODAL,
    });
};

export const closeCommentModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: CLOSE_COMMENT_MODAL,
    });
};

export const openEvictionProfileModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: OPEN_EVICTION_PROFILE_MODAL,
    });
};

export const closeEvictionProfileModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: CLOSE_EVICTION_PROFILE_MODAL,
    });
};

export const openAddEventImageModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: OPEN_ADD_EVENT_IMAGE_MODAL,
    });
};

export const closeAddEventImageModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: CLOSE_ADD_EVENT_IMAGE_MODAL,
    });
};

export const openAddEventVideoModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: OPEN_ADD_EVENT_VIDEO_MODAL,
    });
};

export const closeAddEventVideoModal = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({
        type: CLOSE_ADD_EVENT_VIDEO_MODAL,
    });
};
