import { addContestMediaTypeDb, addMediaTypeDb } from '../../types';
import { baseInstance as axios } from '../../axios-base';

import {
    ADD_CONTESTANT_VIDEO_BY_USER_SUCCESS,
    ADD_CONTESTANT_VIDEO_FAILED,
    ADD_CONTESTANT_VIDEO_INIT,
    ADD_CONTESTANT_VIDEO_SUCCESS,
    FETCH_CONTESTANT_VIDEOS_FAILED,
    FETCH_CONTESTANT_VIDEOS_INIT,
    FETCH_CONTESTANT_VIDEOS_SUCCESS,
} from '../types/contestant.type';
import { Dispatch } from 'redux';
import { USER } from '../../constants';
import {
    ADD_CONTEST_VIDEO_INIT,
    ADD_CONTEST_VIDEO_SUCCESS,
    ADD_CONTEST_VIDEO_FAILED,
    FETCH_CONTEST_VIDEOS_FAILED,
    FETCH_CONTEST_VIDEOS_INIT,
    FETCH_CONTEST_VIDEOS_SUCCESS,
} from '../types/contests.type';

export const addContestantVideo = (
    payload: addMediaTypeDb,
    role: string,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: ADD_CONTESTANT_VIDEO_INIT });
    try {
        const { data } = await axios.post('/videos', payload);
        console.log('video', data);
        if (role !== USER) {
            dispatch({ type: ADD_CONTESTANT_VIDEO_SUCCESS, payload: data });
        } else {
            dispatch({ type: ADD_CONTESTANT_VIDEO_BY_USER_SUCCESS });
        }
    } catch (error) {
        dispatch({
            type: ADD_CONTESTANT_VIDEO_FAILED,
            payload: error.response.data.message,
        });
    }
};
export const getContestantVideos = (query: {
    cid: string;
    eventId: string;
}) => async (dispatch: Dispatch): Promise<void> => {
    const { cid, eventId } = query;
    dispatch({ type: FETCH_CONTESTANT_VIDEOS_INIT });
    try {
        const { data } = await axios.get(
            `/videos?contestant=${cid}&event=${eventId}&isPublished=true`,
        );

        dispatch({ type: FETCH_CONTESTANT_VIDEOS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_CONTESTANT_VIDEOS_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const addEventVideo = (payload: addContestMediaTypeDb) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: ADD_CONTEST_VIDEO_INIT });
    try {
        const { data } = await axios.post('/videos/contest', payload);
        dispatch({ type: ADD_CONTEST_VIDEO_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: ADD_CONTEST_VIDEO_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const getContestVideos = (eventId: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_CONTEST_VIDEOS_INIT });
    try {
        const { data } = await axios.get(
            `/videos?eventMedia=true&event=${eventId}`,
        );
        dispatch({ type: FETCH_CONTEST_VIDEOS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_CONTEST_VIDEOS_FAILED,
            payload: error.response.data.message,
        });
    }
};
