import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { firstCharToUpperCase } from '../../../../utils';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../redux/reducers';

import { columnType } from '../types';
import { fetchedtaskTypeDb, fetchedEventTypeDb } from '../../../../types';
import { EVICTION_HOME } from '../../../../routes';

const dateRender = (value: Date): ReactElement => {
    return <span>{moment(value).format('LL')}</span>;
};

const eventNameRender = (value: fetchedEventTypeDb): ReactElement => {
    return <span>{firstCharToUpperCase(value.eventTitle)}</span>;
};
const titleRender = (value: string): ReactElement => {
    return <span>{firstCharToUpperCase(value)}</span>;
};

// const actionRender = (_: string, record: fetchedtaskTypeDb): ReactElement => (
//     <Link
//         className="view-btn"
//         to={`${EVICTION_HOME}${record._id}/view?event=${record.event._id}`}

//     >
//         view
//     </Link>
// );

const ActionRender = (_: string, record: fetchedtaskTypeDb): ReactElement => {
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;
    return (
        <Link
            className="view-btn-dark"
            to={`${EVICTION_HOME}${record._id}/view?event=${record.event._id}`}
            // to={`${TRANSACTION_VOTING_HISTORY}?id=${record._id}`}

            // style={{ backgroundColor: subdomainUIstyling[0].primaryColor }}
            style={{
                background: `${
                    subdomainUIstyling[0]
                        ? subdomainUIstyling[0].primaryColor
                        : '#1bca8f'
                }`,
                width: '100%',
                color: 'white',
            }}
        >
            view contestants
        </Link>
    );
};

// const postMediaRender = (value: boolean): ReactElement => {
//     return value ? (
//         <Tag className="yes-tag">YES</Tag>
//     ) : (
//         <Tag className="no-tag">NO</Tag>
//     );
// };

const TableColumn: columnType = [
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        render: dateRender,
    },
    {
        title: 'Contest',
        dataIndex: 'event',
        render: eventNameRender,
    },

    {
        title: 'title',
        dataIndex: 'title',
        render: titleRender,
    },
    // {
    //     title: 'Post Image',
    //     dataIndex: 'postImage',
    //     render: postMediaRender,
    // },

    // {
    //     title: 'Post Video',
    //     dataIndex: 'postVideo',
    //     render: postMediaRender,
    // },
    {
        title: 'End Date',
        dataIndex: 'endDate',
        render: dateRender,
    },
    {
        title: '',
        key: 'action',
        dataIndex: 'action',
        render: ActionRender,
    },
];

export default TableColumn;
