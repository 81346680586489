import { CloseOutlined } from '@ant-design/icons';
import { useWindowWidth } from '@react-hook/window-size';
import { Drawer } from 'antd';
import moment from 'moment';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeNotificationDetailModal } from '../../../../redux/actions/UI.action';
import { RootState } from '../../../../redux/reducers';

const DetailsDrawer: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();
    const [isMobile, setIsMobile] = useState(false);
    const notificationModalIsOpen = useSelector(
        (state: RootState) => state.UI.notificationDetailModalIsOpen,
    );
    const notifications = useSelector(
        (state: RootState) => state.notifications,
    );
    const { notificationDetail } = notifications;
    useEffect(() => {
        if (windowWidth <= 760) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowWidth]);

    return (
        <Drawer
            placement="right"
            width={!isMobile ? 420 : '100vw'}
            onClose={() => dispatch(closeNotificationDetailModal())}
            closable={false}
            visible={notificationModalIsOpen}
            mask={false}
            drawerStyle={{ padding: '20px 0px 40px' }}
            bodyStyle={{ padding: '20px', borderLeft: '4px #13bc78 solid' }}
            className="drawer-modal"
        >
            <div style={{ textAlign: 'end' }}>
                <CloseOutlined
                    className="close_icon"
                    style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                    onClick={() => dispatch(closeNotificationDetailModal())}
                />
            </div>
            <div className="notifi_title">{notificationDetail?.title}</div>
            <div className="notifi_time">
                {moment(notificationDetail?.createdAt).fromNow()}
            </div>
            <div className="notifi_details">{notificationDetail?.content}</div>
        </Drawer>
    );
};

export default DetailsDrawer;
