import { fetchSudomainAccountTypeDb } from '../../types';
import { CLEAR_MESSAGE, CLEAR_ERROR } from '../types/common.type';
import {
    FETCH_ACCOUNTS_INIT,
    FETCH_ACCOUNTS_SUCCESS,
    FETCH_ACCOUNTS_FAILED,
    APPROVE_ACCOUNT_INIT,
    APPROVE_ACCOUNT_FAILED,
    APPROVE_ACCOUNT_SUCCESS,
} from '../types/enterprise.type';

type ACCOUNT_INIT_STATE = {
    accountsData: fetchSudomainAccountTypeDb[];
    loading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const ACCOUNT_INITIAL_STATE: ACCOUNT_INIT_STATE = {
    accountsData: [],
    loading: false,
    feedbackMsg: null,
    error: null,
};

const accountsReducer = (
    state = ACCOUNT_INITIAL_STATE,
    action: any,
): ACCOUNT_INIT_STATE => {
    switch (action.type) {
        case FETCH_ACCOUNTS_INIT:
        case APPROVE_ACCOUNT_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case FETCH_ACCOUNTS_SUCCESS: {
            const accounts = [...action.payload].reverse();

            return {
                ...state,
                accountsData: accounts,
                loading: false,
                error: null,
            };
        }

        case APPROVE_ACCOUNT_SUCCESS: {
            const accounts = [...state.accountsData];
            const index = accounts.findIndex(
                (el) => el._id === action.payload._id,
            );
            accounts[index].isApproved = action.payload.isApproved;

            return {
                ...state,
                // accountsData: accounts,
                loading: false,
                error: null,
            };
        }

        case FETCH_ACCOUNTS_FAILED:
        case APPROVE_ACCOUNT_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }
        default:
            return state;
    }
};

export default accountsReducer;
