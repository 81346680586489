export const COMMENT_FETCH_INIT = 'COMMENT_FETCH_INIT';
export const COMMENT_FETCH_SUCCESS = 'COMMENT_FETCH_SUCCESS';
export const COMMENT_FETCH_FAILED = 'COMMENT_FETCH_FAILED';

export const CLEAR_COMMENT_DATAS = 'CLEAR_COMMENT_DATAS';
export const SELECTED_IMAGE_DATA = 'SELECTED_IMAGE_DATA';

export const ADD_COMMENT_INIT = 'ADD_COMMENT_INIT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILED = 'ADD_COMMENT_FAILED';

export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILED = 'DELETE_COMMENT_FAILED';
