import { Alert, Button, Col, List, message, Row } from 'antd';
import { Input, handlerEvent } from '../../../input';
import React, { ReactElement, FC, FormEvent, useState } from 'react';
import { firstCharToUpperCase } from '../../../../utils';
import { routerParamsType } from '../types';

import { useHistory, useParams } from 'react-router-dom';
import { CONTEST_HOME } from '../../../../routes';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { useEffect } from 'react';
import {
    clearError,
    clearMessage,
} from '../../../../redux/actions/common.action';
import { FileUploader } from '../../../fileUploader';
import { addContestant } from '../../../../redux/actions/contestant.action';

const AddContestant: FC = (): ReactElement => {
    const { id }: routerParamsType = useParams();
    const { push } = useHistory();
    const dispatch = useDispatch();
    const contestantsState = useSelector(
        (state: RootState) => state.contestants,
    );
    const { error: requestError, loading, feedbackMsg } = contestantsState;
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    const [formInputs, setFormInputs] = useState({
        fullName: '',
        email: '',
        age: '',
        bio: '',
        imageUrl: '',
    });

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }
        if (feedbackMsg) {
            //clear form inputs
            setFormInputs({
                fullName: '',
                email: '',
                age: '',
                bio: '',
                imageUrl: '',
            });
            message.success(feedbackMsg);
            push(`${CONTEST_HOME}${id}`);
        }

        //clean up error and message
        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
        };
    }, [requestError, feedbackMsg, id, dispatch, push]);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInputs({ ...formInputs, [name]: value });
    };

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        return dispatch(
            addContestant({
                ...formInputs,
                age: parseInt(formInputs.age, 10),
                event: id,
            }),
        );
    };

    const uploadCompleted = (url: string) => {
        setFormInputs({
            ...formInputs,
            imageUrl: url,
        });
    };

    const btnDisable = () => {
        const { fullName, imageUrl, email } = formInputs;
        return !fullName || !imageUrl || !email;
    };
    const data = [
        'The Email Field is not optional.',
        'Contestant email must be provided.',
        // 'If no email is provided, the contest-owner would be the only one to upload images and videos for each contestants.',
        // 'If a contestant email is provided, the contestant would be the only one to upload images and videos.',
        // 'If a support team email is provided, the email associated to the holder would be the only one to upload images and videos for each contestants..',
    ];

    return (
        <Row align="middle">
            <Col md={12}>
                <div className="addcontestant box-shadow">
                    <form onSubmit={onFormSubmit}>
                        <Row
                            gutter={{ sm: 0, md: 20 }}
                            className="fieldrow first-row"
                        >
                            <Col xs={24}>
                                <Input
                                    name="fullName"
                                    label={firstCharToUpperCase('Full Name')}
                                    value={formInputs.fullName}
                                    hasLabel={true}
                                    placeholder="Selina Gomez"
                                    onChange={onInputChange}
                                />
                            </Col>
                        </Row>
                        <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                            <Col xs={24}>
                                <Input
                                    name="email"
                                    onChange={onInputChange}
                                    inputType="email"
                                    hasLabel={true}
                                    placeholder="Enter contestant email Address"
                                    value={formInputs.email}
                                    label="Email"
                                />
                            </Col>
                        </Row>
                        <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                            <Col xs={24} md={12}>
                                <Input
                                    name="age"
                                    onChange={onInputChange}
                                    inputType="number"
                                    label={firstCharToUpperCase('Age')}
                                    hasLabel={true}
                                    value={formInputs.age}
                                    placeholder="35"
                                />
                            </Col>

                            <Col xs={24} md={12}>
                                <FileUploader
                                    label={firstCharToUpperCase('cover image')}
                                    onUploadComplete={uploadCompleted}
                                />
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                            <Col xs={24}>
                                <Input
                                    name="bio"
                                    onChange={onInputChange}
                                    label={firstCharToUpperCase('Bio')}
                                    hasLabel={true}
                                    value={formInputs.bio}
                                    componentType="TextArea"
                                    placeholder="description of yourself"
                                />
                            </Col>
                        </Row>

                        <Row
                            gutter={{ xs: 10, md: 20 }}
                            justify="end"
                            className="fieldrow btn-container"
                        >
                            <Col xs={10} md={5}>
                                <Button
                                    onClick={() => push(`${CONTEST_HOME}${id}`)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={14} md={8}>
                                <Button
                                    disabled={btnDisable()}
                                    loading={loading}
                                    htmlType="submit"
                                    style={{
                                        background: `${
                                            subdomainUIstyling['0'] &&
                                            subdomainUIstyling['0'][
                                                'primaryColor'
                                            ]
                                        }`,
                                        width: '100%',
                                        color: 'white',
                                    }}
                                >
                                    Add Contestant
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Col>
            <Col md={12}>
                <div className="addcontestant box-shadow">
                    <List
                        size="large"
                        header={
                            <Alert
                                message="Please read the following conditions"
                                type="info"
                                showIcon
                            />
                        }
                        footer={
                            <div>
                                <b> Attention:</b> The app creates an account
                                for emails not yet registered and login
                                credentails are sent to the emails added.
                            </div>
                        }
                        // bordered
                        dataSource={data}
                        renderItem={(item, index) => (
                            <List.Item>
                                <span
                                    style={{
                                        marginRight: '5px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {index + 1}.
                                </span>
                                {item}
                            </List.Item>
                        )}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default AddContestant;
