export const ADD_CONTEST_INIT = 'ADD_CONTEST_INIT';
export const ADD_CONTEST_SUCCESS = 'ADD_CONTEST_SUCCESS';
export const ADD_CONTEST_FAILED = 'ADD_CONTEST_FAILED';

export const CONTEST_FETCH_INIT = 'CONTEST_FETCH_INIT';
export const CONTEST_FETCH_SUCCESS = 'CONTEST_FETCH_SUCCESS';
export const CONTEST_FETCH_FAILED = 'CONTEST_FETCH_FAILED';

export const CONTEST_FETCH_INIT_BY_ID = 'CONTEST_FETCH_INIT_BY_ID';
export const CONTEST_FETCH_SUCCESS_BY_ID = 'CONTEST_FETCH_SUCCESS_BY_ID';
export const CONTEST_FETCH_FAILED_BY_ID = 'CONTEST_FETCH_FAILED_BY_ID';

export const CLEAR_EVENT_DETAIL = 'CLEAR_EVENT_DETAIL';
export const CLEAR_CONTEST = 'CLEAR_CONTEST';

export const FETCH_CONTEST_IMAGE_INIT = 'FETCH_CONTEST_IMAGE_INIT';
export const FETCH_CONTEST_IMAGE_SUCCESS = 'FETCH_CONTEST_IMAGE_SUCCESS';
export const FETCH_CONTEST_IMAGE_FAILED = 'FETCH_CONTEST_IMAGE_FAILED';

export const ADD_CONTEST_IMAGE_INIT = 'ADD_CONTEST_IMAGE_INIT';
export const ADD_CONTEST_IMAGE_SUCCESS = 'ADD_CONTEST_IMAGE_SUCCESS';
export const ADD_CONTEST_IMAGE_FAILED = 'ADD_CONTEST_IMAGE_FAILED';

export const ADD_CONTEST_VIDEO_INIT = 'ADD_CONTEST_VIDEO_INIT';
export const ADD_CONTEST_VIDEO_SUCCESS = 'ADD_CONTEST_VIDEO_SUCCESS';
export const ADD_CONTEST_VIDEO_FAILED = 'ADD_CONTEST_VIDEO_FAILED';

export const FETCH_CONTEST_VIDEOS_INIT = 'FETCH_CONTEST_VIDEOS_INIT';
export const FETCH_CONTEST_VIDEOS_SUCCESS = 'FETCH_CONTEST_VIDEOS_SUCCESS';
export const FETCH_CONTEST_VIDEOS_FAILED = 'FETCH_CONTEST_VIDEOS_FAILED';
