import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Row, Col, Table, Image } from 'antd';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPendingImages } from '../../../../redux/actions/media.action';
import { RootState } from '../../../../redux/reducers';
// import { Searchbar } from '../../../searchbar';
import TableColumn from './TableColumn';

const Home: FC = (): ReactElement => {
    const { pendingImages, loading } = useSelector(
        (state: RootState) => state.media,
    );
    const [tableData, setTableData] = useState<any[]>([]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllPendingImages());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTableData(pendingImages);
    }, [pendingImages]);
    return (
        <>
            <Row className="table-content">
                <Col className="table-wrapper" span={24}>
                    <Table
                        loading={loading}
                        title={() => <h2>Images</h2>}
                        columns={TableColumn}
                        dataSource={tableData || []}
                        size="small"
                        rowKey="_id"
                        expandable={{
                            expandedRowRender: (record) => (
                                <Row justify="center">
                                    <Col
                                        style={{
                                            width: '200px',
                                            height: '200px',
                                        }}
                                    >
                                        <Image
                                            className="images-expand"
                                            src={record.url}
                                        />
                                    </Col>
                                </Row>
                            ),
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <MinusCircleTwoTone
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                ) : (
                                    <PlusCircleTwoTone
                                        onClick={(e) => onExpand(record, e)}
                                    />
                                ),
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Home;
