import { Row, Col } from 'antd';
import React, { FC, ReactElement, useContext, useEffect, useMemo } from 'react';
import { handlerEvent, Input } from '../../../../input';
import { FormContext } from '../formProvider/FormContext';
import _debounce from 'lodash/debounce';
import { useState } from 'react';
// import { useEffect } from 'react';

const Step1: FC = (): ReactElement => {
    const { formInputs, setFormInputs, eventInfo, mode } = useContext(
        FormContext,
    );
    const [inputValue, setInputValue] = useState({
        eventTitle: '',
        organiserName: '',
        eventDetails: '',
    });

    useEffect(() => {
        if (eventInfo && mode === 'edit') {
            setInputValue({
                eventTitle: eventInfo.eventTitle,
                organiserName: eventInfo.organiserName,
                eventDetails: eventInfo.eventDetails,
            });
        }
    }, [eventInfo, mode]);

    useEffect(() => {
        servicesDebounced(inputValue);
        // eslint-disable-next-line
    }, [inputValue]);

    const onInputChange = (inputValue) => {
        setFormInputs({
            ...formInputs,
            ...inputValue,
        });
    };

    const servicesDebounced = useMemo(
        () => _debounce(onInputChange, 1000),
        // eslint-disable-next-line
        [formInputs],
    );

    const handleChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    return (
        <>
            <Row className="field-row">
                <Col xs={24}>
                    <Input
                        name="eventTitle"
                        value={inputValue.eventTitle}
                        placeholder="input contest title"
                        onChange={handleChange}
                        hasLabel
                        label="Contest Title"
                    />
                </Col>
            </Row>

            <Row className="field-row">
                <Col xs={24}>
                    <Input
                        name="organiserName"
                        value={inputValue.organiserName}
                        placeholder="input organiser name"
                        onChange={handleChange}
                        hasLabel
                        label="Organiser Name"
                    />
                </Col>
            </Row>

            <Row className="field-row">
                <Col xs={24}>
                    <Input
                        name="eventDetails"
                        value={inputValue.eventDetails}
                        placeholder="A short description to display for this contest"
                        onChange={handleChange}
                        componentType="TextArea"
                        rows={5}
                        hasLabel
                        label="Contest Details"
                    />
                </Col>
            </Row>
        </>
    );
};

export default Step1;
