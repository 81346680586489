import { Dispatch } from 'redux';

import { baseInstance as axios } from '../../axios-base';
import {
    addContestantTypeDb,
    evictContestantType,
    fetchedContestantTypeDb,
} from '../../types';
import {
    EVICT_CONTESTANT_SUCCESS,
    CONTESTANTS_FETCH_SUCCESS,
    DELETE_CONTESTANT,
    DELETE_CONTESTANT_SUCCESS,
    DELETE_CONTESTANT_FAILED,
    ADD_CONTESTANT_SUCCESS,
    FETCH_CONTESTANT_DETAILS_SUCCESS,
    CLEAR_CONTESTANT_DATAS,
    CONTESTANTS_FETCH_FAILED,
    CONTESTANTS_FETCH_INIT,
    EVICT_CONTESTANT_FAILED,
    EVICT_CONTESTANT_INIT,
    ADD_CONTESTANT_FAILED,
    ADD_CONTESTANT_INIT,
    FETCH_CONTESTANT_DETAILS_FAILED,
    FETCH_CONTESTANT_DETAILS_INIT,
    EVICTION_CONTESTANT_DETAILS,
} from '../types/contestant.type';

export const evictContestant = (payload: evictContestantType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: EVICT_CONTESTANT_INIT });
    try {
        const { data } = await axios.post('/contestants/evict', payload);
        dispatch({ type: EVICT_CONTESTANT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: EVICT_CONTESTANT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const fetchContestants = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: CONTESTANTS_FETCH_INIT });
    try {
        const { data } = await axios.get(`/contestants?event=${id}`);
        dispatch({ type: CONTESTANTS_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CONTESTANTS_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const deleteContestant = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: DELETE_CONTESTANT });

    try {
        const { data } = await axios.delete(`/contestants/${id}`);
        dispatch({ type: DELETE_CONTESTANT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: DELETE_CONTESTANT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const getContestant = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_CONTESTANT_DETAILS_INIT });
    try {
        const { data } = await axios.get(`/contestants/${id}`);

        dispatch({ type: FETCH_CONTESTANT_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_CONTESTANT_DETAILS_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const getContestantBySharedId = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_CONTESTANT_DETAILS_INIT });
    try {
        const { data } = await axios.post(`/contestants/shareid`, { id: id });
        dispatch({ type: FETCH_CONTESTANT_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_CONTESTANT_DETAILS_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const clearContestantData = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: CLEAR_CONTESTANT_DATAS });
};

export const addContestant = (payload: addContestantTypeDb) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: ADD_CONTESTANT_INIT });
    try {
        const { data } = await axios.post('/contestants', payload);
        dispatch({ type: ADD_CONTESTANT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: ADD_CONTESTANT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const evictContestantData = (data: fetchedContestantTypeDb) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: EVICTION_CONTESTANT_DETAILS, payload: data });
};
