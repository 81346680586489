import { Dispatch } from 'redux';
import { baseInstance as axios } from '../../axios-base';
import { activeVoteContestantType, voteContestantType } from '../../types';
import { CLOSE_VOTE_MODAL } from '../types/UI.types';
import {
    FETCH_VOTE_SUCCESS,
    VOTE_CONTESTANT,
    SET_ACTIVE_CONTESTANT,
    VOTE_CONTESTANT_SUCCESS,
    VOTE_CONTESTANT_FAILED,
    FETCH_VOTE_FAILED,
    FETCH_VOTE_INIT,
} from '../types/vote.type';

export const fetchAllVotes = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_VOTE_INIT });
    try {
        const { data } = await axios.get('/vote');
        dispatch({ type: FETCH_VOTE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_VOTE_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const fetchEventVotes = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_VOTE_INIT });
    try {
        const { data } = await axios.get(`/vote?event=${id}`);
        dispatch({ type: FETCH_VOTE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_VOTE_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const fetchMyVotes = () => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: FETCH_VOTE_INIT });
    try {
        const { data } = await axios.post('/vote/myvotes');
        dispatch({ type: FETCH_VOTE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_VOTE_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const voteContestant = (payload: voteContestantType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    //initialize fetch process
    dispatch({
        type: VOTE_CONTESTANT,
    });

    try {
        await axios.post('/vote', payload);
        dispatch({ type: VOTE_CONTESTANT_SUCCESS });
    } catch (error) {
        dispatch({
            type: VOTE_CONTESTANT_FAILED,
            payload: error.response.data.message,
        });
    }
    dispatch({
        type: CLOSE_VOTE_MODAL,
    });
};

export const setActiveContestant = (
    activeContestant: activeVoteContestantType,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({
        type: SET_ACTIVE_CONTESTANT,
        payload: activeContestant,
    });
};
