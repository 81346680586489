import React, { ReactElement } from 'react';
import { firstCharToUpperCase } from '../../../../utils';
import moment from 'moment';
import { Image, Popconfirm, Tag } from 'antd';
import { columnType } from '../types';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { applicationStatus } from '../../../../constants/applicationStatusEnum';
import { useDispatch } from 'react-redux';
import {
    acceptApplication,
    rejectApplication,
} from '../../../../redux/actions/application.action';
import { fetchApplicationTypeDb } from '../../../../types';

const ImageRender = (value: string): ReactElement => {
    return <Image className="table-img" src={value} />;
};

const dateRender = (value: Date): ReactElement => {
    return <span>{moment(value).format('LL')}</span>;
};

const fullNameRender = (value: string): ReactElement => {
    return <span>{firstCharToUpperCase(value)}</span>;
};

const statusRender = (value: string): ReactElement => (
    <>
        {value === applicationStatus.PROCESSING && (
            <Tag icon={<SyncOutlined />} color="processing">
                PROCESSING
            </Tag>
        )}
        {value === applicationStatus.ACCEPTED && (
            <Tag icon={<CheckCircleOutlined />} color="success">
                ACCEPTED
            </Tag>
        )}
        {value === applicationStatus.REJECTED && (
            <Tag icon={<CloseCircleOutlined />} color="error">
                REJECTED
            </Tag>
        )}
    </>
);
const ActionRender = (
    value: string,
    record: fetchApplicationTypeDb,
): ReactElement => {
    const dispatch = useDispatch();
    return (
        <>
            {value === applicationStatus.PROCESSING && (
                <>
                    <Popconfirm
                        placement="left"
                        title="Are you sure to ACCEPT this applicant?"
                        onConfirm={() =>
                            dispatch(acceptApplication(record._id))
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tag color="#1bca8f" style={{ cursor: 'pointer' }}>
                            ACCEPT
                        </Tag>
                    </Popconfirm>
                    <Popconfirm
                        placement="left"
                        title="Are you sure to REJECT this applicant?"
                        onConfirm={() =>
                            dispatch(rejectApplication(record._id))
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tag color="#cd201f" style={{ cursor: 'pointer' }}>
                            REJECT
                        </Tag>
                    </Popconfirm>
                </>
            )}
        </>
    );
};

const TableColumn: columnType = [
    {
        title: 'Application Date',
        dataIndex: 'createdAt',
        render: dateRender,
    },
    {
        title: 'Image',
        dataIndex: 'imageUrl',
        render: ImageRender,
    },

    {
        title: 'Full Name',
        dataIndex: 'fullName',
        render: fullNameRender,
    },

    {
        title: 'status',
        dataIndex: 'status',
        render: statusRender,
    },
    {
        title: '',
        key: 'action',
        dataIndex: 'status',
        render: ActionRender,
    },
];

export default TableColumn;
