export const TASK_STATS_FETCH_INIT = 'TASK_STATS_FETCH_INIT';
export const TASK_STATS_FETCH_SUCCESS = 'TASK_STATS_FETCH_SUCCESS';
export const TASK_STATS_FETCH_FAILED = 'TASK_STATS_FETCH_FAILED';

export const VOTE_STATS_FETCH_INIT = 'VOTE_STATS_FETCH_INIT';
export const VOTE_STATS_FETCH_SUCCESS = 'VOTE_STATS_FETCH_SUCCESS';
export const VOTE_STATS_FETCH_FAILED = 'VOTE_STATS_FETCH_FAILED';

export const APPLICANT_STATS_FETCH_INIT = 'APPLICANT_STATS_FETCH_INIT';
export const APPLICANT_STATS_FETCH_SUCCESS = 'APPLICANT_STATS_FETCH_SUCCESS';
export const APPLICANT_STATS_FETCH_FAILED = 'APPLICANT_STATS_FETCH_FAILED';

export const CLEAR_STATS = 'CLEAR_STATS';
