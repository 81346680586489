import { Col, Row } from 'antd';
import React, { FC, ReactElement } from 'react';
import flutterImage from '../../../assets/flutterwave.svg';
import { flutterProps } from '../types';

const FlutterwaveButton: FC<flutterProps> = ({
    handleFlutterPay,
}): ReactElement => {
    return (
        <Row
            style={{
                border: '1px solid #F6B13E',
                padding: '5px 10px',
                cursor: 'pointer',
                borderRadius: '20px',
                height: '40px',
            }}
            justify="center"
            onClick={handleFlutterPay}
        >
            <Col>
                <span
                    style={{
                        fontSize: '16px',
                        marginRight: '7px',
                    }}
                >
                    Pay with
                </span>
                <span>
                    <img
                        style={{ height: '100%', width: '110px' }}
                        src={flutterImage}
                        alt="flutterImage"
                    />
                </span>
            </Col>
        </Row>
    );
};

export default FlutterwaveButton;
