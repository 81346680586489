import { Moment } from 'moment';
import { paymentType } from './constants/transactionEnum';

export type loginUserType = {
    email: string;
    password: string;
    domain: string;
    subDomain: string;
};

export type signupUserType = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    password: string;
    defaultAccount: string;
    domain: string;
};

export type forgotpasswordType = {
    email: string;
    domain: string;
};

export type addContestantTypeDb = {
    fullName: string;
    email?: string;
    age: number;
    bio: string;
    imageUrl: string;
    event: string;
};
export type fetchedContestantTypeDb = {
    _id: string;
    fullName: string;
    age: number;
    bio: string;
    event: string;
    user: string;
    imageUrl: string;
    totalVotes: number;
    isEvicted: boolean;
    evictedStage: string[];
    evictedDate: Date;
    isActive: boolean;
};
export type fetchedSingleContestantTypeDb = {
    _id: string;
    fullName: string;
    age: number;
    bio: string;
    event: fetchedEventTypeDb;
    user: string;
    shareId: string;
    imageUrl: string;
    totalVotes: number;
    isEvicted: boolean;
    evictedStage: string[];
    evictedDate: Date;
    isActive: boolean;
};

export type fetchedEventTypeDb = {
    _id: string;
    user: string;
    eventTitle: string;
    organiserName: string;
    eventDetails: string;
    image: string;
    isPublished: boolean;
    requirements: string;
    formApply: boolean;
    entryPayment: number;
    costPerVote: number;
    isPaid: boolean;
    startDate: Moment;
    closingDate: Moment;
    plan: plansType;
    totalApplications: number;
    subDomain: string;
    totalTasks: number;
};

export type userTypeDb = {
    createdAt: string;
    email: string;
    firstName: string;
    imageUrl: string;
    isVerify: boolean;
    lastName: string;
    phoneNumber: string;
    role: string;
    lastConnected: string;
    walletBalance: number;
    defaultAccount: string;
    _id: string;
};

export type transactionTypeDb = {
    authorizationUrl: string;
    amount: number;
    date: string;
    event: string;
    isApplication: boolean;
    isSuccess: boolean;
    reference: string;
    status: string;
    user: string;
    paymentMethod: paymentType;
    votes: number;
    _id: string;
};

export type voteTypeDb = {
    numberOfVote: number;
    _id: string;
    contestant: fetchedContestantTypeDb;
    userRef: userTypeDb;
    createdAt: string;
    event: string;
};

export type activeVoteContestantType = {
    fullName: string;
    contestantId: string;
    imageUrl: string;
    event: string;
};

export type voteContestantType = {
    numberOfVote: number;
    contestant: string;
    event: string;
};

export type initializePaymentType = {
    amount: number;
    callback_url: string;
    paymentMethod: paymentType;
    event?: string;
};

export type leaderBoardType = {
    _id: string;
    totalVote: number;
    nUser: number;
    contestantRef: {
        fullName: string;
        imageUrl: string;
    };
};

export type addContestTypeDb = {
    eventTitle: string;
    organiserName: string;
    eventDetails: string;
    image: string;
    startDate: Moment;
    closingDate: Moment;
    requirements: string;
    entryPayment: number;
    formApply: boolean;
    isPaid: boolean;
    isPublished: boolean;
};

export type fetchedtaskTypeDb = {
    _id: string;
    title: string;
    task: string;
    postImage: boolean;
    postVideo: boolean;
    event: fetchedEventTypeDb;
    startDate: string;
    endDate: string;
    isActive: boolean;
};

export type evictContestantType = {
    _id: string;
    taskId: string;
    evictionNote: string;
};

export type addTaskInputType = {
    event: string;
    title: string;
    task: string;
    postImage: boolean;
    postVideo: boolean;
    startDate: Moment;
    endDate: Moment;
};

export type updateTaskInputType = {
    title: string;
    task: string;
    postImage: boolean;
    postVideo: boolean;
    startDate: string;
    endDate: string;
};

export type addMediaTypeDb = {
    _id?: string;
    contestant: string;
    event: string;
    task?: string;
    url: string;
};

export type fetchMediaTypeDb = {
    _id: string;
    contestant: string;
    event: string;
    url: string;
    numberOfLikes: number;
    liked: boolean;
    likes: { userId: string }[];
    taskTitle: string;
};

export type fetchVidMediaTypeDb = {
    _id: string;
    contestant: string;
    event: string;
    url: string;
    numberOfLikes: number;
    liked: boolean;
    likes: { userId: string }[];
    task: fetchedtaskTypeDb;
};

export type routerParamsType = {
    id: string;
};

export type initializeApplicationPaymentType = {
    transactionDetails: {
        amount: number;
        callback_url: string;
        event: string;
        user?: string;
        paymentMethod: paymentType;
    };
    applicationDetails: {
        event: string;
        fullName: string;
        bio: string;
        age: number;
        imageUrl: string;
    };
};

export type initializeVotingPaymentType = {
    transactionDetails: {
        amount: number;
        callback_url: string;
        paymentMethod: paymentType;
    };
    voteDetails: {
        numberOfVote: number;
        contestant: string;
        event: string;
    };
    userDetails: {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
    };
};

export type initializeSubscriptionType = {
    transactionDetails: {
        amount: number;
        callback_url: string;
        paymentMethod: paymentType;
    };
    userDetails: {
        email: string;
        firstName: string;
        lastName: string;
        password: string;
        phoneNumber: string;
        defaultAccount: string;
    };
    accountDetails: {
        companyName: string;
        companyAddress: string;
        companyLocation: string;
        plan: string;
        pageantName: string;
        pageantStartDate: string;
        pageantEndDate: string;
    };
};

export type freeSubscriptionType = {
    userDetails: {
        email: string;
        firstName: string;
        lastName: string;
        password: string;
        phoneNumber: string;
        defaultAccount: string;
    };
    accountDetails: {
        companyName: string;
        companyAddress: string;
        companyLocation: string;
        plan?: string;
        pageantName: string;
        pageantStartDate: string;
        pageantEndDate: string;
    };
};
export type fetchApplicationTypeDb = {
    status: boolean;
    _id: string;
    fullName: string;
    age: number;
    bio: string;
    imageUrl: string;
    event: string;
    user: string;
    createdAt: string;
};

export type fetchNotificationTypeDb = {
    notifyType: string;
    _id: string;
    eventId: string;
    title: string;
    content: string;
    createdAt: string;
};

export type fetchEventStats = {
    _id: string;
    eventTitle: string;
    numTasks: number;
};

export type fetchSudomainAccountTypeDb = {
    _id: string;
    logo: string;
    bannerImage: string;
    authImage: string;
    aboutCompany: string;
    companyName: string;
    companyAddress: string;
    companyLocation: string;
    primaryColor: string;
    secondaryColor: string;
    plan: plansType;
    subDomain: string;
    startDate: string;
    endDate: string;
    setUpCompleted: boolean;
    isApproved: boolean;
    userAccess: boolean;
    paymentMethods: string[];
};

export type fetchVoteStats = {
    _id: string;
    eventTitle: string;
    totalVotes: number;
};

export type fetchApplicantStats = {
    _id: string;
    eventTitle: string;
    totalApplcants: number;
    totalAmount: number;
};

export type addApplicationType = {
    event: string;
    fullName: string;
    bio: string;
    age: number;
    imageUrl: string;
};

export type selectedImageType = {
    id: string;
    url: string;
};

export type fetchCommentTypeDb = {
    _id: string;
    comment: string;
    createdAt: string;
    imageId: null | string;
    user: {
        _id: string;
        firstName: string;
        imageUrl: string;
        lastName: string;
    };
    videoId: null | string;
};

export type addCommentType = {
    comment: string;
    imageId: string;
};

export type accountSetup = {
    logo: string;
    bannerImage: string;
    authImage: string;
    primaryColor: string;
    secondaryColor: string;
    aboutCompany: string;
};

export type plansType = {
    _id: string;
    price: number;
    activePlan: string;
    hotPlan: string;
    cycle: string;
    taskLimit: number;
    applicationLimit: number;
    imagesPerContestant: number;
    videosPerContestant: number;
};

export type fetchContestImage = {
    event: string;
    eventMedia: boolean;
    url: string;
    _id: string;
};

export type fetchContestVideo = {
    event: string;
    eventMedia: boolean;
    url: string;
    _id: string;
};

export type addContestMediaTypeDb = {
    event: string;
    url: string;
};

export type paypalApprovedPaymentType = {
    transactionDetails: {
        amount: number;
        paymentMethod: paymentType;
        domain: string;
        transactionID: string;
    };

    voteDetails: {
        numberOfVote: number;
    };
    userDetails: {
        firstName: string;
        lastName: string;
        email: string;
    };
};

export type onVerifyPaypalpaymentType = {
    amount: number;
    transactionID: string;
    status: string;
};

export enum paymentStatus {
    PROCESSING = 'PROCESSING',
    COMPELTE = 'COMPLETE',
    CANCELLED = 'CANCELLED',
    FAILED = 'FAILED',
}

export type fetchPendingMediasDb = {
    isPublished: boolean;
    eventMedia: boolean;
    contestant: fetchedContestantTypeDb;
    event: fetchedEventTypeDb;
    url: string;
    _id: string;
    task: fetchedtaskTypeDb;
    accepted: boolean;
};
