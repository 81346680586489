import { LeftCircleOutlined } from '@ant-design/icons';
import { Col, message, Row } from 'antd';
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { verifySubscriptionPayment } from '../../../../redux/actions/payment.action';
import { fetchPlanById } from '../../../../redux/actions/plans.action';
import { RootState } from '../../../../redux/reducers';
import { HOME } from '../../../../routes';
import { routerParamsType } from '../../../../types';
import { TopBarLoader } from '../../../topBarProgress';
import Loader from 'react-loader-spinner';
import { paymentType } from '../../../../constants/transactionEnum';

const DnsSetup: FC = (): ReactElement => {
    const dispatch = useDispatch();

    const {
        location: { search },
        push,
    } = useHistory();
    const transactionRef = new URLSearchParams(search).get('reference');
    const transactionID = new URLSearchParams(search).get('transaction_id');
    const mode = new URLSearchParams(search).get('m');

    const { id }: routerParamsType = useParams();

    const plansState = useSelector((state: RootState) => state.plans);
    const { singlePlan, loading } = plansState;

    useEffect(() => {
        if (!id) return;
        dispatch(fetchPlanById(id));
    }, [id, dispatch]);

    // on mount
    useEffect(() => {
        if (mode === 'free') {
            return;
        }
        if (!transactionRef && !transactionID) {
            message.error('Invalid route params', 500);
            return push(HOME);
        }
        if (transactionRef) {
            dispatch(
                verifySubscriptionPayment({
                    reference: transactionRef,
                    paymentMethod: paymentType.PAYSTACK,
                }),
            );
        } else if (transactionID) {
            dispatch(
                verifySubscriptionPayment({
                    reference: transactionID,
                    paymentMethod: paymentType.FLUTTERWAVE,
                }),
            );
        }
    }, [push, transactionRef, transactionID, dispatch, mode]);
    return (
        <Row className="enterprise-acc">
            <TopBarLoader loading={loading} />
            <Col md={8} className="enterprise-acc__left">
                <div className="enterprise-acc__left--head">
                    <Link to={HOME}>
                        <span className="enterprise-acc__left--head-icon">
                            <LeftCircleOutlined
                                style={{
                                    color: '#11B86F',
                                    fontSize: '22px',
                                    marginRight: '8px',
                                }}
                            />
                        </span>
                    </Link>

                    <Link to={HOME}>
                        <span className="enterprise-acc__left--head-text">
                            Go home
                        </span>
                    </Link>
                </div>
                <div className="enterprise-acc__left--info">
                    Pageant africa platform provides various solutions to the
                    Pageant Industry. Join us as we pilot your business to the
                    next level.
                </div>
                <div className="enterprise-acc__left--plan">
                    {mode === 'free' ? (
                        <>
                            <span>SUCCESSFULL</span>
                            <span>Free Plan</span>
                        </>
                    ) : (
                        <>
                            <span>SUCCESSFULL PURCHASED!!!</span>
                            <span>
                                {singlePlan?.activePlan.toLowerCase()} Plan
                            </span>
                            <span>$ {singlePlan?.price.toLocaleString()}</span>
                            <span>{singlePlan?.cycle}</span>
                        </>
                    )}
                </div>
                <div className="enterprise-acc__left--steps">
                    <p>
                        <span className="enterprise-acc__left--steps-num">
                            {'1)'}
                        </span>
                        <span>Create an account</span>
                    </p>
                    {mode !== 'free' && (
                        <p>
                            <span className="enterprise-acc__left--steps-num">
                                {'2)'}
                            </span>
                            <span>Go to checkout</span>
                        </p>
                    )}
                    <p>
                        <span className="enterprise-acc__left--steps-num">
                            {mode === 'free' ? '2)' : '3)'}
                        </span>
                        <span style={{ fontWeight: 'bolder' }}>
                            Sub Domain setup{' '}
                        </span>
                    </p>
                    <p>
                        <span className="enterprise-acc__left--steps-num">
                            {mode === 'free' ? '3)' : '4)'}
                        </span>
                        <span>Receive Email Confirmation </span>
                    </p>
                    <p>
                        <span className="enterprise-acc__left--steps-num">
                            {mode === 'free' ? '4)' : '5)'}
                        </span>
                        <span>Start Working With Our Team </span>
                    </p>
                </div>
            </Col>
            <Col md={16} className="enterprise-acc__right">
                <Row justify="center" align="middle" style={{ height: '80vh' }}>
                    <Col
                        style={{ textAlign: 'center' }}
                        className="enterprise-acc__right--details"
                    >
                        <p>SUB DOMAIN SETUP IN PROGRESS....</p>
                        <p>
                            <Loader
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        </p>
                        <p>Account will be setup within 24hrs.</p>
                        <p>
                            Be on the look out for a mail from us. Once again
                            Thanks for subscribing
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DnsSetup;
