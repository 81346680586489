import { TRANSACTION as BASE_PATH } from './dashboard';

export const TRANSACTION_HOME = `${BASE_PATH}/`;
export const TRANSACTION_VOTE = `${BASE_PATH}/vote`;

export const TRANSACTION_PURCHASE = `${BASE_PATH}/purchase`;

export const TRANSACTION_VERIFICATION = `${TRANSACTION_PURCHASE}/verify`;
export const TRANSACTION_APPLICATION_VERIFICATION = `${TRANSACTION_VERIFICATION}/:id`;

export const TRANSACTION_VOTING_HISTORY = `${BASE_PATH}/voting_history`;

export const ALL_TRANSACTION = `${BASE_PATH}/all_transactions`;

export const DISPUTE_TRANSACTION = `${BASE_PATH}/dispute_transactions`;
