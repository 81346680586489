import { Col, Row } from 'antd';
import React, { FC, ReactElement } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

const ContestVideoHome: FC = (): ReactElement => {
    const eventVideos = useSelector(
        (state: RootState) => state.videos.eventVideos,
    );
    return (
        <Row>
            {eventVideos.map(({ _id, url }) => (
                <Col md={12} key={_id}>
                    <ReactPlayer
                        key={'_id'}
                        loop
                        controls
                        style={{
                            padding: '10px',
                        }}
                        width="100%"
                        height="350px"
                        padding="30px"
                        url={url}
                    />
                </Col>
            ))}
        </Row>
    );
};

export default ContestVideoHome;
