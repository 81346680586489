import { fetchedEventTypeDb } from '../../types';
import { CLEAR_MESSAGE, CLEAR_ERROR } from '../types/common.type';
import {
    ADD_CONTEST_FAILED,
    ADD_CONTEST_INIT,
    ADD_CONTEST_SUCCESS,
    CLEAR_EVENT_DETAIL,
    CONTEST_FETCH_FAILED,
    CONTEST_FETCH_FAILED_BY_ID,
    CONTEST_FETCH_INIT,
    CONTEST_FETCH_INIT_BY_ID,
    CONTEST_FETCH_SUCCESS,
    CONTEST_FETCH_SUCCESS_BY_ID,
} from '../types/contests.type';

type CONTESTS_INIT_STATE = {
    contestsInfo: fetchedEventTypeDb[];
    eventInfo: fetchedEventTypeDb | null;
    loading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const CONTESTS_INITIAL_STATE: CONTESTS_INIT_STATE = {
    contestsInfo: [],
    eventInfo: null,
    loading: false,
    feedbackMsg: null,
    error: null,
};

const contestsReducer = (
    state = CONTESTS_INITIAL_STATE,
    action: any,
): CONTESTS_INIT_STATE => {
    switch (action.type) {
        case ADD_CONTEST_INIT:
        case CONTEST_FETCH_INIT:
        case CONTEST_FETCH_INIT_BY_ID: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case ADD_CONTEST_FAILED:
        case CONTEST_FETCH_FAILED:
        case CONTEST_FETCH_FAILED_BY_ID: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case CONTEST_FETCH_SUCCESS: {
            const contests = [...action.payload].reverse();

            return {
                ...state,
                contestsInfo: contests,
                loading: false,
                error: null,
            };
        }

        case CONTEST_FETCH_SUCCESS_BY_ID: {
            return {
                ...state,
                eventInfo: action.payload,
                loading: false,
                error: null,
            };
        }
        case ADD_CONTEST_SUCCESS: {
            return {
                ...state,
                feedbackMsg: 'Contest added successfully',
                loading: false,
                error: null,
            };
        }
        case CLEAR_EVENT_DETAIL: {
            return {
                ...state,
                eventInfo: null,
            };
        }

        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }
        default:
            return state;
    }
};

export default contestsReducer;
