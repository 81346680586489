import { fetchContestImage, fetchMediaTypeDb } from '../../types';
import { CLEAR_MESSAGE, CLEAR_ERROR } from '../types/common.type';
import {
    CLEAR_CONTESTANT_DATAS,
    FETCH_CONTESTANT_IMAGES_SUCCESS,
    ADD_CONTESTANT_IMAGE_SUCCESS,
    ADD_CONTESTANT_IMAGE_FAILED,
    ADD_CONTESTANT_IMAGE_INIT,
    FETCH_CONTESTANT_IMAGES_FAILED,
    FETCH_CONTESTANT_IMAGES_INIT,
} from '../types/contestant.type';
import {
    ADD_CONTEST_IMAGE_FAILED,
    ADD_CONTEST_IMAGE_INIT,
    ADD_CONTEST_IMAGE_SUCCESS,
    FETCH_CONTEST_IMAGE_FAILED,
    FETCH_CONTEST_IMAGE_INIT,
    FETCH_CONTEST_IMAGE_SUCCESS,
} from '../types/contests.type';

type IMAGES_INIT_STATE = {
    contestantImages: fetchMediaTypeDb[];
    eventImages: fetchContestImage[];
    loading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const IMAGES_INITIAL_STATE: IMAGES_INIT_STATE = {
    contestantImages: [],
    eventImages: [],
    loading: false,
    feedbackMsg: null,
    error: null,
};

const imagesReducer = (
    state = IMAGES_INITIAL_STATE,
    action: any,
): IMAGES_INIT_STATE => {
    switch (action.type) {
        case ADD_CONTESTANT_IMAGE_INIT:
        case FETCH_CONTESTANT_IMAGES_INIT:
        case FETCH_CONTEST_IMAGE_INIT:
        case ADD_CONTEST_IMAGE_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case FETCH_CONTESTANT_IMAGES_SUCCESS: {
            const images = [...action.payload].reverse();

            return {
                ...state,
                contestantImages: images,
                loading: false,
                error: null,
            };
        }

        case FETCH_CONTEST_IMAGE_SUCCESS: {
            return {
                ...state,
                eventImages: action.payload,
                loading: false,
                error: null,
            };
        }

        case ADD_CONTEST_IMAGE_SUCCESS: {
            return {
                ...state,
                eventImages: [...state.eventImages, action.payload],
                loading: false,
                error: null,
                feedbackMsg: 'uploaded successfuly!!!',
            };
        }
        case ADD_CONTESTANT_IMAGE_SUCCESS: {
            return {
                ...state,
                feedbackMsg: 'Image added successfully',
                loading: false,
                error: null,
            };
        }
        case ADD_CONTESTANT_IMAGE_FAILED:
        case FETCH_CONTESTANT_IMAGES_FAILED:
        case FETCH_CONTEST_IMAGE_FAILED:
        case ADD_CONTEST_IMAGE_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case CLEAR_CONTESTANT_DATAS: {
            return {
                ...state,
                contestantImages: [],
            };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }
        default:
            return state;
    }
};

export default imagesReducer;
