import { Button, Col, message, Modal, Row } from 'antd';
import React, { ReactElement, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearMediaPublish,
    getAllPendingImages,
    publishImage,
} from '../../../../redux/actions/media.action';
import { RootState } from '../../../../redux/reducers';
import { fetchPendingMediasDb } from '../../../../types';

const ImageRenderModal: FC<{
    record: fetchPendingMediasDb;
    openModal: boolean;
    onActionComplete: () => void;
}> = ({ record, openModal, onActionComplete }): ReactElement => {
    const dispatch = useDispatch();
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { publishingImage, publishFeedback, error } = useSelector(
        (state: RootState) => state.media,
    );
    const { subdomainUIstyling } = stylingState;

    useEffect(() => {
        if (!publishingImage && publishFeedback === 'done') {
            dispatch(getAllPendingImages());
            onActionComplete();
        }
        if (!publishingImage && error && publishFeedback === 'error') {
            return message.error(error);
        }

        return () => {
            dispatch(clearMediaPublish());
        };
        // eslint-disable-next-line
    }, [publishingImage, publishFeedback, dispatch, error]);

    const publish = () => {
        dispatch(publishImage(record._id));
    };
    return (
        <Modal
            onCancel={onActionComplete}
            onOk={onActionComplete}
            visible={openModal}
            title="Sure to publish Image?"
            footer={false}
            width={300}
            className="add-image__modal"
        >
            <Row>
                <Col xs={24}>
                    <img
                        style={{
                            width: '100%',
                            height: '300px',
                        }}
                        src={record.url}
                        alt={record.contestant.fullName}
                    />
                </Col>
            </Row>
            <Row
                style={{
                    marginTop: '10px',
                }}
                justify="space-between"
            >
                <Col md={8}>
                    <Button onClick={onActionComplete}>Cancel</Button>
                </Col>
                <Col md={8}>
                    <Button
                        style={{
                            backgroundColor: subdomainUIstyling[0]
                                ? subdomainUIstyling[0].primaryColor
                                : '#1bca8f',
                            color: '#fff',
                        }}
                        onClick={publish}
                        loading={publishingImage}
                    >
                        Publish
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default ImageRenderModal;
