import { Result, Button } from 'antd';
import React, { FC, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { HOME } from '../../routes';

const NotFoundPage: FC = (): ReactElement => {
    const { push } = useHistory();
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button type="primary" onClick={() => push(HOME)}>
                    Back Home
                </Button>
            }
        />
    );
};

export default NotFoundPage;
