import { HomeFilled, PhoneFilled, MailFilled } from '@ant-design/icons';
import { Row, Col } from 'antd';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import {
    ABOUT_US,
    PRICING_PLAN,
    PRIVACY,
    TERMSANDCONDITIONS,
} from '../../routes';

const FooterSection: FC = (): ReactElement => {
    return (
        <>
            <Row className="main-page__footer">
                <Col md={12} lg={8}>
                    <div className="main-page__footer-box">
                        <h2 className="main-page__footer-box--title">
                            CONTACTS
                        </h2>
                        <div className="main-page__footer-box--content">
                            <HomeFilled />
                            <span className="main-page__footer-box--content-text">
                                128 City Road, London, EC1V 2NX, United Kingdom
                            </span>
                        </div>
                        <div className="main-page__footer-box--content">
                            <PhoneFilled />
                            <a
                                className="main-page__footer-box--content-text"
                                href="tel:+234 802 956 4392"
                            >
                                +234 802 956 4392
                            </a>
                        </div>
                        <div className="main-page__footer-box--content">
                            <MailFilled />
                            <a
                                className="main-page__footer-box--content-text"
                                href="mailto:info@pageant.africa"
                            >
                                info@pageant.africa
                            </a>
                        </div>
                    </div>
                </Col>

                <Col md={12} lg={4}>
                    <div className="main-page__footer-box">
                        <h2 className="main-page__footer-box--title">legals</h2>
                        <Link to={PRIVACY}>
                            <p className="main-page__footer-box--link">
                                Privacy Policy
                            </p>
                        </Link>
                        <Link to={TERMSANDCONDITIONS}>
                            <p className="main-page__footer-box--link">
                                {'Terms & Conditions'}
                            </p>
                        </Link>
                    </div>
                </Col>
                <Col md={12} lg={6}>
                    <div className="main-page__footer-box">
                        <h2 className="main-page__footer-box--title">
                            common links
                        </h2>
                        {/* <Link to={ENTERPRISELANDING}>
                            <p className="main-page__footer-box--link">
                                Enterprise
                            </p>
                        </Link> */}
                        <Link to={PRICING_PLAN}>
                            <p className="main-page__footer-box--link">
                                Pricing
                            </p>
                        </Link>
                        <Link to={ABOUT_US}>
                            <p className="main-page__footer-box--link">about</p>
                        </Link>
                    </div>
                </Col>
                <Col md={12} lg={6}>
                    <div className="main-page__footer-box">
                        <h2 className="main-page__footer-box--title">
                            PAGEANT AFRICA
                        </h2>
                        <p className="main-page__footer-box--text">
                            Pageant Africa is a platform designed to allow one
                            of a kind engagement with the best beauty pageants
                            and contests across Africa.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row
                style={{
                    backgroundColor: '#8c8c8c',
                    fontSize: '16px',
                    color: '#fff',
                    padding: '10px ',
                    textAlign: 'center',
                }}
                justify="center"
            >
                <Col>
                    © Copyright {new Date().getFullYear()} Afripageant
                    Interative, All Rights Reserved
                </Col>
            </Row>
        </>
    );
};

export default FooterSection;
