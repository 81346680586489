import React, { FC, ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { privateRouteProps } from '../types';
import { SIGN_IN } from '../../../routes';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';

const PrivateRoute: FC<privateRouteProps> = ({
    exact,
    path,
    component,
    ...rest
}): ReactElement => {
    //get this values from redux store now
    const authenticated = useSelector(
        (state: RootState) => state.auth.authenticated,
    );

    const Component = component;
    return (
        <Route
            exact={exact}
            path={path}
            {...rest}
            render={({ location }) =>
                authenticated === true ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: SIGN_IN,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
