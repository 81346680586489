import { Dispatch } from 'redux';
import { baseInstance as axios } from '../../axios-base';
import { paymentType } from '../../constants/transactionEnum';
import {
    freeSubscriptionType,
    initializeApplicationPaymentType,
    initializePaymentType,
    initializeSubscriptionType,
    initializeVotingPaymentType,
    onVerifyPaypalpaymentType,
    paypalApprovedPaymentType,
} from '../../types';
import {
    CLEAR_PAYMENT_STATE,
    INITIALIZE_PAYMENT,
    INITIALIZE_PAYMENT_FAILED,
    INITIALIZE_PAYMENT_SUCCESSFUL,
    PAYMENT_CANCELLED,
    VERIFY_PAYMENT_FAILED,
    VERIFY_PAYMENT_INIT,
    VERIFY_PAYMENT_SUCCESSFUL,
} from '../types/payment.type';

export const initializePayment = (payload: initializePaymentType) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: INITIALIZE_PAYMENT });
    try {
        const { data } = await axios.post(
            '/transaction/initializepayment',
            payload,
        );

        dispatch({
            type: INITIALIZE_PAYMENT_SUCCESSFUL,
            payload: data.authorizationUrl,
        });
    } catch (error) {
        dispatch({
            type: INITIALIZE_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const verifyPayment = (payload: {
    reference: string;
    paymentMethod: paymentType;
}) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: VERIFY_PAYMENT_INIT });
    try {
        const { data } = await axios.post(
            '/transaction/verifypayment',
            payload,
        );
        dispatch({
            type: VERIFY_PAYMENT_SUCCESSFUL,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: VERIFY_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const initializeApplicationPayment = (
    payload: initializeApplicationPaymentType,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: INITIALIZE_PAYMENT });
    try {
        const { data } = await axios.post(
            '/transaction/initializeapplication',
            payload,
        );

        dispatch({
            type: INITIALIZE_PAYMENT_SUCCESSFUL,
            payload: data.authorizationUrl,
        });
    } catch (error) {
        dispatch({
            type: INITIALIZE_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const verifyApplication = (payload: {
    reference: string;
    paymentMethod: paymentType;
}) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: VERIFY_PAYMENT_INIT });
    try {
        const { data } = await axios.post(
            '/transaction/verifyapplication',
            payload,
        );
        dispatch({
            type: VERIFY_PAYMENT_SUCCESSFUL,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: VERIFY_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const initializeVotingPayment = (
    payload: initializeVotingPaymentType,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: INITIALIZE_PAYMENT });
    try {
        const { data } = await axios.post(
            '/transaction/initializevoting',
            payload,
        );

        dispatch({
            type: INITIALIZE_PAYMENT_SUCCESSFUL,
            payload: data.authorizationUrl,
        });
    } catch (error) {
        dispatch({
            type: INITIALIZE_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const verifyVotingPayment = (payload: {
    reference: string;
    domain: string;
    paymentMethod: paymentType;
}) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: VERIFY_PAYMENT_INIT });
    try {
        const { data } = await axios.post('/transaction/verifyvoting', payload);
        dispatch({
            type: VERIFY_PAYMENT_SUCCESSFUL,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: VERIFY_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const initializeSubscriptionPayment = (
    payload: initializeSubscriptionType,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: INITIALIZE_PAYMENT });
    try {
        const { data } = await axios.post(
            '/transaction/initializesubscription',
            payload,
        );

        dispatch({
            type: INITIALIZE_PAYMENT_SUCCESSFUL,
            payload: data.authorizationUrl,
        });
    } catch (error) {
        dispatch({
            type: INITIALIZE_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const freeAccountSubscription = (
    payload: freeSubscriptionType,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: INITIALIZE_PAYMENT });
    try {
        await axios.post('/transaction/freesubscription', payload);

        dispatch({
            type: INITIALIZE_PAYMENT_SUCCESSFUL,
            // payload: data,
        });
    } catch (error) {
        dispatch({
            type: INITIALIZE_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};
export const verifySubscriptionPayment = (payload: {
    reference: string;
    paymentMethod: paymentType;
}) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: VERIFY_PAYMENT_INIT });
    try {
        const { data } = await axios.post(
            '/transaction/verifysubscription',
            payload,
        );
        dispatch({
            type: VERIFY_PAYMENT_SUCCESSFUL,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: VERIFY_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const onPayPalTransactionApproved = (
    payload: paypalApprovedPaymentType,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: INITIALIZE_PAYMENT });
    try {
        const { data } = await axios.post('/transaction/paypal', payload);
        dispatch({
            type: VERIFY_PAYMENT_SUCCESSFUL,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: VERIFY_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const onVerifyPaypalpayment = (
    payload: onVerifyPaypalpaymentType,
) => async (dispatch: Dispatch): Promise<void> => {
    try {
        const { data } = await axios.post(
            '/transaction/verifypaypalpayment',
            payload,
        );
        dispatch({
            type: VERIFY_PAYMENT_SUCCESSFUL,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: VERIFY_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const VotingPaymentCancelled = (payload: {
    transactionReference: string;
    domain: string;
    paymentMethod: paymentType;
}) => async (dispatch: Dispatch): Promise<void> => {
    try {
        await axios.post('/transaction/transaction-cancelled', payload);
        dispatch({
            type: PAYMENT_CANCELLED,
        });
    } catch (error) {
        dispatch({
            type: VERIFY_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const clearPayment = () => (dispatch: Dispatch) =>
    dispatch({ type: CLEAR_PAYMENT_STATE });
