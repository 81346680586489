import React, { FC, ReactElement } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';

TopBarProgress.config({
    barColors: {
        '0': '#11B86F',
        '1.0': '#11B86F',
    },
    shadowBlur: 1,
});

const TopBarLoader: FC<{ loading: boolean }> = ({ loading }): ReactElement => {
    return <div>{loading && <TopBarProgress />}</div>;
};

export default TopBarLoader;
