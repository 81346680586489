import {
    fetchApplicantStats,
    fetchEventStats,
    fetchVoteStats,
} from '../../types';
import { CLEAR_MESSAGE, CLEAR_ERROR } from '../types/common.type';
import {
    TASK_STATS_FETCH_INIT,
    TASK_STATS_FETCH_SUCCESS,
    TASK_STATS_FETCH_FAILED,
    VOTE_STATS_FETCH_FAILED,
    VOTE_STATS_FETCH_INIT,
    VOTE_STATS_FETCH_SUCCESS,
    APPLICANT_STATS_FETCH_INIT,
    APPLICANT_STATS_FETCH_FAILED,
    APPLICANT_STATS_FETCH_SUCCESS,
    CLEAR_STATS,
} from '../types/stats.type';

type STATS_INIT_STATE = {
    eventStats: fetchEventStats[];
    voteStats: fetchVoteStats[];
    applicantStats: fetchApplicantStats[];
    loading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const STATS_INITIAL_STATE: STATS_INIT_STATE = {
    eventStats: [],
    voteStats: [],
    applicantStats: [],
    loading: false,
    feedbackMsg: null,
    error: null,
};

const statsReducer = (
    state = STATS_INITIAL_STATE,
    action: any,
): STATS_INIT_STATE => {
    switch (action.type) {
        case TASK_STATS_FETCH_INIT:
        case VOTE_STATS_FETCH_INIT:
        case APPLICANT_STATS_FETCH_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case TASK_STATS_FETCH_SUCCESS: {
            const stats = [...action.payload].reverse();

            return {
                ...state,
                eventStats: stats,
                loading: false,
                error: null,
            };
        }
        case VOTE_STATS_FETCH_SUCCESS: {
            const stats = [...action.payload].reverse();

            return {
                ...state,
                voteStats: stats,
                loading: false,
                error: null,
            };
        }
        case APPLICANT_STATS_FETCH_SUCCESS: {
            const stats = [...action.payload].reverse();

            return {
                ...state,
                applicantStats: stats,
                loading: false,
                error: null,
            };
        }

        case TASK_STATS_FETCH_FAILED:
        case VOTE_STATS_FETCH_FAILED:
        case APPLICANT_STATS_FETCH_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case CLEAR_STATS: {
            return {
                ...state,
                eventStats: [],
                voteStats: [],
                applicantStats: [],
            };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }
        default:
            return state;
    }
};

export default statsReducer;
