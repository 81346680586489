import React, { FC, ReactElement, useState } from 'react';
import { Row, Col, Table, message, Button } from 'antd';
import { Searchbar } from '../../../searchbar';
import { FileDoneOutlined } from '@ant-design/icons';
import TableColumn from './TableColumn';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchtasks } from '../../../../redux/actions/tasks.action';
import { RootState } from '../../../../redux/reducers';
import { clearError } from '../../../../redux/actions/common.action';
import { fetchedtaskTypeDb } from '../../../../types';
import { Link } from 'react-router-dom';
import { ADD_TASK } from '../../../../routes';
import { PlusOutlined } from '@ant-design/icons';

const Home: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const tasksState = useSelector((state: RootState) => state.tasks);
    // const role = useSelector((state: RootState) => state.auth.usersInfo?.role);
    const { error: requestError, loading, tasks } = tasksState;
    const [tableData, setTableData] = useState<fetchedtaskTypeDb[]>([]);

    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    useEffect(() => {
        dispatch(fetchtasks());
    }, [dispatch]);

    useEffect(() => {
        setTableData(tasks);
    }, [tasks]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        //clean up error and message
        return () => {
            dispatch(clearError());
        };
    }, [requestError, dispatch]);

    const onSearch = (input: string) => {
        const query = input.toLowerCase();

        if (query === '') return setTableData(tasks);
        if (tableData.length <= 0) return;

        const result = tasks.filter((task: fetchedtaskTypeDb) => {
            const eventTitle = task.event.eventTitle.toLowerCase();
            const taskTitle = task.title.toLowerCase();
            const isMatch =
                taskTitle.includes(query) || eventTitle.includes(query);
            return isMatch;
        });
        setTableData(result);
    };

    const prefix = <FileDoneOutlined className="search-prefix" />;

    return (
        <>
            <Row className="task-bar" justify="space-between">
                <Col md={10}>
                    <Searchbar
                        onChange={(e) => onSearch(e)}
                        placeholder="Search tasks"
                        onSearch={onSearch}
                        prefix={prefix}
                    />
                </Col>
                <Col className="action-btn">
                    <Link to={ADD_TASK}>
                        <Button
                            icon={<PlusOutlined />}
                            htmlType="submit"
                            style={{
                                background: `${
                                    subdomainUIstyling[0]
                                        ? subdomainUIstyling[0].primaryColor
                                        : '#1bca8f'
                                }`,
                                color: 'white',
                            }}
                        >
                            Create task
                        </Button>
                    </Link>
                </Col>
            </Row>
            <Row className="table-content">
                <Col className="table-wrapper" span={24}>
                    <Table
                        loading={loading}
                        title={() => <h2>Tasks</h2>}
                        columns={TableColumn}
                        dataSource={tableData || []}
                        size="small"
                        rowKey="_id"
                    />
                </Col>
            </Row>
        </>
    );
};

export default Home;
