export type stepRenderType = {
    title: string;
    component: React.FC<{}>;
    step: stepStage;
};

export enum stepStage {
    START = 'START',
    IMAGEUPLOAD = 'IMAGEUPLOAD',
    DATEPICKER = 'DATEPICKER',
    FORMAPPLY = 'FORMAPPLY',
    REQUIREMENT = 'REQUIREMENT',
    ISPAID = 'ISPAID',
    PAYMENT = 'PAYMENT',
    FINALSTEP = 'FINALSTEP',
}
