import { FileDoneOutlined } from '@ant-design/icons';
import { message, Row, Col, Table } from 'antd';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from '../../../../redux/actions/common.action';
import { fetchAllAccounts } from '../../../../redux/actions/enterprise.action';
import { RootState } from '../../../../redux/reducers';
import { fetchSudomainAccountTypeDb } from '../../../../types';
import { Searchbar } from '../../../searchbar';
import TableColumn from './TableColumn';

const EnterpriseAccounts: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const accountsState = useSelector((state: RootState) => state.accounts);
    const { error: requestError, loading, accountsData } = accountsState;
    const [tableData, setTableData] = useState<fetchSudomainAccountTypeDb[]>(
        [],
    );

    useEffect(() => {
        dispatch(fetchAllAccounts());
    }, [dispatch]);

    useEffect(() => {
        setTableData(accountsData);
    }, [accountsData]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        //clean up error and message
        return () => {
            dispatch(clearError());
        };
    }, [requestError, dispatch]);

    const onSearch = (input: string) => {
        const query = input.toLowerCase();

        if (query === '') return setTableData(accountsData);
        if (tableData.length <= 0) return;

        const result = accountsData.filter(
            (account: fetchSudomainAccountTypeDb) => {
                const companyName = account.companyName.toLowerCase();
                const companyLocation = account.companyLocation.toLowerCase();
                const activePlan = account.plan.activePlan.toLowerCase();
                const isMatch =
                    companyName.includes(query) ||
                    activePlan.includes(query) ||
                    companyLocation.includes(query);
                return isMatch;
            },
        );
        setTableData(result);
    };

    const prefix = <FileDoneOutlined className="search-prefix" />;

    return (
        <>
            <Row className="task-bar" justify="space-between">
                <Col md={10}>
                    <Searchbar
                        onChange={(e) => onSearch(e)}
                        placeholder="Search Accounts"
                        onSearch={onSearch}
                        prefix={prefix}
                    />
                </Col>
            </Row>
            <Row className="table-content">
                <Col className="table-wrapper scroll-table-x" span={24}>
                    <Table
                        loading={loading}
                        title={() => <h2>Accounts</h2>}
                        columns={TableColumn}
                        dataSource={tableData || []}
                        size="small"
                        rowKey="_id"
                    />
                </Col>
            </Row>
        </>
    );
};

export default EnterpriseAccounts;
