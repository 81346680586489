import React, { FC, ReactElement, useState } from 'react';
import { Row, Col, Table, message, DatePicker } from 'antd';
import TableColumn from './TableColumn';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { clearError } from '../../../../redux/actions/common.action';
import { fetchEventVotes } from '../../../../redux/actions/vote.action';
import { useHistory } from 'react-router-dom';
import { Searchbar } from '../../../searchbar';
import { voteTypeDb } from '../../../../types';

const { RangePicker } = DatePicker;

const VoteHistory: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const {
        location: { search },
    } = useHistory();

    const votes = useSelector((state: RootState) => state.vote);
    const { loading, error: requestError, votesInfo } = votes;
    const id = new URLSearchParams(search).get('id');
    const [tableData, setTableData] = useState<voteTypeDb[]>([]);

    useEffect(() => {
        if (id) {
            dispatch(fetchEventVotes(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        //clean up error and message
        return () => {
            dispatch(clearError());
        };
    }, [requestError, dispatch]);

    useEffect(() => {
        setTableData(votesInfo);
    }, [votesInfo]);

    const onSearch = (input: string) => {
        const query = input.toLowerCase();
        if (query === '') setTableData(votesInfo);
        if (votesInfo.length <= 0) return;

        const result = votesInfo.filter((el) => {
            const contestant = el.contestant.fullName.toLowerCase();
            const isMatch = contestant.includes(query);
            return isMatch;
        });
        setTableData(result);
    };
    const onDateChange = (_: any, dateString: [string, string]) => {
        if (!dateString[0] || !dateString[1]) {
            setTableData(votesInfo);
            return;
        }
        const aDayPlus = 24 * 60 * 60 * 1000;
        const millisecDate = dateString.map((date) => Date.parse(date));

        const result = votesInfo.filter(
            (el) =>
                Date.parse(el.createdAt) > millisecDate[0] &&
                Date.parse(el.createdAt) < millisecDate[1] + aDayPlus,
        );

        setTableData(result);
    };
    const totalVotes = () => {
        let votes = 0;
        tableData.forEach(({ numberOfVote }) => {
            votes += numberOfVote;
        });
        return votes;
    };
    const headerBar = () => (
        <>
            <Row>
                <Col md={8}>
                    <h2>Users Voting History</h2>
                </Col>
                <Col md={8}>
                    <h2>{totalVotes()} Votes </h2>
                </Col>
            </Row>
            <Row
                justify="space-between"
                style={{
                    marginBottom: '10px',
                    marginTop: '10px',
                }}
            >
                <Col xl={10}>
                    <Searchbar
                        onChange={(e) => onSearch(e)}
                        placeholder="Search for Contestants"
                        onSearch={onSearch}
                        enterButton="Search"
                    />
                </Col>
                <Col xl={8}>
                    <RangePicker onChange={onDateChange} />
                </Col>
            </Row>
        </>
    );

    return (
        <>
            <Row className="table-content">
                <Col className="table-wrapper" span={24}>
                    <Table
                        loading={loading}
                        title={() => headerBar()}
                        columns={TableColumn}
                        dataSource={tableData || []}
                        size="small"
                        rowKey="_id"
                        pagination={{
                            defaultPageSize: 100,
                            showSizeChanger: true,
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default VoteHistory;
