import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

import { Row, Col, Tag, message } from 'antd';
import { ActionModal } from '../../../actionModal';
import { routerParamsType } from '../types';
import { CONTEST_HOME } from '../../../../routes';
import ModalDrawer from './modaldrawer';
import { sentenceTruncater } from '../../../../utils';
import { ENTERPRISE, USER } from '../../../../constants';
import { DeleteOutlined } from '@ant-design/icons';
import { fetchedContestantTypeDb } from '../../../../types';

import './style.scss';
import {
    clearError,
    clearMessage,
} from '../../../../redux/actions/common.action';
import { setActiveContestant } from '../../../../redux/actions/vote.action';
import {
    openAddEventVideoModal,
    openVoteModal,
} from '../../../../redux/actions/UI.action';
import {
    CONTESTANTS_FETCH_INIT,
    CONTESTANTS_FETCH_SUCCESS,
} from '../../../../redux/types/contestant.type';
import {
    clearContestantData,
    deleteContestant,
} from '../../../../redux/actions/contestant.action';
import { DashSpinner } from '../../spinner';
import ContestContent from './ContestContent';
import {
    fetchContestById,
    clearEventDetails,
} from '../../../../redux/actions/contests.action';
import { getContestImages } from '../../../../redux/actions/image.action';
import { socket } from '../../../../UIProvider';
import ContestVideos from './ContestVideos';
import { getContestVideos } from '../../../../redux/actions/video.action';

const ContestDetails: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const contestantsState = useSelector(
        (state: RootState) => state.contestants,
    );
    const user = useSelector((state: RootState) => state.auth.usersInfo);
    // const isNotEnterpriseUser = user?.role !== ENTERPRISE;
    const isAUser = user?.role === USER;

    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    const {
        contestantsInfo,
        error: requestError,
        loading,
        deletingLoading,
        feedbackMsg,
    } = contestantsState;

    const contestState = useSelector((state: RootState) => state.contests);
    const { eventInfo } = contestState;

    const [showDrawer, setShowDrawer] = useState(false);
    const { id }: routerParamsType = useParams();

    useEffect(() => {
        dispatch({ type: CONTESTANTS_FETCH_INIT });
        dispatch(fetchContestById(id));
        dispatch(getContestImages(id));
        dispatch(getContestVideos(id));

        socket.emit('contestants', { event: id });
        socket.on(id, function (data: fetchedContestantTypeDb[]) {
            //dispatch data
            dispatch({ type: CONTESTANTS_FETCH_SUCCESS, payload: data });
        });

        //clean up listener to avoid memory leak
        return () => {
            socket.removeAllListeners(id);
            dispatch(clearContestantData());
            dispatch(clearEventDetails());
        };
    }, [id, dispatch]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        if (feedbackMsg) {
            return message.success(feedbackMsg);
        }

        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
        };
    }, [dispatch, requestError, feedbackMsg]);

    const drawerHandler = () => {
        setShowDrawer((prevState) => !prevState);
    };

    const voteModalHandler = (
        fullName: string,
        contestantId: string,
        imageUrl: string,
    ) => {
        dispatch(
            setActiveContestant({
                fullName,
                contestantId,
                imageUrl,
                event: id,
            }),
        );

        //dispatch show modal
        dispatch(openVoteModal());
    };

    const handleItemDelete = async (_id: string) => {
        // dispatch action to delete
        dispatch(deleteContestant(_id));
    };

    return (
        <>
            {!loading && (
                <>
                    {contestantsInfo.length !== 0 ? (
                        <>
                            <Row
                                gutter={{ xs: 0, md: 20 }}
                                justify="space-between"
                                align="middle"
                                className="contestant-top"
                            >
                                <Col xs={24} sm={8} lg={12}>
                                    <span className="contestant-title">
                                        Contestants
                                    </span>
                                    <span className="contestant-badge">
                                        {[...contestantsInfo].length}
                                    </span>
                                </Col>

                                <Col
                                    xs={24}
                                    sm={16}
                                    lg={12}
                                    className="contestant-top__right"
                                >
                                    {user?.role !== USER && (
                                        <>
                                            <span
                                                className="c-btn contestant-top__right--leader"
                                                onClick={() =>
                                                    dispatch(
                                                        openAddEventVideoModal(),
                                                    )
                                                }
                                                style={{
                                                    background: `${
                                                        subdomainUIstyling[
                                                            '0'
                                                        ] &&
                                                        subdomainUIstyling['0'][
                                                            'primaryColor'
                                                        ]
                                                    }`,
                                                    color: 'white',
                                                }}
                                            >
                                                Add Video
                                            </span>
                                            <Link
                                                to={`${CONTEST_HOME}${id}/addcontestant`}
                                                className="c-btn contestant-top__right--add"
                                                style={{
                                                    backgroundColor: `${
                                                        subdomainUIstyling[
                                                            '0'
                                                        ] &&
                                                        subdomainUIstyling['0'][
                                                            'primaryColor'
                                                        ]
                                                    }`,
                                                }}
                                            >
                                                Add Contestant
                                            </Link>
                                        </>
                                    )}
                                    <span
                                        className="c-btn contestant-top__right--leader"
                                        onClick={drawerHandler}
                                        style={{
                                            background: `${
                                                subdomainUIstyling['0'] &&
                                                subdomainUIstyling['0'][
                                                    'primaryColor'
                                                ]
                                            }`,
                                            color: 'white',
                                        }}
                                    >
                                        Leaders
                                    </span>
                                </Col>
                            </Row>
                            <ContestContent />
                            <ContestVideos />
                            <Row className="contestant">
                                {contestantsInfo.map(
                                    ({
                                        fullName,
                                        age,
                                        imageUrl,
                                        _id,
                                        bio,
                                        totalVotes,
                                        isEvicted,
                                    }: fetchedContestantTypeDb) => (
                                        <Col
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            lg={8}
                                            className="contestant__card"
                                            key={_id}
                                        >
                                            <div className="contestant__card--item">
                                                <div className="contestant__card--title">
                                                    <span>
                                                        {fullName.toUpperCase()}
                                                    </span>
                                                    {user?.role !== USER &&
                                                        eventInfo &&
                                                        !eventInfo.isPublished &&
                                                        !deletingLoading && (
                                                            <DeleteOutlined
                                                                onClick={() =>
                                                                    handleItemDelete(
                                                                        _id,
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                </div>
                                                <div className="contestant__card--image">
                                                    <img
                                                        src={imageUrl}
                                                        alt="logo"
                                                    />
                                                    {isEvicted && (
                                                        <div className="evict-body">
                                                            <span className="evict-body__text">
                                                                EVICTED
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="contestant__card--details">
                                                    <Row
                                                        justify="space-between"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Col md={4}>
                                                            <Tag
                                                                style={{
                                                                    backgroundColor: `${
                                                                        subdomainUIstyling[
                                                                            '0'
                                                                        ]
                                                                            ? subdomainUIstyling[
                                                                                  '0'
                                                                              ][
                                                                                  'primaryColor'
                                                                              ]
                                                                            : '#1bca8f'
                                                                    }`,
                                                                    color:
                                                                        '#fff',
                                                                }}
                                                            >
                                                                {totalVotes.toLocaleString()}{' '}
                                                                Votes
                                                            </Tag>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Tag color="default">
                                                                {age} years
                                                            </Tag>
                                                        </Col>
                                                    </Row>
                                                    <p>
                                                        {sentenceTruncater(
                                                            bio,
                                                            100,
                                                        )}
                                                    </p>
                                                </div>
                                                <Row className="contestant__card--actions">
                                                    {isAUser && (
                                                        <Col
                                                            xs={12}
                                                            onClick={() =>
                                                                voteModalHandler(
                                                                    fullName,
                                                                    _id,
                                                                    imageUrl,
                                                                )
                                                            }
                                                            className="contestant__card--actions-vote"
                                                        >
                                                            <span>Vote</span>
                                                        </Col>
                                                    )}
                                                    <Col
                                                        xs={isAUser ? 12 : 24}
                                                        className="contestant__card--actions-info"
                                                        style={{
                                                            borderTopRightRadius: isAUser
                                                                ? '15px'
                                                                : '0px',
                                                            backgroundColor: `${
                                                                subdomainUIstyling[
                                                                    '0'
                                                                ] &&
                                                                subdomainUIstyling[
                                                                    '0'
                                                                ][
                                                                    'primaryColor'
                                                                ]
                                                            }`,
                                                        }}
                                                    >
                                                        <Link
                                                            to={`${CONTEST_HOME}${id}/view?cid=${_id}`}
                                                            style={{
                                                                display:
                                                                    'block',
                                                                backgroundColor: `${
                                                                    subdomainUIstyling[
                                                                        '0'
                                                                    ] &&
                                                                    subdomainUIstyling[
                                                                        '0'
                                                                    ][
                                                                        'primaryColor'
                                                                    ]
                                                                }`,
                                                            }}
                                                        >
                                                            <span>
                                                                View Profile
                                                            </span>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    ),
                                )}
                            </Row>
                            <ActionModal />
                            <ModalDrawer
                                onClose={drawerHandler}
                                visible={showDrawer}
                            />
                        </>
                    ) : (
                        <Row
                            justify="center"
                            align="middle"
                            style={{
                                height: '200px',
                            }}
                        >
                            <Col xs={24} className="no_contest">
                                <p>No contestant avaliable yet</p>

                                {user?.role === ENTERPRISE ? (
                                    <Col>
                                        <Link
                                            to={`${CONTEST_HOME}${id}/addcontestant`}
                                            style={{
                                                background: `${
                                                    subdomainUIstyling['0'] &&
                                                    subdomainUIstyling['0'][
                                                        'primaryColor'
                                                    ]
                                                }`,
                                            }}
                                            className="c-btn"
                                        >
                                            Add Contestant
                                        </Link>
                                    </Col>
                                ) : (
                                    <p>Do check back later</p>
                                )}
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {loading && <DashSpinner loading={loading} />}
        </>
    );
};

export default ContestDetails;
