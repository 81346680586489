import { Row, Col, Spin } from 'antd';
import React, { FC, ReactElement } from 'react';
import { dashSpinnerType } from '../types';

const DashSpinner: FC<dashSpinnerType> = ({ loading }): ReactElement => {
    return (
        <Row justify="center" align="middle" style={{ height: '80vh' }}>
            <Col xs={6}>
                {loading && <Spin className="dash-spinner" size="large" />}
            </Col>
        </Row>
    );
};

export default DashSpinner;
