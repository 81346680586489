export const ADD_TASK_INIT = 'ADD_TASK_INIT';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAILED = 'ADD_TASK_FAILED';

export const EDITING_TASK_INIT = 'EDITING_TASK_INIT';
export const EDITING_TASK_SUCCESS = 'EDITING_TASK_SUCCESS';
export const EDITING_TASK_FAILED = 'EDITING_TASK_FAILED';

export const UPDATE_TASK_INIT = 'UPDATE_TASK_INIT';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAILED = 'UPDATE_TASK_FAILED';

export const TASK_FETCH_INIT = 'TASK_FETCH_INIT';
export const TASK_FETCH_SUCCESS = 'TASK_FETCH_SUCCESS';
export const TASK_FETCH_FAILED = 'TASK_FETCH_FAILED';

export const CLEAR_TASK_FORM = 'CLEAR_TASK_FORM';
