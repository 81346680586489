import React, { FC, ReactElement, useState } from 'react';
import { Row, Col, Table, message } from 'antd';
import { Searchbar } from '../../../searchbar';
import { FileDoneOutlined } from '@ant-design/icons';
import TableColumn from './TableColumn';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchtasks } from '../../../../redux/actions/tasks.action';
import { RootState } from '../../../../redux/reducers';
import { clearError } from '../../../../redux/actions/common.action';
import { fetchedtaskTypeDb } from '../../../../types';

const Home: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const tasksState = useSelector((state: RootState) => state.tasks);
    const { error: requestError, loading, tasks } = tasksState;
    const [tableData, setTableData] = useState<fetchedtaskTypeDb[]>([]);

    useEffect(() => {
        dispatch(fetchtasks());
    }, [dispatch]);

    useEffect(() => {
        setTableData(tasks);
    }, [tasks]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        //clean up error and message
        return () => {
            dispatch(clearError());
        };
    }, [requestError, dispatch]);

    const onSearch = (input: string) => {
        const query = input.toLowerCase();

        if (query === '') return setTableData(tasks);
        if (tableData.length <= 0) return;

        const result = tasks.filter((task: fetchedtaskTypeDb) => {
            const eventTitle = task.event.eventTitle.toLowerCase();
            const taskTitle = task.title.toLowerCase();
            const isMatch =
                taskTitle.includes(query) || eventTitle.includes(query);
            return isMatch;
        });
        setTableData(result);
    };

    const prefix = <FileDoneOutlined className="search-prefix" />;

    return (
        <>
            <Row className="task-bar" justify="space-between">
                <Col md={10}>
                    <Searchbar
                        onChange={(e) => onSearch(e)}
                        placeholder="Search tasks"
                        onSearch={onSearch}
                        prefix={prefix}
                    />
                </Col>
            </Row>
            <Row className="table-content">
                <Col className="table-wrapper" span={24}>
                    <Table
                        loading={loading}
                        title={() => <h2>Tasks</h2>}
                        columns={TableColumn}
                        dataSource={tableData || []}
                        size="small"
                        rowKey="_id"
                    />
                </Col>
            </Row>
        </>
    );
};

export default Home;
