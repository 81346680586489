export const APPLICATION_FETCH_INIT = 'APPLICATION_FETCH_INIT';
export const APPLICATION_FETCH_SUCCESS = 'APPLICATION_FETCH_SUCCESS';
export const APPLICATION_FETCH_FAILED = 'APPLICATION_FETCH_FAILED';

export const REJECT_APPLICATION_INIT = 'REJECT_APPLICATION_INIT';
export const REJECT_APPLICATION_SUCCESS = 'REJECT_APPLICATION_SUCCESS';
export const REJECT_APPLICATION_FAILED = 'REJECT_APPLICATION_FAILED';

export const ACCEPT_APPLICATION_INIT = 'ACCEPT_APPLICATION_INIT';
export const ACCEPT_APPLICATION_SUCCESS = 'ACCEPT_APPLICATION_SUCCESS';
export const ACCEPT_APPLICATION_FAILED = 'ACCEPT_APPLICATION_FAILED';

export const ADD_APPLICATION_INIT = 'ADD_APPLICATION_INIT';
export const ADD_APPLICATION_SUCCESS = 'ADD_APPLICATION_SUCCESS';
export const ADD_APPLICATION_FAILED = 'ADD_APPLICATION_FAILED';
