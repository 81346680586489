import { ADD_TASK, EDIT_TASK, TASK_HOME } from '../../../routes';
import { AddTask } from '../../taskViews/addTask';
import { TaskHome } from '../../taskViews/home';
import { EditTask } from '../../taskViews/editTask';

const routeTask = [
    {
        id: 2,
        title: 'Home',
        exact: true,
        path: TASK_HOME,
        component: TaskHome,
    },
    {
        id: 3,
        title: 'Add task',
        exact: true,
        path: ADD_TASK,
        component: AddTask,
    },
    // {
    //     id: 4,
    //     title: 'task Details',
    //     exact: true,
    //     path: TASK_DETAIL,
    //     component: TaskDetails,
    // },
    {
        id: 5,
        title: 'edit task',
        exact: true,
        path: EDIT_TASK,
        component: EditTask,
    },
];

export default routeTask;
