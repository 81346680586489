import { Row, Col } from 'antd';
import moment from 'moment';
import React, { FC, ReactElement, useContext } from 'react';
import { DatePickerCustom } from '../../../../datePicker';
import { FormContext } from '../formProvider/FormContext';

const Step3: FC = (): ReactElement => {
    const { formInputs, setFormInputs } = useContext(FormContext);

    const ondateChange = (dateStr: string, name: string) => {
        if (dateStr) {
            setFormInputs({
                ...formInputs,
                [name]: moment(dateStr, 'YYYY-MM-DD'),
            });
        } else {
            setFormInputs({ ...formInputs, [name]: moment() });
        }
    };

    return (
        <div>
            <p className="step3__title">Pick A Starting and Closing Date</p>
            <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                <Col xs={24}>
                    <DatePickerCustom
                        name="startDate"
                        label="Starting Date"
                        hasLabel={true}
                        value={moment(formInputs?.startDate)}
                        placeholder="enter contest startDate"
                        onChange={(_, dateStr) =>
                            ondateChange(dateStr, 'startDate')
                        }
                    />
                </Col>
            </Row>
            <Row
                gutter={{ sm: 0, md: 20 }}
                className="field-row"
                style={{ marginTop: '20px' }}
            >
                <Col xs={24}>
                    <DatePickerCustom
                        name="closingDate"
                        label="Closing Date"
                        hasLabel={true}
                        value={moment(formInputs?.closingDate)}
                        placeholder="enter contest endDate"
                        onChange={(_, dateStr) =>
                            ondateChange(dateStr, 'closingDate')
                        }
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Step3;
