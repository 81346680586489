import { Modal, Tag } from 'antd';
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeEvictionProfileModal } from '../../../../redux/actions/UI.action';
import { RootState } from '../../../../redux/reducers';
import { Col, Row } from 'antd';

import './styles.scss';

const ProfileModal: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const contestants = useSelector(
        (state: RootState) => state.contestants.contestantDetails,
    );

    const isOpen = useSelector(
        (state: RootState) => state.UI.evictionProfileModalIsOpen,
    );

    const onActionComplete = () => {
        dispatch(closeEvictionProfileModal());
    };

    return (
        <Modal
            onCancel={onActionComplete}
            onOk={onActionComplete}
            visible={isOpen}
            title={false}
            footer={false}
            // width={300}
            bodyStyle={{
                padding: '10px !important',
            }}
        >
            <Row justify="center">
                <Col md={12}>
                    <div className="inline-fiel top_navb">
                        <img
                            src={contestants?.imageUrl}
                            alt="profile"
                            className="img_profil"
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </div>
                    {contestants?.isEvicted && (
                        <Tag
                            style={{ width: '100%', textAlign: 'center' }}
                            color="#CD201F"
                        >
                            EVICTED
                        </Tag>
                    )}
                </Col>
            </Row>
            <Row>
                <Col style={{ textAlign: 'center' }}>
                    <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
                        {contestants?.fullName.toUpperCase()}
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                        {' '}
                        {contestants?.totalVotes} votes
                    </p>
                    <p>{contestants?.bio}</p>
                </Col>
            </Row>
        </Modal>
    );
};

export default ProfileModal;
