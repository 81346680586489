import React, { FC, ReactElement, useState, useEffect } from 'react';
import { Col, Row, Tabs, Card, Image, Input as AntInput, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ava from '../img/ava.png';
import { RootState } from '../../../../redux/reducers';
import 'antd/dist/antd.css';

import ReactPlayer from 'react-player';

import 'react-modal-video/scss/modal-video.scss';
import { Link, useHistory, useParams } from 'react-router-dom';
import { clearContestantData } from '../../../../redux/actions/contestant.action';
import { routerParamsType } from '../../../contestsViews/detail';
import AddImageModal from './uploads/AddImageModal';
import AddVideoModal from './uploads/AddVideoModal';
import { getContestantVideos } from '../../../../redux/actions/video.action';
import LoadingSpinner from '../../../spinners/loadingSpinner';
import {
    fetchedSingleContestantTypeDb,
    fetchMediaTypeDb,
} from '../../../../types';
import {
    FETCH_CONTESTANT_DETAILS_INIT,
    FETCH_CONTESTANT_DETAILS_SUCCESS,
    FETCH_CONTESTANT_IMAGES_INIT,
    FETCH_CONTESTANT_IMAGES_SUCCESS,
} from '../../../../redux/types/contestant.type';

import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    WhatsappShareButton,
    EmailIcon,
    TwitterShareButton,
} from 'react-share';
import { BASE_URL, DOMAINPARTS, PUBLIC_CONTESTANT } from '../../../../routes';
import { CommentOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';
import { CommentHome } from '../../../commentViews/home';
import { openCommentModal } from '../../../../redux/actions/UI.action';
import { selectedImage } from '../../../../redux/actions/comment.action';
import { fetchContestById } from '../../../../redux/actions/contests.action';
import { socket } from '../../../../UIProvider';

const { TabPane } = Tabs;

const Home: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const {
        location: { search },
    } = useHistory();

    const contestantState = useSelector(
        (state: RootState) => state.contestants,
    );
    const usersInfo = useSelector((state: RootState) => state.auth.usersInfo);
    const imagesState = useSelector((state: RootState) => state.images);
    const videosState = useSelector((state: RootState) => state.videos);
    const { contestantImages, loading: imageLoading } = imagesState;
    const { contestantVideos, loading: videoLoading } = videosState;

    const contestInfo = useSelector(
        (state: RootState) => state.contests.eventInfo,
    );
    const [addImageModal, setAddImageModal] = useState(false);
    const [addVideoModal, setAddVideoModal] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);

    const contestantID = new URLSearchParams(search).get('cid');
    const { id }: routerParamsType = useParams();

    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    const { contestantDetails, loading } = contestantState;

    useEffect(() => {
        if (!contestantDetails) return;

        const prevTitle = document.title;
        document.title = `${contestantDetails?.fullName?.toUpperCase()} Profile`;
        return () => {
            document.title = prevTitle;
        };
        // eslint-disable-next-line
    }, [contestantDetails?._id]);

    useEffect(() => {
        dispatch(fetchContestById(id));
    }, [id, dispatch]);
    useEffect(() => {
        dispatch({ type: FETCH_CONTESTANT_DETAILS_INIT });
        dispatch({ type: FETCH_CONTESTANT_IMAGES_INIT });

        socket.emit('getcontestant', { cid: contestantID });
        socket.emit('images', {
            cid: contestantID,
            activeUserId: usersInfo?._id,
        });

        socket.on(contestantID, function (data: fetchedSingleContestantTypeDb) {
            dispatch({ type: FETCH_CONTESTANT_DETAILS_SUCCESS, payload: data });
        });

        socket.on(`${contestantID}-img`, function (data: fetchMediaTypeDb) {
            dispatch({ type: FETCH_CONTESTANT_IMAGES_SUCCESS, payload: data });
        });
        //clean up listener to avoid memory leak
        return () => {
            socket.removeAllListeners(contestantID);
        };
    }, [contestantID, dispatch, id, usersInfo]);

    useEffect(() => {
        if (contestantID) {
            //get contestant images and video
            dispatch(
                getContestantVideos({
                    cid: contestantID,
                    eventId: id,
                }),
            );
        }

        return () => {
            dispatch(clearContestantData());
        };
    }, [contestantID, dispatch, id]);

    const shareUrl = `${window.location.origin}${PUBLIC_CONTESTANT}/${contestantDetails?.shareId}`;

    const onCopyClicked = () => {
        setCopySuccess(true);
        navigator.clipboard.writeText(shareUrl);
        setTimeout(() => {
            setCopySuccess(false);
        }, 1000);
    };

    const onCommentHandler = (_id: string, url: string) => {
        dispatch(
            selectedImage({
                id: _id,
                url,
            }),
        );
        dispatch(openCommentModal());
    };

    const domainLength = window.location.host.split('.').length;

    return (
        <div className=" public__profile">
            <Row
                className="content-bio__content"
                style={{
                    backgroundImage:
                        subdomainUIstyling['0'] &&
                        `url(${subdomainUIstyling['0']['bannerImage']})`,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    backgroundBlendMode: 'overlay',
                }}
            >
                <Col xs={12}>
                    <div className="inline-field top_navb">
                        <img src={ava} alt="profile" className="img_profile" />
                        <div>
                            <p className="username">
                                {contestantDetails?.fullName}
                            </p>
                        </div>
                    </div>
                    <div className="content-bio__content--collapse inline-field"></div>
                </Col>

                <Col xs={12}>
                    <div className="data-fig">
                        <p className="username" style={{ margin: '5px' }}>
                            {contestantDetails?.totalVotes} Votes
                        </p>
                        <FacebookShareButton
                            url={shareUrl}
                            title={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                            className="Demo__some-network__share-button"
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>

                        <TwitterShareButton
                            url={shareUrl}
                            title={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                            className="Demo__some-network__share-button"
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>

                        <WhatsappShareButton
                            url={shareUrl}
                            title={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                            separator=":: "
                            className="Demo__some-network__share-button"
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        <EmailShareButton
                            url={shareUrl}
                            subject={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                            body={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                            className="Demo__some-network__share-button"
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </div>
                </Col>
            </Row>

            <Row className="dialog">
                <Col xs={22} md={14} className="content-biography left">
                    <Card className="for_card" title="Biography">
                        {!loading ? contestantDetails?.bio : <LoadingSpinner />}
                    </Card>
                </Col>

                <Col
                    xs={22}
                    md={8}
                    className="content-biography right share_profile"
                >
                    <Card className="content-bio__card" title="Share Profile">
                        <AntInput
                            addonAfter={
                                <span
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={onCopyClicked}
                                >
                                    {copySuccess ? (
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Copied!!
                                        </span>
                                    ) : (
                                        <span>Copy</span>
                                    )}
                                </span>
                            }
                            value={shareUrl}
                            onClick={undefined}
                            onFocus={undefined}
                        />

                        <Row
                            justify="space-around"
                            className="content-bio__card--social"
                        >
                            <FacebookShareButton
                                url={shareUrl}
                                title={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={shareUrl}
                                title={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                                className="Demo__some-network__share-button"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={shareUrl}
                                title={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                                separator=":: "
                                className="Demo__some-network__share-button"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <EmailShareButton
                                url={shareUrl}
                                subject={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                                body={`vote for ${contestantDetails?.fullName}`}
                                className="Demo__some-network__share-button"
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row className="dialog gall">
                <Col
                    xs={24}
                    md={14}
                    className="media left "
                    style={{
                        backgroundImage:
                            subdomainUIstyling['0'] &&
                            `url(${subdomainUIstyling['0']['bannerImage']})`,
                        backgroundColor: 'rgba(225, 225, 225, 0.)',
                        backgroundBlendMode: 'overlay',
                    }}
                >
                    <div className="content-view-tab">
                        <Tabs defaultActiveKey="1">
                            <TabPane
                                tab="IMAGES"
                                key="1"
                                style={{
                                    backgroundImage:
                                        subdomainUIstyling['0'] &&
                                        `url(${subdomainUIstyling['0']['bannerImage']})`,
                                    backgroundColor: 'rgba(225, 225, 225, 0.)',
                                    backgroundBlendMode: 'overlay',
                                }}
                            >
                                {!imageLoading ? (
                                    <Row>
                                        {contestantImages.map(
                                            (
                                                {
                                                    url,
                                                    numberOfLikes,
                                                    likes,
                                                    _id,
                                                    taskTitle,
                                                },
                                                i,
                                            ) => {
                                                return (
                                                    <Col key={i} md={8}>
                                                        <div className="image-gallery">
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        'center',
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {taskTitle}
                                                            </div>
                                                            <Image
                                                                src={url}
                                                                style={{
                                                                    cursor:
                                                                        'pointer',
                                                                }}
                                                            />
                                                            <Row
                                                                justify="space-between"
                                                                style={{
                                                                    padding:
                                                                        '3px 5px',
                                                                }}
                                                            >
                                                                <Col
                                                                    style={{
                                                                        cursor:
                                                                            'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                        onCommentHandler(
                                                                            _id,
                                                                            url,
                                                                        )
                                                                    }
                                                                >
                                                                    <CommentOutlined
                                                                        style={{
                                                                            color:
                                                                                '#1BCA8F',
                                                                            fontSize:
                                                                                '16px',
                                                                        }}
                                                                    />
                                                                    <span className="image-gallery__text image-gallery__text-view">
                                                                        View
                                                                    </span>
                                                                </Col>
                                                                <Col>
                                                                    {likes.findIndex(
                                                                        ({
                                                                            userId,
                                                                        }) =>
                                                                            usersInfo?._id ===
                                                                            userId,
                                                                    ) !== -1 ? (
                                                                        <LikeFilled
                                                                            style={{
                                                                                color:
                                                                                    '#1BCA8F',
                                                                                fontSize:
                                                                                    '16px',
                                                                                cursor:
                                                                                    'pointer',
                                                                            }}
                                                                            onClick={() =>
                                                                                socket.emit(
                                                                                    'unlikeImage',
                                                                                    {
                                                                                        cid: contestantID,
                                                                                        userId:
                                                                                            usersInfo?._id,
                                                                                        imageId: _id,
                                                                                    },
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <LikeOutlined
                                                                            style={{
                                                                                color:
                                                                                    '#1BCA8F',
                                                                                fontSize:
                                                                                    '16px',
                                                                                cursor:
                                                                                    'pointer',
                                                                            }}
                                                                            onClick={() =>
                                                                                socket.emit(
                                                                                    'likeImage',
                                                                                    {
                                                                                        cid: contestantID,
                                                                                        userId:
                                                                                            usersInfo?._id,
                                                                                        imageId: _id,
                                                                                    },
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                    <span className="image-gallery__text">
                                                                        {`${numberOfLikes} likes`}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                );
                                            },
                                        )}
                                    </Row>
                                ) : (
                                    <LoadingSpinner />
                                )}
                            </TabPane>
                            <TabPane tab="VIDEOS" key="2">
                                {!videoLoading ? (
                                    <>
                                        {contestantVideos.map(
                                            ({ _id, url, task }) => (
                                                <div key={_id}>
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {task.title}
                                                    </div>
                                                    <ReactPlayer
                                                        key={_id}
                                                        loop
                                                        controls
                                                        style={{
                                                            padding: '10px',
                                                        }}
                                                        width="100%"
                                                        height="400px"
                                                        padding="30px"
                                                        url={url}
                                                    />
                                                </div>
                                            ),
                                        )}
                                    </>
                                ) : (
                                    <LoadingSpinner />
                                )}
                                {/* <Video
                                    cloudName={CLOUD_NAME}
                                    publicId="v1624871571/2184ae6efa404c7b871161bec0b90b9b_1_tooqib"
                                    controls="true"
                                >
                                    <Transformation
                                        overlay="text:arial_60:watchme"
                                        gravity="north"
                                        y="20"
                                    />
                                </Video> */}
                            </TabPane>
                        </Tabs>
                    </div>
                </Col>
                <Col xs={8} className="media left personal_profile">
                    <Card className="for_card" title="Profile Summary">
                        {!loading ? (
                            <>
                                <p>
                                    <strong> Name:</strong>{' '}
                                    {contestantDetails?.fullName}
                                </p>
                                <p>
                                    <strong> Age:</strong>{' '}
                                    {contestantDetails?.age}
                                </p>
                                {contestantDetails?.user === usersInfo?._id &&
                                domainLength >= DOMAINPARTS ? (
                                    <Row justify="space-between">
                                        {contestantImages.length <
                                            subdomainUIstyling[0].plan
                                                .imagesPerContestant && (
                                            <Col
                                                className="c-btn"
                                                onClick={() =>
                                                    setAddImageModal(
                                                        (prevState) =>
                                                            !prevState,
                                                    )
                                                }
                                            >
                                                Add Image
                                            </Col>
                                        )}
                                        {contestantVideos.length <
                                            subdomainUIstyling[0].plan
                                                .videosPerContestant && (
                                            <Col
                                                className="c-btn"
                                                onClick={() =>
                                                    setAddVideoModal(
                                                        (prevState) =>
                                                            !prevState,
                                                    )
                                                }
                                            >
                                                Add Video
                                            </Col>
                                        )}
                                    </Row>
                                ) : (
                                    <Alert
                                        message={
                                            <p>
                                                <b>Note: </b> Do login to{' '}
                                                <Link
                                                    // eslint-disable-next-line
                                                    to={{
                                                        pathname: `https://${contestInfo?.subDomain}.${BASE_URL}`,
                                                    }}
                                                    target="_blank"
                                                >
                                                    {contestInfo?.subDomain}{' '}
                                                </Link>
                                                to upload media!!!
                                            </p>
                                        }
                                        type="info"
                                        showIcon
                                    />
                                )}
                            </>
                        ) : (
                            <LoadingSpinner />
                        )}
                    </Card>
                </Col>
            </Row>
            <AddImageModal
                onActionComplete={() =>
                    setAddImageModal((prevState) => !prevState)
                }
                isOpen={addImageModal}
            />
            <AddVideoModal
                onActionComplete={() =>
                    setAddVideoModal((prevState) => !prevState)
                }
                isOpen={addVideoModal}
            />
            <CommentHome />
        </div>
    );
};

export default Home;
