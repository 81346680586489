import { Row, Col, Tabs, message, Image, Input as AntInput, Alert } from 'antd';
import React, {
    ChangeEvent,
    FC,
    Fragment,
    ReactElement,
    // useCallback,
    useEffect,
    useState,
} from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
// import LoadingSpinner from '../../components/spinners/loadingSpinner';
import {
    clearContestantData,
    getContestantBySharedId,
} from '../../redux/actions/contestant.action';
import { RootState } from '../../redux/reducers';
import {
    fetchedSingleContestantTypeDb,
    fetchMediaTypeDb,
    routerParamsType,
} from '../../types';

import { handlerEvent, Input } from '../../components/input';
import { firstCharToUpperCase, sentenceTruncater } from '../../utils';
import {
    FETCH_CONTESTANT_DETAILS_INIT,
    FETCH_CONTESTANT_DETAILS_SUCCESS,
    FETCH_CONTESTANT_IMAGES_INIT,
    FETCH_CONTESTANT_IMAGES_SUCCESS,
} from '../../redux/types/contestant.type';
import {
    clearPayment,
    initializeVotingPayment,
    onPayPalTransactionApproved,
    verifyVotingPayment,
    VotingPaymentCancelled,
} from '../../redux/actions/payment.action';
import { clearError } from '../../redux/actions/common.action';
import { NOT_FOUND, PUBLIC_CONTESTANT, SIGN_IN } from '../../routes';

import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    WhatsappShareButton,
    EmailIcon,
    TwitterShareButton,
} from 'react-share';
import { LikeFilled, LikeOutlined } from '@ant-design/icons';
import { getContestantVideos } from '../../redux/actions/video.action';
import { paymentType } from '../../constants/transactionEnum';
import { Navigation } from '../../components/navigation';
import FooterSection from '../landing/FooterSection';

import { socket } from '../../UIProvider';
import { PRICE } from '../../constants/price';
import { PaypalButton } from '../../components/paypalButton';

import { FlutterwaveButton } from '../../components/flutterwaveButton';
import { SelectPayment } from '../../components/selectPayment';
import ReactGA from 'react-ga';

import './styles.scss';
const { TabPane } = Tabs;

const PublicProfile: FC = (): ReactElement => {
    const {
        location: { search },
        replace,
    } = useHistory();

    const dispatch = useDispatch();

    // const loading = useSelector(
    //     (state: RootState) => state.contestants.loading,
    // );
    const contestantDetails = useSelector(
        (state: RootState) => state.contestants.contestantDetails,
    );

    const imagesState = useSelector((state: RootState) => state.images);
    const userState = useSelector((state: RootState) => state.auth);
    const { usersInfo, token } = userState;
    const authenticated = useSelector(
        (state: RootState) => state.auth.authenticated,
    );
    const videosState = useSelector((state: RootState) => state.videos);
    const { contestantImages } = imagesState;
    const { contestantVideos } = videosState;

    const payment = useSelector((state: RootState) => state.payment);
    const { authorizationUrl, error: requestError } = payment;

    const [copySuccess, setCopySuccess] = useState(false);

    const [formInputs, setFormInput] = useState({
        vote: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
    });
    const [isPaypal, setIsPaypal] = useState(false);
    const transactionRef = new URLSearchParams(search).get('reference');
    const transactionID = new URLSearchParams(search).get('transaction_id');

    const flutterwaveStatus = new URLSearchParams(search).get('status');
    const flutterwaveRef = new URLSearchParams(search).get('tx_ref');

    const { id }: routerParamsType = useParams();

    const amountToPay = !isNaN(parseInt(formInputs.vote, 10))
        ? // ? parseInt(formInputs.vote, 10) * 50
          parseInt(formInputs.vote, 10) * 1
        : 0;

    const subdomainAccount = useSelector(
        (state: RootState) => state.subdomainStyling.subdomainUIstyling,
    );

    useEffect(() => {
        if (
            (subdomainAccount[0] && !subdomainAccount[0]?.userAccess) ||
            (contestantDetails && !contestantDetails.isActive)
        ) {
            replace(SIGN_IN);
        }
    }, [subdomainAccount, replace, contestantDetails]);

    useEffect(() => {
        if (flutterwaveStatus === 'cancelled' && flutterwaveRef) {
            const domainParts = window.location.host;
            const protocol = window.location.protocol;
            const domain = `${protocol}//${domainParts}`;
            dispatch(
                VotingPaymentCancelled({
                    transactionReference: flutterwaveRef,
                    domain: domain,
                    paymentMethod: paymentType.FLUTTERWAVE,
                }),
            );
        }
        // eslint-disable-next-line
    }, [flutterwaveRef, flutterwaveStatus]);

    useEffect(() => {
        if (!contestantDetails) return;

        const prevTitle = document.title;
        document.title = `${contestantDetails?.fullName?.toUpperCase()} Profile`;
        return () => {
            document.title = prevTitle;
        };
        // eslint-disable-next-line
    }, [contestantDetails?._id]);

    useEffect(() => {
        const domainParts = window.location.host;
        const protocol = window.location.protocol;
        const domain = `${protocol}//${domainParts}`;
        if (transactionRef) {
            dispatch(
                verifyVotingPayment({
                    reference: transactionRef,
                    domain: domain,
                    paymentMethod: paymentType.PAYSTACK,
                }),
            );
        } else if (transactionID) {
            dispatch(
                verifyVotingPayment({
                    reference: transactionID,
                    domain: domain,
                    paymentMethod: paymentType.FLUTTERWAVE,
                }),
            );
            ReactGA.event({
                category: 'Public Voting',
                action: 'payment verified-flutterwave',
                label: 'Public Voting Widget',
            });
        }
    }, [transactionRef, dispatch, transactionID]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }
        return () => {
            dispatch(clearError());
            dispatch(clearPayment());
        };
    }, [requestError, dispatch]);

    useEffect(() => {
        if (authorizationUrl) {
            window.location.href = authorizationUrl;
        }
    }, [authorizationUrl]);

    useEffect(() => {
        if (!id) return;
        dispatch(getContestantBySharedId(id));

        return () => {
            dispatch(clearContestantData());
        };
    }, [id, dispatch]);

    useEffect(() => {
        if (!contestantDetails) return;
        dispatch({ type: FETCH_CONTESTANT_DETAILS_INIT });
        dispatch({ type: FETCH_CONTESTANT_IMAGES_INIT });
        socket.emit('getcontestant', { cid: contestantDetails._id });
        //get contestant images and video
        socket.emit('images', {
            cid: contestantDetails._id,
            activeUserId: usersInfo?._id,
        });

        dispatch(
            getContestantVideos({
                cid: contestantDetails._id,
                eventId: contestantDetails.event._id,
            }),
        );

        socket.on(contestantDetails._id, function (
            data: fetchedSingleContestantTypeDb | boolean,
        ) {
            //if error || invalid id||not found
            if (data == null || data === false) {
                return replace(NOT_FOUND);
            }
            dispatch({ type: FETCH_CONTESTANT_DETAILS_SUCCESS, payload: data });
        });

        socket.on(`${contestantDetails._id}-img`, function (
            data: fetchMediaTypeDb,
        ) {
            dispatch({
                type: FETCH_CONTESTANT_IMAGES_SUCCESS,
                payload: data,
            });
        });

        //clean up listener to avoid memory leak
        return () => {
            socket.removeAllListeners(contestantDetails?._id);
        };
        // eslint-disable-next-line
    }, [contestantDetails?._id, dispatch, usersInfo, replace]);

    const onCopyClicked = () => {
        setCopySuccess(true);
        navigator.clipboard.writeText(shareUrl);
        setTimeout(() => {
            setCopySuccess(false);
        }, 1000);
    };

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInput({ ...formInputs, [name]: value });
    };
    const selectFundingChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsPaypal(event.target.value !== 'OTHERS');
    };
    const onPayPalApproved = (data: {
        payer: {
            email_address: string;
            name: { surname: string; given_name: string };
        };
        purchase_units: any;
        id: string;
    }) => {
        const domainParts = window.location.host;
        const protocol = window.location.protocol;
        const domain = `${protocol}//${domainParts}`;
        const {
            payer: { email_address, name },
            purchase_units,
            id,
        } = data;

        const vote = parseInt(purchase_units[0]?.amount?.value, 10) / PRICE;
        dispatch(
            onPayPalTransactionApproved({
                transactionDetails: {
                    amount: purchase_units[0]?.amount?.value,
                    paymentMethod: paymentType.PAYPAL,
                    domain: domain,
                    transactionID: id,
                },
                userDetails: {
                    firstName: name?.surname,
                    lastName: name?.given_name,
                    email: email_address,
                },
                voteDetails: {
                    numberOfVote: vote,
                },
            }),
        );
        setFormInput({
            ...formInputs,
            vote: '',
        });
        ReactGA.event({
            category: 'Public Voting',
            action: 'payment verified- paypal',
            label: 'Public Voting Widget',
        });
    };

    const onPayHandler = async (paymentMethod) => {
        if (!contestantDetails) return message.error('reload browser');
        const { vote, firstName, lastName, email, phoneNumber } = formInputs;

        if (!firstName || !vote || !email) {
            return message.error('All fields are required!!!');
        }
        if (parseInt(vote, 10) < 1) {
            return message.error('Number of votes required!!!');
        }
        const callback_url = `${window.location.origin}${PUBLIC_CONTESTANT}/${id}`;
        message.loading('Redirecting to secured payment portal...');

        //dispatch vote contestant
        dispatch(
            initializeVotingPayment({
                transactionDetails: {
                    amount: amountToPay,
                    callback_url: callback_url,
                    paymentMethod: paymentMethod,
                },
                userDetails: {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                },
                voteDetails: {
                    contestant: contestantDetails._id,
                    event: contestantDetails.event._id,
                    numberOfVote: parseInt(vote, 10),
                },
            }),
        );
        ReactGA.event({
            category: 'Public Voting',
            action: 'payment init-flutterwave',
            label: 'Public Voting Widget',
        });
    };

    const shareUrl = `${window.location.origin}${PUBLIC_CONTESTANT}/${id}`;
    return (
        <Fragment>
            {/* {!loading ? ( */}
            <>
                <Navigation />
                <Row className="public-profile">
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <div className="public-profile__imgwrapper">
                            <Image
                                className="public-profile__imgwrapper--img"
                                src={contestantDetails?.imageUrl}
                            />
                        </div>
                    </Col>
                    <Col
                        xs={24}
                        sm={12}
                        md={{ span: 14, offset: 2 }}
                        lg={{ span: 10, offset: 0 }}
                    >
                        <div className="public-profile__details">
                            <div className="public-profile__details-item">
                                <p> Contestant Name</p>
                                <p>{contestantDetails?.fullName}</p>
                            </div>
                            <div className="public-profile__details-item">
                                <p> Contestant Votes</p>
                                <p>
                                    {contestantDetails?.totalVotes.toLocaleString()}
                                </p>
                            </div>
                        </div>
                        <div className="public-profile__details">
                            <h2>Share Profile</h2>

                            <AntInput
                                addonAfter={
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={onCopyClicked}
                                    >
                                        {copySuccess ? (
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Copied!!
                                            </span>
                                        ) : (
                                            <span>Copy</span>
                                        )}
                                    </span>
                                }
                                value={shareUrl}
                                onClick={undefined}
                                onFocus={undefined}
                            />

                            <Row
                                justify="space-around"
                                className="public-profile__details--social"
                            >
                                <FacebookShareButton
                                    url={shareUrl}
                                    title={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookIcon size={37} />
                                </FacebookShareButton>

                                <TwitterShareButton
                                    url={shareUrl}
                                    title={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                                    className="Demo__some-network__share-button"
                                >
                                    <TwitterIcon size={37} />
                                </TwitterShareButton>

                                <WhatsappShareButton
                                    url={shareUrl}
                                    title={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                                    separator=":: "
                                    className="Demo__some-network__share-button"
                                >
                                    <WhatsappIcon size={37} />
                                </WhatsappShareButton>

                                <EmailShareButton
                                    url={shareUrl}
                                    subject={`Vote for ${contestantDetails?.fullName?.toUpperCase()} for the ${contestantDetails?.event.eventTitle.toUpperCase()} voting contest`}
                                    body={`vote for ${contestantDetails?.fullName}`}
                                    className="Demo__some-network__share-button"
                                >
                                    <EmailIcon size={37} />
                                </EmailShareButton>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={0} md={24} lg={8}>
                        <div className="public-profile__details public-profile__bio">
                            <h2>Biography</h2>
                            <p>
                                {sentenceTruncater(
                                    contestantDetails?.bio
                                        ? contestantDetails?.bio
                                        : '',
                                    600,
                                )}
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="public-action">
                    <Col
                        xs={{ order: 1, span: 24, offset: 0 }}
                        md={{ order: 1, span: 20, offset: 2 }}
                        lg={{ order: 0, span: 16, offset: 0 }}
                    >
                        <div className="public-action__media">
                            {!token && (
                                <div>
                                    <Alert
                                        message={
                                            <span>
                                                Please do{' '}
                                                <Link to={SIGN_IN}>
                                                    Sign In
                                                </Link>{' '}
                                                to like, comment, view more
                                                images and videos
                                            </span>
                                        }
                                        type="info"
                                        showIcon
                                    />
                                </div>
                            )}
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="IMAGES" key="1">
                                    <Row>
                                        {contestantImages
                                            .slice(-2)
                                            .map(
                                                (
                                                    {
                                                        url,
                                                        numberOfLikes,
                                                        likes,
                                                        _id,
                                                    },
                                                    i,
                                                ) => {
                                                    return (
                                                        <Col key={i} lg={8}>
                                                            <div className="image-gallery">
                                                                <Image
                                                                    src={url}
                                                                    style={{
                                                                        cursor:
                                                                            'pointer',
                                                                    }}
                                                                />
                                                                <Row
                                                                    justify="end"
                                                                    style={{
                                                                        padding:
                                                                            '3px 5px',
                                                                    }}
                                                                >
                                                                    <Col>
                                                                        {likes.findIndex(
                                                                            ({
                                                                                userId,
                                                                            }) =>
                                                                                usersInfo?._id ===
                                                                                userId,
                                                                        ) !==
                                                                        -1 ? (
                                                                            <LikeFilled
                                                                                style={{
                                                                                    color:
                                                                                        '#1BCA8F',
                                                                                    fontSize:
                                                                                        '16px',
                                                                                    cursor:
                                                                                        'pointer',
                                                                                }}
                                                                                onClick={
                                                                                    !authenticated
                                                                                        ? undefined
                                                                                        : () =>
                                                                                              socket.emit(
                                                                                                  'unlikeImage',
                                                                                                  {
                                                                                                      cid: id,
                                                                                                      userId:
                                                                                                          usersInfo?._id,
                                                                                                      imageId: _id,
                                                                                                  },
                                                                                              )
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <LikeOutlined
                                                                                style={{
                                                                                    color:
                                                                                        '#1BCA8F',
                                                                                    fontSize:
                                                                                        '16px',
                                                                                    cursor:
                                                                                        'pointer',
                                                                                }}
                                                                                onClick={
                                                                                    !authenticated
                                                                                        ? undefined
                                                                                        : () =>
                                                                                              socket.emit(
                                                                                                  'likeImage',
                                                                                                  {
                                                                                                      cid: id,
                                                                                                      userId:
                                                                                                          usersInfo?._id,
                                                                                                      imageId: _id,
                                                                                                  },
                                                                                              )
                                                                                }
                                                                            />
                                                                        )}
                                                                        <span className="image-gallery__text">
                                                                            {`${numberOfLikes} likes`}
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    );
                                                },
                                            )}
                                    </Row>
                                </TabPane>
                                <TabPane tab="VIDEOS" key="2">
                                    <>
                                        {contestantVideos
                                            .slice(-1)
                                            .map(({ _id, url }) => (
                                                <ReactPlayer
                                                    key={_id}
                                                    loop
                                                    controls
                                                    style={{
                                                        padding: '10px',
                                                    }}
                                                    width="100%"
                                                    height="400px"
                                                    padding="30px"
                                                    url={url}
                                                />
                                            ))}
                                    </>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                    <Col
                        xs={{ order: 0, span: 24, offset: 0 }}
                        md={{ order: 0, span: 14, offset: 5 }}
                        lg={{ order: 1, span: 8, offset: 0 }}
                    >
                        <div className="public-action__card">
                            <h2 className="public-action__card-title">
                                vote for {contestantDetails?.fullName}{' '}
                            </h2>
                            <SelectPayment
                                onRadioSelect={selectFundingChange}
                            />
                            <form>
                                <Col xs={24}>
                                    <Input
                                        name="vote"
                                        onChange={onInputChange}
                                        inputType="number"
                                        min={0}
                                        hasLabel={true}
                                        placeholder={firstCharToUpperCase(
                                            'Number of votes',
                                        )}
                                        value={formInputs.vote}
                                        label="Number of votes"
                                    />
                                    {isPaypal && (
                                        <small style={{ fontWeight: 'bold' }}>
                                            Minimum of 10 votes
                                        </small>
                                    )}
                                </Col>
                                {!isPaypal && (
                                    <Fragment>
                                        <Col xs={24} className="field-row">
                                            <Input
                                                name="firstName"
                                                onChange={onInputChange}
                                                hasLabel={true}
                                                placeholder={firstCharToUpperCase(
                                                    'First Name',
                                                )}
                                                value={formInputs.firstName}
                                                label="First name"
                                            />
                                        </Col>
                                        <Col xs={24} className="field-row">
                                            <Input
                                                name="lastName"
                                                onChange={onInputChange}
                                                hasLabel={true}
                                                placeholder={firstCharToUpperCase(
                                                    'Last Name',
                                                )}
                                                value={formInputs.lastName}
                                                label="Last name"
                                            />
                                        </Col>
                                        <Col xs={24} className="field-row">
                                            <Input
                                                name="email"
                                                onChange={onInputChange}
                                                inputType="email"
                                                hasLabel={true}
                                                placeholder={firstCharToUpperCase(
                                                    'Email Address',
                                                )}
                                                value={formInputs.email}
                                                label="Email"
                                            />
                                        </Col>
                                        <Col xs={24} className="field-row">
                                            <Input
                                                name="phoneNumber"
                                                onChange={onInputChange}
                                                inputType="number"
                                                hasLabel={true}
                                                placeholder={firstCharToUpperCase(
                                                    'Phone Number',
                                                )}
                                                value={formInputs.phoneNumber}
                                                label="Phone number"
                                            />
                                        </Col>
                                    </Fragment>
                                )}
                                <p
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}
                                    className="field-row"
                                >
                                    Each vote costs {`$`}
                                    {PRICE}
                                </p>
                                <Col xs={24}>
                                    <p className="pay_title">
                                        <span style={{ fontWeight: 'lighter' }}>
                                            Pay
                                        </span>
                                        <span style={{ fontSize: '18px' }}>
                                            {amountToPay === 0
                                                ? ''
                                                : ` $${amountToPay.toLocaleString()}`}
                                        </span>
                                    </p>
                                    <Row gutter={[20, 20]}>
                                        <Col xs={24}>
                                            {isPaypal && contestantDetails ? (
                                                <PaypalButton
                                                    isPublicVote
                                                    amount={amountToPay}
                                                    visible={amountToPay < 10}
                                                    onPaymentApproved={
                                                        onPayPalApproved
                                                    }
                                                    initData={{
                                                        contestant:
                                                            contestantDetails?._id,
                                                        event:
                                                            contestantDetails
                                                                ?.event._id,
                                                    }}
                                                />
                                            ) : (
                                                <FlutterwaveButton
                                                    handleFlutterPay={() =>
                                                        onPayHandler(
                                                            paymentType.FLUTTERWAVE,
                                                        )
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </form>
                        </div>
                    </Col>
                </Row>

                <FooterSection />
            </>
            {/* ) : (
                <LoadingSpinner />
            )} */}
        </Fragment>
    );
};

export default PublicProfile;
