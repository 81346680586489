import { useWindowWidth } from '@react-hook/window-size';
import { Drawer } from 'antd';
import moment from 'moment';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeNotificationModal,
    openNotificationDetailModal,
} from '../../../../redux/actions/UI.action';
import { RootState } from '../../../../redux/reducers';
import { sentenceTruncater } from '../../../../utils';

const HomeDrawer: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();
    const [isMobile, setIsMobile] = useState(false);
    const notificationModalIsOpen = useSelector(
        (state: RootState) => state.UI.notificationModalIsOpen,
    );
    const notifications = useSelector(
        (state: RootState) => state.notifications,
    );

    const { notificationsList } = notifications;

    useEffect(() => {
        if (windowWidth <= 760) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowWidth]);

    return (
        <Drawer
            placement="right"
            title={
                <p
                    style={{
                        fontSize: '16px',
                        textAlign: 'center',
                        margin: '0px',
                        textTransform: 'uppercase',
                    }}
                >
                    Notifications
                </p>
            }
            width={!isMobile ? 420 : '100vw'}
            onClose={() => dispatch(closeNotificationModal())}
            visible={notificationModalIsOpen}
            mask={false}
            drawerStyle={{ padding: '20px 0px 60px' }}
            bodyStyle={{ padding: '0px' }}
            headerStyle={{ boxShadow: ' 0 0 1.5rem -0.5rem #777' }}
            className="drawer-modal"
        >
            {notificationsList.map(({ _id, content, title, createdAt }) => (
                <div
                    key={_id}
                    className="notification__item"
                    onClick={() => dispatch(openNotificationDetailModal(_id))}
                >
                    <span>{sentenceTruncater(title, 40)}</span>
                    <span>{sentenceTruncater(content, 55)}</span>
                    <span>{moment(createdAt).fromNow()}</span>
                </div>
            ))}
        </Drawer>
    );
};

export default HomeDrawer;
