import React, { ReactElement } from 'react';
import { firstCharToUpperCase } from '../../../../utils';
import moment from 'moment';

import { columnType } from '../types';
import { fetchSudomainAccountTypeDb, plansType } from '../../../../types';
import {
    CheckCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { Popconfirm, Tag } from 'antd';
import { approveAccount } from '../../../../redux/actions/enterprise.action';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../../routes';

const dateRender = (value: Date): ReactElement => {
    return <span>{moment(value).format('LL')}</span>;
};

const titleRender = (value: string): ReactElement => {
    return <span>{firstCharToUpperCase(value || '')}</span>;
};

const planRender = (value: plansType): ReactElement => {
    return <span>{firstCharToUpperCase(value?.activePlan || 'free')}</span>;
};

const SetUpCompletedRender = (value: boolean): ReactElement => {
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;
    return (
        <span>
            {!value ? (
                <MinusCircleOutlined
                    style={{ color: 'red', fontSize: '16px' }}
                />
            ) : (
                <CheckCircleOutlined
                    style={{
                        color: subdomainUIstyling['0']
                            ? subdomainUIstyling['0']['primaryColor']
                            : '#1BCA8F',
                        fontSize: '16px',
                    }}
                />
            )}
        </span>
    );
};

const StatusRender = (
    value: string,
    record: fetchSudomainAccountTypeDb,
): ReactElement => {
    const dispatch = useDispatch();
    return (
        <>
            {value ? (
                <Tag icon={<CheckCircleOutlined />} color="success">
                    RESOLVED
                </Tag>
            ) : (
                <Popconfirm
                    placement="left"
                    title={
                        <p>
                            Are you sure the sub-domain{' '}
                            <b>
                                <Link
                                    to={{
                                        pathname: `https://${record.subDomain}.${BASE_URL}`,
                                    }}
                                    target="_blank"
                                >
                                    {record.subDomain}{' '}
                                </Link>
                            </b>
                            is successfully resolved ?
                        </p>
                    }
                    onConfirm={() =>
                        dispatch(approveAccount({ id: record._id }))
                    }
                    // onCancel={cancel}
                    okText="Approve"
                    cancelText="Cancel"
                >
                    <Tag
                        icon={<SyncOutlined />}
                        style={{ cursor: 'pointer' }}
                        color="processing"
                    >
                        PENDING
                    </Tag>
                </Popconfirm>
            )}
        </>
    );
};

const TableColumn: columnType = [
    {
        title: 'Company Name',
        dataIndex: 'companyName',
        render: titleRender,
    },
    {
        title: 'Company Location',
        dataIndex: 'companyLocation',
        render: titleRender,
    },
    {
        title: 'Pageant Name',
        dataIndex: 'pageantName',
        render: titleRender,
    },
    {
        title: 'Start Date',
        dataIndex: 'pageantStartDate',
        render: dateRender,
    },
    {
        title: 'End Date',
        dataIndex: 'pageantEndDate',
        render: dateRender,
    },

    {
        title: 'Active Plan',
        dataIndex: 'plan',
        render: planRender,
    },
    {
        title: 'Assigned Name',
        dataIndex: 'subDomain',
    },
    {
        title: 'Paid Date',
        dataIndex: 'startDate',
        render: dateRender,
    },
    {
        title: 'Expire Date',
        dataIndex: 'endDate',
        render: dateRender,
    },
    {
        title: 'Account Setup',
        dataIndex: 'setUpCompleted',
        render: SetUpCompletedRender,
    },
    {
        title: 'Status',
        dataIndex: 'isApproved',
        render: StatusRender,
    },

    // {
    //     title: '',
    //     key: 'action',
    //     dataIndex: 'action',
    //     render: ActionRender,
    // },
];

export default TableColumn;
