import { DASHBOARD } from '../../../routes';
import { DashboardHome } from '../../dashboardViews/home';

// C:\1024\frontend-pageant\src\components\dashboardViews\home\components\DashStats.tsx

const dashStatsRoutes = [
    {
        id: 2,
        title: 'Home',
        exact: true,
        path: DASHBOARD,
        component: DashboardHome,
    },
];

export default dashStatsRoutes;
