export const OPEN_VOTE_MODAL = 'OPEN_VOTE_MODAL';
export const CLOSE_VOTE_MODAL = 'CLOSE_VOTE_MODAL';

export const OPEN_NOTIFICATION_MODAL = 'OPEN_NOTIFICATION_MODAL';
export const CLOSE_NOTIFICATION_MODAL = 'CLOSE_NOTIFICATION_MODAL';

export const OPEN_NOTIFICATION_DETAIL_MODAL = 'OPEN_NOTIFICATION_DETAIL_MODAL';
export const CLOSE_NOTIFICATION_DETAIL_MODAL =
    'CLOSE_NOTIFICATION_DETAIL_MODAL';

export const OPEN_COMMENT_MODAL = 'OPEN_COMMENT_MODAL';
export const CLOSE_COMMENT_MODAL = 'CLOSE_COMMENT_MODAL';

export const OPEN_EVICTION_PROFILE_MODAL = 'OPEN_EVICTION_PROFILE_MODAL';
export const CLOSE_EVICTION_PROFILE_MODAL = 'CLOSE_EVICTION_PROFILE_MODAL';

export const OPEN_ADD_EVENT_IMAGE_MODAL = 'OPEN_ADD_EVENT_IMAGE_MODAL';
export const CLOSE_ADD_EVENT_IMAGE_MODAL = 'CLOSE_ADD_EVENT_IMAGE_MODAL';

export const OPEN_ADD_EVENT_VIDEO_MODAL = 'OPEN_ADD_EVENT_VIDEO_MODAL';
export const CLOSE_ADD_EVENT_VIDEO_MODAL = 'CLOSE_ADD_EVENT_VIDEO_MODAL';
