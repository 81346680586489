import React, { ReactElement } from 'react';
import { columnType } from '../types';
import { Tag } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import { paymentStatus } from '../../../../types';

const statusRender = (value: string): ReactElement => {
    return (
        <span>
            {value === paymentStatus.COMPELTE && (
                <Tag className="payment-success">Success</Tag>
            )}
            {value === paymentStatus.CANCELLED && (
                <Tag className="payment-cancel">Cancelled</Tag>
            )}
            {value === paymentStatus.PROCESSING && (
                <Tag className="payment-processing">Processing</Tag>
            )}
            {value === paymentStatus.FAILED && (
                <Tag className="payment-failed">Failed</Tag>
            )}
        </span>
    );
};

const purposeRender = (): ReactElement => (
    <span className="purpose-render">
        <span>Purchased</span>
        <ArrowDownOutlined className="arr-up" />
    </span>
);

const amountRender = (val: string): ReactElement => (
    <span className="amount-col">
        {`$`}
        {val.toLocaleString()}
    </span>
);
const voteRender = (val: string): ReactElement => (
    <span className="amount-col">{val ? val : '---'}</span>
);

const dateRender = (val: string): ReactElement => (
    <span className="date-column">{new Date(val).toDateString()}</span>
);

const refRender = (val: string): ReactElement => (
    <span className="date-column">{val ? val : '----------------'}</span>
);
const TableColumn: columnType = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: dateRender,
    },
    {
        title: 'Payment Reference',
        dataIndex: 'reference',
        render: refRender,
    },
    {
        title: 'Payment Method',
        dataIndex: 'paymentMethod',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: amountRender,
    },
    {
        title: 'Votes',
        dataIndex: 'votes',
        render: voteRender,
    },
    {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: statusRender,
    },
    {
        // title: '',
        key: 'purpose',
        dataIndex: 'votes',
        render: purposeRender,
    },
];

export default TableColumn;
