import { DASHBOARD } from './main';

export const DASHBOARDVIEW = `${DASHBOARD}/view`;
export const CONTEST = `${DASHBOARD}/contest`;
export const TRANSACTION = `${DASHBOARD}/transactions`;
export const PROFILE = `${DASHBOARD}/profile`;
export const TASK = `${DASHBOARD}/task`;
export const EVICTION = `${DASHBOARD}/eviction`;
export const APPLICATION = `${DASHBOARD}/application`;
export const DASHSTATS = `${DASHBOARD}/stats`;
export const SETTINGS = `${DASHBOARD}/settings`;
export const ENTERPRISE = `${DASHBOARD}/enterprise`;
export const MEDIA = `${DASHBOARD}/media`;
