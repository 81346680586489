import React, { ReactElement, FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';
import warningIcon from '../../assets/warning.svg';
import { HOME } from '../../routes';
import { firstCharToUpperCase } from '../../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';

const { Text } = Typography;

const FailedView: FC = (): ReactElement => {
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;
    return (
        <div className="content-wrapper">
            <div className="image-guard">
                <img className="image" src={warningIcon} alt="not found" />
            </div>
            <Text className="text-2">
                This link as expired, please do resend a new request.
            </Text>
            <Link to={HOME}>
                <Button
                    style={{
                        backgroundColor: `${
                            subdomainUIstyling['0']
                                ? subdomainUIstyling['0']['primaryColor']
                                : '#1bca8f'
                        }`,
                        color: 'white',
                    }}
                >
                    {firstCharToUpperCase('Go back home')}
                </Button>
            </Link>
        </div>
    );
};

export default FailedView;
