import { Button } from 'antd';
import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import {
    fetchedContestantTypeDb,
    fetchedEventTypeDb,
    fetchedtaskTypeDb,
    fetchPendingMediasDb,
} from '../../../../types';
import { columnType } from '../types';
import VideoRenderModal from './modal';

const contestantRender = (value: fetchedContestantTypeDb): ReactElement => {
    return <span>{value.fullName}</span>;
};
const eventRender = (value: fetchedEventTypeDb): ReactElement => {
    return <span>{value.eventTitle}</span>;
};
const taskRender = (value: fetchedtaskTypeDb): ReactElement => {
    return <span>{value.title}</span>;
};

const ActionRender = (_, record: fetchPendingMediasDb): ReactElement => {
    const [openModal, setModalOpen] = useState(false);
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;
    const onActionComplete = () => {
        setModalOpen((prevState) => !prevState);
    };
    return (
        <>
            <Button
                style={{
                    backgroundColor: subdomainUIstyling[0]
                        ? subdomainUIstyling[0].primaryColor
                        : '#1bca8f',
                    color: '#fff',
                }}
                onClick={() => setModalOpen(true)}
            >
                {`View & Publish`}
            </Button>
            {openModal && (
                <VideoRenderModal
                    record={record}
                    openModal={openModal}
                    onActionComplete={onActionComplete}
                />
            )}
        </>
    );
};
const TableColumn: columnType = [
    {
        title: 'contestant',
        dataIndex: 'contestant',
        render: contestantRender,
    },
    {
        title: 'Event',
        dataIndex: 'event',
        render: eventRender,
    },
    {
        title: 'Task',
        dataIndex: 'task',
        render: taskRender,
    },

    {
        title: '',
        key: 'action',
        dataIndex: 'action',
        render: ActionRender,
    },
];

export default TableColumn;
