import React, { ReactElement, FC } from 'react';
import { Input as AntInput } from 'antd';
import { inputProps } from '../types';

const getComponenByType = (componenctType: string | undefined) => {
    switch (componenctType) {
        case 'Search':
            return AntInput['Search'];
        case 'Password':
            return AntInput['Password'];
        case 'TextArea':
            return AntInput['TextArea'];
        default:
            return AntInput;
    }
};

const CustomInput: FC<inputProps> = ({
    label,
    placeholder,
    hasLabel,
    onChange,
    componentType,
    inputType,
    name,
    value,
    disabled,
    min,
    rows,
    isRequired,
    addonBefore,
    suffix,
    minLength,
}): ReactElement => {
    const Input = getComponenByType(componentType);
    return (
        <div className="custom-input">
            {hasLabel && (
                <label htmlFor={name}>
                    {isRequired && (
                        <span style={{ color: 'red', marginRight: '2px' }}>
                            *
                        </span>
                    )}
                    {label}
                </label>
            )}

            {componentType === 'TextArea' && (
                <Input
                    disabled={disabled}
                    value={value}
                    id={name}
                    name={name}
                    type={inputType}
                    placeholder={placeholder}
                    onChange={onChange}
                    min={min}
                    rows={rows}
                />
            )}
            {componentType !== 'TextArea' && (
                <Input
                    disabled={disabled}
                    value={value}
                    id={name}
                    name={name}
                    type={inputType}
                    placeholder={placeholder}
                    onChange={onChange}
                    min={min}
                    rows={rows}
                    addonBefore={addonBefore}
                    suffix={suffix}
                    minLength={minLength}
                />
            )}
        </div>
    );
};

export default CustomInput;
