import {
    AimOutlined,
    DeliveredProcedureOutlined,
    EditOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { CREATE_ENTERPRISE } from '../../routes';

const EnterpriseLandingPage: FC = (): ReactElement => {
    return (
        <div className="enterprise-page">
            <Row className="">
                <div
                    className="overlay-bg1"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '55vh',
                        backgroundPosition: '50% 30%',
                    }}
                >
                    <Col
                        md={12}
                        className="enterprise-page__bg--left"
                        style={{
                            marginTop: 'unset',
                        }}
                    >
                        <p className="enterprise-page__bg--left-title">
                            Take your Pageant to the Next Level.
                        </p>

                        <p className="enterprise-page__bg--left-arrow">
                            Get access to a cutting edge web platform that
                            handles every stage from contestant application,
                            tasks, challenges and voting. Promote and build your
                            brand with your own customized domain.
                        </p>
                        <div
                            className="enterprise-page__bg--left-btn"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {/* <Link to={PRICING_PLAN}>
                                <Button type="primary">START A CONTEST</Button>
                            </Link> */}

                            <Link to={`${CREATE_ENTERPRISE}?m=free`}>
                                <Button type="primary">
                                    OPEN A FREE ACCOUNT
                                </Button>
                            </Link>
                        </div>
                    </Col>
                </div>
            </Row>
            <Row
                className="enterprise-page__points"
                style={{
                    padding: '2rem',
                }}
            >
                <Col
                    xs={24}
                    sm={12}
                    lg={6}
                    className="enterprise-page__points--box"
                >
                    <div>
                        <span className="enterprise-page__points--box-icon">
                            <AimOutlined
                                style={{ color: '#fff', fontSize: '35px' }}
                            />
                        </span>
                        <p className="enterprise-page__points--box-title">
                            Personalize url
                        </p>
                        <p>Get a sub-domain as an organization website</p>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    lg={6}
                    className="enterprise-page__points--box"
                >
                    <div>
                        <span className="enterprise-page__points--box-icon">
                            <TeamOutlined
                                style={{ color: '#fff', fontSize: '35px' }}
                            />
                        </span>
                        <p className="enterprise-page__points--box-title">
                            Audience Engagement
                        </p>
                        <p>Full audience engagement all through the contest</p>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    lg={6}
                    className="enterprise-page__points--box"
                >
                    <div>
                        <span className="enterprise-page__points--box-icon">
                            <EditOutlined
                                style={{ color: '#fff', fontSize: '35px' }}
                            />
                        </span>
                        <p className="enterprise-page__points--box-title">
                            Excellent reporting
                        </p>
                        <p>View real-time logs of all transactions and votes</p>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    lg={6}
                    className="enterprise-page__points--box"
                >
                    <div>
                        <span className="enterprise-page__points--box-icon">
                            <DeliveredProcedureOutlined
                                style={{ color: '#fff', fontSize: '35px' }}
                            />
                        </span>
                        <p className="enterprise-page__points--box-title">
                            Easy voting
                        </p>
                        <p>Quick and smooth voting system</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default EnterpriseLandingPage;
