import React, { FC, ReactElement, useEffect, Fragment } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { TRANSACTION_PURCHASE } from '../../../../routes';
import { Button, notification, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { verifyPayment } from '../../../../redux/actions/payment.action';
import { RootState } from '../../../../redux/reducers';
import { paymentType } from '../../../../constants/transactionEnum';
import { PRICE } from '../../../../constants/price';

const VerifyPayment: FC = (): ReactElement => {
    const dispatch = useDispatch();

    const {
        location: { search },
        push,
        replace,
    } = useHistory();
    const transactionRef = new URLSearchParams(search).get('reference');
    const transactionID = new URLSearchParams(search).get('transaction_id');

    const payment = useSelector((state: RootState) => state.payment);
    const { loading, transactionInfo } = payment;

    // on mount
    useEffect(() => {
        if (!transactionRef && !transactionID) {
            return replace(TRANSACTION_PURCHASE);
        }
        if (transactionRef) {
            dispatch(
                verifyPayment({
                    reference: transactionRef,
                    paymentMethod: paymentType.PAYSTACK,
                }),
            );
        } else if (transactionID) {
            dispatch(
                verifyPayment({
                    reference: transactionID,
                    paymentMethod: paymentType.FLUTTERWAVE,
                }),
            );
            notification.success({
                message:
                    'Votes have been added to your account. You can now vote for your favourite contestants',
            });
        }
    }, [push, transactionRef, transactionID, dispatch, replace]);

    return (
        <div className="payment-verification">
            <div className="content success">
                {!loading && transactionInfo && (
                    <Fragment>
                        <div className="circle">
                            <h3 className="icon-container">
                                <CheckOutlined />
                            </h3>
                        </div>
                        <h3 className="heading-text">
                            Payment Verification Complete!
                        </h3>
                        <h4 style={{ color: 'red' }}>
                            Votes have been added to your account.You can now
                            vote for your favourite contestants
                        </h4>
                        <div className="info-item">
                            <h4>Amount</h4>
                            <h4 className="value">
                                {`$`}
                                {transactionInfo.amount.toLocaleString()}
                            </h4>
                        </div>
                        <div className="info-item">
                            <h4>votes</h4>
                            <h4 className="value">
                                {transactionInfo.amount / PRICE}
                            </h4>
                        </div>
                        <div className="info-item">
                            <h4>Transaction Reference</h4>
                            <h4 className="value">
                                {transactionInfo.reference}
                            </h4>
                        </div>
                        <div className="control">
                            <Link to={TRANSACTION_PURCHASE}>
                                <Button type="primary">Continue</Button>
                            </Link>
                        </div>
                    </Fragment>
                )}
                {loading && <Spin className="spinner-invest" size="large" />}
            </div>
        </div>
    );
};
export default VerifyPayment;
