import React, { ReactElement, FC } from 'react';
// import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';

const LoadingSpinner: FC = (): ReactElement => {
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;
    return (
        <div className="detail-spin-wrappers">
            <LoadingOutlined
                style={{
                    fontSize: 50,
                    color: `${
                        subdomainUIstyling['0']
                            ? subdomainUIstyling['0']['primaryColor']
                            : '#1bca8f'
                    }`,
                }}
                spin
            />
            ;{/* <Spin size="large" /> */}
        </div>
    );
};

export default LoadingSpinner;
