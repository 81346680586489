import { Col, Row } from 'antd';
import React, { FC, ReactElement, useContext } from 'react';
import { StepComponentProps } from 'react-step-builder';
import { handlerEvent, Input } from '../../../../input';
import { FormContext } from '../formProvider/FormContext';

const Step4: FC<StepComponentProps> = (): ReactElement => {
    const { formInputs, setFormInputs } = useContext(FormContext);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInputs({ ...formInputs, [name]: value });
    };

    return (
        <Row justify="center" className="step1">
            <Col md={14}>
                <p className="step1__text1">What is the Challenge Title?</p>

                <Row gutter={{ sm: 0, md: 20 }} className="fieldrow">
                    <Col xs={24}>
                        <Input
                            name="title"
                            value={formInputs?.title}
                            placeholder="task title"
                            onChange={onInputChange}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default Step4;
