import { stepStage } from '../../types';

//case one, formapply =NO
export const caseOne = [
    stepStage.START,
    stepStage.IMAGEUPLOAD,
    stepStage.DATEPICKER,
    stepStage.FORMAPPLY,
    stepStage.FINALSTEP,
];

//case one, ispaid =free
export const caseTwo = [
    stepStage.START,
    stepStage.IMAGEUPLOAD,
    stepStage.DATEPICKER,
    stepStage.FORMAPPLY,
    stepStage.REQUIREMENT,
    stepStage.ISPAID,
    stepStage.FINALSTEP,
];
