import { activeVoteContestantType, voteTypeDb } from '../../types';
import { CLEAR_ERROR, CLEAR_MESSAGE } from '../types/common.type';
import {
    FETCH_VOTE_FAILED,
    FETCH_VOTE_INIT,
    FETCH_VOTE_SUCCESS,
    SET_ACTIVE_CONTESTANT,
    VOTE_CONTESTANT,
    VOTE_CONTESTANT_FAILED,
    VOTE_CONTESTANT_SUCCESS,
} from '../types/vote.type';

type VOTE_INIT_STATE = {
    votesInfo: voteTypeDb[];
    activeVoteContestant: activeVoteContestantType;
    loading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const VOTE_INITIAL_STATE: VOTE_INIT_STATE = {
    votesInfo: [],
    activeVoteContestant: {
        fullName: '',
        contestantId: '',
        imageUrl: '',
        event: '',
    },
    loading: false,
    feedbackMsg: null,
    error: null,
};

const voteReducer = (
    state = VOTE_INITIAL_STATE,
    action: any,
): VOTE_INIT_STATE => {
    switch (action.type) {
        case FETCH_VOTE_INIT:
        case VOTE_CONTESTANT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case FETCH_VOTE_SUCCESS: {
            const votes = [...action.payload].reverse();

            return {
                ...state,
                loading: false,
                votesInfo: votes,
                error: null,
            };
        }

        case VOTE_CONTESTANT_FAILED:
        case FETCH_VOTE_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case VOTE_CONTESTANT_SUCCESS: {
            return {
                ...state,
                loading: false,
                feedbackMsg: 'Voted successfully',
                error: null,
            };
        }

        case SET_ACTIVE_CONTESTANT: {
            return {
                ...state,
                activeVoteContestant: action.payload,
            };
        }

        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }
        default:
            return state;
    }
};

export default voteReducer;
