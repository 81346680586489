import { Row, Col } from 'antd';
import React, { FC, ReactElement } from 'react';
import { Navigation } from '../../components/navigation';
import aboutSvg from '../../assets/aboutus.svg';

import './styles.scss';
import FooterSection from '../landing/FooterSection';
import { BulbFilled } from '@ant-design/icons';
const AboutUs: FC = (): ReactElement => {
    return (
        <>
            <Row>
                <Col xs={24}>
                    <Navigation />
                </Col>
            </Row>
            <Row className="privacy-header" justify="center" align="middle">
                <Col className="privacy-header__title">About Us.</Col>
            </Row>
            <Row className="about_page">
                <Col md={10}>
                    <div className="about_page__left">
                        <img src={aboutSvg} alt="voting" />
                    </div>
                </Col>
                <Col md={14}>
                    <div className="about_page__right">
                        <p className="enterprise-page__about--left-text">
                            <span className="enterprise-page__underline">
                                WHO
                            </span>
                            WE ARE
                        </p>
                        <p
                            className="about_page__right-content"
                            style={{ fontSize: '16px' }}
                        >
                            Pageant Africa is a platform designed to allow one
                            of a kind engagement with the best beauty pageants
                            and contests across Africa. It is a one-stop shop
                            that provides an efficient, engaging and more
                            enjoyable pageant experience for viewers,
                            contestants and pageant owners alike!
                        </p>
                        <p
                            className="about_page__right-content"
                            style={{ fontSize: '16px' }}
                        >
                            The African Pageant Sector is rapidly evolving as
                            African Queens and Kings have begun to gain
                            international recognition and have increasing appeal
                            to global markets.
                        </p>
                        <p
                            className="about_page__right-content"
                            style={{ fontSize: '16px' }}
                        >
                            Many young African women and men see pageant
                            competitions as an opportunity to showcase
                            themselves (and their innate talents) to gain an
                            audience, popularity and fame. Pageantry by virtue
                            of its inherent glamour and popularity also provides
                            an easier entry point to the larger entertainment
                            and media industry.
                        </p>
                        <p
                            className="about_page__right-content"
                            style={{ fontSize: '16px' }}
                        >
                            Our platform provides access to unique pageantry
                            events for Africa thus providing an excellent
                            platform for talent discovery and development.
                        </p>
                        <p
                            className="about_page__right-content"
                            style={{ fontSize: '16px' }}
                        >
                            From application through to the grand finale where
                            the winner is crowned, Pageant Africa gives easy
                            online access to all players to immerse themselves
                            in the entire process.
                        </p>
                        <p
                            className="about_page__right-content"
                            style={{ fontSize: '16px' }}
                        >
                            For viewers, no longer will you have to skip from
                            one app or site to the other to keep track of a
                            beauty pageant as this is an integrated media
                            platform that allows you to view photos, watch
                            videos, like, share, follow and even vote during the
                            process of any pageant while ensuring that none of
                            entertainment and glamour is lost.
                        </p>
                        <p
                            className="about_page__right-content"
                            style={{ fontSize: '16px' }}
                        >
                            For contestants, create an account on the site and
                            have instant access to apply for any pageant of your
                            choice, Get notified instantly of any updates
                            regarding the pageant you are participating in and
                            also send your unique page to your friends, family
                            and fans to support you as you contest for that
                            coveted crown!
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="enterprise-page__detail">
                <Col xs={24}>
                    <h3 className="enterprise-page__detail--title">
                        <span className="enterprise-page__underline">
                            How we
                        </span>
                        make a difference
                    </h3>
                </Col>
                <Col md={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Fast pay-out
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    We process your payment once contest is
                                    finished.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Excellent Support
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    We provide support if the need arise
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Marketing Support
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    We engage on various marketing solutions to
                                    drive revenue.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Secure payment
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    Our system does not store user payment
                                    details.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Smart Engagement
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    Create task or challenges for your
                                    contestants
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Transparent Reporting
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    View real-time report logs on all
                                    transactions. Monitor sales as it happens.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <FooterSection />
        </>
    );
};

export default AboutUs;
