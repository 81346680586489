import { baseInstance as axios } from '../../axios-base';

import { Dispatch } from 'redux';
import {
    FETCH_ACCOUNTS_INIT,
    FETCH_ACCOUNTS_SUCCESS,
    FETCH_ACCOUNTS_FAILED,
    APPROVE_ACCOUNT_FAILED,
    APPROVE_ACCOUNT_INIT,
    APPROVE_ACCOUNT_SUCCESS,
} from '../types/enterprise.type';

export const fetchAllAccounts = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_ACCOUNTS_INIT });
    try {
        const { data } = await axios.get(`/account`);
        /**
         * @todo set token here to local storage or persist user
         * consider walletbalance been expose and
         * user have to persist to reduce uneccessary queries on refetch
         */
        //on success dispatch payload
        dispatch({ type: FETCH_ACCOUNTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_ACCOUNTS_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const approveAccount = (payload: { id: string }) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: APPROVE_ACCOUNT_INIT });
    try {
        const { data } = await axios.post(`/account/approve`, payload);

        dispatch({ type: APPROVE_ACCOUNT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: APPROVE_ACCOUNT_FAILED,
            payload: error.response.data.message,
        });
    }
};
