import React, { FC, ReactElement } from 'react';

import { DOMAINPARTS } from '../../routes';

import MainLandingPage from './MainLandingPage';
import SubLandingPage from './SubDomainPage';

import './style.scss';
const Landing: FC = (): ReactElement => {
    const domainParts = window.location.host.split('.');

    return (
        <>
            {domainParts.length >= DOMAINPARTS ? (
                <SubLandingPage />
            ) : (
                <MainLandingPage />
            )}
        </>
    );
};

export default Landing;
