import { Col, Row } from 'antd';
import React, { FC, ReactElement, useContext, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepComponentProps } from 'react-step-builder';
import { fetchUserOwnedContests } from '../../../../../redux/actions/contests.action';
import { RootState } from '../../../../../redux/reducers';
import {
    SelectDropdown,
    selectDropDownOptionsType,
} from '../../../../SelectDropdown';
import { FormContext } from '../formProvider/FormContext';

const Step2: FC<StepComponentProps> = (): ReactElement => {
    const { eventOptions, formInputs, loading, setFormInputs } = useContext(
        FormContext,
    );
    const [selectTextValue, setSelectTextValue] = useState('');

    const dispatch = useDispatch();
    const userId = useSelector(
        (state: RootState) => state.auth.usersInfo?._id,
    ) as string;

    useEffect(() => {
        if (formInputs?.event && eventOptions) {
            const selectedText = eventOptions?.findIndex(
                ({ id }) => id === formInputs.event,
            );
            if (selectedText !== -1) {
                setSelectTextValue(eventOptions[selectedText].name);
            }
        }
    }, [formInputs, eventOptions]);

    return (
        <Row justify="center" className="step1">
            <Col md={10}>
                <p className="step1__text1">Select A Contest</p>
                <SelectDropdown
                    options={eventOptions as selectDropDownOptionsType[]}
                    value={formInputs?.event}
                    placeholder={
                        formInputs?.event ? selectTextValue : 'select contest'
                    }
                    loading={loading}
                    onClick={() => dispatch(fetchUserOwnedContests(userId))}
                    onChange={(val) =>
                        setFormInputs({
                            ...formInputs,
                            event: val?.toString(),
                        })
                    }
                />
            </Col>
        </Row>
    );
};

export default Step2;
