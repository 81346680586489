import { Row, Col, Button } from 'antd';
import React, { FC, ReactElement, useContext } from 'react';
import StepWizard from 'react-step-wizard';
import { FormContext } from './formProvider/FormContext';

import { stepRenderType } from '../types';

import Loader from 'react-loader-spinner';
import Step1 from './formSteps/Step1';
import Step2 from './formSteps/Step2';
import Step3 from './formSteps/Step3';
import Step4 from './formSteps/Step4';
import Step5 from './formSteps/Step5';

const StepNavigation = (props: any) => {
    const { onFormSubmit, loadingUI } = useContext(FormContext);
    return (
        <Row
            justify="end"
            gutter={{ xs: 10, md: 30 }}
            style={{ marginTop: '40px' }}
        >
            <Col md={5}>
                {props.currentStep !== 1 && (
                    <Button
                        style={{ width: '100%' }}
                        onClick={props.previousStep}
                    >
                        Previous
                    </Button>
                )}
            </Col>
            <Col md={5}>
                {props.currentStep !== props.totalSteps && (
                    <Button
                        style={{ width: '100%' }}
                        type="primary"
                        onClick={props.nextStep}
                    >
                        Next
                    </Button>
                )}
                {props.currentStep === props.totalSteps && (
                    <Button
                        style={{ width: '100%' }}
                        type="primary"
                        onClick={onFormSubmit}
                        loading={loadingUI}
                    >
                        Submit
                    </Button>
                )}
            </Col>
        </Row>
    );
};

const stepRender: stepRenderType[] = [
    {
        title: 'logo',
        component: Step1,
    },
    {
        title: 'Banner upload',
        component: Step2,
    },
    {
        title: 'Sign in image',
        component: Step3,
    },
    {
        title: 'About company',
        component: Step4,
    },
    {
        title: 'Colors',
        component: Step5,
    },
];

const Wizard: FC = (): ReactElement => {
    const { loading } = useContext(FormContext);

    return (
        <Row className="account-setup" justify="center">
            <Col xs={22} md={14}>
                <h1 style={{ textAlign: 'center' }}>Setup Your Account</h1>
                <StepWizard nav={<StepNavigation />} className="step-wizard">
                    {stepRender.map(({ component }, i: number) => {
                        const Component = component;
                        return (
                            <div key={i} className="step-wizard__steps">
                                <Component />
                            </div>
                        );
                    })}
                </StepWizard>
            </Col>
            {loading && (
                <div className="overlay">
                    <Row
                        justify="center"
                        align="middle"
                        style={{ width: '100%', height: '100%' }}
                    >
                        <Col md={4}>
                            <Loader
                                type="BallTriangle"
                                color="#1bca8f"
                                height={100}
                                width={100}
                            />
                        </Col>
                    </Row>
                </div>
            )}
        </Row>
    );
};

export default Wizard;
