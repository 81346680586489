import { Col, Row } from 'antd';
import React, {
    FC,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { handlerEvent, Input } from '../../../../input';
import { FormContext } from '../formProvider/FormContext';
import _debounce from 'lodash/debounce';

const Step5: FC = (): ReactElement => {
    const { formInputs, setFormInputs, eventInfo, mode } = useContext(
        FormContext,
    );

    const [inputValue, setInputValue] = useState({
        requirements: '',
    });

    useEffect(() => {
        if (eventInfo && mode === 'edit') {
            setInputValue({
                requirements: eventInfo.requirements,
            });
        }
    }, [eventInfo, mode]);

    useEffect(() => {
        valueDebounced(inputValue);
        // eslint-disable-next-line
    }, [inputValue]);

    const onInputChange = (inputValue) => {
        setFormInputs({
            ...formInputs,
            ...inputValue,
        });
    };

    const valueDebounced = useMemo(
        () => _debounce(onInputChange, 1000),
        // eslint-disable-next-line
        [formInputs],
    );

    const handleChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    return (
        <div>
            <div className="step5__title">
                Contest requirements for this application
            </div>
            <div className="step5__text">
                <span>Note:</span>
                <span>
                    requirements will be display on the form application
                </span>
            </div>

            <Row className="field-row">
                <Col xs={24}>
                    <Input
                        name="requirements"
                        value={inputValue.requirements}
                        placeholder="input basic requirements"
                        onChange={handleChange}
                        componentType="TextArea"
                        rows={5}
                        label="Contest Requirements"
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Step5;
