import { PayPalButtons } from '@paypal/react-paypal-js';
import { message } from 'antd';
import React, { FC, ReactElement, useEffect, useRef } from 'react';
import { paypalProps } from '../types';
import { baseInstance as axios } from '../../../axios-base';
import { paymentType } from '../../../constants/transactionEnum';
import ReactGA from 'react-ga';

const PaypalButton: FC<paypalProps> = ({
    amount,
    visible,
    onPaymentApproved,
    initData,
    isPublicVote,
}): ReactElement => {
    const ref = useRef(0);

    useEffect(() => {
        ref.current = amount;
    }, [amount]);

    const createOrder = async (_: Record<string, unknown>, actions: any) => {
        // let txnId = null;

        return actions.order
            .create({
                purchase_units: [
                    {
                        // invoice_id: txnId,
                        amount: {
                            value: ref.current,
                        },
                    },
                ],
            })
            .then(async (orderID: any) => {
                if (isPublicVote) {
                    //create transaction
                    await axios.post('/transaction/initializepaypal', {
                        ...initData,
                        amount: ref.current,
                        reference: orderID,
                    });
                    ReactGA.event({
                        category: 'Public Voting',
                        action: 'payment init- paypal',
                        label: 'Public Voting Widget',
                    });
                } else {
                    await axios.post('/transaction/initializepayment', {
                        callback_url: 'paypal.com',
                        amount: ref.current,
                        paymentMethod: paymentType.PAYPAL,
                        reference: orderID,
                    });
                    ReactGA.event({
                        category: 'BUY VOTES',
                        action: 'buying vote from dashboard -PAYPAL',
                        label: 'Wallet Widget',
                    });
                }
                return orderID;
            });
    };
    return (
        <PayPalButtons
            style={{
                layout: 'horizontal',
                color: 'blue',
                shape: 'pill',
                label: 'pay',
                height: 40,
            }}
            forceReRender={[visible]}
            disabled={visible}
            createOrder={createOrder}
            onApprove={(_, actions) => {
                return actions.order.capture().then((details) => {
                    onPaymentApproved(details);
                });
            }}
            onError={() => {
                message.error('Could not process payment. Do retry!');
            }}
            onCancel={async (data) => {
                await axios.post('/transaction/transaction-cancelled', {
                    transactionReference: data.orderID,
                    paymentMethod: paymentType.PAYPAL,
                });
            }}
        />
    );
};

export default PaypalButton;
