import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { useWindowWidth } from '@react-hook/window-size';
import { modalDrawerType, routerParamsType } from '../../types';
import { useHistory, useParams } from 'react-router-dom';
import { CONTEST } from '../../../../../routes';
import { fetchedContestantTypeDb } from '../../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/reducers';

const ModalDrawer: FC<modalDrawerType> = ({
    onClose,
    visible,
}): ReactElement => {
    const windowWidth = useWindowWidth();
    const [isMobile, setIsMobile] = useState(false);
    const { id }: routerParamsType = useParams();
    const contestantsState = useSelector(
        (state: RootState) => state.contestants,
    );

    const { contestantsInfo } = contestantsState;
    const { push } = useHistory();

    const [contestants, setContestant] = useState<fetchedContestantTypeDb[]>(
        [],
    );

    useEffect(() => {
        const sorted = [...contestantsInfo].sort(
            (a, b) => b.totalVotes - a.totalVotes,
        );
        setContestant(sorted);
    }, [contestantsInfo]);

    useEffect(() => {
        if (windowWidth <= 760) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowWidth]);

    return (
        <Drawer
            placement="right"
            closable={false}
            width={!isMobile ? 420 : '100vw'}
            onClose={onClose}
            visible={visible}
            // mask={false}
            className="drawer-modal"
        >
            <div className="drawer-board">
                <div className="drawer-board__icon">
                    <span onClick={onClose}>
                        <RightCircleOutlined />
                    </span>
                    <span>Leaderboard</span>
                </div>
                <div className="board-details">
                    {contestants.map(
                        ({
                            _id,
                            totalVotes,
                            imageUrl,
                            fullName,
                        }: fetchedContestantTypeDb) => (
                            <div
                                className="board-details__item"
                                key={_id}
                                onClick={() =>
                                    push(`${CONTEST}/${id}/view?cid=${_id}`)
                                }
                            >
                                <div>
                                    <div className="board-details__item--img">
                                        <img src={imageUrl} alt="pic" />
                                    </div>
                                    <span className="board-details__item--name">
                                        {fullName}
                                    </span>
                                </div>
                                <span className="board-details__item--votes">
                                    {totalVotes} votes
                                </span>
                            </div>
                        ),
                    )}
                </div>
            </div>
        </Drawer>
    );
};

export default ModalDrawer;
