import React, { FC, ReactElement } from 'react';
import { FormProvider } from './formProvider';
import Wizard from './Wizard';

const AccountSetup: FC = (): ReactElement => {
    return (
        <FormProvider>
            <Wizard />
        </FormProvider>
    );
};

export default AccountSetup;
