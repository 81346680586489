import React, { FC, Fragment, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subdomainUI } from './redux/actions/subdomainUI.action';
import { DOMAINPARTS, SOCKET_URL } from './routes';
import io from 'socket.io-client';
import { RootState } from './redux/reducers';

export let socket = io.connect(SOCKET_URL);

const UIProvider: FC = ({ children }): ReactElement => {
    const token = useSelector((state: RootState) => state.auth.token);
    const dispatch = useDispatch();
    useEffect(() => {
        // eslint-disable-next-line
        let domainParts = window.location.host.split('.');

        //
        if (domainParts.length >= DOMAINPARTS) {
            const subDomain = domainParts[0];
            dispatch(subdomainUI(subDomain));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const socketOptions = {
            query: {
                token: token,
            },
        };

        socket = io.connect(SOCKET_URL, socketOptions);
    }, [token]);

    return <Fragment>{children}</Fragment>;
};

export default UIProvider;
