import { HomeFilled, MailFilled, PhoneFilled } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ENTERPRISELANDING, SIGN_UP } from '../../routes';
import ContactForm from './ContactForm';
import FooterSection from './FooterSection';
import imageHero from './img/mi2.jpg';
import ReactPlayer from 'react-player';
import { HOME } from '../../routes';
import { HomeOutlined } from '@ant-design/icons';
import { useWindowWidth } from '@react-hook/window-size';
// import heroVideo from './HeroVideo.mp4';
// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css';
// import votingSvg from '../../assets/voting.svg';
// import contestSvg from '../../assets/contest.svg';

const MainLandingPage: FC = (): ReactElement => {
    const windowWidth = useWindowWidth();
    return (
        <div className="main-page">
            {/* <Row>
                <Col xs={24}>
                    <Navigation />
                </Col>
            </Row> */}
            {/* <Row className="main-page__bgwr " justify="center"> */}
            <div className="auth-page__icon" style={{ zIndex: 100 }}>
                <Link to={HOME}>
                    <HomeOutlined className="auth-page__icon--desktop" />
                    <span>Pageant Africa</span>

                    {/* {subdomainUIstyling.length > 0 ? (
                    ) : (
                        <HomeOutlined className="auth-page__icon--desktop-2" />
                    )} */}
                </Link>
            </div>
            <Row className="" justify="start" style={{ width: '100vw' }}>
                <Col md={12} lg={24} className="hero-bg">
                    <ReactPlayer
                        url={'https://www.youtube.com/embed/TClpNTMJ4Vc'}
                        playing
                        loop
                        muted
                        width="100vw"
                        height="95vh"
                    />
                    <div className="hero-overlay-bg">
                        <h2
                            className="main-page__about--title"
                            style={{
                                color: '#3FC68B',
                                marginTop: '40vh',
                                fontSize: '40px',
                                marginBottom: '0px',
                            }}
                        >
                            Welcome to Pageant Africa
                        </h2>
                        <p
                            style={{
                                color: '#fff',
                                display: 'flex',
                                fontSize: '15px',
                                justifyContent: 'center',
                            }}
                        >
                            Home to the best pageants in Africa
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Link to={SIGN_UP}>
                                <Button
                                    style={{
                                        backgroundColor: '#3FC68B',
                                        color: '#fff',
                                        height: '38px',
                                        padding: '5px 30px',
                                        border: 'none',
                                        borderRadius: '20px',
                                    }}
                                >
                                    Create Account
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="main-page__about">
                <Col xs={24} xl={12}>
                    <h2 className="main-page__about--title">
                        Welcome to Pageant Africa
                    </h2>
                    <p
                        className="main-page__about--content"
                        style={{ fontSize: '16px' }}
                    >
                        Pageant Africa is a platform designed to allow one of a
                        kind engagement with the best beauty pageants and
                        contests across Africa. It is a one-stop shop that
                        provides an efficient, engaging and more enjoyable
                        pageant experience for viewers, contestants and pageant
                        owners alike!
                    </p>
                    <p
                        className="main-page__about--content"
                        style={{ fontSize: '16px' }}
                    >
                        The African Pageant Sector is rapidly evolving as
                        African Queens and Kings have begun to gain
                        international recognition and have increasing appeal to
                        global markets.
                    </p>
                    {windowWidth > 760 && (
                        <>
                            <p
                                className="main-page__about--content"
                                style={{ fontSize: '16px' }}
                            >
                                Many young African women and men see pageant
                                competitions as an opportunity to showcase
                                themselves (and their innate talents) to gain an
                                audience, popularity and fame. Pageantry by
                                virtue of its inherent glamour and popularity
                                also provides an easier entry point to the
                                larger entertainment and media industry.
                            </p>
                            <p
                                className="main-page__about--content"
                                style={{ fontSize: '16px' }}
                            >
                                Our platform provides access to unique pageantry
                                events for Africa thus providing an excellent
                                platform for talent discovery and development.
                            </p>
                        </>
                    )}
                    <p
                        className="main-page__about--content"
                        style={{ fontSize: '16px' }}
                    >
                        From application through to the grand finale where the
                        winner is crowned, Pageant Africa gives easy online
                        access to all players to immerse themselves in the
                        entire process.
                    </p>
                    <p
                        className="main-page__about--content"
                        style={{ fontSize: '16px' }}
                    >
                        For viewers, no longer will you have to skip from one
                        app or site to the other to keep track of a beauty
                        pageant as this is an integrated media platform that
                        allows you to view photos, watch videos, like, share,
                        follow and even vote during the process of any pageant
                        while ensuring that none of entertainment and glamour is
                        lost.
                    </p>
                    <p
                        className="main-page__about--content"
                        style={{ fontSize: '16px' }}
                    >
                        For contestants, create an account on the site and have
                        instant access to apply for any pageant of your choice,
                        Get notified instantly of any updates regarding the
                        pageant you are participating in and also send your
                        unique page to your friends, family and fans to support
                        you as you contest for that coveted crown!
                    </p>
                </Col>
                <Col xl={12} xs={0}>
                    <div className="about-image-right">
                        <img
                            src={imageHero}
                            alt="hero"
                            style={{
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.19)',
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="main-page__voting">
                <Col xs={24} sm={18}>
                    <div className="main-page__voting--left">
                        <p
                            className="main-page__voting--left-text"
                            style={{ color: '#3FC68B' }}
                        >
                            Engage With The Best and Most Exciting Pageants
                            Across Africa
                        </p>
                        <p
                            className="main-page__voting--left-content"
                            style={{ color: 'white', fontSize: '16px' }}
                        >
                            Creating an account on Pageant Africa is free and
                            easy. Once you’ve created an account you will be
                            able to view all the pageants currently running on
                            our platform. Interested in contesting, simply
                            apply; otherwise, follow your favourite pageants and
                            vote for your contestant – the choice is yours.
                        </p>
                        <p
                            className="main-page__voting--left-content"
                            style={{ color: 'white', fontSize: '16px' }}
                        >
                            See you on the inside!
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={6}>
                    <Row
                        className="main-page__voting--right"
                        justify="center"
                        align="middle"
                    >
                        <Link to={SIGN_UP}>
                            <Button
                                style={{
                                    backgroundColor: '#3FC68B',
                                    color: '#fff',
                                    height: '38px',
                                    padding: '5px 30px',
                                    border: 'none',
                                    borderRadius: '20px',
                                }}
                                className="main-page__voting--left-btn"
                            >
                                Create Account
                            </Button>
                        </Link>
                    </Row>
                </Col>
                {/* <Col xs={24} sm={12}>
                    <div className="main-page__voting--right">
                        <img src={votingSvg} alt="voting" />
                    </div>
                </Col> */}
            </Row>

            <Row className="main-page__contest">
                <Col xs={24} sm={18}>
                    <div className="main-page__contest--right">
                        <p className="main-page__contest--right-text">
                            Are You a Pageant Owner / Manager?
                        </p>
                        <p
                            className="main-page__contest--right-content"
                            style={{ fontSize: '16px' }}
                        >
                            Receive applications, create tasks and challenges,
                            handle evictions, engage your participants and
                            audience, enable voting, keep track of all of it in
                            one place.
                        </p>
                        <p
                            className="main-page__contest--right-content"
                            style={{ fontSize: '16px' }}
                        >
                            Take control.
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={6}>
                    <Row
                        className="main-page__voting--right"
                        justify="center"
                        align="middle"
                    >
                        {' '}
                        <Link to={ENTERPRISELANDING}>
                            <Button
                                // type="primary"
                                style={{
                                    color: '#fff',
                                    height: '38px',
                                    padding: '5px 30px',
                                    border: 'none',
                                    borderRadius: '20px',
                                    background: 'black !important',
                                }}
                                className="main-page__contest--right-btn"
                            >
                                Start Here
                            </Button>
                        </Link>
                    </Row>
                </Col>
            </Row>

            <Row className="main-page__contact">
                <Col xs={24}>
                    <h3 className="enterprise-page__detail--title">
                        <span className="enterprise-page__underline">
                            REACH
                        </span>
                        US
                    </h3>
                </Col>

                <Col md={12}>
                    <ContactForm />
                </Col>
                <Col xs={0} md={12}>
                    <div className="main-page__footer-box">
                        <h2 className="main-page__footer-box--title">
                            CONTACTS
                        </h2>
                        <div className="main-page__footer-box--content">
                            <HomeFilled />
                            <span className="main-page__footer-box--content-text">
                                No 30,teni street,lekki
                            </span>
                        </div>
                        <div className="main-page__footer-box--content">
                            <PhoneFilled />
                            <a
                                className="main-page__footer-box--content-text"
                                href="tel:+234 803 567 6575"
                            >
                                +234 803 567 6575
                            </a>
                        </div>
                        <div className="main-page__footer-box--content">
                            <MailFilled />
                            <a
                                className="main-page__footer-box--content-text"
                                href="mailto:info@pageant.africa"
                            >
                                info@pageant.africa
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
            <FooterSection />
        </div>
    );
};

export default MainLandingPage;
