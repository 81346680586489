import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { firstCharToUpperCase } from '../../../../utils';
import moment from 'moment';

import { columnType } from '../types';
import { fetchedtaskTypeDb, fetchedEventTypeDb } from '../../../../types';
import { EDIT_TASK } from '../../../../routes';

const dateRender = (value: Date): ReactElement => {
    return <span>{moment(value).format('LL')}</span>;
};

const eventNameRender = (value: fetchedEventTypeDb): ReactElement => {
    return <span>{firstCharToUpperCase(value.eventTitle)}</span>;
};
const titleRender = (value: string): ReactElement => {
    return <span>{firstCharToUpperCase(value)}</span>;
};

const actionRender = (_: string, record: fetchedtaskTypeDb): ReactElement => (
    <Link className="edit-btn" to={`${EDIT_TASK}?id=${record._id}`}>
        edit
    </Link>
);

// const postMediaRender = (value: boolean): ReactElement => {
//     return value ? (
//         <Tag className="yes-tag">YES</Tag>
//     ) : (
//         <Tag className="no-tag">NO</Tag>
//     );
// };

const TableColumn: columnType = [
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        render: dateRender,
    },
    {
        title: 'Contest',
        dataIndex: 'event',
        render: eventNameRender,
    },

    {
        title: 'title',
        dataIndex: 'title',
        render: titleRender,
    },
    // {
    //     title: 'Post Image',
    //     dataIndex: 'postImage',
    //     render: postMediaRender,
    // },

    // {
    //     title: 'Post Video',
    //     dataIndex: 'postVideo',
    //     render: postMediaRender,
    // },
    {
        title: 'End Date',
        dataIndex: 'endDate',
        render: dateRender,
    },
    {
        title: '',
        key: 'action',
        dataIndex: 'action',
        render: actionRender,
    },
];

export default TableColumn;
