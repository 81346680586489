import { TagsOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Navigation } from '../../components/navigation';
import FooterSection from '../landing/FooterSection';
import * as Scroll from 'react-scroll';

const ScrollLink = Scroll.Link;

const TandC: FC = (): ReactElement => {
    const scrollTo: { to: string; title: string }[] = [
        {
            to: 'abouttheweb',
            title: 'ABOUT THE WEBSITE',
        },
        {
            to: 'acceptterms',
            title: 'ACCEPTANCE OF THE TERMS',
        },
        {
            to: 'registration',
            title: 'REGISTRATION TO USE THE WEBSITE SERVICES',
        },
        {
            to: 'disclaimer',
            title: 'DISCLAIMER',
        },
    ];
    return (
        <>
            <Row>
                <Col xs={24}>
                    <Navigation />
                </Col>
            </Row>
            <Row className="privacy-header" justify="center" align="middle">
                <Col className="privacy-header__title">
                    TERMS AND CONDITIONS
                </Col>
            </Row>
            <Row className="privacy-section">
                <Col md={6} className="privacy-section__left">
                    {scrollTo.map(({ to, title }, i) => (
                        <p key={i}>
                            <ScrollLink
                                activeClass="active"
                                to={to}
                                spy={true}
                                smooth={true}
                                offset={-50}
                                duration={500}
                            >
                                {title.toLowerCase()}
                            </ScrollLink>
                        </p>
                    ))}
                </Col>
                <Col md={18} className="privacy-section__right">
                    <div className="privacy-section__right-wrapper">
                        <div
                            id="abouttheweb"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                ABOUT THE WEBSITE
                            </h2>
                            <div className="privacy-section__right--text">
                                1.1. Welcome to{' '}
                                <Link to="/">Pageant Africa Website</Link> the
                                website provides you with an opportunity to
                                engage with all the various Pageants and
                                contests hosted by our clients and service
                                providers The website provides this service by
                                way of granting you access to the content on the
                                website.
                                <br />
                                <br />
                                1.2. The Website is operated by 1024 Concepts.
                                Access to and use of the Pageant Africa Website,
                                or any of its associated products or services,
                                is provided by Pageant Africa. Please read these
                                terms and conditions (The ‘Terms’) carefully. By
                                using, browsing and or reading the Website, it
                                signifies that you the User have read,
                                understood and agree to be bound by the Terms.
                                <br />
                                <br />
                                1.3. 1024 Concepts reserves the right to review
                                and change any of the Terms by updating this
                                page at its sole discretion. When 1024 Concepts
                                updates the Terms, it will use reasonable
                                endeavors to provide you with notice of updates
                                to the Terms. Any Changes to the Terms will take
                                immediate effect from the date of their
                                publication.
                            </div>
                        </div>
                        <div
                            id="acceptterms"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                ACCEPTANCE OF THE TERMS
                            </h2>
                            <div className="privacy-section__right--text">
                                You accept the Terms by remaining on the
                                Website. You may also accept the Terms by
                                clicking to accept or agree to the Terms where
                                this option is made available to you by Pageant
                                Africa in the User Interface.
                            </div>
                        </div>
                        <div
                            id="registration"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                REGISTRATION TO USE THE WEBSITE SERVICES
                            </h2>
                            <div className="privacy-section__right--text">
                                In order to access the Pageant Africa Website
                                services, you must first register as a user of
                                the Website. As part of the registration process
                                or as part of your continued use of the Website,
                                you may be required to provide personal
                                information about yourself such as
                                identification or contact details including: •
                                An email address • A preferred username • A
                                telephone number • A password You warrant that
                                any information given to the Pageant Africa
                                Website while completing the registration
                                process will always be accurate, correct and up
                                to date. Once you have completed the
                                registration process, you will be a registered
                                member of the Website and agree to be bound by
                                the Terms. As a member you will be granted
                                immediate access to the Website. Users of this
                                website will be required to provide payment
                                details upon purchase of a service. Payment may
                                be made via the following channels: VISA,
                                MasterCard, USSD etc. by submitting a payment
                                request, permission is granted to the designated
                                account with the equivalent amount. An email
                                receipt upon confirmation of payment will be
                                issued. Our website does not store payment card
                                information. Pageant Africa base currency is in
                                Naira (the official Nigerian Currency) which our
                                website converts to the local currency of
                                international users. By using a trusted currency
                                converter, users in any part of the world will
                                see their local currency when processing
                                payments on Pageant Africa. In the event of a
                                lost, stolen or destroyed payment receipt, only
                                a person with an electronic receipt containing
                                the relevant details such as name and unique
                                reference code will be attended to. Clients /
                                Pageant owners may postpone or cancel their
                                services or contests. In such cases, all users
                                who have paid or indicated interest will be duly
                                refunded or communicated to by the respective
                                client / pageant owner. Clients / pageant owners
                                acknowledge that they are bound to comply with
                                all applicable laws governing Nigeria and
                                therefore refunds must be made according to the
                                applicable laws.
                            </div>
                        </div>
                        <div
                            id="disclaimer"
                            className="privacy-section__right-section"
                        >
                            <h2 className="privacy-section__right--title">
                                <TagsOutlined
                                    style={{
                                        color: '#11b86f',
                                        marginRight: '10px',
                                    }}
                                />
                                DISCLAIMER
                            </h2>
                            <div className="privacy-section__right--text">
                                Users acknowledge that access to the Site may
                                occasionally be restricted to allow for
                                maintenance and introduction of new functions or
                                services. The Site will be restored as soon as
                                possible when repairs are completed. While 1024
                                Concepts undertakes to use reasonable means to
                                maintain operations on the website, it does not
                                guarantee uninterrupted and continuous access to
                                the services and operations of the Site. 1024
                                Concepts shall not be held liable for any delay
                                or failure in the running of its services or the
                                seamless working of its site where such factors
                                are beyond the control of 1024 Concepts. 1024
                                Concepts shall endeavor to make payments to
                                Service owners/providers as soon as reasonably
                                possible. However, 1024 Concepts makes no
                                warranties regarding the amount of time needed
                                to complete processing of payments because such
                                is dependent on third parties and other factors
                                beyond 1024 Concepts’ control.
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <FooterSection />
        </>
    );
};

export default TandC;
