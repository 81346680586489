import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    LeftCircleOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import {
    Col,
    message,
    Row,
    Spin,
    Input as AntInput,
    Button,
    Radio,
    RadioChangeEvent,
    DatePicker,
} from 'antd';
import React, { FC, FormEvent, ReactElement, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fetchPlanById } from '../../../../redux/actions/plans.action';
import { RootState } from '../../../../redux/reducers';
import { SETUP_ENTERPRISE, PRICING_PLAN, JOIN_US } from '../../../../routes';
import { firstCharToUpperCase } from '../../../../utils';
import { handlerEvent, Input } from '../../../input';
import _debounce from 'lodash/debounce';
import { baseInstance as axios } from '../../../../axios-base';
import * as EmailValidator from 'email-validator';
import {
    clearPayment,
    freeAccountSubscription,
    initializeSubscriptionPayment,
} from '../../../../redux/actions/payment.action';
import { paymentType } from '../../../../constants/transactionEnum';
import { clearError } from '../../../../redux/actions/common.action';
import { TopBarLoader } from '../../../topBarProgress';
import { SelectDropdown } from '../../../SelectDropdown';
import { country } from '../../../../constants/country';

const { RangePicker } = DatePicker;

const CreateEnterprise: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const payment = useSelector((state: RootState) => state.payment);
    const {
        authorizationUrl,
        error: requestError,
        loading: paymentLoading,
    } = payment;

    const {
        location: { search },
        push,
    } = useHistory();

    const plansState = useSelector((state: RootState) => state.plans);
    const { singlePlan, loading: singlePlanLoading } = plansState;

    const planId = new URLSearchParams(search).get('r');
    const mode = new URLSearchParams(search).get('m');

    const [loading, setLoading] = useState(false);
    const [loadingPay, setLoadingPay] = useState(false);
    const [isAvaliable, setIsavaliable] = useState('');
    const [isEmailExist, setIsEmailExist] = useState('');
    const [formInputs, setFormInput] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
        companyName: '',
        companyAddress: '',
        companyLocation: '',
        pageantName: '',
        startDate: '',
        endDate: '',
        subdomain: '',
    });

    const [paymentMethod, setPaymentMethod] = useState<paymentType>(
        paymentType.FLUTTERWAVE,
    );

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        if (paymentLoading) {
            message.loading('Connecting payment..');
        }

        return () => {
            dispatch(clearError());
            dispatch(clearPayment());
        };
    }, [requestError, dispatch, paymentLoading]);

    useEffect(() => {
        if (authorizationUrl) {
            setLoadingPay(false);

            window.location.href = authorizationUrl;
        }
    }, [authorizationUrl]);

    useEffect(() => {
        if (!planId) return;
        dispatch(fetchPlanById(planId));
    }, [planId, dispatch]);

    useEffect(() => {
        servicesDebounced(formInputs.subdomain);
        // eslint-disable-next-line
    }, [formInputs.subdomain]);

    useEffect(() => {
        emailSearchDebounced(formInputs.email);
        // eslint-disable-next-line
    }, [formInputs.email]);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInput({ ...formInputs, [name]: value });
    };

    const querySearch = async (queryValue: string) => {
        if (queryValue === '' || !queryValue) return;
        //dispatch to backend
        setLoading(true);
        try {
            const { data } = await axios.get(
                `/account?subDomain=${queryValue}`,
            );
            if (data.length >= 1) {
                setIsavaliable('UNAVAILABLE');
            } else {
                setIsavaliable('AVAILABLE');
            }
        } catch (err) {
            message.error('could not process search, please do try again');
        }
        setLoading(false);
    };
    const emailSearch = async (email: string) => {
        //if invalid email return
        if (!EmailValidator.validate(email)) return;
        try {
            const { data } = await axios.post(`/auth/email`, { email: email });

            if (!data) {
                setIsEmailExist('N0T_EXIST');
            } else {
                setIsEmailExist('EXIST');
            }
        } catch (err) {
            message.error('could not check email, please do try again');
        }
    };

    const servicesDebounced = useMemo(
        () => _debounce(querySearch, 1000),
        // eslint-disable-next-line
        [],
    );

    const emailSearchDebounced = useMemo(
        () => _debounce(emailSearch, 1000),
        // eslint-disable-next-line
        [],
    );

    const onSearch = (e: handlerEvent) => {
        onInputChange(e);
        setIsavaliable('');
    };

    const onEmailAvailability = (e: handlerEvent) => {
        onInputChange(e);
        setIsEmailExist('');
    };

    const handleCountrySelection = (val: string) => {
        setFormInput({ ...formInputs, companyLocation: val });
    };

    const onDateChange = (_: any, dateString: [string, string]) => {
        setFormInput({
            ...formInputs,
            startDate: dateString[0],
            endDate: dateString[1],
        });
    };

    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        const {
            email,
            companyAddress,
            subdomain,
            phoneNumber,
            password,
            confirmPassword,
            companyLocation,
            companyName,
            firstName,
            lastName,
            startDate,
            endDate,
            pageantName,
        } = formInputs;
        if (
            !companyAddress ||
            !phoneNumber ||
            !password ||
            !confirmPassword ||
            !companyLocation ||
            !companyName ||
            !firstName ||
            !lastName ||
            !subdomain ||
            !pageantName ||
            !startDate ||
            !endDate
        ) {
            return message.info('All fields are required!!!');
        }

        if (!EmailValidator.validate(email)) {
            return message.error('Invalid email!!!');
        }

        if (isEmailExist === 'EXIST') {
            return message.error('email already exist!!');
        }

        if (password !== confirmPassword) {
            return message.error('Passwords are not same!!');
        }

        if (isAvaliable === 'UNAVAILABLE') {
            return message.error('url already exist!!');
        }

        const callback_url = `${window.location.origin}${SETUP_ENTERPRISE}/${planId}`;
        setLoadingPay(true);
        if (mode === 'free') {
            dispatch(
                freeAccountSubscription({
                    userDetails: {
                        email: email,
                        firstName: firstName,
                        lastName: lastName,
                        password: password,
                        phoneNumber: phoneNumber,
                        defaultAccount: subdomain.toLowerCase(),
                    },
                    accountDetails: {
                        companyName: companyName,
                        companyAddress: companyAddress,
                        companyLocation: companyLocation,
                        pageantName: pageantName,
                        pageantStartDate: startDate,
                        pageantEndDate: endDate,
                    },
                }),
            );
            setLoadingPay(false);
            const url = `${SETUP_ENTERPRISE}?m=free`;
            return push(url);
        } else if (singlePlan) {
            dispatch(
                initializeSubscriptionPayment({
                    userDetails: {
                        email: email,
                        firstName: firstName,
                        lastName: lastName,
                        password: password,
                        phoneNumber: phoneNumber,
                        defaultAccount: subdomain.toLowerCase(),
                    },
                    accountDetails: {
                        companyName: companyName,
                        companyAddress: companyAddress,
                        companyLocation: companyLocation,
                        plan: singlePlan._id,
                        pageantName: pageantName,
                        pageantStartDate: startDate,
                        pageantEndDate: endDate,
                    },
                    transactionDetails: {
                        amount: singlePlan.price,
                        callback_url: callback_url,
                        paymentMethod: paymentMethod,
                    },
                }),
            );
        }
    };

    const onPaymentSelectorHandler = (e: RadioChangeEvent) => {
        const { value } = e.target;
        setPaymentMethod(value);
    };

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const countryOptions = country.map(({ CountryName }) => ({
        id: CountryName,
        name: CountryName,
    }));
    return (
        <Row className="enterprise-acc">
            <TopBarLoader loading={singlePlanLoading || paymentLoading} />
            <Col md={8} className="enterprise-acc__left">
                <div className="enterprise-acc__left--head">
                    <Link to={PRICING_PLAN}>
                        <span className="enterprise-acc__left--head-icon">
                            <LeftCircleOutlined
                                style={{
                                    color: '#11B86F',
                                    fontSize: '22px',
                                    marginRight: '8px',
                                }}
                            />
                        </span>
                    </Link>

                    <Link to={mode === 'free' ? JOIN_US : PRICING_PLAN}>
                        <span className="enterprise-acc__left--head-text">
                            Go back
                        </span>
                    </Link>
                </div>
                <div className="enterprise-acc__left--info">
                    Pageant.Africa is the web platform of choice for pageant
                    owners and promoters. Start your journey with us by creating
                    your free account.
                </div>
                <div className="enterprise-acc__left--plan">
                    <span>PURCHASING</span>
                    <span>
                        {singlePlan?.activePlan.toLowerCase() || 'Lite'} Plan
                    </span>

                    <span>
                        {mode === 'free'
                            ? 'Free'
                            : `$${singlePlan?.price.toLocaleString()}`}
                    </span>

                    <span>{singlePlan?.cycle || ''}</span>
                </div>
                <div className="enterprise-acc__left--steps">
                    <p>
                        <span className="enterprise-acc__left--steps-num">
                            {'1)'}
                        </span>
                        <span style={{ fontWeight: 'bolder' }}>
                            Create an account
                        </span>
                    </p>
                    {mode !== 'free' && (
                        <p>
                            <span className="enterprise-acc__left--steps-num">
                                {'2)'}
                            </span>
                            <span>Go to checkout</span>
                        </p>
                    )}
                    <p>
                        <span className="enterprise-acc__left--steps-num">
                            {mode === 'free' ? '2)' : '3)'}
                        </span>
                        <span>Sub Domain setup </span>
                    </p>
                    <p>
                        <span className="enterprise-acc__left--steps-num">
                            {mode === 'free' ? '3)' : '4)'}
                        </span>
                        <span>Receive Email Confirmation </span>
                    </p>
                    <p>
                        <span className="enterprise-acc__left--steps-num">
                            {mode === 'free' ? '4)' : '5)'}
                        </span>
                        <span>Start Working With Our Team </span>
                    </p>
                </div>
            </Col>
            <Col md={16} className="enterprise-acc__right">
                <form onSubmit={onFormSubmit}>
                    <Row justify="center">
                        <h3 className="text-muted">
                            Create enterprise account.
                        </h3>
                    </Row>
                    <Row
                        gutter={{ sm: 0, md: 20 }}
                        className="field-row firstRow"
                    >
                        <Col xs={24} md={12}>
                            <Input
                                name="firstName"
                                onChange={onInputChange}
                                hasLabel={true}
                                placeholder={firstCharToUpperCase('First Name')}
                                value={formInputs.firstName}
                                label={firstCharToUpperCase('firstname')}
                                isRequired
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <Input
                                name="lastName"
                                onChange={onInputChange}
                                hasLabel={true}
                                placeholder={firstCharToUpperCase('Last Name')}
                                value={formInputs.lastName}
                                label={firstCharToUpperCase('Lastname')}
                                isRequired
                            />
                        </Col>
                    </Row>
                    <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                        <Col xs={24} md={12}>
                            <Input
                                name="email"
                                onChange={onEmailAvailability}
                                hasLabel={true}
                                placeholder={firstCharToUpperCase('email')}
                                value={formInputs.email}
                                label={firstCharToUpperCase('email')}
                                isRequired
                            />
                            {isEmailExist === 'EXIST' && (
                                <small style={{ color: 'red' }}>
                                    Email already in use
                                </small>
                            )}
                        </Col>

                        <Col xs={24} md={12}>
                            <Input
                                name="phoneNumber"
                                onChange={onInputChange}
                                hasLabel={true}
                                placeholder={firstCharToUpperCase(
                                    'Phone number',
                                )}
                                value={formInputs.phoneNumber}
                                label={firstCharToUpperCase('phone Number')}
                                min={0}
                                inputType="number"
                                isRequired
                            />
                        </Col>
                    </Row>

                    <Row
                        gutter={{ sm: 0, md: 20 }}
                        className="field-row first-row"
                    >
                        <Col xs={24} md={12}>
                            <div className="passwd-input">
                                <label htmlFor="password">
                                    <span
                                        style={{
                                            color: 'red',
                                            marginRight: '2px',
                                        }}
                                    >
                                        *
                                    </span>
                                    Password
                                </label>
                                <AntInput.Password
                                    placeholder="input password"
                                    value={formInputs.password}
                                    onChange={onInputChange}
                                    name="password"
                                    minLength={6}
                                    iconRender={(visible) =>
                                        visible ? (
                                            <EyeTwoTone />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        )
                                    }
                                />
                            </div>
                        </Col>
                        <Col xs={24} md={12}>
                            <div className="passwd-input">
                                <label htmlFor="password">
                                    <span
                                        style={{
                                            color: 'red',
                                            marginRight: '2px',
                                        }}
                                    >
                                        *
                                    </span>
                                    Confirm Password
                                </label>
                                <AntInput.Password
                                    placeholder="confirm password"
                                    value={formInputs.confirmPassword}
                                    onChange={onInputChange}
                                    name="confirmPassword"
                                    minLength={6}
                                    iconRender={(visible) =>
                                        visible ? (
                                            <EyeTwoTone />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        )
                                    }
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row
                        gutter={{ sm: 0, md: 20 }}
                        className="field-row first-row"
                    >
                        <Col xs={24} md={12}>
                            <Input
                                name="companyName"
                                onChange={onInputChange}
                                hasLabel={true}
                                placeholder={firstCharToUpperCase(
                                    'organization name',
                                )}
                                value={formInputs.companyName}
                                label={firstCharToUpperCase(
                                    'organization name',
                                )}
                                isRequired
                            />
                        </Col>

                        <Col xs={24} md={12} className="createenterprise">
                            <SelectDropdown
                                hasLabel
                                options={countryOptions}
                                placeholder="select country"
                                loading={loading}
                                onChange={(val) => {
                                    console.log(val);
                                    handleCountrySelection(
                                        val ? val.toString() : '',
                                    );
                                }}
                                value={formInputs.companyLocation}
                                label={firstCharToUpperCase(
                                    'organization location',
                                )}
                                isRequired
                            />
                        </Col>
                    </Row>
                    <Row
                        gutter={{ sm: 0, md: 20 }}
                        className="field-row first-row"
                    >
                        <Col xs={24}>
                            <Input
                                name="companyAddress"
                                onChange={onInputChange}
                                hasLabel={true}
                                placeholder={firstCharToUpperCase(
                                    'organization address',
                                )}
                                value={formInputs.companyAddress}
                                label={firstCharToUpperCase(
                                    'organization address',
                                )}
                                isRequired
                            />
                        </Col>
                    </Row>
                    <Row
                        gutter={{ sm: 0, md: 20 }}
                        className="field-row first-row"
                    >
                        <Col xs={24} md={12}>
                            <Input
                                name="pageantName"
                                onChange={onInputChange}
                                hasLabel={true}
                                placeholder={firstCharToUpperCase(
                                    'pageant name',
                                )}
                                value={formInputs.pageantName}
                                label={firstCharToUpperCase('pageant name')}
                                isRequired
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <div className="custom-rangepicker">
                                <label htmlFor="date">
                                    <span
                                        style={{
                                            color: 'red',
                                            marginRight: '2px',
                                        }}
                                    >
                                        *
                                    </span>
                                    Start and End Date
                                </label>
                                <RangePicker
                                    // value={value}
                                    id="date"
                                    name="date"
                                    // placeholder='Select Start and End Date'
                                    onChange={onDateChange}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row
                        gutter={{ sm: 0, md: 20 }}
                        className="field-row first-row sub-url"
                        justify="center"
                        style={{ textAlign: 'center' }}
                    >
                        <Col xs={24} md={16}>
                            <Input
                                name="subdomain"
                                onChange={onSearch}
                                hasLabel={true}
                                placeholder={firstCharToUpperCase('subdomain')}
                                value={formInputs.subdomain}
                                label={firstCharToUpperCase(
                                    'personalize url (missxyz.pageant.africa)',
                                )}
                                isRequired
                                addonBefore={
                                    loading ? (
                                        <Spin indicator={antIcon} />
                                    ) : (
                                        'https://  '
                                    )
                                }
                                suffix=".Pageant.africa"
                                inputType="search"
                            />
                            <small
                                style={{
                                    textAlign: 'end',
                                    display: 'block',
                                    marginTop: '5px',
                                    fontSize: '12px',
                                    color:
                                        (isAvaliable === 'AVAILABLE' &&
                                            'green') ||
                                        (isAvaliable === 'UNAVAILABLE' &&
                                            'red') ||
                                        'black',
                                }}
                            >{`${formInputs.subdomain}.Pageant.africa ${
                                (isAvaliable === 'AVAILABLE' &&
                                    ' is  available') ||
                                (isAvaliable === 'UNAVAILABLE' &&
                                    'is not  available') ||
                                ''
                            }`}</small>
                        </Col>
                    </Row>
                    <Row>
                        {mode !== 'free' && (
                            <Col xs={24}>
                                <p className="pay_title">Pay with :</p>
                                <Radio.Group
                                    defaultValue={paymentType.FLUTTERWAVE}
                                    buttonStyle="solid"
                                    onChange={onPaymentSelectorHandler}
                                >
                                    {/* <Radio.Button value={paymentType.PAYSTACK}>
                                    PAYSTACK
                                </Radio.Button> */}
                                    <Radio.Button
                                        value={paymentType.FLUTTERWAVE}
                                    >
                                        FLUTTERWAVE
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        )}
                    </Row>

                    <Row
                        gutter={{ xs: 10, md: 20 }}
                        justify="end"
                        className="fieldrow btn-container"
                    >
                        <Col xs={14} md={8} style={{ marginTop: '20px' }}>
                            <Button
                                loading={paymentLoading || loadingPay}
                                htmlType="submit"
                                type="primary"
                            >
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Col>
        </Row>
    );
};

export default CreateEnterprise;
