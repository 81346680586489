import { userTypeDb } from '../../types';
import {
    AUTH_USER,
    FORGOT_PASSWORD_FAILED,
    FORGOT_PASSWORD_INIT,
    FORGOT_PASSWORD_SUCCESS,
    LOGIN_FAILED,
    LOGIN_INIT,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    NOTIFICATION_UNVIEWED,
    NOTIFICATION_VIEWED,
    RESET_AUTH_STATE,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_INIT,
    RESET_PASSWORD_SUCCESS,
    SIGNUP_FAILED,
    SIGNUP_INIT,
    SIGNUP_SUCCESS,
    VERIFY_USER_FAILED,
    VERIFY_USER_INIT,
    VERIFY_USER_SUCCESS,
} from '../types/auth.type';
import { CLEAR_ERROR, CLEAR_MESSAGE } from '../types/common.type';

type AUTH_INIT_STATE = {
    token: string | null;
    usersInfo: userTypeDb | null;
    loading: boolean;
    error: string | null;
    authenticated: boolean;
    isNotificationNew: boolean;
    feedbackMsg: string | null;
};

const AUTH_INITIAL_STATE: AUTH_INIT_STATE = {
    token: null,
    authenticated: false,
    usersInfo: null,
    loading: false,
    error: null,
    isNotificationNew: true,
    feedbackMsg: '',
};

const authReducer = (
    state = AUTH_INITIAL_STATE,
    action: any,
): AUTH_INIT_STATE => {
    switch (action.type) {
        case LOGIN_INIT:
        case SIGNUP_INIT:
        case VERIFY_USER_INIT:
        case FORGOT_PASSWORD_INIT:
        case RESET_PASSWORD_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case LOGIN_FAILED:
        case SIGNUP_FAILED:
        case VERIFY_USER_FAILED:
        case FORGOT_PASSWORD_FAILED:
        case RESET_PASSWORD_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case LOGOUT_USER:
            return {
                ...state,
                token: null,
                usersInfo: null,
                authenticated: false,
                loading: false,
                error: null,
                feedbackMsg: '',
            };
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                usersInfo: action.payload,
                loading: false,
                error: null,
                feedbackMsg: 'success',
            };
        }
        case LOGIN_SUCCESS:
        case VERIFY_USER_SUCCESS:
        case RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                usersInfo: action.payload.user,
                token: action.payload.token,
                authenticated: action.payload.token !== null,
                loading: false,
                error: null,
            };
        }
        case AUTH_USER: {
            return {
                ...state,
                usersInfo: action.payload,
                loading: false,
                error: null,
            };
        }

        case FORGOT_PASSWORD_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
            };
        }

        case NOTIFICATION_VIEWED: {
            return {
                ...state,
                isNotificationNew: false,
            };
        }

        case NOTIFICATION_UNVIEWED: {
            return {
                ...state,
                isNotificationNew: true,
            };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }
        case RESET_AUTH_STATE: {
            return {
                ...state,
                loading: false,
                error: null,
            };
        }
        default:
            return state;
    }
};

export default authReducer;
