import { Dispatch } from 'redux';
import {
    APPROVE_PAYMENT_FAILED,
    APPROVE_PAYMENT_SUCCESSFUL,
    FETCH_TRANSACTION_FAILED,
    FETCH_TRANSACTION_INIT,
    FETCH_TRANSACTION_SUCCESS,
} from '../types/transaction.type';
import { baseInstance as axios } from '../../axios-base';
import { paymentType } from '../../constants/transactionEnum';

export const fetchAllTransactions = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_TRANSACTION_INIT });
    try {
        const { data } = await axios.get('/transaction');
        dispatch({ type: FETCH_TRANSACTION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_TRANSACTION_FAILED,
            payload: error.response.data.message,
        });
    }
};
export const fetchMyTransactions = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_TRANSACTION_INIT });
    try {
        const { data } = await axios.post('/transaction/mytransactions');
        dispatch({ type: FETCH_TRANSACTION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_TRANSACTION_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const fetchDisputeTransactions = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: FETCH_TRANSACTION_INIT });
    try {
        const { data } = await axios.get('/transaction?isPending=true');
        dispatch({ type: FETCH_TRANSACTION_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_TRANSACTION_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const approvePayment = (payload: {
    reference: string;
    paymentMethod: paymentType;
}) => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: FETCH_TRANSACTION_INIT });

    try {
        const { data } = await axios.post(
            '/transaction/pending-approved',
            payload,
        );
        dispatch({
            type: APPROVE_PAYMENT_SUCCESSFUL,
            payload: data,
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: APPROVE_PAYMENT_FAILED,
            payload: error.response.data.message,
        });
    }
};
