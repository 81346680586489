export const AUTH_ACTIONS = '/auth/action';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_RESET = '/passwordreset';
export const FORGOT_PASSWORD = '/forgotpassword';
export const HOME = '/';
export const DASHBOARD = '/dashboard';
export const NOT_FOUND = '/notfound';
export const PUBLIC_CONTESTANT = '/vote_contestant';
export const VOTE_CONTESTANT = '/vote_contestant/:id';
export const ENTERPRISELANDING = '/enterprise';
export const ACCOUNT_SETUP = `/enterprise/setup`;
export const CREATE_ENTERPRISE = `/enterprise/create`;
export const SETUP_ENTERPRISE = `/enterprise/dns_setup`;
export const DNS_SETUP_ENTERPRISE = `${SETUP_ENTERPRISE}/:id`;
export const PRICING_PLAN = `/enterprise/pricing-plan`;

export const ABOUT_US = `/about-us`;
export const JOIN_US = '/join';

export const PRIVACY = '/privacy';
export const TERMSANDCONDITIONS = '/tandc';
