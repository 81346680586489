import { Button, Col, Row } from 'antd';
import React, { FC, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { StepComponentProps } from 'react-step-builder';
import { TASK_HOME } from '../../../../../routes';
import { RootState } from '../../../../../redux/reducers';
import { useSelector } from 'react-redux';

const Step1: FC<StepComponentProps> = (props): ReactElement => {
    const { push } = useHistory();
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    return (
        <Row justify="center" className="step1">
            <Col md={18}>
                <p className="step1__text1">
                    Task created for a contest would only be available for
                    contestants who are not yet evicted.
                </p>
                <p className="step1__text2">
                    Click on continue button and follow the detailed
                    instructions to setup a task.
                </p>
                <Row
                    gutter={{ xs: 10, md: 20 }}
                    justify="space-around"
                    className="btn-container"
                >
                    <Col xs={10} md={5}>
                        <Button onClick={() => push(TASK_HOME)}>CANCEL</Button>
                    </Col>
                    <Col xs={14} md={5}>
                        <Button
                            onClick={() => props.next()}
                            style={{
                                background: `${
                                    subdomainUIstyling['0']
                                        ? subdomainUIstyling['0'][
                                              'primaryColor'
                                          ]
                                        : '#1bca8f'
                                }`,
                                color: 'white',
                            }}
                        >
                            CONTINUE
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default Step1;
