import React, { FC, ReactElement, useState } from 'react';
import { Upload, Button, message } from 'antd';
import { fileUploaderProps } from '../types';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadOutlined } from '@ant-design/icons';
import {
    UPLOAD_URL,
    UPLOAD_PRESET_DOCS,
    UPLOAD_PRESET,
} from '../../../constants/upload';

const FileUploader: FC<fileUploaderProps> = ({
    onUploadComplete,
    label,
    optional,
    showUploadList,
    isDoc,
    className,
}): ReactElement => {
    const [uploading, setUploading] = useState(false);

    const onFileUploadChange = (info: UploadChangeParam) => {
        const { file } = info;
        if (file.status === 'uploading') {
            setUploading(true);
        }
        if (file.status === 'done') {
            console.log(info);
            // upload to cloudinary complete
            const { response } = file;
            if (response && response.secure_url) {
                console.log(info);
                setUploading(false);
                //https:res.cloudinary.com/content-1024concepts/image/upload/q_auto:low/v1636580470/Australia_zr7twf.jpg
                return onUploadComplete(response.secure_url);
            }
        }
        if (file.status === 'error') {
            message.error('Error uploading image retry');
            setUploading(false);

            // onActionComplete();
        }

        //TODO: run a check for error upload
    };

    return (
        <div className={`custom-file-upload ${className}`}>
            <label htmlFor="file-uploader">
                {label} {optional && <span> (Optional)</span>}
            </label>
            <Upload
                id="file-uploader"
                action={UPLOAD_URL}
                maxCount={1}
                accept={
                    isDoc
                        ? '.doc,.docx,.csv,.xlsx'
                        : 'image/png,image/jpeg,image/jpg'
                }
                data={{
                    upload_preset: isDoc
                        ? `${UPLOAD_PRESET_DOCS}`
                        : `${UPLOAD_PRESET}`,
                }}
                onChange={onFileUploadChange}
                showUploadList={showUploadList}
            >
                <Button loading={uploading}>
                    <UploadOutlined /> Click to Upload
                </Button>
            </Upload>
        </div>
    );
};

export default FileUploader;
