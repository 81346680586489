import React, { FC, ReactElement, useEffect, Fragment } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CONTEST_HOME, TRANSACTION_PURCHASE } from '../../../../routes';
import { Button, message, Spin } from 'antd';
import { RootState } from '../../../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { verifyApplication } from '../../../../redux/actions/payment.action';
import { routerParamsType } from '../../../../types';
import { fetchContestById } from '../../../../redux/actions/contests.action';
import { paymentType } from '../../../../constants/transactionEnum';

const VerifyApplicationPayment: FC = (): ReactElement => {
    const {
        location: { search },
        push,
    } = useHistory();
    const dispatch = useDispatch();
    const transactionRef = new URLSearchParams(search).get('reference');
    const transactionID = new URLSearchParams(search).get('transaction_id');

    const { id }: routerParamsType = useParams();

    const payment = useSelector((state: RootState) => state.payment);
    const { loading, transactionInfo } = payment;

    const contestState = useSelector((state: RootState) => state.contests);
    const { eventInfo, error: requestError } = contestState;

    useEffect(() => {
        dispatch(fetchContestById(id));
    }, [id, dispatch]);

    useEffect(() => {
        if (requestError) {
            return push(CONTEST_HOME);
        }
    }, [requestError, push]);

    useEffect(() => {
        if (!transactionRef && !transactionID) {
            message.error('Invalid route params', 500);
            return push(TRANSACTION_PURCHASE);
        }
        if (transactionRef) {
            dispatch(
                verifyApplication({
                    reference: transactionRef,
                    paymentMethod: paymentType.PAYSTACK,
                }),
            );
        } else if (transactionID) {
            dispatch(
                verifyApplication({
                    reference: transactionID,
                    paymentMethod: paymentType.FLUTTERWAVE,
                }),
            );
        }
    }, [push, transactionRef, dispatch, transactionID]);

    return (
        <div className="payment-verification">
            <div className="content success">
                {!loading && (
                    <Fragment>
                        <div className="circle">
                            <h3 className="icon-container">
                                <CheckOutlined />
                            </h3>
                        </div>
                        <h3 className="heading-text">
                            Payment Verification Completed!
                        </h3>
                        <p>Application submitted successfully</p>
                        <div className="info-item">
                            <h4>Amount</h4>
                            <h4 className="value">
                                {`$`}
                                {transactionInfo?.amount.toLocaleString()}
                            </h4>
                        </div>
                        <div className="info-item">
                            <h4>Contest</h4>
                            <h4 className="value">{eventInfo?.eventTitle}</h4>
                        </div>
                        <div className="info-item">
                            <h4>Transaction Reference</h4>
                            <h4 className="value">
                                {transactionInfo?.reference}
                            </h4>
                        </div>
                        <div className="control">
                            <Link to={`${CONTEST_HOME}`}>
                                <Button type="primary">Continue</Button>
                            </Link>
                        </div>
                    </Fragment>
                )}
                {loading && <Spin className="spinner-invest" size="large" />}
            </div>
        </div>
    );
};
export default VerifyApplicationPayment;
