import { Row, Col, Typography, message, Dropdown, Menu } from 'antd';
import React, { FC, ReactElement, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CREATE_CONTEST, CONTEST_HOME, DOMAINPARTS } from '../../../../routes';
import { DashSpinner } from '../../spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
    // clearContest,
    // clearContest,
    fetchContests,
    fetchContestWithSubDn,
} from '../../../../redux/actions/contests.action';
import { RootState } from '../../../../redux/reducers';
import { fetchedEventTypeDb } from '../../../../types';
import { clearError } from '../../../../redux/actions/common.action';
import { ENTERPRISE, USER } from '../../../../constants';
import { sentenceTruncater } from '../../../../utils';
import { MoreOutlined } from '@ant-design/icons';

const { Title } = Typography;

const ContestHome: FC = (): ReactElement => {
    const { push } = useHistory();
    const dispatch = useDispatch();
    const contestState = useSelector((state: RootState) => state.contests);
    const { contestsInfo, error: requestError, loading } = contestState;
    // console.log(contestsInfo);
    const usersInfo = useSelector((state: RootState) => state.auth.usersInfo);
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    useEffect(() => {
        const domainParts = window.location.host.split('.');

        //there exist no subdomain here
        if (domainParts.length >= DOMAINPARTS) {
            const subDomain = domainParts[0];
            dispatch(fetchContestWithSubDn(subDomain));
        } else {
            dispatch(fetchContests());
        }
    }, [dispatch]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        return () => {
            dispatch(clearError());
        };
    }, [contestsInfo, requestError, dispatch]);

    const menu = (id: string) => (
        <Menu>
            <Menu.Item key={id}>
                <span
                    className="edit"
                    onClick={() => push(`${CREATE_CONTEST}?id=${id}&mode=edit`)}
                >
                    EDIT
                </span>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="campaign">
            {!loading && (
                <>
                    {contestsInfo.length !== 0 ? (
                        <>
                            <Row className="campaign-header add-contests">
                                <Col>
                                    <Title level={4}>Contest</Title>
                                </Col>
                                {usersInfo?.role === ENTERPRISE && (
                                    <Col className="contestant-top__right">
                                        <Link
                                            to={`${CREATE_CONTEST}`}
                                            style={{
                                                background: `${
                                                    subdomainUIstyling['0'] &&
                                                    subdomainUIstyling['0'][
                                                        'primaryColor'
                                                    ]
                                                }`,
                                            }}
                                            className="c-btn"
                                        >
                                            Create Contest
                                        </Link>
                                    </Col>
                                )}
                            </Row>
                            <Row className="campaign-content">
                                {contestsInfo.map(
                                    ({
                                        eventTitle,
                                        image,
                                        eventDetails,
                                        _id,
                                        formApply,
                                        isPublished,
                                        totalApplications,
                                        plan: { applicationLimit },
                                    }: fetchedEventTypeDb) => (
                                        <Col
                                            xs={24}
                                            md={12}
                                            lg={8}
                                            className="card-item"
                                            key={_id}
                                        >
                                            <div className="card">
                                                {usersInfo?.role !== USER &&
                                                    !isPublished && (
                                                        <Dropdown
                                                            overlay={() =>
                                                                menu(_id)
                                                            }
                                                            placement="bottomLeft"
                                                            arrow
                                                        >
                                                            <MoreOutlined
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '2px',
                                                                    right:
                                                                        '2px',
                                                                    color:
                                                                        '#ffff',
                                                                    fontSize:
                                                                        '30px',
                                                                    cursor:
                                                                        'pointer',
                                                                    backgroundColor:
                                                                        'rgba(57, 57, 57,0.3)',
                                                                }}
                                                            />
                                                        </Dropdown>
                                                    )}
                                                <div className="card-img">
                                                    <img
                                                        src={image}
                                                        alt="logo"
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    {usersInfo?.role !==
                                                        USER && (
                                                        <>
                                                            {!isPublished ? (
                                                                <span className="drafted">
                                                                    NOT
                                                                    PUBLISHED
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className="publish"
                                                                    style={{
                                                                        color: `${
                                                                            subdomainUIstyling[
                                                                                '0'
                                                                            ] &&
                                                                            subdomainUIstyling[
                                                                                '0'
                                                                            ][
                                                                                'primaryColor'
                                                                            ]
                                                                        }`,
                                                                    }}
                                                                >
                                                                    PUBLISHED
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className="caption">
                                                        <h2>
                                                            {sentenceTruncater(
                                                                eventTitle,
                                                                25,
                                                            )}
                                                        </h2>
                                                        <p>
                                                            {sentenceTruncater(
                                                                eventDetails,
                                                                100,
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="card-action">
                                                        {usersInfo?.role ===
                                                        USER ? (
                                                            <>
                                                                {formApply &&
                                                                    totalApplications <
                                                                        applicationLimit && (
                                                                        <Link
                                                                            className="join-view"
                                                                            style={{
                                                                                background: `${
                                                                                    subdomainUIstyling[
                                                                                        '0'
                                                                                    ] &&
                                                                                    subdomainUIstyling[
                                                                                        '0'
                                                                                    ][
                                                                                        'primaryColor'
                                                                                    ]
                                                                                }`,
                                                                            }}
                                                                            to={`${CONTEST_HOME}${_id}/applycontestant`}
                                                                        >
                                                                            Join
                                                                        </Link>
                                                                    )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {!isPublished && (
                                                                    <Link
                                                                        className="join-view"
                                                                        style={{
                                                                            background: `${
                                                                                subdomainUIstyling[
                                                                                    '0'
                                                                                ] &&
                                                                                subdomainUIstyling[
                                                                                    '0'
                                                                                ][
                                                                                    'primaryColor'
                                                                                ]
                                                                            }`,
                                                                        }}
                                                                        to={`${CREATE_CONTEST}?id=${_id}&mode=edit`}
                                                                    >
                                                                        Edit
                                                                    </Link>
                                                                )}
                                                            </>
                                                        )}

                                                        <Link
                                                            className="join-view2"
                                                            style={{
                                                                background: `${
                                                                    subdomainUIstyling[
                                                                        '0'
                                                                    ] &&
                                                                    subdomainUIstyling[
                                                                        '0'
                                                                    ][
                                                                        'primaryColor'
                                                                    ]
                                                                }`,
                                                            }}
                                                            to={`${CONTEST_HOME}${_id}`}
                                                        >
                                                            View
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ),
                                )}
                            </Row>
                        </>
                    ) : (
                        <Row
                            justify="center"
                            align="middle"
                            style={{
                                height: '200px',
                            }}
                        >
                            <Col xs={24} className="no_contest">
                                <p>No avaliable contest yet</p>
                                {usersInfo?.role === ENTERPRISE && (
                                    <>
                                        <p>
                                            create your first contest by
                                            clicking on the button below
                                        </p>

                                        <Col>
                                            <Link
                                                to={`${CREATE_CONTEST}`}
                                                style={{
                                                    background: `${
                                                        subdomainUIstyling[
                                                            '0'
                                                        ] &&
                                                        subdomainUIstyling['0'][
                                                            'primaryColor'
                                                        ]
                                                    }`,
                                                }}
                                                className="c-btn"
                                            >
                                                Create Contest
                                            </Link>
                                        </Col>
                                    </>
                                )}
                            </Col>
                        </Row>
                    )}
                </>
            )}
            {loading && <DashSpinner loading={loading} />}
        </div>
    );
};

export default ContestHome;
