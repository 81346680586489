import React, { ReactElement, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown, Divider } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { HOME, PROFILE, PROFILE_BUY_MORE } from '../../../routes';
import profilePic from '../../../assets/profile.svg';
import { setAuthToken } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../redux/actions/auth.action';
import { RootState } from '../../../redux/reducers';
import { ENTERPRISE } from '../../../constants';
import { socket } from '../../../UIProvider';
// import { socket } from '../../../App';

const CustomAvatar: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.usersInfo);

    const { push } = useHistory();

    const onProfile = () => {
        return push(PROFILE);
    };

    const onBuyNow = () => {
        return push(PROFILE_BUY_MORE);
    };

    const onSignOut = () => {
        localStorage.removeItem('token');

        //set header authorization to null
        setAuthToken();

        //close socket connections
        socket.disconnect();

        //dispatch log out
        dispatch(logOut());

        //Re-direct landing page
        return push(HOME);
    };

    const menu = (
        <Menu>
            {user?.role !== ENTERPRISE && (
                <Menu.Item key="BUY_MORE" onClick={onBuyNow}>
                    Buy Votes
                </Menu.Item>
            )}
            <Menu.Item key="MY_PROFILE" onClick={onProfile}>
                My Profile
            </Menu.Item>
            <Menu.Item key="LOG_OUT" onClick={onSignOut}>
                Log Out
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="image-container">
            <div className="text-guard">
                <span className="title ">{user?.role}</span>
                <Divider type="vertical" className="avatar-divider" />
                <span className="name ">{`${user?.firstName} ${user?.lastName}`}</span>
            </div>
            <div className="image-guard">
                <img src={profilePic} alt="profile" />
            </div>
            <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomRight"
                arrow
            >
                <UnorderedListOutlined className="icon" />
            </Dropdown>
        </div>
    );
};

export default CustomAvatar;
