import { Dispatch } from 'redux';
import {
    PLANS_FETCH_FAILED,
    PLANS_FETCH_INIT,
    PLANS_FETCH_SUCCESS,
    PLAN_FETCH_ID_FAILED,
    PLAN_FETCH_ID_INIT,
    PLAN_FETCH_ID_SUCCESS,
} from '../types/plans.type';
import { baseInstance as axios } from '../../axios-base';

export const fetchPlans = () => async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: PLANS_FETCH_INIT });
    try {
        const { data } = await axios.get('/plans');
        dispatch({ type: PLANS_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PLANS_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const fetchPlanById = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: PLAN_FETCH_ID_INIT });
    try {
        const { data } = await axios.get(`/plans/${id}`);
        dispatch({ type: PLAN_FETCH_ID_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PLAN_FETCH_ID_FAILED,
            payload: error.response.data.message,
        });
    }
};
