import axios from 'axios';
import { store } from './redux/store';
import { LOGOUT_USER } from './redux/types/auth.type';
import { API_URL } from './routes';

const baseInstance = axios.create({
    baseURL: API_URL,
});

baseInstance.interceptors.request.use(
    async (config) => {
        config.headers = {
            Authorization: `Bearer ${store.getState().auth.token}`,
        };
        return config;
    },
    (error) => {
        // console.log(error.response.data);

        return Promise.reject(error);
    },
);

baseInstance.interceptors.response.use(
    (res) => res,
    (err) => {
        // console.log(err.request.data);
        // console.log(err.response);

        let error = err;

        //logout if no authorization
        if (err.response && err.response.data.statusCode === 401) {
            store.dispatch({
                type: LOGOUT_USER,
            });
            return Promise.reject(error);
        }

        if (!err.response && !err.request.data) {
            error = {
                response: {
                    data: {
                        message: 'No data found!, please reload browser',
                    },
                },
            };
            return Promise.reject(error);
        }

        return Promise.reject(error);
    },
);

export { baseInstance };
