import {
    CLOSE_ADD_EVENT_IMAGE_MODAL,
    CLOSE_ADD_EVENT_VIDEO_MODAL,
    CLOSE_COMMENT_MODAL,
    CLOSE_EVICTION_PROFILE_MODAL,
    CLOSE_NOTIFICATION_DETAIL_MODAL,
    CLOSE_NOTIFICATION_MODAL,
    CLOSE_VOTE_MODAL,
    OPEN_ADD_EVENT_IMAGE_MODAL,
    OPEN_ADD_EVENT_VIDEO_MODAL,
    OPEN_COMMENT_MODAL,
    OPEN_EVICTION_PROFILE_MODAL,
    OPEN_NOTIFICATION_DETAIL_MODAL,
    OPEN_NOTIFICATION_MODAL,
    OPEN_VOTE_MODAL,
} from '../types/UI.types';

type UI_INIT_STATE = {
    voteModalIsOpen: boolean;
    notificationModalIsOpen: boolean;
    notificationDetailModalIsOpen: boolean;
    commentModalIsOpen: boolean;
    evictionProfileModalIsOpen: boolean;
    addContestImageModalIsOpen: boolean;
    addContestVideoModalIsOpen: boolean;
};

const UI_INITIAL_STATE: UI_INIT_STATE = {
    voteModalIsOpen: false,
    notificationModalIsOpen: false,
    notificationDetailModalIsOpen: false,
    commentModalIsOpen: false,
    evictionProfileModalIsOpen: false,
    addContestImageModalIsOpen: false,
    addContestVideoModalIsOpen: false,
};

const UIReducer = (state = UI_INITIAL_STATE, action: any): UI_INIT_STATE => {
    switch (action.type) {
        case OPEN_VOTE_MODAL: {
            return {
                ...state,
                voteModalIsOpen: true,
            };
        }
        case CLOSE_VOTE_MODAL: {
            return {
                ...state,
                voteModalIsOpen: false,
            };
        }
        case OPEN_NOTIFICATION_MODAL: {
            return {
                ...state,
                notificationModalIsOpen: true,
            };
        }
        case CLOSE_NOTIFICATION_MODAL: {
            return {
                ...state,
                notificationModalIsOpen: false,
            };
        }
        case OPEN_NOTIFICATION_DETAIL_MODAL: {
            return {
                ...state,
                notificationDetailModalIsOpen: true,
            };
        }
        case CLOSE_NOTIFICATION_DETAIL_MODAL: {
            return {
                ...state,
                notificationDetailModalIsOpen: false,
            };
        }

        case OPEN_COMMENT_MODAL: {
            return {
                ...state,
                commentModalIsOpen: true,
            };
        }
        case CLOSE_COMMENT_MODAL: {
            return {
                ...state,
                commentModalIsOpen: false,
            };
        }

        case OPEN_EVICTION_PROFILE_MODAL: {
            return {
                ...state,
                evictionProfileModalIsOpen: true,
            };
        }
        case CLOSE_EVICTION_PROFILE_MODAL: {
            return {
                ...state,
                evictionProfileModalIsOpen: false,
            };
        }
        case OPEN_ADD_EVENT_IMAGE_MODAL: {
            return {
                ...state,
                addContestImageModalIsOpen: true,
            };
        }
        case CLOSE_ADD_EVENT_IMAGE_MODAL: {
            return {
                ...state,
                addContestImageModalIsOpen: false,
            };
        }
        case OPEN_ADD_EVENT_VIDEO_MODAL: {
            return {
                ...state,
                addContestVideoModalIsOpen: true,
            };
        }
        case CLOSE_ADD_EVENT_VIDEO_MODAL: {
            return {
                ...state,
                addContestVideoModalIsOpen: false,
            };
        }
        default:
            return state;
    }
};

export default UIReducer;
