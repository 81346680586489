const environment = process.env.NODE_ENV === 'development';

export const API_URL = environment
    ? 'http://localhost:3009/api/v1/'
    : 'https://apiv1.pageant.africa/api/v1/';
export const SOCKET_URL = environment
    ? 'ws://localhost:3009'
    : 'wss://apiv1.pageant.africa/';
export const BASE_URL = environment ? 'localhost:3000' : 'pageant.africa';
export const DOMAINPARTS = environment ? 2 : 3;
// export const API_URL = environment
//     ? 'https://apiv1.pageant.africa/api/v1/'
//     : 'http://localhost:3009/api/v1/';
// export const SOCKET_URL = environment
//     ? 'wss://apiv1.pageant.africa/'
//     : 'ws://localhost:3009';
// export const BASE_URL = environment ?  'pageant.africa' : 'localhost:3000'
// export const DOMAINPARTS = environment ? 3 : 2;
const PAYPAL_SANDBOX_KEY =
    'AYy4TbRpDQ4lrr0fUlM4WxpOl4LFhU8VJo0YUmZi0sciQxhlE1gfSd_-Xa2XdMR8nqXwxkGZtCjlZy8W';
// 'AfTaxyQZe4CtHjboQh_5ACbADi2TARPm3q-1bP9pOFHMsBAWh-bom14cWggTuJ5QIQi33wuA_w8gz3VE';
const PAYPAL_LIVE_KEY =
    'ATqoui1Gr4TMt69d4BiYcmk4vCqSudK6Cg4n1XhACXdUhAbCav1FAuw8kT56qsaev015nNheMeDbdcTr';
// 'AZQ9_hxbF95L1S-zwZZW7LhTF-5mZfyAZbgIFsFmOWMhGuIilR9VydZVkvj81EgHcoL_I-tPhxt0autE';

export const PAYPAL_KEY = environment ? PAYPAL_SANDBOX_KEY : PAYPAL_LIVE_KEY;
