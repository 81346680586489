import { MEDIA_IMAGE, MEDIA_VIDEO } from '../../../routes';
import { MediaImage } from '../../mediaViews/image';
import { MediaVideo } from '../../mediaViews/video';

const routeEviction = [
    {
        id: 2,
        title: 'image',
        exact: true,
        path: MEDIA_IMAGE,
        component: MediaImage,
    },
    {
        id: 3,
        title: 'video',
        exact: true,
        path: MEDIA_VIDEO,
        component: MediaVideo,
    },
];

export default routeEviction;
