import { Row, Col, Button } from 'antd';
import React, { FC, ReactElement, useContext } from 'react';
import { FormContext } from '../formProvider/FormContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/reducers';

const FinalStep: FC = (): ReactElement => {
    const { onFormSubmit } = useContext(FormContext);
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    const publishContest = () => {
        if (!onFormSubmit) return;
        onFormSubmit({ isDraft: false });
    };

    const draftContest = () => {
        if (!onFormSubmit) return;
        onFormSubmit({ isDraft: true });
    };
    return (
        <div>
            <div className="finalStep__title">Final Step</div>
            <p className="finalStep__text">
                Would you like contest to be publish live ?
            </p>
            <div
                style={{
                    color: 'red',
                    fontSize: '14px',
                    textAlign: 'center',
                }}
            >
                Please only publish contest if no further editing is required as
                this action is irreversible!!!
            </div>
            <Row
                className="finalStep_btn"
                justify="center"
                gutter={{ xs: 10, md: 30 }}
            >
                <Col md={6}>
                    <Button onClick={draftContest} style={{ width: '100%' }}>
                        Draft Contest
                    </Button>
                </Col>

                <Col md={6}>
                    <Button
                        // type="primary"
                        onClick={publishContest}
                        // style={{ width: '100%' }}
                        style={{
                            background: `${
                                subdomainUIstyling['0'] &&
                                subdomainUIstyling['0']['primaryColor']
                            }`,
                            width: '100%',
                            color: 'white',
                        }}
                    >
                        Publish Contest
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default FinalStep;
