import { InboxOutlined } from '@ant-design/icons';
import { message, Image } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import React, { FC, ReactElement } from 'react';
import { useContext } from 'react';
import { UPLOAD_URL, UPLOAD_PRESET } from '../../../../../constants/upload';
import { FormContext } from '../formProvider/FormContext';

const Step1: FC = (): ReactElement => {
    const { formInputs, setFormInputs } = useContext(FormContext);

    const props = {
        name: 'file',
        action: UPLOAD_URL,
        maxCount: 1,
        data: { upload_preset: UPLOAD_PRESET },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent: number | undefined) => {
                if (percent) {
                    const current = `${parseFloat(percent.toFixed(2))}%`;
                    return current;
                }
            },
        },
        onChange(info: UploadChangeParam) {
            const { status, response } = info.file;
            if (status === 'done') {
                // upload to cloudinary complete
                if (response && response.secure_url) {
                    setFormInputs({
                        ...formInputs,
                        logo: response.secure_url,
                    });
                    message.success(
                        `${info.file.name} file uploaded successfully.`,
                    );
                }
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        // onDrop(e: React.DragEvent) {
        //     console.log('Dropped files', e.dataTransfer.files);
        // },
    };

    return (
        <div>
            <div className="step2__title">Upload Company logo</div>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                    Support for a single upload only. Strictly prohibit from
                    uploading illegal band files
                </p>
            </Dragger>
            {formInputs?.logo && (
                <div className="upload-preview-img">
                    <Image src={formInputs?.logo} />
                </div>
            )}
        </div>
    );
};

export default Step1;
