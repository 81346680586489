import React, { FormEvent, ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { RootState } from '../../../../redux/reducers';

import { firstCharToUpperCase } from '../../../../utils';
import { Button, Col, Popover, Row, Tag } from 'antd';
import { fetchedContestantTypeDb } from '../../../../types';
import { useDispatch } from 'react-redux';
import { columnType, routerParamsType } from '../types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import {
    evictContestant,
    evictContestantData,
} from '../../../../redux/actions/contestant.action';
import { openEvictionProfileModal } from '../../../../redux/actions/UI.action';
import Input from '../../../input/components';
import { handlerEvent } from '../../../input';

const nameRender = (value: string): ReactElement => {
    return <span>{firstCharToUpperCase(value)}</span>;
};
const tagRender = (value: string): ReactElement => {
    return <span>{value}</span>;
};

const votesRender = (value: string): ReactElement => {
    return <span>{value}</span>;
};

const ActionRender = (
    _: string,
    record: fetchedContestantTypeDb,
): ReactElement => {
    const dispatch = useDispatch();
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    const profileModalHandler = () => {
        dispatch(openEvictionProfileModal());
        dispatch(evictContestantData(record));
    };
    return (
        <Button
            className="contestant__profile-btn"
            type="link"
            onClick={profileModalHandler}
            style={{
                color: `${
                    subdomainUIstyling['0'] &&
                    subdomainUIstyling['0']['primaryColor']
                }`,
            }}
        >
            View Profile
        </Button>
    );
};

const EvictedDateRender = (
    value: Date,
    record: fetchedContestantTypeDb,
): ReactElement => {
    const { id }: routerParamsType = useParams();

    return record.evictedStage.includes(id) ? (
        <span>{moment(value).format('LLL')}</span>
    ) : (
        <span>--------</span>
    );
};

const IsEvictedRender = (
    value: string[],
    record: fetchedContestantTypeDb,
): ReactElement => {
    const { id }: routerParamsType = useParams();
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    const dispatch = useDispatch();
    const [formInputs, setFormInput] = useState({
        evictionNote: '',
        confirmation: '',
    });
    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInput({ ...formInputs, [name]: value });
    };
    const confirmText = 'Evict';
    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        const { evictionNote } = formInputs;

        dispatch(
            evictContestant({
                _id: record._id,
                taskId: id,
                evictionNote,
            }),
        );
    };
    const content = (
        <form onSubmit={onFormSubmit}>
            <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                <Col xs={24}>
                    <Input
                        name="evictionNote"
                        onChange={onInputChange}
                        inputType="text"
                        hasLabel={true}
                        componentType="TextArea"
                        rows={3}
                        placeholder="Reason for eviction"
                        value={formInputs.evictionNote}
                        label="Reason (optional)"
                    />
                </Col>
            </Row>
            <Row className="field-row">
                <Col xs={24}>
                    <Input
                        name="confirmation"
                        onChange={onInputChange}
                        inputType="text"
                        hasLabel={true}
                        isRequired
                        placeholder="Enter confirmation text"
                        value={formInputs.confirmation}
                        label={`Please type '${confirmText}'`}
                    />
                </Col>
            </Row>

            <Row className="field-row">
                <Col span={24}>
                    <Button
                        htmlType="submit"
                        className="custom-btn"
                        // loading={loading}
                        disabled={formInputs.confirmation !== confirmText}
                        style={{
                            background: `${
                                subdomainUIstyling[0]
                                    ? subdomainUIstyling[0].primaryColor
                                    : '#1bca8f'
                            }`,
                            color: 'white',
                        }}
                    >
                        Evict
                    </Button>
                </Col>
            </Row>
        </form>
    );
    return value.includes(id) ? (
        <Tag color="#CD201F">EVICTED</Tag>
    ) : (
        <Popover
            title={`Sure to evict contestant? ${record.fullName.toUpperCase()}`}
            content={content}
        >
            <Tag color="geekblue" className="evict-tag">
                Evict
            </Tag>
        </Popover>
    );
};

const TableColumn: columnType = [
    {
        title: 'name',
        dataIndex: 'fullName',
        render: nameRender,
    },

    {
        title: 'tag',
        dataIndex: 'tagIdentity',
        render: tagRender,
    },
    // {
    //     title: 'likes',
    //     dataIndex: 'likes',
    //     render: likesRender,
    // },

    {
        title: 'votes',
        dataIndex: 'totalVotes',
        render: votesRender,
    },
    {
        title: 'status',
        dataIndex: 'evictedStage',
        render: IsEvictedRender,
    },
    {
        title: 'evicted date',
        dataIndex: 'evictedDate',
        render: EvictedDateRender,
    },

    {
        title: '',
        key: 'action',
        dataIndex: 'action',
        render: ActionRender,
    },
];

export default TableColumn;
