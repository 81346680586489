import { Dispatch } from 'redux';
import {
    CONTEST_FETCH_SUCCESS,
    CONTEST_FETCH_SUCCESS_BY_ID,
    ADD_CONTEST_SUCCESS,
    ADD_CONTEST_FAILED,
    ADD_CONTEST_INIT,
    CONTEST_FETCH_FAILED,
    CONTEST_FETCH_INIT,
    CONTEST_FETCH_FAILED_BY_ID,
    CONTEST_FETCH_INIT_BY_ID,
    CLEAR_EVENT_DETAIL,
} from '../types/contests.type';

import { baseInstance as axios } from '../../axios-base';
import { addContestTypeDb } from '../../types';

export const addContest = (payload: addContestTypeDb) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: ADD_CONTEST_INIT });
    try {
        await axios.post('/events', payload);
        dispatch({ type: ADD_CONTEST_SUCCESS });
    } catch (error) {
        dispatch({
            type: ADD_CONTEST_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const updateContest = (id: string, payload: addContestTypeDb) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: ADD_CONTEST_INIT });
    try {
        await axios.patch(`/events/${id}`, payload);

        dispatch({ type: ADD_CONTEST_SUCCESS });
    } catch (error) {
        dispatch({
            type: ADD_CONTEST_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const fetchContestWithSubDn = (subDomain?: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: CONTEST_FETCH_INIT });
    try {
        const { data } = await axios.get(`/events?subDomain=${subDomain}`);

        dispatch({ type: CONTEST_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CONTEST_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const fetchContests = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    //server content without subdomain restriction
    dispatch({ type: CONTEST_FETCH_INIT });
    try {
        const { data } = await axios.get(`/events`);

        dispatch({ type: CONTEST_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CONTEST_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const fetchOnlyAppliedContests = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: CONTEST_FETCH_INIT });
    try {
        const { data } = await axios.get(`/events?user=${id}&formApply=true`);

        dispatch({ type: CONTEST_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CONTEST_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const fetchUserOwnedContests = (userId: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: CONTEST_FETCH_INIT });
    try {
        const { data } = await axios.get(`/events?user=${userId}`);
        dispatch({ type: CONTEST_FETCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CONTEST_FETCH_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const fetchContestById = (id: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: CONTEST_FETCH_INIT_BY_ID });
    try {
        const { data } = await axios.get(`/events/${id}`);
        dispatch({ type: CONTEST_FETCH_SUCCESS_BY_ID, payload: data });
    } catch (error) {
        dispatch({
            type: CONTEST_FETCH_FAILED_BY_ID,
            payload: error.response.data.message,
        });
    }
};

export const clearEventDetails = () => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: CLEAR_EVENT_DETAIL });
};

// export const clearContest = () => async (dispatch: Dispatch): Promise<void> => {
//     dispatch({ type: CLEAR_CONTEST });
// };
