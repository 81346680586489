import React, { ReactElement, FC } from 'react';
import { Typography } from 'antd';
import mailSent from '../../assets/mailSent.svg';
import { useHistory } from 'react-router-dom';

const { Text } = Typography;

const ConfirmnoticeView: FC = (): ReactElement => {
    const {
        location: { search },
    } = useHistory();

    const parsedSearch = new URLSearchParams(search);
    const email = parsedSearch.get('email');

    return (
        <div className="content-wrapper">
            <div className="image-guard">
                <img className="image" src={mailSent} alt="mail" />
            </div>
            <Text className="text-1">
                We just sent you a mail to <b>{email}</b>
            </Text>
            <Text className="text-2">
                Open your email, click on the provided link to proceed
            </Text>
            <Text className="text-2">
                If you {`don't`} see the email, kindly check your SPAM folder.
            </Text>
            <Text className="text-2">Thank you!</Text>
        </div>
    );
};

export default ConfirmnoticeView;
