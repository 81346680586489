import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { firstCharToUpperCase } from '../../../../utils';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../redux/reducers';

import { columnType } from '../types';
import { fetchedEventTypeDb } from '../../../../types';
import { TRANSACTION_VOTING_HISTORY } from '../../../../routes';

const dateRender = (value: Date): ReactElement => {
    return <span>{moment(value).format('LL')}</span>;
};

const eventNameRender = (value: string): ReactElement => {
    return <span>{firstCharToUpperCase(value)}</span>;
};

const ActionRender = (_: string, record: fetchedEventTypeDb): ReactElement => {
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;
    return (
        <Link
            className="trans-view-btn"
            to={`${TRANSACTION_VOTING_HISTORY}?id=${record._id}`}
            style={{
                backgroundColor: `${
                    subdomainUIstyling['0'] &&
                    subdomainUIstyling['0']['primaryColor']
                }`,
            }}
        >
            voting history
        </Link>
    );
};

const TableColumn: columnType = [
    {
        title: 'Created Date',
        dataIndex: 'startDate',
        render: dateRender,
    },
    {
        title: 'Contest',
        dataIndex: 'eventTitle',
        render: eventNameRender,
    },

    {
        title: 'End Date',
        dataIndex: 'closingDate',
        render: dateRender,
    },
    {
        title: '',
        key: 'action',
        dataIndex: 'action',
        render: ActionRender,
    },
];

export default TableColumn;
