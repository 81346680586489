export const FETCH_PENDING_IMAGES_INIT = 'FETCH_PENDING_IMAGES_INIT';
export const FETCH_PENDING_IMAGES_SUCCESS = 'FETCH_PENDING_IMAGES_SUCCESS';
export const FETCH_PENDING_IMAGES_FAILED = 'FETCH_PENDING_IMAGES_FAILED';

export const PUBLISH_PENDING_IMAGES_INIT = 'PUBLISH_PENDING_IMAGES_INIT';
export const PUBLISH_PENDING_IMAGES_SUCCESS = 'PUBLISH_PENDING_IMAGES_SUCCESS';
export const PUBLISH_PENDING_IMAGES_FAILED = 'PUBLISH_PENDING_IMAGES_FAILED';

export const PENDING_IMAGES_CLEAR = 'PENDING_IMAGES_CLEAR';

export const FETCH_PENDING_VIDEOS_INIT = 'FETCH_PENDING_VIDEOS_INIT';
export const FETCH_PENDING_VIDEOS_SUCCESS = 'FETCH_PENDING_VIDEOS_SUCCESS';
export const FETCH_PENDING_VIDEOS_FAILED = 'FETCH_PENDING_VIDEOS_FAILED';

export const PUBLISH_PENDING_VIDEOS_INIT = 'PUBLISH_PENDING_VIDEOS_INIT';
export const PUBLISH_PENDING_VIDEOS_SUCCESS = 'PUBLISH_PENDING_VIDEOS_SUCCESS';
export const PUBLISH_PENDING_VIDEOS_FAILED = 'PUBLISH_PENDING_VIDEOS_FAILED';
