import { Col, Row, Table } from 'antd';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchContestants } from '../../../../redux/actions/contestant.action';
import { RootState } from '../../../../redux/reducers';
import { fetchedContestantTypeDb } from '../../../../types';
import ProfileModal from './ProfileModal';
import TableColumn from './TableColumn';

const EvictionDetails: FC = (): ReactElement => {
    const {
        location: { search },
    } = useHistory();

    const dispatch = useDispatch();
    const contestsState = useSelector((state: RootState) => state.contestants);
    const { loading, contestantsInfo } = contestsState;
    const [tableData, setTableData] = useState<fetchedContestantTypeDb[]>([]);

    const eventID = new URLSearchParams(search).get('event');

    useEffect(() => {
        if (eventID) {
            dispatch(fetchContestants(eventID));
        }
    }, [eventID, dispatch]);

    useEffect(() => {
        setTableData(contestantsInfo);
    }, [contestantsInfo]);

    return (
        <Row className="table-content">
            <Col className="table-wrapper" span={24}>
                <Table
                    loading={loading}
                    title={() => <h2>Contestants</h2>}
                    columns={TableColumn}
                    dataSource={tableData || []}
                    size="small"
                    rowKey="_id"
                />
            </Col>
            <ProfileModal />
        </Row>
    );
};

export default EvictionDetails;
