import { Dispatch } from 'redux';
import { baseInstance as axios } from '../../axios-base';
import { accountSetup } from '../../types';
import {
    UPDATE_SUBDOMAIN_UI_INIT,
    UPDATE_SUBDOMAIN_UI_SUCCESS,
    UPDATE_SUBDOMAIN_UI_FAILED,
    SUBDOMAIN_UI_FAILED,
    SUBDOMAIN_UI_INIT,
    SUBDOMAIN_UI_SUCCESS,
} from '../types/subdomainUI.type';

export const subdomainUI = (subDomain?: string) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: SUBDOMAIN_UI_INIT });
    try {
        const { data } = await axios.get(`/account?subDomain=${subDomain}`);
        /**
         * @todo set token here to local storage or persist user
         * consider walletbalance been expose and
         * user have to persist to reduce uneccessary queries on refetch
         */
        //on success dispatch payload
        dispatch({ type: SUBDOMAIN_UI_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: SUBDOMAIN_UI_FAILED,
            payload: error.response.data.message,
        });
    }
};

export const updateSubdomainUI = (id: string, payload: accountSetup) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch({ type: UPDATE_SUBDOMAIN_UI_INIT });
    try {
        const { data } = await axios.patch(`/account/${id}`, payload);
        /**
         * @todo set token here to local storage or persist user
         * consider walletbalance been expose and
         * user have to persist to reduce uneccessary queries on refetch
         */
        //on success dispatch payload
        dispatch({ type: UPDATE_SUBDOMAIN_UI_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: UPDATE_SUBDOMAIN_UI_FAILED,
            payload: error.response.data.message,
        });
    }
};
