import React, { FC, ReactElement } from 'react';
import { Input } from 'antd';
import { searchProps } from '../types';

import './style.scss';

const { Search } = Input;
const Searchbar: FC<searchProps> = ({
    placeholder,
    onSearch,
    prefix,
    onChange,
    addonBefore,
    suffix,
    enterButton,
}): ReactElement => {
    return (
        <Search
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            onSearch={(value) => onSearch(value)}
            prefix={prefix}
            addonBefore={addonBefore}
            suffix={suffix}
            enterButton={enterButton}
        />
    );
};

export default Searchbar;
