import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Layout } from 'antd';
import { Navigation } from '../../components/navigation';
import { useSelector } from 'react-redux';

import 'react-multi-carousel/lib/styles.css';
import { RootState } from '../../redux/reducers';
import { OverlayLoader } from '../../components/overlayLoader';
import { spinnerEnum } from '../../components/overlayLoader/types';

const { Footer } = Layout;

const SubLandingPage: FC = (): ReactElement => {
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling, loadingUI } = stylingState;

    return (
        <>
            {subdomainUIstyling.length !== 0 && !loadingUI ? (
                <>
                    <Row className="landing__header">
                        <Col xs={24}>
                            <Navigation />
                        </Col>
                    </Row>
                    <Row className="landing__background">
                        <Col
                            xs={24}
                            className="landing land"
                            style={{
                                backgroundImage:
                                    subdomainUIstyling['0'] &&
                                    `url(${
                                        subdomainUIstyling['0'] &&
                                        subdomainUIstyling['0']['bannerImage']
                                    })`,
                            }}
                        ></Col>
                    </Row>
                    <Row className="contents">
                        <Col xs={24}>
                            <h1>
                                Welcome to{' '}
                                <span>
                                    {subdomainUIstyling['0']
                                        ? subdomainUIstyling['0']['companyName']
                                        : `Mr. Ideal Nigeria`}
                                </span>{' '}
                                voting platform!
                            </h1>
                        </Col>

                        <Col
                            xs={24}
                            style={{ textAlign: 'center', fontSize: '16px' }}
                        >
                            <p>{subdomainUIstyling['0']['aboutCompany']}</p>
                        </Col>
                    </Row>

                    <Footer
                        style={{
                            textAlign: 'center',
                            backgroundImage:
                                'linear-gradient(to right top, #caa82d, #caa627, #c9a520, #c9a318, #c9a10d)',
                        }}
                    >
                        <div>
                            <Link
                                to="/privacy"
                                style={{ textAlign: 'center', color: 'white' }}
                            >
                                Privacy Policy
                            </Link>
                        </div>
                        <div>
                            <Link
                                to="/tandc"
                                style={{ textAlign: 'center', color: 'white' }}
                            >
                                Terms and Conditions
                            </Link>
                        </div>
                        <p style={{ textAlign: 'center', color: 'white' }}>
                            ©{' '}
                            {subdomainUIstyling['0']
                                ? subdomainUIstyling['0']['companyName']
                                : `Pageant.africa`}{' '}
                            2021
                        </p>
                    </Footer>
                </>
            ) : (
                <OverlayLoader
                    opacity={0.1}
                    spinnerType={spinnerEnum.TailSpin}
                />
            )}
        </>
    );
};

export default SubLandingPage;
