import React, { FC, Fragment, ReactElement } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';

import {
    AUTH_ACTIONS,
    HOME,
    SIGN_IN,
    SIGN_UP,
    FORGOT_PASSWORD,
    DASHBOARD,
    VOTE_CONTESTANT,
    NOT_FOUND,
    CREATE_CONTEST,
    PRICING_PLAN,
    CREATE_ENTERPRISE,
    DNS_SETUP_ENTERPRISE,
    PRIVACY,
    ACCOUNT_SETUP,
    TERMSANDCONDITIONS,
    ABOUT_US,
    ENTERPRISELANDING,
    PAYPAL_KEY,
    SETUP_ENTERPRISE,
    JOIN_US,
} from './routes';
import StoreProvider from './redux/StoreProvider';
import { PrivateRoute } from './components/PrivateRoute';

import LandingPage from './pages/landing/Landing.page';
import AuthPage from './pages/auth/Auth.page';
import AuthActionPage from './pages/authactions/AuthActions.page';
import DashboardPage from './pages/dashboard/Dashboard.page';
import PublicProfile from './pages/publicProfile/PublicProfile.page';
import NotFoundPage from './pages/NotFoundPage';
import Privacy from './pages/privacyPage/Privacy.page';
import JoinPage from './pages/landing/JoinPage';
import { CreateContest } from './components/contestsViews/createContest';
import { PricingPlan } from './components/pricingPlan';
import { AccountSetup } from './components/enterpriseAccount/accountSetup';
import { CreateEnterprise } from './components/enterpriseAccount/createEnterprise';
import { DnsSetup } from './components/enterpriseAccount/dnsSetup';

import './App.less';
import TandC from './pages/tandc/Tandc.page';
import AboutUs from './pages/aboutus/Aboutus.page';
import { ScrollToTop } from './utils';
import UIProvider from './UIProvider';
import EnterpriseLandingPage from './pages/landing/EnterpriseLanding.page';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import ReactGA from 'react-ga';
import RouteChangeTracker from './RouteChangeTracker';

const TRACKING_ID = 'UA-213149326-1';
ReactGA.initialize(TRACKING_ID);

const App: FC = (): ReactElement => {
    // live key paypal= AZQ9_hxbF95L1S-zwZZW7LhTF-5mZfyAZbgIFsFmOWMhGuIilR9VydZVkvj81EgHcoL_I-tPhxt0autE
    return (
        <StoreProvider>
            <UIProvider>
                <PayPalScriptProvider
                    options={{
                        'client-id': PAYPAL_KEY,
                        currency: 'USD',
                        intent: 'capture',
                        components: 'marks,buttons',
                    }}
                >
                    <Router>
                        <Fragment>
                            <ScrollToTop />
                            <RouteChangeTracker />
                            <Switch>
                                <Route
                                    exact
                                    path={HOME}
                                    component={LandingPage}
                                />
                                <Route
                                    exact
                                    path={SIGN_UP}
                                    component={AuthPage}
                                />
                                <Route
                                    exact
                                    path={SIGN_IN}
                                    component={AuthPage}
                                />
                                <Route
                                    exact
                                    path={ENTERPRISELANDING}
                                    component={EnterpriseLandingPage}
                                />
                                <Route
                                    exact
                                    path={FORGOT_PASSWORD}
                                    component={AuthPage}
                                />
                                <Route
                                    exact
                                    path={PRIVACY}
                                    component={Privacy}
                                />
                                <Route
                                    exact
                                    path={TERMSANDCONDITIONS}
                                    component={TandC}
                                />
                                <Route
                                    exact
                                    path={ABOUT_US}
                                    component={AboutUs}
                                />
                                <Route
                                    exact
                                    path={JOIN_US}
                                    component={JoinPage}
                                />
                                <Route
                                    exact
                                    path={AUTH_ACTIONS}
                                    component={AuthActionPage}
                                />

                                <Route
                                    exact
                                    path={VOTE_CONTESTANT}
                                    component={PublicProfile}
                                />
                                <Route
                                    exact
                                    path={DNS_SETUP_ENTERPRISE}
                                    component={DnsSetup}
                                />
                                <Route
                                    exact
                                    path={SETUP_ENTERPRISE}
                                    component={DnsSetup}
                                />
                                <Route
                                    exact
                                    path={CREATE_ENTERPRISE}
                                    component={CreateEnterprise}
                                />
                                <PrivateRoute
                                    exact
                                    path={ACCOUNT_SETUP}
                                    component={AccountSetup}
                                />
                                <Route
                                    exact
                                    path={PRICING_PLAN}
                                    component={PricingPlan}
                                />
                                <PrivateRoute
                                    exact
                                    path={CREATE_CONTEST}
                                    component={CreateContest}
                                />
                                <PrivateRoute
                                    exact={false}
                                    path={DASHBOARD}
                                    component={DashboardPage}
                                />
                                <Route
                                    exact
                                    path={NOT_FOUND}
                                    component={NotFoundPage}
                                />
                                <Redirect from="*" to="/" />
                            </Switch>
                        </Fragment>
                    </Router>
                </PayPalScriptProvider>
            </UIProvider>
        </StoreProvider>
    );
};

export default App;
