import { Col, Row } from 'antd';
import React from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../../../redux/reducers';
import { CONTEST_HOME } from '../../../../routes';
import { routerParamsType } from '../types';
import AddContestVideoModal from './addMedia/AddVideo';

export default function ContestVideos() {
    const eventVideos = useSelector(
        (state: RootState) => state.videos.eventVideos,
    );
    const { push } = useHistory();
    const { id }: routerParamsType = useParams();

    // const videos = [
    //     'https://www.youtube.com/watch?v=o6MutG-FoU0',
    //     'https://www.youtube.com/watch?v=o6MutG-FoU0',
    // ];
    return (
        <div className="contestVideos">
            <Row justify="center">
                <Col md={18}>
                    <ReactPlayer
                        key={'_id'}
                        loop
                        controls
                        style={{
                            padding: '10px',
                        }}
                        width="100%"
                        height="350px"
                        padding="30px"
                        url={eventVideos[eventVideos.length - 1]?.url}
                    />
                </Col>
            </Row>
            <Row justify="center">
                <Col
                    xs={4}
                    className="contestVideos__moreVideos"
                    onClick={() => push(`${CONTEST_HOME}${id}/video`)}
                >
                    View Videos
                </Col>
            </Row>
            <AddContestVideoModal />
        </div>
    );
}
