import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Row, Col, Table, Typography, message } from 'antd';
import tableColumns from './TableColumn';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { clearError } from '../../../../redux/actions/common.action';
import {
    fetchAllTransactions,
    fetchMyTransactions,
} from '../../../../redux/actions/transaction.action';
import { ADMIN } from '../../../../constants';
import { Searchbar } from '../../../searchbar';
import { transactionTypeDb } from '../../../../types';

const { Title } = Typography;
const Purchase: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const usersInfo = useSelector((state: RootState) => state.auth.usersInfo);
    const transactions = useSelector((state: RootState) => state.transaction);
    const { loading, error: requestError, transactionsInfo } = transactions;
    const [tableData, setTableData] = useState<transactionTypeDb[]>([]);

    useEffect(() => {
        setTableData(transactionsInfo);
    }, [transactionsInfo]);
    useEffect(() => {
        if (usersInfo?.role === ADMIN) {
            dispatch(fetchAllTransactions());
        } else {
            //user transaction
            dispatch(fetchMyTransactions());
        }
    }, [usersInfo, dispatch]);

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        return () => {
            dispatch(clearError());
        };
    }, [requestError, dispatch]);

    const onSearch = (input: string) => {
        const query = input.toLowerCase();

        if (query === '') return setTableData(transactionsInfo);
        if (transactionsInfo.length <= 0) return;

        const result = transactionsInfo.filter((trans: transactionTypeDb) => {
            const paymentMethod = trans.paymentMethod.toLowerCase();
            const reference = trans?.reference?.toLowerCase();
            const votes = trans.votes.toString();
            const amount = trans.amount.toString();
            const isMatch =
                paymentMethod.includes(query) ||
                reference?.includes(query) ||
                votes.includes(query) ||
                amount.includes(query);
            return isMatch;
        });
        setTableData(result);
    };

    return (
        <div className="transaction-container">
            <div className="table-content box-shadow">
                <Row className="table-header">
                    <Col span={24}>
                        <Title level={4}>Purchase history</Title>
                    </Col>
                </Row>
                <Row>
                    {usersInfo?.role === ADMIN && (
                        <Col md={14}>
                            <Searchbar
                                onChange={(e) => onSearch(e)}
                                placeholder="Search transactions by reference, votes, amount, payment method"
                                onSearch={onSearch}
                                enterButton="Search"
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col className="table-wrapper" span={24}>
                        <Table
                            loading={loading}
                            columns={tableColumns}
                            dataSource={tableData || []}
                            rowKey="_id"
                            pagination={{
                                defaultPageSize: 100,
                                showSizeChanger: true,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Purchase;
