import {
    fetchedContestantTypeDb,
    fetchedSingleContestantTypeDb,
} from '../../types';
import { CLEAR_MESSAGE, CLEAR_ERROR } from '../types/common.type';
import {
    CONTESTANTS_FETCH_SUCCESS,
    EVICT_CONTESTANT_SUCCESS,
    ADD_CONTESTANT_SUCCESS,
    DELETE_CONTESTANT,
    DELETE_CONTESTANT_SUCCESS,
    DELETE_CONTESTANT_FAILED,
    FETCH_CONTESTANT_DETAILS_SUCCESS,
    CLEAR_CONTESTANT_DATAS,
    ADD_CONTESTANT_INIT,
    CONTESTANTS_FETCH_INIT,
    EVICT_CONTESTANT_INIT,
    FETCH_CONTESTANT_DETAILS_INIT,
    ADD_CONTESTANT_FAILED,
    CONTESTANTS_FETCH_FAILED,
    EVICT_CONTESTANT_FAILED,
    FETCH_CONTESTANT_DETAILS_FAILED,
    EVICTION_CONTESTANT_DETAILS,
} from '../types/contestant.type';

type CONTESTANTS_INIT_STATE = {
    contestantsInfo: fetchedContestantTypeDb[];

    contestantDetails: fetchedSingleContestantTypeDb | null;

    loading: boolean;
    deletingLoading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const CONTESTANTS_INITIAL_STATE: CONTESTANTS_INIT_STATE = {
    contestantsInfo: [],
    contestantDetails: null,
    loading: false,
    deletingLoading: false,
    feedbackMsg: null,
    error: null,
};

const contestantsReducer = (
    state = CONTESTANTS_INITIAL_STATE,
    action: any,
): CONTESTANTS_INIT_STATE => {
    switch (action.type) {
        case EVICT_CONTESTANT_INIT:
        case CONTESTANTS_FETCH_INIT:
        case FETCH_CONTESTANT_DETAILS_INIT:
        case ADD_CONTESTANT_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case CONTESTANTS_FETCH_SUCCESS: {
            const contestants = [...action.payload].reverse();

            return {
                ...state,
                contestantsInfo: contestants,
                loading: false,
            };
        }
        case FETCH_CONTESTANT_DETAILS_SUCCESS: {
            return {
                ...state,
                contestantDetails: action.payload,
                loading: false,
                error: null,
            };
        }

        case EVICTION_CONTESTANT_DETAILS: {
            return {
                ...state,
                contestantDetails: action.payload,
                loading: false,
                error: null,
            };
        }

        case EVICT_CONTESTANT_SUCCESS: {
            const newContestants = [...state.contestantsInfo];
            const index = newContestants.findIndex(
                (el) => el._id === action.payload._id,
            );
            newContestants[index].isEvicted = action.payload.isEvicted;
            newContestants[index].evictedStage = action.payload.evictedStage;

            return {
                ...state,
                contestantsInfo: newContestants,
                loading: false,
                error: null,
            };
        }

        case ADD_CONTESTANT_SUCCESS: {
            const newContestant = [
                action.payload,
                ...state.contestantsInfo,
            ].reverse();
            return {
                ...state,
                feedbackMsg: 'Contestant added successfully',
                contestantsInfo: newContestant,
                loading: false,
                error: null,
            };
        }

        case DELETE_CONTESTANT: {
            return {
                ...state,
                deletingLoading: true,
                error: null,
            };
        }
        case DELETE_CONTESTANT_SUCCESS: {
            const remainingContestant = state.contestantsInfo.filter(
                ({ _id }: any) => _id !== action.payload._id,
            );
            return {
                ...state,
                deletingLoading: false,
                contestantsInfo: remainingContestant,
                feedbackMsg: 'Contestant deleted successfully !!!',
            };
        }

        case DELETE_CONTESTANT_FAILED: {
            return {
                ...state,
                deletingLoading: false,
                error: action.payload,
            };
        }
        case CLEAR_CONTESTANT_DATAS: {
            return {
                ...state,
                contestantDetails: null,
                contestantsInfo: [],
            };
        }
        case CONTESTANTS_FETCH_FAILED:
        case FETCH_CONTESTANT_DETAILS_FAILED:
        case ADD_CONTESTANT_FAILED:
        case EVICT_CONTESTANT_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }
        default:
            return state;
    }
};

export default contestantsReducer;
