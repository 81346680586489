import { Col, Row } from 'antd';
import React, { FC, ReactElement } from 'react';
import { useContext } from 'react';
import { SwatchesPicker } from 'react-color';
import { materialColors } from '../../../../../constants/colors';
import { FormContext } from '../formProvider/FormContext';
import { useWindowWidth } from '@react-hook/window-size';

const Step5: FC = (): ReactElement => {
    const windowWidth = useWindowWidth();
    const { formInputs, setFormInputs } = useContext(FormContext);
    const handlePrimaryChange = (color) => {
        setFormInputs({
            ...formInputs,
            primaryColor: color.hex,
        });
    };
    const handleSecondaryChange = (color) => {
        setFormInputs({
            ...formInputs,
            secondaryColor: color.hex,
        });
    };
    return (
        <>
            <p
                style={{
                    textAlign: 'center',
                }}
            >
                Dashboard Colors <b>(optional)</b>
            </p>
            <Row justify="space-between">
                <Col xs={24} md={10}>
                    <div>
                        <span>Primary color</span>
                        <span
                            style={{
                                backgroundColor:
                                    formInputs?.primaryColor ||
                                    'rgb(56,142,60)',
                                height: '20px',
                                width: '100%',
                                display: 'inline-block',
                            }}
                        ></span>
                    </div>
                    <SwatchesPicker
                        height={300}
                        onChange={handlePrimaryChange}
                        color={formInputs?.primaryColor}
                        colors={materialColors}
                    />
                </Col>
                {windowWidth > 568 && (
                    <Col xs={24} md={10}>
                        <div>
                            <span>Secondary color</span>
                            <span
                                style={{
                                    backgroundColor:
                                        formInputs?.secondaryColor ||
                                        'rgb(56,142,60)',
                                    height: '20px',
                                    width: '100%',
                                    display: 'inline-block',
                                }}
                            ></span>
                        </div>
                        <SwatchesPicker
                            color={formInputs?.secondaryColor}
                            height={300}
                            onChange={handleSecondaryChange}
                            colors={materialColors}
                        />
                    </Col>
                )}
            </Row>
        </>
    );
};

export default Step5;
