import { transactionTypeDb } from '../../types';
import { CLEAR_ERROR, CLEAR_MESSAGE } from '../types/common.type';
import {
    APPROVE_PAYMENT_FAILED,
    APPROVE_PAYMENT_SUCCESSFUL,
    FETCH_TRANSACTION_FAILED,
    FETCH_TRANSACTION_INIT,
    FETCH_TRANSACTION_SUCCESS,
} from '../types/transaction.type';

type TRANSACTION_INIT_STATE = {
    transactionsInfo: transactionTypeDb[];
    loading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const TRANSACTION_INITIAL_STATE: TRANSACTION_INIT_STATE = {
    transactionsInfo: [],
    loading: false,
    feedbackMsg: null,
    error: null,
};

const transactionReducer = (
    state = TRANSACTION_INITIAL_STATE,
    action: any,
): TRANSACTION_INIT_STATE => {
    switch (action.type) {
        case FETCH_TRANSACTION_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case FETCH_TRANSACTION_FAILED:
        case APPROVE_PAYMENT_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case FETCH_TRANSACTION_SUCCESS: {
            const transactions = [...action.payload].reverse();

            return {
                ...state,
                transactionsInfo: transactions,
                loading: false,
                error: null,
            };
        }
        case APPROVE_PAYMENT_SUCCESSFUL: {
            const prevTran = [...state.transactionsInfo];
            const pendingTran = prevTran.filter(
                (el) => el._id !== action.payload._id,
            );
            return {
                ...state,
                transactionsInfo: pendingTran,
                loading: false,
                error: null,
            };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }
        default:
            return state;
    }
};

export default transactionReducer;
