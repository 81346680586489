import { Row, Col, Button } from 'antd';
import React, { FC, ReactElement } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FooterSection from '../../../pages/landing/FooterSection';
import { fetchPlans } from '../../../redux/actions/plans.action';
import { RootState } from '../../../redux/reducers';
import { CREATE_ENTERPRISE } from '../../../routes';
import { Navigation } from '../../navigation';
import { TopBarLoader } from '../../topBarProgress';

const PricingPlan: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const plansState = useSelector((state: RootState) => state.plans);
    const { loading, plans } = plansState;
    useEffect(() => {
        dispatch(fetchPlans());
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Navigation />
            <TopBarLoader loading={loading} />
            <Row>
                <Col xs={0} md={24}>
                    <Row className="enterprise-page__steps">
                        <Col md={6}>
                            <div className="enterprise-page__steps-card">
                                <div className="enterprise-page__steps-card-num">
                                    <span className="enterprise-page__steps-card-num-main">
                                        1
                                    </span>
                                    <span className="enterprise-page__steps-card-num-clone">
                                        1
                                    </span>
                                </div>
                                <p className="enterprise-page__steps-card-title">
                                    SELECT A PLAN
                                </p>
                                <p className="enterprise-page__steps-card-text">
                                    Select a paid plan that best suit your
                                    needs.
                                </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="enterprise-page__steps-card">
                                <div className="enterprise-page__steps-card-num">
                                    <span className="enterprise-page__steps-card-num-main">
                                        2
                                    </span>
                                    <span className="enterprise-page__steps-card-num-clone">
                                        2
                                    </span>
                                </div>
                                <p className="enterprise-page__steps-card-title">
                                    register
                                </p>
                                <p className="enterprise-page__steps-card-text">
                                    Fill in required details and select your
                                    preferred sub domain to get started.
                                </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="enterprise-page__steps-card">
                                <div className="enterprise-page__steps-card-num">
                                    <span className="enterprise-page__steps-card-num-main">
                                        3
                                    </span>
                                    <span className="enterprise-page__steps-card-num-clone">
                                        3
                                    </span>
                                </div>
                                <p className="enterprise-page__steps-card-title">
                                    Sub domain mapping
                                </p>
                                <p className="enterprise-page__steps-card-text">
                                    Allow sub domain mapping to propagate fully
                                    within a short time.
                                </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="enterprise-page__steps-card">
                                <div className="enterprise-page__steps-card-num enterprise-page__steps-card-last ">
                                    <span className="enterprise-page__steps-card-num-main">
                                        4
                                    </span>
                                    <span className="enterprise-page__steps-card-num-clone">
                                        4
                                    </span>
                                </div>
                                <p className="enterprise-page__steps-card-title">
                                    Create a contest
                                </p>
                                <p className="enterprise-page__steps-card-text">
                                    Start your first contest and engage your
                                    audience.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="plan-page">
                <Row className="plan-page__title" justify="center">
                    <Col>
                        <h1>PRICING PLANS</h1>
                        <p>
                            Simple plans to stay aligned. Choose a plan and get
                            started
                        </p>
                    </Col>
                </Row>
                <Row className="plan-page__wrapper">
                    {!loading &&
                        plans.map(
                            ({
                                _id,
                                price,
                                activePlan,
                                hotPlan,
                                taskLimit,
                                applicationLimit,
                                imagesPerContestant,
                                videosPerContestant,
                            }) => (
                                <Col xs={24} lg={8} key={_id}>
                                    <div
                                        className={`pricing-card  ${
                                            hotPlan ? 'main-plan' : ''
                                        }`}
                                    >
                                        {hotPlan && (
                                            <span className="pricing-card__active ">
                                                RECOMMENDED
                                            </span>
                                        )}
                                        <p className="pricing-card__title">
                                            {activePlan.toUpperCase()}
                                        </p>
                                        <p className="pricing-card__price">
                                            ${price.toLocaleString()}
                                            <span
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: 'lighter',
                                                    marginTop: '-5px',
                                                    display: 'block',
                                                }}
                                            >
                                                monthly
                                            </span>
                                        </p>
                                        <p className="pricing-card__action ">
                                            <Link
                                                to={`${CREATE_ENTERPRISE}?r=${_id}`}
                                            >
                                                <Button
                                                    type={
                                                        hotPlan
                                                            ? 'primary'
                                                            : 'default'
                                                    }
                                                    style={{
                                                        width: '80%',
                                                        borderColor: '#11b86f',
                                                    }}
                                                >
                                                    Register
                                                </Button>
                                            </Link>
                                        </p>
                                        <div className="pricing-card__divider"></div>
                                        <span className="pricing-card__features">
                                            {applicationLimit} applications max
                                        </span>
                                        <span className="pricing-card__features">
                                            {taskLimit} challenges/tasks
                                        </span>
                                        <span className="pricing-card__features">
                                            {imagesPerContestant}{' '}
                                            images/contestant
                                        </span>
                                        <span className="pricing-card__features">
                                            {videosPerContestant}{' '}
                                            videos/contestant
                                        </span>

                                        <span className="pricing-card__features">
                                            Smart Setup Wizard
                                        </span>
                                        <span className="pricing-card__features">
                                            Quick support (Email)
                                        </span>
                                    </div>
                                </Col>
                            ),
                        )}
                </Row>
            </div>
            <FooterSection />
        </>
    );
};

export default PricingPlan;
