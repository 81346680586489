import React, { FC, ReactElement } from 'react';
import Wizard from './Wizard';
import { FormProvider } from './formProvider';

const CreateContest: FC = (): ReactElement => {
    return (
        <FormProvider>
            <Wizard />
        </FormProvider>
    );
};

export default CreateContest;
