import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Button, Col, Collapse, Row } from 'antd';
import { openAddEventImageModal } from '../../../../redux/actions/UI.action';
import AddContestImageModal from './addMedia/AddImage';
import { ENTERPRISE } from '../../../../constants';

const { Panel } = Collapse;

const ContestContent: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const contestState = useSelector((state: RootState) => state.contests);
    const { eventInfo } = contestState;
    const imageState = useSelector((state: RootState) => state.images);
    const usersInfo = useSelector((state: RootState) => state.auth.usersInfo);
    const { eventImages } = imageState;
    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { subdomainUIstyling } = stylingState;

    return (
        <Collapse expandIconPosition="right">
            <Panel
                header={
                    <Row justify="space-between">
                        <Col
                            md={6}
                            style={{
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                fontSize: '16px',
                            }}
                        >
                            {eventInfo?.eventTitle}
                        </Col>
                        <Col
                            md={3}
                            style={{ display: 'flex', justifyContent: 'end' }}
                        >
                            View Images
                        </Col>
                    </Row>
                }
                key="2"
            >
                <div className="contestcontent">
                    <div>
                        <p>{eventInfo?.eventDetails}</p>
                    </div>
                    {usersInfo?.role === ENTERPRISE && (
                        <Row justify="end" style={{ marginBottom: '15px' }}>
                            <Col>
                                <Button
                                    style={{
                                        backgroundColor: `${
                                            subdomainUIstyling[0]
                                                ? subdomainUIstyling[0]
                                                      .primaryColor
                                                : '#1bca8f'
                                        }`,
                                        color: '#fff',
                                        padding: '5px 15px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        dispatch(openAddEventImageModal())
                                    }
                                >
                                    Add Image
                                </Button>
                            </Col>
                        </Row>
                    )}

                    {eventImages.length > 0 && (
                        <div>
                            <Slide easing="ease">
                                {eventImages.map(({ url }, i) => (
                                    <div key={i} className="each-slide">
                                        <div
                                            style={{
                                                backgroundImage: `url(${url})`,
                                            }}
                                            key={i}
                                        ></div>
                                    </div>
                                ))}
                            </Slide>
                        </div>
                    )}
                </div>
            </Panel>
            <AddContestImageModal />
        </Collapse>
    );
};

export default ContestContent;
