import { Row, Col } from 'antd';
import React, { FC, ReactElement } from 'react';
import Loader from 'react-loader-spinner';
import { spinnerEnum } from '../types';

const OverlayLoader: FC<{ opacity: number; spinnerType: spinnerEnum }> = ({
    opacity,
    spinnerType,
}): ReactElement => {
    // BallTriangle
    return (
        <div
            className="overlay"
            style={{ backgroundColor: `rgba(255, 255, 255, ${opacity})` }}
        >
            <Row
                justify="center"
                align="middle"
                style={{ width: '100%', height: '100%' }}
            >
                <Col md={4}>
                    <Loader
                        type={
                            spinnerType ? spinnerType : spinnerEnum.BallTriangle
                        }
                        color="#1bca8f"
                        height={100}
                        width={100}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default OverlayLoader;
