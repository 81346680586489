import React, { FC, ReactElement, useState, FormEvent, useEffect } from 'react';
import { Row, Col, Button, Typography, message } from 'antd';
import { signupUserFormType, signUpPropType } from '../types';
import { handlerEvent } from '../../input/types';
import { Input } from '../../input';
import { Link, useHistory } from 'react-router-dom';
import { AUTH_ACTIONS, DOMAINPARTS, SIGN_IN } from '../../../routes';
import { firstCharToUpperCase } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { signupUser } from '../../../redux/actions/auth.action';
import { RootState } from '../../../redux/reducers';
import { clearError, clearMessage } from '../../../redux/actions/common.action';
import { AUTHREDIRECT } from '../../../constants';

const { Title } = Typography;

const Signup: FC<signUpPropType> = ({ primaryColor }): ReactElement => {
    const dispatch = useDispatch();
    const { push, replace } = useHistory();
    const authState = useSelector((state: RootState) => state.auth);
    const {
        loading,
        token,
        error: requestError,
        usersInfo,
        feedbackMsg,
    } = authState;

    const [formInputs, setFormInput] = useState<signupUserFormType>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
    });

    useEffect(() => {
        if (requestError) {
            return message.error(requestError);
        }

        if (feedbackMsg && usersInfo) {
            //clear form inputs
            setFormInput({
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                password: '',
            });

            return push(
                `${AUTH_ACTIONS}?mode=confirmNotice&email=${usersInfo.email}`,
            );
        }

        //if already signed in
        if (token) {
            return replace(AUTHREDIRECT(usersInfo?.role));
        }
        //clean up error
        return () => {
            dispatch(clearError());
            dispatch(clearMessage());
        };
    }, [token, usersInfo, push, requestError, dispatch, replace, feedbackMsg]);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInput({ ...formInputs, [name]: value });
    };
    const domainParts = window.location.host.split('.');

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const { password, firstName, lastName, email } = formInputs;

        if (!firstName || !lastName || !password || !email) {
            return message.error('Please fillout all fields');
        }

        let subDomain = '';
        if (domainParts.length >= DOMAINPARTS) {
            subDomain = domainParts[0];
        }

        const protocol = window.location.protocol;
        const domain = `${protocol}//${domainParts.join('.')}`;
        // console.log(domain);
        // console.log(subDomain);
        return dispatch(
            signupUser({
                ...formInputs,
                defaultAccount: subDomain,
                domain: domain,
            }),
        );
    };

    return (
        <form onSubmit={onFormSubmit} className="signup-form">
            <Row justify="center">
                <Title
                    className="form-title"
                    level={4}
                    style={{ color: `${primaryColor}` }}
                >
                    {firstCharToUpperCase('Register An Account')}
                </Title>
            </Row>

            <Row justify="center">
                <div className="text-muted">Create your free account now.</div>
            </Row>
            <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                <Col xs={24} md={12}>
                    <Input
                        name="firstName"
                        onChange={onInputChange}
                        hasLabel={true}
                        placeholder={firstCharToUpperCase('First Name')}
                        value={formInputs.firstName}
                        label={firstCharToUpperCase('Firstname')}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <Input
                        name="lastName"
                        onChange={onInputChange}
                        hasLabel={true}
                        placeholder={firstCharToUpperCase('Last Name')}
                        value={formInputs.lastName}
                        label={firstCharToUpperCase('Lastname')}
                    />
                </Col>
            </Row>

            <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                <Col xs={24}>
                    <Input
                        name="phoneNumber"
                        onChange={onInputChange}
                        inputType="number"
                        hasLabel={true}
                        placeholder={firstCharToUpperCase('Phone Number')}
                        value={formInputs.phoneNumber}
                        label={firstCharToUpperCase('Phonenumber')}
                    />
                </Col>
            </Row>
            <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                <Col xs={24}>
                    <Input
                        name="email"
                        onChange={onInputChange}
                        inputType="email"
                        hasLabel={true}
                        placeholder={firstCharToUpperCase('Email Address')}
                        value={formInputs.email}
                        label={firstCharToUpperCase('email')}
                    />
                </Col>
            </Row>
            <Row className="field-row">
                <Col xs={24}>
                    <Input
                        name="password"
                        onChange={onInputChange}
                        inputType="password"
                        hasLabel={true}
                        placeholder={firstCharToUpperCase('Password')}
                        value={formInputs.password}
                        label={firstCharToUpperCase('password')}
                    />
                </Col>
            </Row>

            <Row className="field-row">
                <Col span={24}>
                    <Button
                        htmlType="submit"
                        className="custom-btn"
                        // type="primary"
                        loading={loading}
                        style={{
                            backgroundColor: `${
                                primaryColor ? primaryColor : '#1bca8f'
                            }`,
                            color: 'white',
                        }}

                        // style={{backgroundColor: `${primaryColor}`}}
                    >
                        {firstCharToUpperCase('Register')}
                    </Button>
                </Col>
            </Row>
            <div className="extras">
                <small>
                    Already have an account?
                    <Link to={SIGN_IN}>
                        <span style={{ color: `${primaryColor}` }}>
                            {' '}
                            {firstCharToUpperCase('Sign In')}
                        </span>
                    </Link>
                </small>
            </div>
        </form>
    );
};

export default Signup;
