import {
    AimOutlined,
    BulbFilled,
    CaretDownOutlined,
    MinusOutlined,
    DeliveredProcedureOutlined,
    EditOutlined,
    HomeFilled,
    MailFilled,
    PhoneFilled,
    TeamOutlined,
} from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Navigation } from '../../components/navigation';
import { CREATE_ENTERPRISE, PRICING_PLAN } from '../../routes';
// import ReactPlayer from 'react-player';
// import votingSvg from '../../assets/voting.svg';
import imageHero from './img/mi2.jpg';
import ContactForm from './ContactForm';
import FooterSection from './FooterSection';

const EnterpriseLandingPage: FC = (): ReactElement => {
    return (
        <div className="enterprise-page">
            <Row>
                <Col xs={24}>
                    <Navigation />
                </Col>
            </Row>
            <Row className="">
                {/* <ReactPlayer
                    url={'https://www.youtube.com/watch?v=TClpNTMJ4Vc'}
                    playing
                    loop
                    muted
                    width="100vw"
                    height="95vh"
                /> */}
                <div className="overlay-bg1">
                    <Col
                        md={12}
                        className="enterprise-page__bg--left"
                        // style={{
                        //     marginTop: '40vh',
                        //     color: 'white',
                        //     marginLeft: '30px',
                        // }}
                    >
                        <p className="enterprise-page__bg--left-title">
                            Take your Pageant to the Next Level.
                        </p>
                        <p className="enterprise-page__bg--left-arrow">
                            Get access to a cutting edge web platform that
                            handles every stage from contestant application,
                            tasks, challenges and voting. Promote and build your
                            brand with your own customized domain.
                        </p>
                        <div className="enterprise-page__bg--left-btn">
                            {/* <Link to={PRICING_PLAN}>
                                <Button type="primary">START A CONTEST</Button>
                            </Link> */}

                            <Link to={`${CREATE_ENTERPRISE}?m=free`}>
                                <Button type="primary">
                                    CREATE A FREE CONTEST
                                </Button>
                            </Link>
                        </div>
                    </Col>
                </div>

                {/* <Col md={10}>
                    {[1, 2, 26, 33, 3, 37].map((i) => (
                        <p
                            key={i}
                            style={{
                                color: '#fff',
                                fontSize: '18px',
                                margin: '10px',
                            }}
                        >
                            PLACEHOLDER BACKGROUND IMAGE
                        </p>
                    ))}
                </Col> */}
            </Row>
            <Row className="enterprise-page__points">
                <Col
                    xs={24}
                    sm={12}
                    lg={6}
                    className="enterprise-page__points--box"
                >
                    <div>
                        <span className="enterprise-page__points--box-icon">
                            <AimOutlined
                                style={{ color: '#fff', fontSize: '35px' }}
                            />
                        </span>
                        <p className="enterprise-page__points--box-title">
                            Personalize url
                        </p>
                        <p>Get a sub-domain as an organization website</p>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    lg={6}
                    className="enterprise-page__points--box"
                >
                    <div>
                        <span className="enterprise-page__points--box-icon">
                            <TeamOutlined
                                style={{ color: '#fff', fontSize: '35px' }}
                            />
                        </span>
                        <p className="enterprise-page__points--box-title">
                            Audience Engagement
                        </p>
                        <p>Full audience engagement all through the contest</p>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    lg={6}
                    className="enterprise-page__points--box"
                >
                    <div>
                        <span className="enterprise-page__points--box-icon">
                            <EditOutlined
                                style={{ color: '#fff', fontSize: '35px' }}
                            />
                        </span>
                        <p className="enterprise-page__points--box-title">
                            Excellent reporting
                        </p>
                        <p>View real-time logs of all transactions and votes</p>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    lg={6}
                    className="enterprise-page__points--box"
                >
                    <div>
                        <span className="enterprise-page__points--box-icon">
                            <DeliveredProcedureOutlined
                                style={{ color: '#fff', fontSize: '35px' }}
                            />
                        </span>
                        <p className="enterprise-page__points--box-title">
                            Easy voting
                        </p>
                        <p>Quick and smooth voting system</p>
                    </div>
                </Col>
            </Row>
            <Row className="enterprise-page__about">
                <Col xs={24} sm={12}>
                    <div className="enterprise-page__about--left">
                        <p className="enterprise-page__about--left-title">
                            About Us{' '}
                        </p>
                        <p className="enterprise-page__about--left-text">
                            <span className="enterprise-page__underline">
                                GET TO
                            </span>
                            KNOW US
                        </p>
                        <p
                            className="enterprise-page__about--left-content"
                            style={{ fontSize: '15px' }}
                        >
                            Pageant Africa is a service provided by 1024
                            Concepts Limited, an Africa focused business
                            Development Company that specializes in providing
                            tech-enabled platforms that promote the integration
                            of people, communities, services and products across
                            value chains. We are headquartered in Lagos, Nigeria
                        </p>
                        <p
                            className="enterprise-page__about--left-content"
                            style={{ fontSize: '15px' }}
                        >
                            We know there are many challenges to running a
                            Pageant and we have developed this platform to meet
                            several of these challenges (not all though, but we
                            are getting there). The Pageant Africa platform will
                            serve you throughout the contest. The app will:
                        </p>
                        <p>
                            <MinusOutlined
                                style={{
                                    fontSize: '12px',
                                    marginRight: '10px',
                                }}
                            />
                            <span>
                                Let you determine whether you want to receive
                                applications on the platform or add contestants
                                by yourself (or do both)
                            </span>
                        </p>
                        <p>
                            <MinusOutlined
                                style={{
                                    fontSize: '12px',
                                    marginRight: '10px',
                                }}
                            />
                            <span>Enable you create tasks and challenges</span>
                        </p>
                        <p>
                            <MinusOutlined
                                style={{
                                    fontSize: '12px',
                                    marginRight: '10px',
                                }}
                            />
                            <span>
                                Allow you review participants at every stage and
                                decide who goes forward (or not) at the touch of
                                a button.
                            </span>
                        </p>
                        <p>
                            <MinusOutlined
                                style={{
                                    fontSize: '12px',
                                    marginRight: '10px',
                                }}
                            />
                            <span>
                                Automatically notify participants and viewers of
                                new updates and uploads (acceptance, evictions,
                                new task etc)
                            </span>
                        </p>
                        <p>
                            <MinusOutlined
                                style={{
                                    fontSize: '12px',
                                    marginRight: '10px',
                                }}
                            />

                            <span>
                                Give your audience opportunity to vote during
                                your pageant from anywhere in the world as long
                                as they have a MasterCard or Visa card.
                            </span>
                        </p>
                        {/* <Link to={ABOUT_US}>
                            <Button
                                // type="dashed"
                                style={{
                                    backgroundColor: '#0e0e0e',
                                    color: '#fff',
                                    height: '38px',
                                    padding: '5px 30px',
                                }}
                                className="enterprise-page__about--left-btn"
                            >
                                Continue Reading
                            </Button>
                        </Link> */}
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <img
                        src={imageHero}
                        style={{
                            width: '100%',
                            height: '90vh',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.19)',
                            marginTop: '15vh',
                            objectFit: 'cover',
                        }}
                        alt="hero"
                    />
                </Col>
            </Row>
            <Row className="enterprise-page__detail">
                <Col xs={24}>
                    <h3 className="enterprise-page__detail--title">
                        <span className="enterprise-page__underline">
                            How we
                        </span>
                        make a difference
                    </h3>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Fast pay-out
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    We process payment every week.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Excellent Support
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    We provide support if the need arise
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Marketing Support
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    We engage on various marketing solutions to
                                    drive revenue.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Secure payment
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    Our system does not store user payment
                                    details.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Smart Engagement
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    Create task or challenges for your
                                    contestants
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm={12}>
                    <div className="enterprise-page__detail--benefits">
                        <Row>
                            <Col xs={3}>
                                <div className="enterprise-page__detail--benefits-icon">
                                    <BulbFilled
                                        style={{
                                            fontSize: '20px',
                                            color: '#11b86f',
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={20}
                                className="enterprise-page__detail--benefits-content"
                            >
                                <p className="enterprise-page__detail--benefits-content-title">
                                    Transparent Reporting
                                </p>
                                <p className="enterprise-page__detail--benefits-content-text">
                                    View real-time report logs on all
                                    transactions. Monitor sales as it happens.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className="enterprise-page__steps steps__voting">
                <Col xs={24}>
                    <h3
                        className="enterprise-page__detail--title"
                        style={{ color: 'white' }}
                    >
                        <span className="enterprise-page__underline">
                            Four steps
                        </span>
                        to get you started
                    </h3>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                    <div className="enterprise-page__steps-card">
                        <div className="enterprise-page__steps-card-num">
                            <span className="enterprise-page__steps-card-num-main">
                                1
                            </span>
                            <span className="enterprise-page__steps-card-num-clone">
                                1
                            </span>
                        </div>
                        <p
                            className="enterprise-page__steps-card-title"
                            style={{ color: 'black' }}
                        >
                            SELECT A PLAN
                        </p>
                        <p
                            className="enterprise-page__steps-card-text"
                            style={{ color: 'white' }}
                        >
                            Select a paid plan that best suit your needs.
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                    <div className="enterprise-page__steps-card">
                        <div className="enterprise-page__steps-card-num">
                            <span className="enterprise-page__steps-card-num-main">
                                2
                            </span>
                            <span className="enterprise-page__steps-card-num-clone">
                                2
                            </span>
                        </div>
                        <p
                            className="enterprise-page__steps-card-title"
                            style={{ color: 'black' }}
                        >
                            register
                        </p>
                        <p
                            className="enterprise-page__steps-card-text"
                            style={{ color: 'white' }}
                        >
                            Fill in required details and select your preferred
                            sub domain to get started.
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                    <div className="enterprise-page__steps-card">
                        <div className="enterprise-page__steps-card-num">
                            <span className="enterprise-page__steps-card-num-main">
                                3
                            </span>
                            <span className="enterprise-page__steps-card-num-clone">
                                3
                            </span>
                        </div>
                        <p
                            className="enterprise-page__steps-card-title"
                            style={{ color: 'black' }}
                        >
                            Sub domain mapping
                        </p>
                        <p
                            className="enterprise-page__steps-card-text"
                            style={{ color: 'white' }}
                        >
                            Allow sub domain mapping to propagate fully within a
                            short time.
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                    <div className="enterprise-page__steps-card">
                        <div className="enterprise-page__steps-card-num enterprise-page__steps-card-last ">
                            <span className="enterprise-page__steps-card-num-main">
                                4
                            </span>
                            <span className="enterprise-page__steps-card-num-clone">
                                4
                            </span>
                        </div>
                        <p
                            className="enterprise-page__steps-card-title"
                            style={{ color: 'black' }}
                        >
                            Create a contest
                        </p>
                        <p
                            className="enterprise-page__steps-card-text"
                            style={{ color: 'white' }}
                        >
                            Start your first contest and engage your audience.
                        </p>
                        <CaretDownOutlined
                            className="enterprise-page__steps-card-last-icon"
                            style={{
                                fontSize: '28px',
                                color: '#11b86f',
                                marginBottom: '10px',
                            }}
                        />
                        <div className="enterprise-page__steps-card-last-btn">
                            <Link to={PRICING_PLAN}>
                                <Button>GET STARTED</Button>
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="enterprise-page__contact">
                <Col xs={24}>
                    <h3 className="enterprise-page__detail--title">
                        <span className="enterprise-page__underline">
                            REACH
                        </span>
                        US
                    </h3>
                </Col>

                <Col md={12}>
                    <ContactForm />
                </Col>
                <Col xs={0} md={12}>
                    <div className="main-page__footer-box">
                        <h2 className="main-page__footer-box--title">
                            CONTACTS
                        </h2>
                        <div className="main-page__footer-box--content">
                            <HomeFilled />
                            <span className="main-page__footer-box--content-text">
                                83, Community Road, Akoka Yaba, Lagos
                            </span>
                        </div>
                        <div className="main-page__footer-box--content">
                            <PhoneFilled />
                            <a
                                className="main-page__footer-box--content-text"
                                href="tel:+234 802 956 4392"
                            >
                                +234 802 956 4392
                            </a>
                        </div>
                        <div className="main-page__footer-box--content">
                            <MailFilled />
                            <a
                                className="main-page__footer-box--content-text"
                                href="mailto:info@pageant.africa"
                            >
                                info@pageant.africa
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
            <FooterSection />
        </div>
    );
};

export default EnterpriseLandingPage;
