import React, { FC, ReactElement, useState, FormEvent, useEffect } from 'react';
import { Row, Col, Button, Typography, message, notification } from 'antd';
import { loginUserFormType, signInPropType } from '../types';
import { handlerEvent } from '../../input/types';
import { Input } from '../../input';
import loginIcon from '../../../assets/loginIcon.svg';
import { Link, useHistory } from 'react-router-dom';
import { DOMAINPARTS, FORGOT_PASSWORD, SIGN_UP } from '../../../routes';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../redux/actions/auth.action';
import { RootState } from '../../../redux/reducers';
import { AUTHREDIRECT } from '../../../constants';
import { clearError } from '../../../redux/actions/common.action';
import { socket } from '../../../UIProvider';

const { Title } = Typography;

const Signin: FC<signInPropType> = ({ primaryColor }): ReactElement => {
    const { push, location } = useHistory();
    const dispatch = useDispatch();
    const authState = useSelector((state: RootState) => state.auth);
    const { loading, token, error: requestError, usersInfo } = authState;

    const [formInputs, setFormInput] = useState<loginUserFormType>({
        email: '',
        password: '',
    });

    useEffect(() => {
        if (requestError) {
            return notification.error({
                message: requestError,
            });
        }
        if (token) {
            //clear form inputs
            setFormInput({
                email: '',
                password: '',
            });

            //connect socket on( re-login)
            socket.connect();

            //if user is enterprise and logins in firsttime replace to wizard

            // get user last know path
            const { from }: any = location.state || {
                from: { pathname: AUTHREDIRECT(usersInfo?.role) },
            };

            //push to dashboard
            /**
             * @todo
             * replace(from) to be used once landing page is fully done
             */
            return push(from);
        }
        //clean up error
        return () => {
            dispatch(clearError());
        };
    }, [token, push, requestError, dispatch, usersInfo, location]);

    const onInputChange = (e: handlerEvent) => {
        const { name, value } = e.target;
        setFormInput({ ...formInputs, [name]: value });
    };

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const { password, email } = formInputs;

        if (!password || !email) {
            return message.error('Please fillout all fields');
        }

        const domainParts = window.location.host;
        const protocol = window.location.protocol;
        const domain = `${protocol}//${domainParts}`;

        let subDomain = '';
        const dnParts = domainParts.split('.');
        if (dnParts.length === DOMAINPARTS) {
            subDomain = dnParts[0];
        }
        //dispatch payload
        return dispatch(
            loginUser({ ...formInputs, domain: domain, subDomain }),
        );
    };

    return (
        <form onSubmit={onFormSubmit} className="signup-form">
            <Row justify="center">
                <Title
                    className="form-title"
                    level={4}
                    style={{ color: `${primaryColor}` }}
                >
                    Welcome Back !
                </Title>
            </Row>
            <Row justify="center">
                <div className="text-muted">Sign in to continue.</div>
            </Row>

            <Row gutter={{ sm: 0, md: 20 }} className="field-row">
                <Col xs={24}>
                    <Input
                        name="email"
                        onChange={onInputChange}
                        inputType="email"
                        hasLabel={true}
                        placeholder="Enter email Address"
                        value={formInputs.email}
                        label="Email"
                    />
                </Col>
            </Row>
            <Row className="field-row">
                <Col xs={24}>
                    <Input
                        name="password"
                        onChange={onInputChange}
                        inputType="password"
                        hasLabel={true}
                        placeholder="Enter password"
                        value={formInputs.password}
                        label="Password"
                    />
                </Col>
            </Row>
            <Row className="field-row" justify="end">
                <Link to={FORGOT_PASSWORD} className="forgot-password">
                    <img
                        src={loginIcon}
                        style={{ color: `${primaryColor}` }}
                        alt="logo"
                    />
                    <span style={{ color: `${primaryColor}` }}>
                        Forgot Password?{' '}
                    </span>
                </Link>
            </Row>
            <Row className="field-row">
                <Col span={24}>
                    <Button
                        htmlType="submit"
                        className="custom-btn"
                        // type="primary"
                        loading={loading}
                        style={{
                            backgroundColor: `${
                                primaryColor ? primaryColor : '#1bca8f'
                            }`,
                            color: 'white',
                        }}
                    >
                        Log In
                    </Button>
                </Col>
            </Row>

            <div className="extras text-muted">
                <small>
                    {`Don't`} have an account?
                    <Link to={SIGN_UP}>
                        <span style={{ color: `${primaryColor}` }}>
                            {' '}
                            Register
                        </span>
                    </Link>
                </small>
            </div>
        </form>
    );
};

export default Signin;
