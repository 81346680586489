import {
    FETCH_PENDING_IMAGES_FAILED,
    FETCH_PENDING_IMAGES_INIT,
    FETCH_PENDING_IMAGES_SUCCESS,
    FETCH_PENDING_VIDEOS_FAILED,
    FETCH_PENDING_VIDEOS_INIT,
    FETCH_PENDING_VIDEOS_SUCCESS,
    PENDING_IMAGES_CLEAR,
    PUBLISH_PENDING_IMAGES_FAILED,
    PUBLISH_PENDING_IMAGES_INIT,
    PUBLISH_PENDING_IMAGES_SUCCESS,
    PUBLISH_PENDING_VIDEOS_FAILED,
    PUBLISH_PENDING_VIDEOS_INIT,
    PUBLISH_PENDING_VIDEOS_SUCCESS,
} from '../types/media.type';

type MEDIA_INIT_STATE = {
    pendingImages: any[];
    pendingVideos: any[];
    loading: boolean;
    publishingImage: boolean;
    publishingVideo: boolean;

    feedbackMsg: string | null;
    publishFeedback: string | null;
    error: string | null;
};

const MEDIA_INITIAL_STATE: MEDIA_INIT_STATE = {
    pendingImages: [],
    pendingVideos: [],
    loading: false,
    feedbackMsg: null,
    publishingImage: false,
    publishingVideo: false,
    publishFeedback: null,
    error: null,
};

const MediaReducer = (
    state = MEDIA_INITIAL_STATE,
    action: any,
): MEDIA_INIT_STATE => {
    switch (action.type) {
        case FETCH_PENDING_IMAGES_INIT:
        case FETCH_PENDING_VIDEOS_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case FETCH_PENDING_IMAGES_SUCCESS: {
            return {
                ...state,
                pendingImages: action.payload,
                loading: false,
                error: null,
            };
        }

        case FETCH_PENDING_VIDEOS_SUCCESS: {
            return {
                ...state,
                pendingVideos: action.payload,
                loading: false,
                error: null,
            };
        }

        case FETCH_PENDING_IMAGES_FAILED:
        case FETCH_PENDING_VIDEOS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case PUBLISH_PENDING_IMAGES_INIT: {
            return {
                ...state,
                publishingImage: true,
                error: null,
            };
        }
        case PUBLISH_PENDING_IMAGES_SUCCESS: {
            return {
                ...state,
                publishingImage: false,
                publishFeedback: 'done',
                error: null,
            };
        }

        case PUBLISH_PENDING_IMAGES_FAILED: {
            return {
                ...state,
                publishingImage: false,
                publishFeedback: 'error',
                error: action.payload,
            };
        }

        case PUBLISH_PENDING_VIDEOS_INIT: {
            return {
                ...state,
                publishingVideo: true,
                error: null,
            };
        }
        case PUBLISH_PENDING_VIDEOS_SUCCESS: {
            return {
                ...state,
                publishingVideo: false,
                publishFeedback: 'done',
                error: null,
            };
        }

        case PUBLISH_PENDING_VIDEOS_FAILED: {
            return {
                ...state,
                publishingVideo: false,
                publishFeedback: 'error',
                error: action.payload,
            };
        }

        case PENDING_IMAGES_CLEAR: {
            return {
                ...state,
                feedbackMsg: null,
                publishingImage: false,
                publishingVideo: false,
                publishFeedback: null,
                error: null,
            };
        }
        default:
            return state;
    }
};

export default MediaReducer;
