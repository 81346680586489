import { Button, Col, Row, Typography, Steps as AntDSteps } from 'antd';
import React, { FC, ReactElement, useContext, useEffect } from 'react';
import { useState } from 'react';
import {
    NavigationComponentProps,
    Step,
    Steps,
    StepsConfig,
} from 'react-step-builder';
import { FormProvider } from './formProvider';
import { FormContext } from './formProvider/FormContext';
import {
    LoadingStep,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    // Step6,
} from './formSteps';

const { Title } = Typography;

const Addtask: FC = (): ReactElement => {
    const stepRender = [
        {
            title: 'Instructions',
            component: Step1,
        },
        {
            title: 'Select Contest',
            component: Step2,
        },
        {
            title: 'Dates',
            component: Step3,
        },
        {
            title: 'Title',
            component: Step4,
        },
        {
            title: 'Description',
            component: Step5,
        },
        // {
        //     title: 'Image and Video',
        //     component: Step6,
        // },
        {
            title: 'Finish',
            component: LoadingStep,
        },
    ];

    const Navigation = (props: NavigationComponentProps) => {
        const { formInputs, onFormSubmit } = useContext(FormContext);
        const [disabled, setDisabled] = useState(true);
        const [submit, setSubmit] = useState(true);

        useEffect(() => {
            if (formInputs && formInputs.event) {
                setDisabled(false);
                const { endDate, startDate, event, task, title } = formInputs;

                if (!endDate || !event || !startDate || !task || !title) {
                    setSubmit(true);
                } else {
                    setSubmit(false);
                }
            }
        }, [formInputs]);

        const submitBtnHandler = () => {
            if (!onFormSubmit) return;
            onFormSubmit();
            props.next();
        };

        return (
            <div className="addTask__step">
                {props.current !== 1 && props.size !== props.current && (
                    <Row
                        gutter={{ xs: 10, md: 20 }}
                        justify="space-around"
                        className="addTask__step--container"
                    >
                        <Col xs={10} md={5}>
                            <Button onClick={props.prev}>
                                {props.current === 2 ? 'Back' : 'Previous'}
                            </Button>
                        </Col>
                        <Col xs={14} md={5}>
                            {props.current === props.size - 1 ? (
                                <Button
                                    type="primary"
                                    onClick={submitBtnHandler}
                                    disabled={submit}
                                >
                                    Create Task
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    onClick={props.next}
                                    disabled={disabled}
                                >
                                    Next
                                </Button>
                            )}
                        </Col>
                    </Row>
                )}
            </div>
        );
    };

    const Before = (props: NavigationComponentProps) => {
        /**
         * @todo AntDSteps not to show in mobile mode
         */
        return (
            <>
                <AntDSteps current={props.current - 1} progressDot>
                    {stepRender.map(({ title }, i: number) => (
                        <AntDSteps.Step key={i} title={title} />
                    ))}
                </AntDSteps>
                ,
                {props.size !== props.current ? (
                    <Title level={3} style={{ textAlign: 'center' }}>
                        Create Task
                    </Title>
                ) : (
                    <Title level={3} style={{ textAlign: 'center' }}>
                        Creating task please wait ....
                    </Title>
                )}
            </>
        );
    };

    // const After = () => {
    //     return (
    //         <span>
    //             This component will be rendered after the Step components in
    //             every step
    //         </span>
    //     );
    // };

    const config: StepsConfig = {
        before: Before,
        // after: After,
        navigation: {
            component: Navigation,
            location: 'after',
        },
    };

    return (
        <FormProvider>
            <div className="addtask">
                <Steps config={config}>
                    {stepRender.map(({ title, component }, i: number) => (
                        <Step key={i} title={title} component={component} />
                    ))}
                </Steps>
            </div>
        </FormProvider>
    );
};

export default Addtask;
