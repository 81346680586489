import { fetchContestVideo, fetchVidMediaTypeDb } from '../../types';
import { CLEAR_MESSAGE, CLEAR_ERROR } from '../types/common.type';
import {
    FETCH_CONTESTANT_VIDEOS_SUCCESS,
    CLEAR_CONTESTANT_DATAS,
    ADD_CONTESTANT_VIDEO_SUCCESS,
    ADD_CONTESTANT_VIDEO_BY_USER_SUCCESS,
    ADD_CONTESTANT_VIDEO_INIT,
    FETCH_CONTESTANT_VIDEOS_INIT,
    ADD_CONTESTANT_VIDEO_FAILED,
    FETCH_CONTESTANT_VIDEOS_FAILED,
} from '../types/contestant.type';
import {
    ADD_CONTEST_VIDEO_FAILED,
    ADD_CONTEST_VIDEO_INIT,
    ADD_CONTEST_VIDEO_SUCCESS,
    FETCH_CONTEST_VIDEOS_FAILED,
    FETCH_CONTEST_VIDEOS_INIT,
    FETCH_CONTEST_VIDEOS_SUCCESS,
} from '../types/contests.type';

type VIDEOS_INIT_STATE = {
    contestantVideos: fetchVidMediaTypeDb[];
    eventVideos: fetchContestVideo[];
    loading: boolean;
    feedbackMsg: string | null;
    error: string | null;
};

const VIDEOS_INITIAL_STATE: VIDEOS_INIT_STATE = {
    contestantVideos: [],
    loading: false,
    feedbackMsg: null,
    error: null,
    eventVideos: [],
};

const videosReducer = (
    state = VIDEOS_INITIAL_STATE,
    action: any,
): VIDEOS_INIT_STATE => {
    switch (action.type) {
        case ADD_CONTESTANT_VIDEO_INIT:
        case FETCH_CONTESTANT_VIDEOS_INIT:
        case ADD_CONTEST_VIDEO_INIT:
        case FETCH_CONTEST_VIDEOS_INIT: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case ADD_CONTESTANT_VIDEO_FAILED:
        case FETCH_CONTESTANT_VIDEOS_FAILED:
        case ADD_CONTEST_VIDEO_FAILED:
        case FETCH_CONTEST_VIDEOS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        case FETCH_CONTESTANT_VIDEOS_SUCCESS: {
            const videos = [...action.payload].reverse();

            return {
                ...state,
                contestantVideos: videos,
                loading: false,
                error: null,
            };
        }

        case ADD_CONTESTANT_VIDEO_SUCCESS: {
            const newContestantVideos = [
                action.payload,
                ...state.contestantVideos,
            ].reverse();
            return {
                ...state,
                feedbackMsg: 'Video added successfully',
                contestantVideos: newContestantVideos,
                loading: false,
                error: null,
            };
        }

        case FETCH_CONTEST_VIDEOS_SUCCESS: {
            return {
                ...state,
                eventVideos: action.payload,
                loading: false,
                error: null,
            };
        }
        case ADD_CONTEST_VIDEO_SUCCESS: {
            return {
                ...state,
                eventVideos: [...state.eventVideos, action.payload],
                loading: false,
                error: null,
                feedbackMsg: 'uploaded successfuly!!!',
            };
        }
        case ADD_CONTESTANT_VIDEO_BY_USER_SUCCESS: {
            return {
                ...state,
                feedbackMsg: 'Video added successfully',
                loading: false,
                error: null,
            };
        }
        case CLEAR_CONTESTANT_DATAS: {
            return {
                ...state,
                contestantVideos: [],
            };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...state,
                feedbackMsg: null,
            };
        }
        default:
            return state;
    }
};

export default videosReducer;
