import React, { ReactElement } from 'react';
import { columnType, nameRenderType } from '../types';
import { Tag } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';

const statusRender = (): ReactElement => {
    return (
        <span>
            <Tag className="payment-success">Success</Tag>
        </span>
    );
};

const purposeRender = (): ReactElement => (
    <span className="purpose-render">
        <span>Voted</span>
        <ArrowUpOutlined className="arr-down " />
    </span>
);

const voteRender = (val: string): ReactElement => (
    <span className="amount-col">{val.toLocaleString()}</span>
);

const dateRender = (val: string): ReactElement => (
    <span className="date-column">{new Date(val).toDateString()}</span>
);
const userRender = (val: nameRenderType): ReactElement => (
    <span className="date-column">{val.firstName}</span>
);

const emailRender = (val: nameRenderType): ReactElement => (
    <span className="date-column">{val.email}</span>
);
const contestantRender = (val: nameRenderType): ReactElement => (
    <span className="date-column">{val.fullName}</span>
);

const TableColumn: columnType = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: dateRender,
    },
    {
        title: 'User',
        dataIndex: 'userRef',
        render: userRender,
    },
    {
        title: 'Email',
        dataIndex: 'userRef',
        render: emailRender,
    },
    {
        title: 'Contestant',
        dataIndex: 'contestant',
        render: contestantRender,
    },
    {
        title: 'Votes',
        dataIndex: 'numberOfVote',
        render: voteRender,
    },
    {
        title: 'Status',
        key: 'isSuccess',
        dataIndex: 'isSuccess',
        render: statusRender,
    },
    {
        // title: '',
        key: 'purpose',
        dataIndex: 'purpose',
        render: purposeRender,
    },
];

export default TableColumn;
