import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { Layout, Tag } from 'antd';
import { Sidebar } from '../../components/sidebar';
import { useWindowWidth } from '@react-hook/window-size';
import { Avatar } from '../../components/avatar';
import { Logo } from '../../components/logo';

import { adminRoutes, enterpriseRoutes, userRoutes } from './dash-routes';
import { BellOutlined, MenuOutlined } from '@ant-design/icons';

import './style.scss';
import { ACCOUNT_SETUP, PROFILE_BUY_MORE } from '../../routes';
import { RootState } from '../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { routeType } from './types';
import { ADMIN, ENTERPRISE, USER } from '../../constants';
import HomeDrawer from './notifications/homeDrawer';
import { openNotificationModal } from '../../redux/actions/UI.action';
import {
    NOTIFICATION_FETCH_INIT,
    NOTIFICATION_FETCH_SUCCESS,
    CLEAR_NOTIFICATION,
} from '../../redux/types/notification.type';
import { fetchNotificationTypeDb, userTypeDb } from '../../types';
import DetailsDrawer from './notifications/detailsDrawer';
import moment from 'moment';
import {
    notificationUnviewed,
    notificationViewed,
} from '../../redux/actions/auth.action';
import { AUTH_USER } from '../../redux/types/auth.type';
import LoadingSpinner from '../../components/spinners/loadingSpinner';
import { socket } from '../../UIProvider';

const { Header, Content } = Layout;

const Dashboard: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const { replace } = useHistory();

    const [isMobile, setIsMobile] = useState(false);
    const [routes, setRoutes] = useState<routeType | null>(null);

    const [collapsed, setCollapsed] = useState<boolean>(false);
    const windowWidth = useWindowWidth();
    const usersInfo = useSelector((state: RootState) => state.auth.usersInfo);
    const isNotificationNew = useSelector(
        (state: RootState) => state.auth.isNotificationNew,
    );
    const notificationsList = useSelector(
        (state: RootState) => state.notifications.notificationsList,
    );

    const stylingState = useSelector(
        (state: RootState) => state.subdomainStyling,
    );
    const { loadingUI, subdomainUIstyling } = stylingState;

    useEffect(() => {
        if (
            usersInfo?.role === ENTERPRISE &&
            subdomainUIstyling.length > 0 &&
            !subdomainUIstyling[0].setUpCompleted
        ) {
            replace(ACCOUNT_SETUP);
        }
    }, [subdomainUIstyling, replace, usersInfo]);

    useEffect(() => {
        // const domainParts = window.location.host.split('.');
        // alert(domainParts.length)

        // if (domainParts.length >= DOMAINPARTS) {
        //     const subDomain = domainParts[0];

        //     dispatch(subdomainUI(subDomain));
        // } else {
        //     dispatch(subdomainUI());
        // }
        if (notificationsList.length >= 1 && usersInfo?.lastConnected) {
            const isNew =
                moment(notificationsList[0].createdAt) >
                moment(usersInfo?.lastConnected);

            if (isNew) {
                dispatch(notificationUnviewed());
            } else {
                dispatch(notificationViewed());
            }
        }
    }, [notificationsList, usersInfo, dispatch]);

    useEffect(() => {
        if (usersInfo) {
            if (usersInfo.role === ADMIN) {
                return setRoutes(adminRoutes);
            } else if (usersInfo.role === ENTERPRISE) {
                return setRoutes(enterpriseRoutes);
            } else {
                return setRoutes(userRoutes);
            }
        }
    }, [usersInfo]);

    useEffect(() => {
        //run on mount only
        socket.emit('currentuser', { userId: usersInfo?._id });
        socket.on(`user-${usersInfo?._id}`, function (data: userTypeDb) {
            //dispatch data
            dispatch({ type: AUTH_USER, payload: data });
        });
        return () => {
            socket.removeAllListeners(`user-${usersInfo?._id}`);
        };
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        dispatch({ type: NOTIFICATION_FETCH_INIT });
        // console.log('---am called');

        // socket.on('connect', function () {
        //     console.log('Connected');
        // });

        // socket.on('disconnect', function () {
        //     console.log('disconnected');
        //     axios.post('/auth/logout', { userId: usersInfo?._id });
        // });
        socket.emit('notifications', { userId: usersInfo?._id });
        socket.on(`notify-${usersInfo?._id}`, function (
            data: fetchNotificationTypeDb[],
        ) {
            // console.log('---am emitetd');
            //dispatch data
            dispatch({ type: NOTIFICATION_FETCH_SUCCESS, payload: data });
        });

        //clean up listener to avoid memory leak
        return () => {
            socket.removeAllListeners(`notify-${usersInfo?._id}`);
            dispatch({ type: CLEAR_NOTIFICATION });
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (windowWidth <= 768) {
            setIsMobile(true);
            setCollapsed(true);
        } else {
            setIsMobile(false);
            setCollapsed(false);
        }
    }, [windowWidth]);

    const toggleSidebar = () => {
        setCollapsed((prevState) => !prevState);
    };

    return loadingUI ? (
        <LoadingSpinner />
    ) : (
        <Layout
            className="dashboard-container"
            style={{ height: !collapsed && isMobile ? '100vh' : '100%' }}
        >
            <Header
                className="dashboard-header"
                style={{
                    padding: '0px 10px',
                    boxShadow: '0 4px 8px rgb(0 0 0 / 19%)',

                    backgroundImage: `${
                        subdomainUIstyling['0']
                            ? `linear-gradient(to right top, ${subdomainUIstyling['0']['primaryColor']}, ${subdomainUIstyling['0']['secondaryColor']})`
                            : 'linear-gradient(to right top,#11b56c,#12ba75,#15c07e,#17c586,#1bca8f)'
                    }`,
                }}
            >
                <div className="head_flex" style={{ width: '20%' }}>
                    <MenuOutlined
                        data-testid="mobile-icon"
                        className="dashboard-header__menu-icon"
                        onClick={toggleSidebar}
                    />
                    <Logo />
                </div>
                <div>
                    {usersInfo?.role === USER && (
                        <>
                            {usersInfo?.walletBalance === 0 ? (
                                <Link to={PROFILE_BUY_MORE}>
                                    <Tag className="dashboard--buyvote">
                                        Buy Votes
                                    </Tag>
                                </Link>
                            ) : (
                                <Link to={PROFILE_BUY_MORE}>
                                    <Tag
                                        color="#1BCA8F"
                                        className="dashboard--units"
                                    >
                                        {`${
                                            usersInfo
                                                ? usersInfo.walletBalance
                                                : '0'
                                        } votes`}
                                    </Tag>
                                </Link>
                            )}
                        </>
                    )}
                    {usersInfo?.role === USER && (
                        <div className="bellWrapper">
                            <BellOutlined
                                style={{
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    margin: '0px 10px',
                                }}
                                onClick={() => {
                                    dispatch(openNotificationModal());
                                    dispatch(notificationViewed());
                                }}
                            />
                            {isNotificationNew && (
                                <div className="bellWrapper__active "></div>
                            )}
                        </div>
                    )}
                    <Avatar />
                </div>
            </Header>
            <Sidebar
                isMobile={isMobile}
                collapsed={collapsed}
                toggleSidebar={toggleSidebar}
                routes={routes}
                companyImage={
                    subdomainUIstyling['0'] &&
                    subdomainUIstyling['0']['bannerImage']
                }
                primaryColor={
                    subdomainUIstyling['0'] &&
                    subdomainUIstyling['0']['primaryColor']
                }
            />

            <Layout
                className="dashboard-layout"
                style={{
                    backgroundImage:
                        subdomainUIstyling['0'] &&
                        `url(${
                            subdomainUIstyling['0'] &&
                            subdomainUIstyling['0']['logo']
                        })`,
                }}
            >
                <Content
                    className={`dashboard-layout__content ${
                        !isMobile && collapsed && 'collapsed'
                    }`}
                >
                    <div className="dashboard-layout__content--wrapper">
                        <Switch>
                            {routes &&
                                routes.map(
                                    (
                                        { path, Page, exact, sub, hasSubRoute },
                                        idx,
                                    ) => {
                                        if (hasSubRoute) {
                                            return sub?.map(
                                                (
                                                    {
                                                        path: subPath,
                                                        Page: subPage,
                                                    },
                                                    idx,
                                                ) => (
                                                    <Route
                                                        key={idx}
                                                        exact={exact}
                                                        path={subPath}
                                                        component={subPage}
                                                    />
                                                ),
                                            );
                                        }
                                        return (
                                            <Route
                                                key={idx}
                                                exact={exact}
                                                path={path}
                                                component={Page}
                                            />
                                        );
                                    },
                                )}
                            {/* invalid url */}
                            {/* <Route key={190} component={ContestHome} /> */}
                        </Switch>
                    </div>
                </Content>
            </Layout>
            {isMobile && !collapsed && (
                <div className="outsideLayer" onClick={toggleSidebar}></div>
            )}
            <HomeDrawer />
            <DetailsDrawer />
        </Layout>
    );
};

export default Dashboard;
